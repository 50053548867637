import styled from "styled-components"
import { useState, useEffect } from "react"
import moment from "moment"
import _ from "lodash"
import {
  useFetchEntities,
  useAddNewCalendarNote,
  useUpdateCalendarNote,
  useDeleteCalendarNote,
} from "../../../reactQueryHooks"
import { storage } from "../../../firebase"
import DeleteIcon from "@mui/icons-material/Delete"
import FileDownloadIcon from "@mui/icons-material/FileDownload"
import IconButton from "@mui/material/IconButton"
import EditIcon from "@mui/icons-material/Edit"
import { Formik } from "formik"
import * as Yup from "yup"
import Button from "@mui/material/Button"
import CustomMaterialSwitch from "./CustomMaterialSwitch"
import CheckIcon from "@mui/icons-material/Check"
import {
  ref,
  uploadBytesResumable,
  listAll,
  getDownloadURL,
  deleteObject,
} from "firebase/storage"
import { useAxios } from "../../../axiosProvider"

//----------------------------------------------------------------

const EditButtonContainer = styled.div`
  display: none;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 5px;
  right: 5px;
  background-color: whitesmoke;
  border-radius: 50%;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: white;
  padding: 7px;
  border-radius: 5px;
  font-size: 12px;
  width: 100%;
  //light shadow
  box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.3);
  gap: 5px;
  position: relative;

  &:hover ${EditButtonContainer} {
    display: flex;
  }
`

const BodyContainer = styled.div`
  width: 100%;
  height: 100%;
  //border-bottom: 1px solid lightgray;
  //padding-bottom: 5px;
`
const DetailsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
`

const FilesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
`

const FileContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 3px;
  font-size: 10px;
`
const Textarea = styled.textarea`
  width: 100%;
  height: 100%;
  border: 1px solid rgb(250 250 250);
  resize: none;
  font-size: 12px;
  font-family: Arial, sans-serif;
  padding: 6px;
  border-radius: 5px;
  background-color: rgb(250 250 250);
  min-height: 200px;
  outline: none;
  //on focus
  &:focus {
    border: 1px solid #aab1e0;
  }
`
const NoteContainer = styled.div`
  white-space: pre-wrap;
  word-wrap: break-word;
  overflow-wrap: break-word;
  font-size: 12px;
  font-family: Arial, sans-serif;
  padding: 6px;
  border: 1px solid rgb(250 250 250);
  border-radius: 5px;
  background-color: rgb(250 250 250);
`

const InputContainer = styled.div`
  width: 100%;
`

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 5px;
`

//----------------------------------------------------------------

const CalendarNote = ({ note, refetch }) => {
  const { user } = useAxios()
  const [isEditing, setIsEditing] = useState(!note._id)
  const [isUploadingFile, setIsUploadingFile] = useState(false)
  const [uploadProgress, setUploadProgress] = useState(0)
  const [folderFilesUrls, setFolderFilesUrls] = useState([])

  const { data: entities } = useFetchEntities()
  const users = entities?.data?.staff
  const author = users?.find((user) => user._id === note.author_id)
  const isNew = !note._id

  const { mutateAsync: addNewCalendarNote, isLoading: isAdding } =
    useAddNewCalendarNote()
  const { mutateAsync: updateCalendarNote, isLoading: isUpdating } =
    useUpdateCalendarNote()
  const { mutateAsync: deleteCalendarNote, isLoading: isDeleting } =
    useDeleteCalendarNote()

  const isLoading = isAdding || isUpdating || isDeleting

  const userIsAdmin = user.isAdmin
  const userIsPermittedToEdit = userIsAdmin || user._id === note.author_id

  //----------------------------------------------------------------------------------

  useEffect(() => {
    const getFiles = async () => {
      try {
        const storageRef = ref(storage, `/calendar_notes/${note._id}`)
        const result = await listAll(storageRef)
        const urlPromises = result.items.map(async (imageRef) => {
          const downloadURL = await getDownloadURL(imageRef)
          const filename = imageRef.name
          return { url: downloadURL, filename }
        })
        return Promise.all(urlPromises)
      } catch (error) {
        console.error("Error occurred:", error)
      }
    }

    const getUrls = async () => {
      try {
        const urls = await getFiles()
        setFolderFilesUrls(urls)
      } catch (error) {
        console.error("Error occurred:", error)
        alert("Error fetching files")
      }
    }

    if (!isNew) {
      getUrls()
    }
  }, [note, isUploadingFile, isNew])

  const handleFilesUpload = async (files) => {
    if (files && files.length > 0) {
      try {
        setIsUploadingFile(true) // Set isUploadingFile to true while the files are being uploaded

        // Array to store all upload tasks
        const uploadPromises = []

        // Total bytes to track overall progress
        let totalBytes = 0

        // Calculate total bytes of all files
        for (let i = 0; i < files.length; i++) {
          totalBytes += files[i].size
        }

        // Variable to track total bytes uploaded
        let bytesUploaded = 0

        // Function to handle progress event
        const handleProgress = (snapshot, file) => {
          // Update bytesUploaded to track overall progress
          bytesUploaded += snapshot.bytesTransferred

          // Calculate overall progress based on bytesUploaded and totalBytes
          const overallProgress = (bytesUploaded / totalBytes) * 100

          // Set upload progress state to the overall progress
          setUploadProgress(overallProgress)
        }

        // Loop through each selected file and create upload tasks
        for (let i = 0; i < files.length; i++) {
          const file = files[i]
          const storageRef = ref(
            storage,
            `/calendar_notes/${note._id}/${file.name}`
          )

          // Create a resumable upload task for each file and push it to the uploadPromises array
          const uploadTask = uploadBytesResumable(storageRef, file)

          // Listen for state changes, such as progress updates, for each upload task
          uploadTask.on(
            "state_changed",
            (snapshot) => handleProgress(snapshot, file),
            (error) => {
              console.error("Error uploading file:", error)
              setIsUploadingFile(false) // Reset isUploadingFile state in case of an error
            }
          )

          // Push the upload task to the uploadPromises array
          uploadPromises.push(uploadTask)
        }

        // Wait for all upload tasks to complete using Promise.all
        await Promise.all(uploadPromises)

        // After all uploads are completed, reset isUploadingFile state
        setIsUploadingFile(false)
        //refetch(); // Refetch data if needed
      } catch (error) {
        console.error("Error uploading files:", error)
        setIsUploadingFile(false) // Reset isUploadingFile state in case of an error
      }
    }
  }

  const handleDeleteFile = async (filename) => {
    try {
      // Get the reference to the file in Firebase Storage
      const storageRef = ref(storage, `/calendar_notes/${note._id}/${filename}`)

      // Delete the file
      await deleteObject(storageRef)

      // Remove the file from the folderFilesUrls state
      setFolderFilesUrls((prevUrls) =>
        prevUrls.filter((file) => file.filename !== filename)
      )
    } catch (error) {
      console.error("Error deleting file:", error)
      alert("Error deleting file")
    }
  }

  const handleDeleteAllFiles = async () => {
    try {
      // Iterate over each file in folderFilesUrls and delete them
      await Promise.all(
        folderFilesUrls.map(async (file) => {
          // Get the reference to each file in Firebase Storage
          const storageRef = ref(
            storage,
            `/calendar_notes/${note._id}/${file.filename}`
          )

          // Delete the file
          await deleteObject(storageRef)
        })
      )

      // After deleting all files, clear the folderFilesUrls state
      setFolderFilesUrls([])
    } catch (error) {
      console.error("Error deleting files:", error)
      alert("Error deleting files")
    }
  }

  //----------------------------------------------------------------------------------

  return (
    <Formik
      validateOnMount
      enableReinitialize
      initialValues={{
        _id: note?._id,
        body: note?.body,
        date: note?.date,
        author_id: note?.author_id,
        public: note?.public,
      }}
      validationSchema={Yup.object({
        body: Yup.string().required("Note body is required"),
        date: Yup.string().required("Date required"),
        author_id: Yup.string().required("Author id is required"),
        public: Yup.boolean().required("Public or private required"),
      })}
      onSubmit={async (values, { setSubmitting, setFieldValue }) => {
        try {
          if (isNew) {
            await addNewCalendarNote(_.omit(values, "_id"))

            refetch()
          } else {
            await updateCalendarNote(values)
            setIsEditing(false)
            refetch()
          }
        } catch (error) {
          alert(error?.toString())
        }
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
        resetForm,
        isSubmitting,
        validateForm,
      }) => {
        return (
          <Container>
            <BodyContainer>
              {isEditing ? (
                <div>
                  <Textarea
                    autoFocus
                    value={values.body}
                    onChange={(e) => setFieldValue("body", e.target.value)}
                  />
                </div>
              ) : (
                <NoteContainer>{note.body}</NoteContainer>
              )}
            </BodyContainer>
            {folderFilesUrls.length > 0 && (
              <FilesContainer>
                {folderFilesUrls.map((file) => (
                  <FileContainer key={file.filename}>
                    <FileDownloadIcon
                      titleAccess="Download file"
                      sx={{
                        fontSize: 13,
                        color: "gray",
                      }}
                    />
                    <a
                      title={file.filename}
                      style={{
                        //wrap with ellipsis
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                      href={file.url}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {file.filename}
                    </a>
                    <button
                      onClick={() => handleDeleteFile(file.filename)}
                      style={{ color: "indianred" }}
                    >
                      {isEditing && (
                        <DeleteIcon
                          titleAccess="Delete file"
                          sx={{
                            fontSize: 15,
                          }}
                        />
                      )}
                    </button>
                  </FileContainer>
                ))}

                {isUploadingFile && (
                  <div>
                    <progress value={uploadProgress} max="100" />
                  </div>
                )}
              </FilesContainer>
            )}

            {isEditing && !isNew && (
              <InputContainer>
                <input
                  style={{
                    fontSize: "9px",
                    border: "none",
                  }}
                  type="file"
                  onChange={(e) => handleFilesUpload(e.target.files)}
                  multiple
                />
              </InputContainer>
            )}

            {isEditing && (
              <div
                title={
                  values.public
                    ? "Note will be shown to all users"
                    : "Note will be private"
                }
                style={{
                  width: "100%",
                }}
              >
                <CustomMaterialSwitch
                  label={values.public ? "Public" : "Private"}
                  checked={values.public}
                  set={(e) => {
                    setFieldValue("public", e.target.checked)
                  }}
                />
              </div>
            )}

            {!isNew && !isEditing && (
              <DetailsContainer>
                <div
                  style={{
                    color: "grey",
                    fontSize: "9px",
                    display: "flex",
                    gap: "5px",
                  }}
                >
                  <span>
                    {`${author?.name}, ${moment(note.createdAt).format(
                      "DD MMM YY HH:mm"
                    )}`}
                  </span>
                  {note.public ? (
                    <span
                      style={{
                        fontWeight: "bold",
                        color: "darkgreen",
                      }}
                    >
                      PUBLIC
                    </span>
                  ) : (
                    <span
                      style={{
                        fontWeight: "bold",
                        color: "rgb(197 105 44)",
                      }}
                    >
                      PRIVATE
                    </span>
                  )}
                </div>
              </DetailsContainer>
            )}
            {!isEditing && userIsPermittedToEdit && (
              <EditButtonContainer title="Edit note">
                <IconButton
                  disabled={isLoading}
                  onClick={() => {
                    setIsEditing(!isEditing)
                  }}
                >
                  <EditIcon color="primary" sx={{ fontSize: 15 }} />
                </IconButton>
              </EditButtonContainer>
            )}
            {isEditing && (
              <ButtonContainer>
                <Button
                  disabled={isLoading}
                  title="Delete note"
                  color="error"
                  variant="outlined"
                  onClick={async () => {
                    try {
                      if (
                        window.confirm(
                          "Are you sure you want to delete this note?"
                        )
                      ) {
                        await deleteCalendarNote(values._id)
                        await handleDeleteAllFiles()
                        refetch()
                      }
                    } catch (error) {
                      alert(error?.toString())
                    }
                  }}
                >
                  <DeleteIcon fontSize="small" />
                </Button>
                <Button
                  disabled={isLoading}
                  sx={{
                    flex: 1,
                  }}
                  title="Save note"
                  color="success"
                  variant="outlined"
                  onClick={() => {
                    console.log(errors)
                    //if there is an error
                    if (Object.keys(errors).length > 0) {
                      validateForm()
                      //alert the error
                      for (const [key, value] of Object.entries(errors)) {
                        alert(value)
                      }
                    } else {
                      handleSubmit()
                    }
                  }}
                >
                  <CheckIcon fontSize="small" />
                </Button>
              </ButtonContainer>
            )}
            {isEditing && (
              <Button
                size="small"
                disabled={isLoading}
                onClick={() => {
                  if (isNew) {
                    refetch()
                  }
                  setIsEditing(false)
                  resetForm()
                }}
              >
                CANCEL
              </Button>
            )}
          </Container>
        )
      }}
    </Formik>
  )
}

export default CalendarNote
