import styled from "styled-components";
import { useState, useEffect, useRef } from "react";
import { storage } from "../../../../firebase";
import {
  ref,
  listAll,
  getDownloadURL,
  uploadBytesResumable,
  deleteObject,
} from "firebase/storage";
import { useAxios } from "../../../../axiosProvider";

//----------------------------------------------------------------

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin: 10px 14px;
`;

const LabelContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  color: dodgerblue;
  span {
    font-size: 12px;
    font-weight: bold;
  }
`;

const DeleteIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  i {
    font-size: 13px;
    color: indianred;
    display: none;
  }
`;

const FileNameRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  &:hover {
    .fa-trash-alt {
      display: block;
    }
    color: dodgerblue;
  }
`;

const FilenameDownIconContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  font-size: 13px;
  cursor: pointer;
  user-select: none;

  i {
    font-size: 12px;
  }
`;

const FileInputContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: 5px;
`;

//----------------------------------------------------------------

const ServiceLogFiles = ({ logId }) => {
  const [isFetchingFiles, setIsFetchingFiles] = useState(true);
  const [isUploadingFile, setIsUploadingFile] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [folderFilesUrls, setFolderFilesUrls] = useState([]);
  const fileInputRef = useRef(null)

  const { user } = useAxios();
  const userIsAdmin = user.isAdmin
  const userIsPermittedToHandleServiceRecords = user.permissions.handleServiceRecords || userIsAdmin;



  useEffect(() => {
    const getFiles = async () => {
      try {
        const storageRef = ref(storage, `/service_logs/${logId}`);
        const result = await listAll(storageRef);
        const urlPromises = result.items.map(async (imageRef) => {
          const downloadURL = await getDownloadURL(imageRef);
          const filename = imageRef.name;
          return { url: downloadURL, filename };
        });
        return Promise.all(urlPromises);
      } catch (error) {
        console.error("Error occurred:", error);
      }
    };

    const getUrls = async () => {
      try {
        setIsFetchingFiles(true);
        const urls = await getFiles();
        setFolderFilesUrls(urls);
      } catch (error) {
        console.error("Error occurred:", error);
        alert("Error fetching files");
      } finally {
        setIsFetchingFiles(false);
      }
    };

    getUrls();
  }, [logId]);

  // Handle file upload using uploadBytesResumable
  const handleFileUpload = async (file) => {
    if (file) {
      try {
        setIsUploadingFile(true); // Set isUploadingFile to true while the file is being uploaded

        // Get the reference to the file in Firebase Storage
        const storageRef = ref(storage, `/service_logs/${logId}/${file.name}`);

        // Create a resumable upload task
        const uploadTask = uploadBytesResumable(storageRef, file);

        // Listen for state changes, such as progress updates
        uploadTask.on(
          "state_changed",
          (snapshot) => {
            // Calculate the upload progress percentage
            const progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            setUploadProgress(progress);
          },
          (error) => {
            console.error("Error uploading file:", error);
            setIsUploadingFile(false); // Reset isUploadingFile state in case of an error
          },
          () => {
            // Upload completed
            setIsUploadingFile(false); // Reset isUploadingFile state after successful upload
          }
        );

        // Await the completion of the upload
        const uploadTaskSnapshot = await uploadTask;

        // Get the download URL of the uploaded file
        const downloadURL = await getDownloadURL(uploadTaskSnapshot.ref);

        // Add the uploaded file URL and filename to the folderFilesUrls state
        setFolderFilesUrls((prevUrls) => [
          ...prevUrls,
          { url: downloadURL, filename: file.name },
        ]);
      } catch (error) {
        console.error("Error uploading file:", error);
        setIsUploadingFile(false); // Reset isUploadingFile state in case of an error
      }
    }
  };

  const handleDeleteFile = async (filename) => {
    try {
      // Get the reference to the file in Firebase Storage
      const storageRef = ref(storage, `/service_logs/${logId}/${filename}`);

      // Delete the file
      await deleteObject(storageRef);

      // Remove the file from the folderFilesUrls state
      setFolderFilesUrls((prevUrls) =>
        prevUrls.filter((file) => file.filename !== filename)
      );
    } catch (error) {
      console.error("Error deleting file:", error);
      alert("Error deleting file");
    }
  };

  const noFolderOrFiles = !folderFilesUrls || folderFilesUrls.length === 0;

  return (
    <Container
      initial={{ opacity: 0, y: -3 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -3 }}
      transition={{ duration: 0.2 }}
    >
      <LabelContainer>
        <span>SERVICE RECORD FILES</span>
      </LabelContainer>
      {!isFetchingFiles && noFolderOrFiles ? (
        <span
          style={{
            fontWeight: "bold",
            fontSize: "13px",
            color: "grey",
          }}
        >
          EMPTY FOLDER
        </span>
      ) : (
        folderFilesUrls.map((file, index) => {
          return (
            <FileNameRow key={index}>
              <FilenameDownIconContainer
                onClick={() => {
                  window.open(file.url, "_blank");
                }}
              >
                <span>{file.filename}</span>
                <i className="fas fa-download"></i>
              </FilenameDownIconContainer>
              <DeleteIconContainer
                onClick={() => {
                  if (userIsPermittedToHandleServiceRecords) {
                    if (window.confirm("Delete this file?")) {
                      handleDeleteFile(file.filename);
                    }
                  } else {
                    alert('You do not have permission to handle service records')
                  }
                }}
              >
                <i className="fas fa-trash-alt"></i>
              </DeleteIconContainer>
            </FileNameRow>
          );
        })
      )}

      {isFetchingFiles && (
        <span
          style={{
            fontSize: "11px",
          }}
        >
          FETCHING FILES...
        </span>
      )}

      <FileInputContainer>
        {isUploadingFile ? (
          <span
            style={{
              fontWeight: "bold",
              fontSize: "13px",
            }}
          >
            {uploadProgress.toFixed(0)}% Uploading...
          </span>
        ) : (
          <input
              ref={fileInputRef}
            style={{
              fontSize: "12px",
            }}
            type="file"
            accept="*"
              onClick={(e) => {
                if (userIsPermittedToHandleServiceRecords) {
                  fileInputRef.current.click();
                } else {
                  alert('You do not have permission to handle service records')
                  e.preventDefault()
                }
              }}
            onChange={(e) => handleFileUpload(e.target.files[0])}
          />
        )}
      </FileInputContainer>
    </Container>
  );
};

export default ServiceLogFiles;
