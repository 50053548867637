import styled from "styled-components";
import Header from "../components/header/Header";
import BookingPortalLogsContent from "../components/bookings_manager/booking_portal_logs/content/BookingPortalLogsContent";
import { useNavigate } from "react-router-dom";

//------------------------------------------------------------------------

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const ContentContainer = styled.div`
  flex: 1;
  display: flex;
  overflow: hidden;
`;

//------------------------------------------------------------------------

const BookingPortalLogs = () => {
  const navigate = useNavigate();
  return (
    <Container>
      <Header
        text={"Booking Portal Logs"}
        redirectFn={() => {
          navigate("/home");
        }}
      />
      <ContentContainer>
        <BookingPortalLogsContent />
      </ContentContainer>
    </Container>
  );
};

export default BookingPortalLogs;
