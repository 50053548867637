import { ContentItem, ContentItemContent, ContentItemTitle } from './styled';

//-------------------------------------------

const SortableTableInfoRevenue = ({ tourGroup }) => {

    //accumulate all tourGroup.bookings --> booking.total_paid

    const bookings = tourGroup?.bookings;
    const revenue = bookings?.reduce((acc, booking) => {
        let revenue = booking?.total_paid;
        if (booking.cancelled) revenue = 0;
        return acc + revenue;
    }, 0);


    return (
      <ContentItem
        title="Group revenue"
        //a fade in with a little left to right movement
        initial={{ opacity: 0, x: -5 }}
        animate={{ opacity: 1, x: 0 }}
        exit={{ opacity: 0, x: -5 }}
        transition={{ duration: 0.25, delay: 0.2 }}
      >
        <ContentItemTitle>Revenue</ContentItemTitle>
        <ContentItemContent>
          <i className="fa-solid fa-euro-sign"></i>
          <span>{revenue?.toFixed(2) || ""}</span>
        </ContentItemContent>
      </ContentItem>
    );
};

export default SortableTableInfoRevenue;