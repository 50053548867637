import { createGlobalStyle } from "styled-components";

// $white: #fff !default;
// $gray-100: #f8f9fc !default;
// $gray-200: #f4f4f4 !default;
// $gray-300: #dddfeb !default;
// $gray-400: #d1d3e2 !default;
// $gray-500: #b7b9cc !default;
// $gray-600: #858796 !default;
// $gray-700: #6e707e !default;
// $gray-800: #5a5c69 !default;
// $gray-900: #3a3b45 !default;
// $black: #000 !default;
// $blue: #4e73df !default;
// $indigo: #6610f2 !default;
// $purple: #6f42c1 !default;
// $pink: #e83e8c !default;
// $red: #e74a3b !default;
// $orange: #fd7e14 !default;
// $yellow: #f6c23e !default;
// $green: #1cc88a !default;
// $teal: #20c9a6 !default;
// $cyan: #36b9cc !default;

// // Custom Colors
// $brand-google: #ea4335 !default;
// $brand-facebook: #3b5998 !default;

const GlobalStyles = createGlobalStyle`

html{
  max-height: 100vh;
  min-height: 100vh;
  display: flex;
  /* scroll-behavior: smooth;
  overflow: scroll; */
//overflow:hidden;

  .popover-arrow::after {
    display: none;
    opacity: 0;
  }

  .popover-arrow::before {
    display: none;
    opacity: 0;
  }
   overflow-x: hidden;
}
body{
font-family: 'Poppins', sans-serif;
//background-color: ${(props) => props.theme.colors.lightblue};
//background-color: rgb(220 218 220) !important;
  background-image: radial-gradient(#B6B6B6 1px, rgb(231 231 231) 1px);
  background-size: 20px 20px;
  flex: 1;
  display: flex;
  //overflow-x: hidden --> vertical overscrolling effect + FIX ISSUE IN MOBILE WHERE NOT ALL PAGE IS VISIBLE
  overflow-x: hidden;
}
#root{
  flex: 1;
  display: flex;
//padding: 5px;
//media queries
@media (max-width: 250px) {
  font-size: 10px;
}

  /* scroll-behavior: smooth;
  overflow-y: scroll; */
  overflow-x: hidden;
}

button {
  all: unset;
  cursor: pointer;
  outline: none !important;
}

input { 
  outline: none !important;
  border: none ;
}
.react-datepicker-popper {
    z-index: 9999 !important;
}

.mapboxgl-popup {
  padding: 0;
  margin: 0;
  
}
.mapboxgl-popup-content {
  padding: 0;
  margin: 0;
   box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.3);
   //after 
   background-color: rgba(255, 255, 255, 0.6);
   //text shadow
    //text-shadow: 0 0 5px rgba(255, 255, 255, 0.6);
}

.rdt_TableRow {
  border-bottom: none !important;
}

.rdt_Table {
margin: 0px !important;
}

.MuiTableBody-root{
  overflow-x: hidden;
  height: 100%;
}
.MuiPaper-root{ 
overflow-x: hidden;
height: 100%;
}
.MuiTable-root{
overflow-x: hidden;
height: 100%;
}
`;

export default GlobalStyles;
