import moment from "moment";
import styled from "styled-components";
import { Formik } from "formik";
import { motion } from "framer-motion";
import * as Yup from "yup";
import { ThreeDots } from "react-loader-spinner";
import { useFetchEntities, useSendOlpEmail } from "../../../../reactQueryHooks";

//---------------------------------------------------------

const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(100, 100, 100, 0.8);
  border-radius: 5px;
  padding: 5px;
  overflow: hidden;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
`;

const Container = styled(motion.div)`
  border-radius: 5px;
  padding: 5px;
  height: 100%;
  max-width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
  overflow: hidden;
`;

const ContentContainer = styled.div`
  overflow-y: auto;
  padding: 0 5px;
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
  padding: 5px;
  background-color: white;
  border-radius: 5px;
`;

const Button = styled.button`
  border: none;
  outline: none;
  flex: 1;
  border-radius: 4px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: lab(100 0 -0.03);
  padding: 0 5px;
`;

const AddNameButton = styled.button`
  border: none;
  outline: none;
  flex: 1;
  border-radius: 4px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: white;
  padding: 0 5px;
  background-color: #8297b5;
`;

const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
  min-height: 35px;
`;

const IconContainer = styled.div`
  min-height: 35px;
  width: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: whitesmoke;
  cursor: pointer;
  border-radius: 4px;
  &:hover {
    i {
      color: indianred;
    }
  }
`;

const Select = styled.select`
  flex: 1;
  border: none;
  outline: none;
  height: 100%;
  width: 100%;
  background-color: whitesmoke;
`;

const InputContainer = styled.div`
  min-height: 35px;
  flex: 1;
  border-radius: 4px;
  background-color: whitesmoke;
`;

const Input = styled.input`
  background-color: transparent;
  text-transform: uppercase;
  width: 100%;
  height: 100%;
  padding: 0 5px;
`;

const CloseButton = styled.button`
  border: none;
  color: white;
  height: 35px;
  width: 35px;
  border-radius: 4px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: absolute;
  top: 5px;
  right: 5px;
  i {
    font-size: 20px;
  }
  &:hover {
    i {
      color: indianred;
    }
  }
`;

//---------------------------------------------------------

const SendOlpMailsModal = ({ close, data }) => {
  const { data: entities } = useFetchEntities();
  const drivers = entities?.data?.staff.filter((staff) =>
    staff.roles.some((role) => role._id === "636d1318f2535b158a9acd08")
  );
  const vehicles = entities?.data?.vehicles;
  const { isLoading: isSendOlpEmailLoading, mutateAsync: sendOlpEmail } =
    useSendOlpEmail();

  //-----------------------------------

  return (
    <Modal>
      <CloseButton onClick={close}>
        <i className="fas fa-times"></i>
      </CloseButton>
      <Formik
        enableReinitialize
        validateOnMount
        initialValues={{
          DATE: data?.date ? moment(data.date).format("YYYY-MM-DD") : "",
          TIME: data?.time || "",
          DRIVER_NAME: data?.driver_name || "",
          DRIVER_ID: data?.driver_id || "",
          ID_NUMBER: data?.driver_id_number || "",
          VEHICLE_ID: data?.vehicle_id || "",
          PLATE_NUMBER: data?.vehicle_plate || "",
          VEHICLE_TYPE: data?.vehicle_type || "",
          CLIENT_NAMES: data?.client_names || [],
          PARTICIPANTS: data?.client_count || "",
          TERMINAL: "",
          SHIP_NAME: "",
        }}
        validationSchema={Yup.object({
          DATE: Yup.string().required(),
          TIME: Yup.string().required(),
          DRIVER_ID: Yup.string().required(),
          DRIVER_NAME: Yup.string().required(),
          ID_NUMBER: Yup.string().required(),
          PLATE_NUMBER: Yup.string().required(),
          VEHICLE_TYPE: Yup.string().required(),
          CLIENT_NAMES: Yup.array().required(),
          PARTICIPANTS: Yup.string().required(),
          TERMINAL: Yup.string().required(),
          SHIP_NAME: Yup.string().required(),
        })}
        onSubmit={async (values, { setSubmitting, setFieldValue }) => {
          await sendOlpEmail({
            ...values,
            CLIENT_NAMES: values.CLIENT_NAMES.map((name) => name.toUpperCase()),
            DRIVER_NAME: values.DRIVER_NAME.toUpperCase(),
            PLATE_NUMBER: values.PLATE_NUMBER.toUpperCase(),
            VEHICLE_TYPE: values.VEHICLE_TYPE.toUpperCase(),
            DATE: moment(values.DATE).format("DD/MM/YYYY"),
          });
          close();
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          resetForm,
          isSubmitting,
          validateForm,
        }) => {
          return (
            <Container
              initial={{ opacity: 0, scale: 0 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{
                opacity: { duration: 0.2 },
                scale: {
                  //type: "spring",
                  // stiffness: 300, // Adjust stiffness for more/less bounce
                  // damping: 20, // Adjust damping for faster/slower settle
                  // duration: 0.02,
                },
              }}
            >
              <ContentContainer>
                <FormContainer>
                  <RowContainer>
                    <InputContainer>
                      <Input
                        type="date"
                        name="DATE"
                        value={values.DATE}
                        onChange={handleChange}
                      />
                    </InputContainer>
                    <InputContainer>
                      <Input
                        type="time"
                        name="TIME"
                        value={values.TIME}
                        onChange={handleChange}
                      />
                    </InputContainer>
                  </RowContainer>
                  <RowContainer>
                    <InputContainer>
                      <Select
                        name="Driver"
                        value={values.DRIVER_ID}
                        onChange={(e) => {
                          if (e.target.value === "reset") {
                            setFieldValue("DRIVER_ID", "");
                            setFieldValue("DRIVER_NAME", "");
                            setFieldValue("ID_NUMBER", "");
                          } else {
                            const id_num = drivers.find(
                              (driver) => driver._id === e.target.value
                            ).id_number;
                            const name = drivers.find(
                              (driver) => driver._id === e.target.value
                            ).name;

                            setFieldValue("DRIVER_NAME", name);
                            setFieldValue("DRIVER_ID", e.target.value);
                            setFieldValue("ID_NUMBER", id_num);
                          }
                        }}
                      >
                        <option value="reset">Select driver</option>
                        {drivers?.map((driver) => (
                          <option key={driver._id} value={driver._id}>
                            {driver.name}
                          </option>
                        ))}
                      </Select>
                    </InputContainer>
                    <InputContainer>
                      <Input
                        type="text"
                        placeholder="DRIVER ID NUMBER"
                        onChange={handleChange}
                        value={values.ID_NUMBER}
                      />
                    </InputContainer>
                  </RowContainer>
                  <RowContainer>
                    <InputContainer>
                      <Select
                        name="Vehicle"
                        value={values.VEHICLE_ID}
                        onChange={(e) => {
                          if (e.target.value === "reset") {
                            setFieldValue("VEHICLE_ID", "");
                            setFieldValue("VEHICLE_TYPE", "");
                            setFieldValue("PLATE_NUMBER", "");
                          } else {
                            const type = vehicles.find(
                              (vehicle) => vehicle._id === e.target.value
                            ).type;
                            const plate = vehicles.find(
                              (vehicle) => vehicle._id === e.target.value
                            ).plate;
                            setFieldValue("VEHICLE_ID", e.target.value);
                            setFieldValue("PLATE_NUMBER", plate.toUpperCase());
                            setFieldValue("VEHICLE_TYPE", type.toUpperCase());
                          }
                        }}
                      >
                        <option value="reset">Select vehicle</option>
                        {vehicles?.map((vehicle) => (
                          <option key={vehicle._id} value={vehicle._id}>
                            {vehicle.plate}
                          </option>
                        ))}
                      </Select>
                    </InputContainer>
                    <InputContainer>
                      <Input
                        type="text"
                        placeholder="VEHICLE TYPE"
                        value={values.VEHICLE_TYPE}
                        onChange={(e) =>
                          setFieldValue(
                            "VEHICLE_TYPE",
                            e.target.value.toUpperCase()
                          )
                        }
                      />
                    </InputContainer>
                  </RowContainer>
                  <RowContainer>
                    <InputContainer>
                      <Input
                        type="text"
                        placeholder="TERMINAL"
                        value={values.TERMINAL}
                        onChange={(e) =>
                          setFieldValue(
                            "TERMINAL",
                            e.target.value.toUpperCase()
                          )
                        }
                      />
                    </InputContainer>
                    <InputContainer>
                      <Input
                        type="text"
                        placeholder="SHIP NAME"
                        value={values.SHIP_NAME}
                        onChange={(e) =>
                          setFieldValue(
                            "SHIP_NAME",
                            e.target.value.toUpperCase()
                          )
                        }
                      />
                    </InputContainer>
                  </RowContainer>
                  <RowContainer>
                    <InputContainer>
                      <Input
                        type="number"
                        placeholder="PAX"
                        min={1}
                        value={values.PARTICIPANTS}
                        onChange={(e) =>
                          setFieldValue("PARTICIPANTS", e.target.value)
                        }
                      />
                    </InputContainer>
                  </RowContainer>
                  <RowContainer>
                    <AddNameButton
                      onClick={() => {
                        const newNames = [...values.CLIENT_NAMES];
                        newNames.push("");
                        setFieldValue("CLIENT_NAMES", newNames);
                      }}
                    >
                      ADD +1 NAME
                    </AddNameButton>
                  </RowContainer>

                  {values.CLIENT_NAMES.map((name, index) => {
                    return (
                      <RowContainer key={index}>
                        <InputContainer>
                          <Input
                            type="text"
                            placeholder={`CLIENT NAME #${index + 1}`}
                            value={name}
                            onChange={(e) => {
                              const newNames = [...values.CLIENT_NAMES];
                              newNames[index] = e.target.value.toUpperCase();
                              setFieldValue("CLIENT_NAMES", newNames);
                            }}
                          />
                        </InputContainer>
                        <IconContainer
                          onClick={() => {
                            const newNames = [...values.CLIENT_NAMES];
                            newNames.splice(index, 1);
                            setFieldValue("CLIENT_NAMES", newNames);
                          }}
                        >
                          <i className="fas fa-times"></i>
                        </IconContainer>
                      </RowContainer>
                    );
                  })}

                  <RowContainer>
                    <Button
                      type="button"
                      style={{
                        backgroundColor: "rgb(85,165,85)",
                      }}
                      onClick={() => {
                        validateForm().then((errors) => {
                          const itHasErrors = Object.keys(errors).length > 0;
                          if (itHasErrors) {
                            console.log(errors);
                            alert("Please fill all fields");
                          } else {
                            if (window.confirm("Are you sure?")) {
                              handleSubmit();
                            }
                          }
                        });
                      }}
                    >
                      {isSendOlpEmailLoading ? (
                        <ThreeDots
                          height="30"
                          width="30"
                          radius="9"
                          color="whitesmoke"
                          ariaLabel="three-dots-loading"
                          wrapperStyle={{}}
                          wrapperClassName=""
                          visible={true}
                        />
                      ) : (
                        "SEND EMAIL"
                      )}
                    </Button>
                  </RowContainer>
                </FormContainer>
              </ContentContainer>
            </Container>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default SendOlpMailsModal;