import styled from "styled-components";
import DatePicker from "react-datepicker";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";

//----------------------------------------------------------

const Container = styled.div`
  display: flex;
  flex-direction: row;
  input {
    text-align: center;
    height: 30px;
    background-color: white;
    border-radius: 5px;
    border: 1px solid #f1f1f1;
    font-size: 12px;
    //shadow
    //box-shadow: 0 0 5px rgba(0, 0, 0, 0.07);

    font-family: "Roboto", sans-serif;
    cursor: default;
    //box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.1);
  }

  .react-datepicker-wrapper:first-child {
    margin-right: 10px;
  }
`;

const DateSelection = ({ startDate, endDate, setStartDate, setEndDate }) => {
  return (
    <Container>
      <DatePicker
        //portalId="root-portal" //id for css
        selected={startDate}
        onChange={(date) => {
          if (endDate) {
            const start_date = moment(date).format("YYYY-MM-DD");
            const end_date = moment(endDate).format("YYYY-MM-DD");
            const dateIsEarlierOrEqual =
              moment(start_date).isSameOrBefore(end_date);

            if (dateIsEarlierOrEqual) {
              setStartDate(date);
            } else {
              alert("Start date must be earlier or equal to end date");
            }
          } else {
            setStartDate(date);
          }
        }}
        //selectsRange //selects start and end date at the same input
        dateFormat={"dd MMM yyyy"}
      />
      <DatePicker
        //portalId="root-portal" //id for css
        selected={endDate}
        onChange={(date) => {
          //return date if later than start date
          const start_date = moment(startDate).format("YYYY-MM-DD");
          const end_date = moment(date).format("YYYY-MM-DD");
          const dateIsLaterOrEqual = moment(end_date).isSameOrAfter(start_date);

          if (dateIsLaterOrEqual) {
            setEndDate(date);
          } else {
            alert("End date must be later or equal to start date");
          }
        }}
        //selectsRange
        dateFormat={"dd MMM yyyy"}
      />
    </Container>
  );
};

export default DateSelection;
