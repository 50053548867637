import styled from "styled-components"
import { useEffect, useState } from "react"
import { motion } from "framer-motion"
import { ThreeDots } from "react-loader-spinner"
import { useNavigate } from "react-router-dom"
import { Formik } from "formik"
import * as Yup from "yup"
import { TextField } from "@mui/material"
import Button from "@mui/material/Button"
import { RotatingLines } from "react-loader-spinner"
import { useLoginJWTAuth, useFetchEntities } from "../../reactQueryHooks"
const logo = require("./gg_logo_only_text2.png")

//------------------------------------------------------------------------

const formSchema = Yup.object().shape({
  username: Yup.string().required("Username is required"),
  password: Yup.string().required("Password is required"),
})

const Container = styled.div`
  flex: 1;
  display: flex;
`

const ContentContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`

const LoginFormContainer = styled(motion.div)`
  display: ${({ shown }) => (shown === "shown" ? "flex" : "none")};
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 200px;
  background-color: white;
  padding: 15px;
  border-radius: 5px;
  box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.1);
`

const Logo = styled.img`
  width: 80%;
  object-fit: contain;
  margin-bottom: 10px;
`

const CopyRight = styled.div`
  position: absolute;
  bottom: 10px;
  right: 10px;
  font-size: 10px;
  color: #c1c2c0;
`

//------------------------------------------------------------------------

const LoginContent = () => {
  const { loginJWTAuth } = useLoginJWTAuth()
  const [loginOk, setLoginOk] = useState(false)
  const { isLoading, isFetched } = useFetchEntities()
  const navigate = useNavigate()

  useEffect(() => {
    if (loginOk && isFetched) {
      navigate("/home")
    }
  }, [isFetched, navigate, loginOk])

  return (
    <Container>
      <ContentContainer>
        <Formik
          initialValues={{
            username: "",
            password: "",
          }}
          validationSchema={formSchema}
          onSubmit={async (values, { setSubmitting }) => {
            try {
              setSubmitting(true)
              await loginJWTAuth(values)
              setSubmitting(false)
              setLoginOk(true)
            } catch (error) {
              console.log("error", error)
              alert(error?.response?.data)
              setSubmitting(false)
              setLoginOk(false)
            }
          }}
        >
          {({ values, errors, handleChange, handleSubmit, isSubmitting }) => {
            return (
              <LoginFormContainer
                shown={!loginOk ? "shown" : "hidden"}
                initial={{ opacity: 0, scale: 0.5 }}
                animate={{ opacity: 1, scale: 1 }}
                exit={{ opacity: 0 }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    const itHasErrors = Object.keys(errors).length > 0
                    if (itHasErrors) {
                      console.log("errors", errors)
                      alert(Object.values(errors).join(", "), "")
                    } else {
                      handleSubmit()
                    }
                  }
                }}
              >
                <Logo src={logo} />

                <TextField
                  variant="filled"
                  label="Username"
                  name="username"
                  onChange={handleChange}
                  value={values.username}
                  size="small"
                  slotProps={{
                    inputLabel: {
                      shrink: true,
                    },
                    htmlInput: {
                      style: { fontSize: 13 },
                    },
                  }}
                  error={errors.username ? true : false}
                />
                <TextField
                  variant="filled"
                  label="Password"
                  type="password"
                  name="password"
                  onChange={handleChange}
                  value={values.password}
                  size="small"
                  slotProps={{
                    inputLabel: {
                      shrink: true,
                    },
                    htmlInput: {
                      style: { fontSize: 13 },
                    },
                  }}
                  error={errors.password ? true : false}
                />
                <Button
                  type="submit"
                  onClick={() => {
                    const itHasErrors = Object.keys(errors).length > 0
                    if (itHasErrors) {
                      console.log("errors", errors)
                      alert(Object.values(errors).join(", "), "")
                    } else {
                      handleSubmit()
                    }
                  }}
                  variant="outlined"
                  fullWidth
                  size="large"
                  style={{
                    border: "1px solid #f3f3f3",
                    height: "45px",
                  }}
                >
                  {!isSubmitting && (
                    <i
                      style={{
                        color: "darkgreen",
                      }}
                      className="fa-solid fa-check"
                    />
                  )}
                  {isSubmitting && (
                    <RotatingLines
                      width="17"
                      strokeColor="grey"
                      //speed
                      strokeWidth={4}
                    />
                  )}
                </Button>
              </LoginFormContainer>
            )
          }}
        </Formik>

        {loginOk && isLoading && (
          <ThreeDots
            height="50"
            width="50"
            radius="9"
            color="grey"
            ariaLabel="three-dots-loading"
            wrapperStyle={{}}
            wrapperClassName=""
            visible={true}
          />
        )}
        <CopyRight>
          &copy; This software is property of Getaways Greece 2024
        </CopyRight>
      </ContentContainer>
    </Container>
  )
}

export default LoginContent
