import { useState, useRef, createContext } from "react"
import styled from "styled-components"
import FilesList from "./FilesList"
import AddFileModal from "./modal/AddFileModal"
import { useAxios } from "@/axiosProvider"
import { Button } from "@mui/material"

//----------------------------------------------------------------

const Container = styled.div`
  display: flex;
  flex: 1 0 450px;
  max-height: 500px;
  flex-direction: column;
  gap: 7px;
  background-color: white;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
  padding: 7px;
  border-radius: 5px;
  user-select: none;
  width: 100%;
  height: 100%;
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  //font-weight: bold;
  color: rgb(35, 139, 243);
`

const ContentContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 7px;
  overflow-y: auto;
  position: relative;
`

const AddButtonContainer = styled.div`
  width: 100%;
  min-height: 40px;
  border-radius: 5px;
`

//------------------------------------------------------------------------

export interface IFile {
  _id: string
  url: string
  name: string
  description: string
  type: string
  size: number
  data: File
}

export const FilesContext = createContext<any>(null)

const FilesOptions = () => {
  const [filesData, setFilesData] = useState<IFile[]>([])
  const [filesManagerModalShown, setFilesManagerModalShown] = useState(false)
  const filesListRef = useRef<{ reset: () => void } | null>()
  const { user } = useAxios()
  const userIsAdmin = user.isAdmin
  const userIsPermittedToUploadFiles =
    user.permissions.uploadFiles || userIsAdmin

  return (
    <FilesContext.Provider
      value={{
        filesData,
        setFilesData,
        filesManagerModalShown,
        setFilesManagerModalShown,
      }}
    >
      <Container>
        <TitleContainer>Files</TitleContainer>
        <ContentContainer>
          <FilesList ref={filesListRef} />
          <AddButtonContainer>
            <Button
              sx={{
                width: "100%",
              }}
              variant="outlined"
              color="success"
              onClick={() => {
                if (userIsPermittedToUploadFiles) {
                  setFilesData([])
                  setFilesManagerModalShown(true)
                } else {
                  alert("You don't have permission to add/edit files")
                }
              }}
              title="Add File"
            >
              OPEN FILE MANAGER
            </Button>
          </AddButtonContainer>
        </ContentContainer>
        {filesManagerModalShown && (
          <AddFileModal
            close={() => {
              setFilesData([])
              setFilesManagerModalShown(false)
              filesListRef.current?.reset()
            }}
          />
        )}
      </Container>
    </FilesContext.Provider>
  )
}

export default FilesOptions
