import { useEffect, useState } from "react";
import QRCodeSVG from "qrcode.react";
import axios from "axios";
// import moment from "moment";
import styled from "styled-components";

//-------------------------------------------------------------------------------------

const Container = styled.div`
  flex: 1;
  margin-top: 3px;

  .icon {
    font-size: 14px;
    color: #636363;
  }

  .spinner {
    animation: spin 1s linear infinite;
    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
`;

//-------------------------------------------------------------------------------------

const CustomQrCode = ({ phoneNumberOrEmail, messageBody, platform }) => {
  const [shortUrl, setShortUrl] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const smsUrl = `sms:${phoneNumberOrEmail}&body=${messageBody
    .split("\n")
    .map((line) => encodeURIComponent(line))
    .join("%0A")}`;

  const whatsappUrl = `whatsapp://send?text=${messageBody
    .split("\n")
    .map((line) => encodeURIComponent(line))
    .join("%0A")}&phone=+${phoneNumberOrEmail}&abid=+${phoneNumberOrEmail}`;
  const viberUrl = `viber://chat?number=${phoneNumberOrEmail}&draft=${messageBody
    .split("\n")
    .map((line) => encodeURIComponent(line))
    .join("%0A")}`;
  const mailtoUrl = `mailto:${phoneNumberOrEmail}?subject=${encodeURIComponent(
    "GETAWAYS GREECE - IMPORTANT INFORMATION ABOUT YOUR TOUR"
  )}&body=${messageBody
    .split("\n")
    .map((line) => encodeURIComponent(line))
    .join("%0A")}`;

  //tinyurl nospipian@gmail.com
  //ClFkyfZpDfhke3kXjpyAtQSpYNZN6fB3WuBxkXlXMRfVprZGMDsYvoVceMEF
  //tinyurl getaways.magonezos@gmail.com
  //JvCdQyTRcXDzpd34NeiTkzVjtAv8t8AWJoRLB4oyey6MGTHnSwCS6FYvEUnr

  // const expiresAt = moment().add(2, "minutes").format("YYYY-MM-DD HH:mm:ss");
  // console.log("expiresAt", expiresAt);

  useEffect(() => {
    const shortenUrl = async () => {
      try {
        setIsLoading(true);
        //shorten url with tinyurl
        const response = await axios.post(
          "https://api.tinyurl.com/create?api_token=JvCdQyTRcXDzpd34NeiTkzVjtAv8t8AWJoRLB4oyey6MGTHnSwCS6FYvEUnr",
          {
            url:
              platform === "sms"
                ? smsUrl
                : platform === "whatsapp"
                ? whatsappUrl
                : platform === "viber"
                ? viberUrl
                : mailtoUrl,
            //expires_at: expiresAt, using expiration date appears restricted
          }
        );

        setShortUrl(response.data.data.tiny_url);
        // console.log("shortUrl", response.data.data.tiny_url);
        setIsLoading(false);
      } catch (error) {
        console.error("Error shortening URL:", error);
        setIsLoading(false);
      }
    };

    shortenUrl();
  }, [smsUrl, platform, whatsappUrl, viberUrl, mailtoUrl]);

  return (
    <Container>
      {isLoading ? (
        <i
          style={{
            fontSize: "15px",

            color: "white",
          }}
          className="fa-solid fa-circle-notch spinner icon"
        ></i>
      ) : (
        <QRCodeSVG
          size={100}
          style={{
            height: "100%",
            maxWidth: "100%",
            width: "100%",
            backgroundColor: "white",
            padding: "3px",
            borderRadius: "5px",
          }}
          value={shortUrl}
          // viewBox={`0 0 256 256`}
        />
      )}
    </Container>
  );
};

export default CustomQrCode;

// import Barcode from "react-barcode";

// const CustomQrCode = ({ phoneNumber, messageBody }) => {
//   const smsUrl = `sms:${phoneNumber}&body=${encodeURIComponent(messageBody)}`;

//   return (
//     <Barcode
//       value={smsUrl}
//       format="CODE128"
//       width={2}
//       height={80}
//       fontSize={5}
//       displayValue={false}
//     />
//   );
// };

// export default CustomQrCode;
