import { useState, useEffect } from "react";
import styled from "styled-components";
import BarLoader from "react-spinners/BarLoader";
import { useAxios } from "../../../../axiosProvider";

//----------------------------------------------------------------

const Wrapper = styled.div`
  display: flex;
  gap: 6px;
  height: 100%;
  max-height: 30px;

`;

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 4px;
`;

const Input = styled.input`
  background-color: transparent;
  border: none;
  font-size: 13px;
  padding: 0 3px;
  max-width: 70px;
  &:focus {
    outline: none;
  }
  text-transform: ${({ upperCase }) => (upperCase ? "uppercase" : "none")};
`;

const OkButton = styled.button`
  background-color: #4caf50;
  border: none;
  color: white;
  height: 100%;
  width: 27px;
  text-align: center;
  text-decoration: none;
  font-size: 12px;
  border-radius: 3px;
  cursor: pointer;
  &:hover {
    filter: brightness(0.9);
  }
  white-space: nowrap;
  i {
    color: white;
  }
`;

const CancelButton = styled.button`
  background-color: indianred;
  border: none;
  color: white;
  height: 100%;
  width: 27px;
  text-align: center;
  text-decoration: none;
  font-size: 12px;
  border-radius: 3px;
  cursor: pointer;
  &:hover {
    filter: brightness(0.9);
  }
  white-space: nowrap;
  i {
    color: white;
  }
`;

const IconContainer = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  cursor: pointer;
  &:hover {
    .fa-edit {
      color: dodgerblue;
    }
    .fa-circle-plus {
      color: darkgreen;
    }
    .fa-trash {
      color: indianred;
    }
  }
`;

const ValueInputContainer = styled.div`
  flex: 1;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  max-width: ${({ maxWidth }) => `${maxWidth}px` || "100px"};
  .fas {
    color: rgb(179, 179, 179);
  }
  ${IconContainer} {
    opacity: 0;
    transition: opacity 0.1s ease-in-out;
  }
  &:hover {
    ${IconContainer} {
      opacity: 1;
      transition: opacity 0.1s ease-in-out;
    }
  }
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
const ValueContainer = styled.div`
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

//----------------------------------------------------------------

const EditableCell = ({
  shownValue,
  inputValue,
  inputType,
  upperCase,
  maxWidth,
  isLoading,
  onClickOk,
  onClickAdd,
  onClickDelete,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [tempValue, setTempValue] = useState(null);

  const { user } = useAxios();
  const userIsAdmin = user.isAdmin
  const userIsPermittedToHandleServiceRecords = user.permissions.handleServiceRecords || userIsAdmin;

  useEffect(() => {
    setTempValue(inputValue);
  }, [inputValue]);

  const handleInputChange = (e) => {
    setTempValue(e.target.value);
  };

  const handleConfirmClick = () => {
    onClickOk(tempValue);
    setIsEditing(false);
  };

  return (
    <Wrapper>
      {isEditing && !isLoading && (
        <Container>
          <ValueInputContainer
            maxWidth={maxWidth}
            style={{
              border: "1px solid rgb(211,203,203)",
              borderRadius: "3px",
            }}
          >
            <Input
              upperCase={upperCase}
              type={inputType}
              value={tempValue}
              onChange={handleInputChange}
            />
          </ValueInputContainer>
          <OkButton onClick={handleConfirmClick} disabled={isLoading}>
            <i className="fas fa-check"></i>
          </OkButton>
          <CancelButton
            onClick={() => setIsEditing(false)}
            disabled={isLoading}
          >
            <i className="fas fa-times"></i>
          </CancelButton>
        </Container>
      )}

      {!isEditing && !isLoading && (
        <Container>
          <ValueInputContainer>
            <ValueContainer>{shownValue || "-"}</ValueContainer>
            <IconContainer title="Edit" onClick={() => {
              if (userIsPermittedToHandleServiceRecords) {
                setIsEditing(true)
              } else {
                alert('You do not have permission to handle service records')
              }
            }}>
              <i className="fas fa-edit"></i>
            </IconContainer>
            {onClickAdd && (
              <IconContainer title="Add below" onClick={() => {
                if (userIsPermittedToHandleServiceRecords) {
                  onClickAdd()
                } else {
                  alert('You do not have permission to handle service records')
                }
              }}>
                <i className="fas fa-circle-plus"></i>
              </IconContainer>
            )}
            {onClickDelete && (
              <IconContainer title="Delete" onClick={() => {
                if (userIsPermittedToHandleServiceRecords) {
                  onClickDelete()
                } else {
                  alert('You do not have permission to handle service records')
                }
              }}>
                <i className="fas fa-trash"></i>
              </IconContainer>
            )}
          </ValueInputContainer>
        </Container>
      )}

      {isLoading && (
        <Container>
          <BarLoader color="#c1c2c0" loading={true} width={65} />
        </Container>
      )}
    </Wrapper>
  );
};

export default EditableCell;
