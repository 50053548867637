import { useContext, createRef } from "react";
import { FilesContext } from "../FilesOptions";
import styled from "styled-components";
import { motion } from "framer-motion";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import FileCard from "./FileCard";
import { IFile } from "../FilesOptions";
import Dropzone, { DropzoneRef } from "react-dropzone";
import { Button } from "@mui/material";
import AttachFileIcon from "@mui/icons-material/AttachFile";

//---------------------------------------------------------

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  padding: 20px;
`;

const Modal = styled(motion.div)`
  background-color: white;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: flex-end;
  padding: 10px;
  width: 100%;
  height: 100%;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: whitesmoke;
  border-radius: 7px;
  border: 1px dashed black;
  gap: 10px;
  padding: 10px;
`;

const FilesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
  border-radius: 7px;
  background-color: whitesmoke;
  flex: 1;
  overflow-x: hidden;
  overflow-y: auto;
`;

const CloseContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`;

const TopContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 5px;
  width: 100%;
`;

//---------------------------------------------------------

const AddFileModal = ({ close }: { close: () => void }) => {
  const { filesData, setFilesData } = useContext(FilesContext);
  const dropzoneRef = createRef<DropzoneRef>();

  return (
    <Wrapper>
      <Modal
        initial={{ opacity: 0, scale: 0 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{
          opacity: { duration: 0.2 },
        }}
      >
        <CloseContainer>
          <IconButton
            aria-label="delete"
            size="medium"
            onClick={async () => {
              close();
            }}
          >
            <CloseIcon fontSize="inherit" />
          </IconButton>
        </CloseContainer>
        {filesData?.length > 0 && (
          <TopContainer>
            <Button
              size="small"
              variant="outlined"
              onClick={() => {
                if (dropzoneRef.current) {
                  dropzoneRef.current.open();
                }
              }}
              title="Add Files"
              endIcon={<AttachFileIcon />}
            >
              DROP FILES IN THIS AREA OR CLICK HERE TO SELECT
            </Button>
          </TopContainer>
        )}
        <Dropzone
          ref={dropzoneRef}
          noDragEventsBubbling
          noKeyboard
          noClick
          accept={{
            "image/avif": [],
            "image/webp": [],
            "application/pdf": [],
            "application/msword": [],
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
              [],
            "application/vnd.ms-excel": [],
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
              [],
            "application/vnd.ms-powerpoint": [],
            "application/vnd.openxmlformats-officedocument.presentationml.presentation":
              [],
            "text/plain": [],
            "application/zip": [],
            "application/x-rar-compressed": [],
            "application/x-7z-compressed": [],
            "application/x-tar": [],
            "application/x-gzip": [],
            "application/x-bzip2": [],
            "application/x-xz": [],
            "application/x-compressed": [],
            "application/x-zip-compressed": [],
            "application/x-zip": [],
            "application/x-rar": [],
          }}
          onDropRejected={(rejected) => {
            const rejectedFiles = rejected.map((item) => item.file);
            alert(
              `The following files are not supported: \n
${rejectedFiles.map((file, i) => `${i + 1}. ${file.name}`).join("\n")}`
            );
          }}
          onDrop={(acceptedFiles: File[]) => {
            const filesBiggerThanMaximum = Array.from(acceptedFiles).filter(
              (file) => file.size > 524288
            ); // 0.5 MB

            if (filesBiggerThanMaximum.length > 0) {
              // Show error for invalid files
              alert(
                `The following files exceed the size limit of 0.5MB: \n 
${filesBiggerThanMaximum.map((file, i) => `${i + 1}. ${file.name}`).join("\n")}`
              );
            }

            const existingFiles = filesData.filter((formFile: IFile) =>
              Array.from(acceptedFiles).some(
                (file: File) => file.name === formFile.name
              )
            );

            if (existingFiles?.length > 0) {
              alert(
                `The following files already exist in the list: \n
${existingFiles
  .map((file: IFile, i: number) => `${i + 1}. ${file.name}`)
  .join("\n")}`
              );
            }

            const newFiles = Array.from(acceptedFiles)
              .filter(
                (file) =>
                  !filesData.some(
                    (formFile: IFile) => formFile.name === file.name
                  ) && file.size <= 524288
              )
              .map((file) => {
                const url = URL.createObjectURL(file);

                return {
                  url: url,
                  name: file.name,
                  description: "",
                  size: file.size,
                  type: file.type,
                  data: file,
                };
              });

            setFilesData([...filesData, ...newFiles]);
          }}
        >
          {({ getRootProps, getInputProps }) => (
            <ContentContainer {...getRootProps()}>
              <input {...getInputProps()} />
              {!filesData.length && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    flex: 1,
                  }}
                >
                  <Button
                    variant="outlined"
                    onClick={() => {
                      if (dropzoneRef.current) {
                        dropzoneRef.current.open();
                      }
                    }}
                    title="Add Files"
                    endIcon={<AttachFileIcon />}
                  >
                    DROP FILES IN THIS AREA OR CLICK HERE TO SELECT
                  </Button>
                </div>
              )}

              {filesData?.length > 0 && (
                <FilesContainer>
                  {filesData?.map((file: IFile, index: number) => (
                    <FileCard key={file.url} file={file} index={index} />
                  ))}
                </FilesContainer>
              )}
            </ContentContainer>
          )}
        </Dropzone>
      </Modal>
    </Wrapper>
  );
};

export default AddFileModal;
