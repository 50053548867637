import styled from "styled-components";
import { motion } from "framer-motion";
import { useState } from "react";
import {
  useFetchEntities,
  useDeleteVehicle,
} from "../../../../reactQueryHooks";
import VehicleInfoPage from "./VehicleInfoPage";
import VehiclesListPage from "./VehiclesListPage";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import OutsideClickHandler from "react-outside-click-handler";
import { useAxios } from "../../../../axiosProvider";
//----------------------------------------------------------------

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 100%;
  overflow: hidden;
`;

const TopRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const BackButton = styled(motion.div)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border-radius: 10px;
  cursor: pointer;
  &:hover {
    background-color: #e6e6e6;
  }
`;

const MenuButton = styled(motion.div)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 13px;
  border-radius: 10px;
  cursor: pointer;
  &:hover {
    background-color: #e6e6e6;
  }
`;

const ContentContainer = styled.div`
  flex: 1;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 10px;
  border-radius: 10px;
  height: 100%;
  overflow: auto;
`;

const PopoverContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 5px;
  min-width: 150px;
`;

const PopoverBtn = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(239, 239, 239);
  border-radius: 5px;
  padding: 0 10px;
  height: 35px;
  cursor: pointer;
  &:hover {
    filter: brightness(0.9);
  }
`;

//----------------------------------------------------------------

const VehicleInfo = ({ vehicleId, setVehicleId, openModal }) => {
  const { data: entities, refetch } = useFetchEntities();
  const vehicle = entities?.data?.vehicles?.find((v) => v._id === vehicleId);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [page, setPage] = useState(1);

  const { user } = useAxios();
  const userIsAdmin = user.isAdmin
  const userIsPermittedToHandleVehicles = user.permissions.addNewVehicle || userIsAdmin;

  const { mutateAsync: deleteVehicle } = useDeleteVehicle();

  const handleDeleteVehicle = async () => {
    try {
      await deleteVehicle(vehicleId);
      refetch();
      setPage(1);
      setVehicleId("all");
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Container>
      <TopRow isListPage={page === 1}>
        {page === 2 && (
          <>
            <BackButton
              onClick={() => {
                setPage(1);
                setVehicleId("all");
              }}
            >
              <i className="fas fa-arrow-left"></i>
            </BackButton>
          </>
        )}

        <span
          style={{
            fontWeight: "bold",
            fontSize: "15px",
            marginLeft: "5px",
          }}
        >
          {vehicle?.plate || "All Vehicles"}
        </span>
        <OutsideClickHandler
          onOutsideClick={() => {
            setIsPopoverOpen(false);
          }}
        >
          <OverlayTrigger
            placement="left-start"
            show={isPopoverOpen}
            overlay={
              <Popover
                id="popover-basic"
                style={{
                  position: "absolute",
                }}
              >
                <PopoverContainer>
                  {page === 1 ? (
                    <PopoverBtn
                      onClick={() => {
                        if (userIsPermittedToHandleVehicles) {
                          openModal();
                          setIsPopoverOpen(false);
                        } else {
                          alert('You do not have permission to handle vehicles')
                        }
                      }}
                    >
                      Add new vehicle
                    </PopoverBtn>
                  ) : (
                    <PopoverBtn
                      onClick={() => {
                          if (userIsPermittedToHandleVehicles) {
                            if (
                              window.confirm(
                                "Are you sure you want to delete this vehicle?"
                              )
                            ) {
                              var plate = prompt(
                                "Please enter the vehicle plate to confirm deletion"
                              );
                              if (plate === vehicle.plate) {
                                handleDeleteVehicle();
                              } else {
                                alert("Vehicle plate does not match");
                              }
                            }
                          } else {
                            alert('You do not have permission to handle vehicles')
                          }
                      }}
                    >
                      Delete vehicle
                    </PopoverBtn>
                  )}
                </PopoverContainer>
              </Popover>
            }
          >
            <MenuButton onClick={() => setIsPopoverOpen(!isPopoverOpen)}>
              <i className="fas fa-ellipsis-v"></i>
            </MenuButton>
          </OverlayTrigger>
        </OutsideClickHandler>
      </TopRow>
      <ContentContainer>
        {page === 1 ? (
          <VehiclesListPage setVehicleId={setVehicleId} setPage={setPage} />
        ) : (
          <VehicleInfoPage vehicleId={vehicleId} />
        )}
      </ContentContainer>
    </Container>
  );
};

export default VehicleInfo;
