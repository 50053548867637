import styled from "styled-components";

//------------------------------------------------------------------------------------------

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: black;
  color: white;
  box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.5);
  padding: 5px;
  border-radius: 5px;
`;

const TablesContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  gap: 5px;
  height: 100%;
`;

const Table = styled.table`
  height: 100%;
  border-collapse: collapse;
  text-align: left;
  border: 1px solid grey;
  flex: 1;
  th {
    border: 1px solid grey;
  }

  td {
    padding: 3px;
    border: 1px solid grey;
  }
`;

//------------------------------------------------------------------------------------------

const DifferencesTable = ({ plan, task }) => {
  function findDifferences(arr1, arr2) {
    let arr1MeetingPoints = arr1.map((item) => item.meeting_point);
    let arr2MeetingPoints = arr2.map((item) => item.meeting_point);

    let arr1differenceswith2 = [];
    let arr2differenceswith1 = [];

    // Helper function to compare guests arrays
    const compareGuests = (guests1, guests2) => {
      if (guests1.length !== guests2.length) {
        return true; // Different number of guests
      }
      for (let i = 0; i < guests1.length; i++) {
        if (
          !guests2[i] ||
          guests1[i].name !== guests2[i].name ||
          guests1[i].count !== guests2[i].count
        ) {
          return true; // Guest details don't match
        }
      }
      return false; // Guests are identical
    };

    // Find differences in arr1 compared to arr2
    arr1.forEach((item, index) => {
      let arr2Index = arr2MeetingPoints.indexOf(item.meeting_point);
      if (arr2Index === -1) {
        arr1differenceswith2.push({ index, reason: "Missing meeting point" });
      } else {
        if (arr2[arr2Index].time !== item.time) {
          arr1differenceswith2.push({ index, reason: "Different time" });
        }
        if (arr2Index !== index) {
          arr1differenceswith2.push({ index, reason: "Different order" });
        }
        if (compareGuests(item.guests, arr2[arr2Index].guests)) {
          arr1differenceswith2.push({
            index,
            reason: "Different guests details",
          });
        }
      }
    });

    // Find differences in arr2 compared to arr1
    arr2.forEach((item, index) => {
      let arr1Index = arr1MeetingPoints.indexOf(item.meeting_point);
      if (arr1Index === -1) {
        arr2differenceswith1.push({ index, reason: "Missing meeting point" });
      } else {
        if (arr1[arr1Index].time !== item.time) {
          arr2differenceswith1.push({ index, reason: "Different time" });
        }
        if (arr1Index !== index) {
          arr2differenceswith1.push({ index, reason: "Different order" });
        }
        if (compareGuests(item.guests, arr1[arr1Index].guests)) {
          arr2differenceswith1.push({
            index,
            reason: "Different guests details",
          });
        }
      }
    });

    return {
      plan: arr1differenceswith2,
      task: arr2differenceswith1,
    };
  }

  // Use the function with your provided arrays
  let differences = findDifferences(plan, task);

  return (
    <Container>
      <span
        style={{
          flex: "1",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: "indianred",
          padding: "5px",
          backgroundColor: "#424242",
        }}
      >
        Current plan differences with current task
      </span>
      <TablesContainer>
        <Table>
          <thead>
            <tr>
              <th
                style={{
                  textAlign: "center",
                  backgroundColor: "#757575",
                }}
                colSpan={2}
              >
                Plan
              </th>
            </tr>
          </thead>
          <tbody>
            {plan.map((mp, index) => {
              const indexIsIncludedInDifferences = differences.plan
                .map((item) => item.index)
                .includes(index);
              const allDifferencesForIndex = differences.plan.filter(
                (item) => item.index === index
              );
              const reasonsArray = allDifferencesForIndex.map(
                (item) => item.reason
              );
              const reasonsString = reasonsArray.join(", ");

              return (
                <>
                  <th
                    style={{
                      backgroundColor: indexIsIncludedInDifferences
                        ? "indianred"
                        : "#616161",
                    }}
                    key={`mp-${index}`}
                  >
                    {mp.meeting_point}
                  </th>
                  <th
                    style={{
                      backgroundColor: indexIsIncludedInDifferences
                        ? "indianred"
                        : "#616161",
                    }}
                  >
                    {mp.time}
                  </th>

                  {mp.guests.map((guest, guestIndex) => (
                    <tr key={`guest-${index}-${guestIndex}`}>
                      <td>{guest.name}</td>
                      <td
                        style={{
                          textAlign: "center",
                        }}
                      >
                        X{guest.count}
                      </td>
                    </tr>
                  ))}
                  {indexIsIncludedInDifferences && (
                    <tr>
                      <td
                        colSpan={2}
                        style={{ textAlign: "center", color: "indianred" }}
                      >
                        {reasonsString}
                      </td>
                    </tr>
                  )}
                </>
              );
            })}
          </tbody>
        </Table>
        <Table>
          <thead>
            <tr>
              <th
                style={{
                  textAlign: "center",
                  backgroundColor: "#757575",
                }}
                colSpan={2}
              >
                Task
              </th>
            </tr>
          </thead>
          <tbody>
            {task.map((mp, index) => {
              return (
                <>
                  <th
                    style={{
                      backgroundColor: "#616161",
                    }}
                    key={`mp-${index}`}
                  >
                    {mp.meeting_point}
                  </th>
                  <th
                    style={{
                      backgroundColor: "#616161",
                    }}
                  >
                    {mp.time}
                  </th>
                  {mp.guests.map((guest, guestIndex) => (
                    <tr key={`guest-${index}-${guestIndex}`}>
                      <td>{guest.name}</td>
                      <td
                        style={{
                          textAlign: "center",
                        }}
                      >
                        X{guest.count}
                      </td>
                    </tr>
                  ))}
                </>
              );
            })}
          </tbody>
        </Table>
      </TablesContainer>
    </Container>
  );
};

export default DifferencesTable;
