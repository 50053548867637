import styled from 'styled-components';
import moment from 'moment';
import { AnimatePresence } from "framer-motion";
import ActionsList from "./ActionsList";

import { useState, useEffect } from "react";

//----------------------------------------------------------------

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ isExpanded }) =>
    isExpanded ? "gainsboro" : "whitesmoke"};

  gap: 5px;
  user-select: none;

  &:hover {
    background-color: white;
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  padding: 5px;
  span {
    flex: 1;
    font-size: 12px;
    font-weight: 600;
    //wrap with ellipsis
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
  }
  cursor: pointer;
`;

const ArrowSvgContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  // if isFilesExpanded is false, rotate 90deg with transition 0.3s
  transform: rotate(${({ isExpanded }) => (isExpanded ? "-90deg" : "0deg")});
  transition: 0.2s;
  path {
    fill: #757575;
  }
`;

//----------------------------------------------------------------

const SessionItem = ({ session }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  //     const deviceInfoString = `Platform: ${session.device_info.platform}
  // OS Name: ${session.osName}
  // OS Version: ${session.device_info.osVersion}
  // Browser Name: ${session.device_info.browserName}
  // Browser Version: ${session.device_info.browserVersion}
  // Mobile Vendor: ${session.device_info.mobileVendor}
  // Mobile Model: ${session.device_info.mobileModel}`;

  //turn isExpanded to false when after 3 seconds when true
  useEffect(() => {
    if (isExpanded) {
      const timer = setTimeout(() => {
        setIsExpanded(false);
      }, 4000);
      return () => clearTimeout(timer);
    }
  }, [isExpanded]);

  return (
    <Container
      onClick={() => setIsExpanded(!isExpanded)}
      isExpanded={isExpanded}
    >
      <Row>
        <span
          style={{
            maxWidth: "20px",
          }}
          title="Click to show user actions"
        >
          <ArrowSvgContainer isExpanded={isExpanded}>
            <svg
              width="24"
              height="18"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M7.41 7.84L12 12.42l4.59-4.58L18 9.25l-6 6-6-6z"></path>
            </svg>
          </ArrowSvgContainer>
        </span>
        <span title="Session date and time">
          {moment(session.date_time).format("DD-MM-YY HH:mm")}
        </span>
        <span title="Session booking ref">{session.booking_ref}</span>
        <span title="Session client name">{session.client_name}</span>
        <span title="Session booking product title">
          {session.product_title}
        </span>
        <span
          style={{
            textAlign: "center",
          }}
          title="Session booking date"
        >
          {moment(session.booking_date).format("DD-MM-YYYY")}
        </span>
      </Row>
      <AnimatePresence>
        {isExpanded && <ActionsList session={session} />}
      </AnimatePresence>
    </Container>
  );
};

export default SessionItem;