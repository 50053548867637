import styled from "styled-components";
import { useState, useEffect, useContext } from "react";
import EditableInput from "./EditableInput";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import Tooltip from "@mui/material/Tooltip";
import CustomSelect from "./CustomSelect";
import SearchableMultiSelect from "../../settings/content/users/SearchableMultiSelect";
import OutsideClickHandler from "react-outside-click-handler";
import SendGuideMailModal from "./SendGuideMailModal";
import { useAxios } from "../../../../axiosProvider";
import Icon from "@mdi/react";
import { mdiEmailCheck, mdiEmailOff } from "@mdi/js";
import moment from "moment";
import { TourGuidesContentContext } from "./TourGuidesContent";
import {
  useAddConfirmationToTourGroup,
  useAddGuideDetailsToTourGroup,
  useFetchEntities,
  useAddGuideToTourGroup,
  useRemoveGuideFromTourGroup,
  useReplaceGuideFromTourGroup,
  useAddTourGuidesInTourGroupGuidesAskedArray,
} from "../../../../reactQueryHooks";
import TourGroupModal from "./TourGroupModal";

//-------------------------------------------------------------------------------------

const TbodyTr = styled.tr`
  height: 40px;
  border-bottom: thin solid rgba(0, 0, 0, 0.1);
  font-weight: ${(props) => (props.isCancelledWithGuide ? "800" : "500")};
  background: ${(props) => {
    if (props.isCancelled) {
      return "rgb(255 220 220)";
    } else {
      return "rgb(250 250 250)";
    }
  }};
  z-index: 0;
  &:hover {
    filter: brightness(0.9);
  }
`;

const TdCell = styled.td`
  //height: 100%;
  //min-height: 100%;
  width: 100%;
  text-align: center;
  white-space: nowrap; /* Prevents the text from wrapping onto the next line */
  overflow: hidden; /* Hides text that overflows the cell */
  text-overflow: ellipsis; /* Displays an ellipsis when text is hidden */
  width: auto;
  padding-left: 5px !important;
  margin: 0 !important;
  max-width: 0;
  font-size: 12px;
  font-family: "Roboto", sans-serif;
  color: ${(props) => {
    if (props.isCancelledWithGuide) {
      return "#DD2C00";
    }
    if (props.isCancelled) {
      return "rgb(66 66 66)";
    }
    if (props.isPending) {
      return "rgb(70 43 223)";
    }
    if (props.isScheduledOk) {
      return "rgb(15 119 41)";
    }
  }};

  &:hover {
    svg {
      transform: scale(1.1);
      filter: brightness(0.5);
    }
  }
`;

const StyledPopover = styled(Popover)`
  max-width: 250px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const ExtendedTdCell = styled(TdCell)`
  cursor: pointer;
  &:hover {
    text-decoration: underline;
    color: rgb(9 71 9);
  }
  div {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    gap: 5px;
    i {
      margin-right: 5px;
    }
  }
`;

//-------------------------------------------------------------------------------------

const CustomTr = ({ row }) => {

  const [isEmailModalShown, setIsEmailModalShown] = useState(false)
  const [isTourGroupModalShown, setShowTourGroupModal] = useState(false)
  const { data: entities } = useFetchEntities()
  const [isGuidePopoverOpen, setIsGuidePopoverOpen] = useState(false)
  const [isProposalsPopoverOpen, setIsProposalsPopoverOpen] = useState(false)
  const [guide_id, setGuide_id] = useState(null)
  const [guide_email_sent, setGuide_email_sent] = useState(false)
  const [guides_asked, setGuides_asked] = useState([])
  const [uds_id, setUds_id] = useState()
  const [confirmation, setConfirmation] = useState()
  const [details, setDetails] = useState()
  const { IsListSelectable, selectedItems, setSelectedItems } = useContext(
    TourGuidesContentContext
  )

  const { user } = useAxios()
  const userIsAdmin = user.isAdmin
  const userIsPermittedToEditGuidePlan =
    user.permissions.editGuidePlan || userIsAdmin

  const isCancelledWithGuide = row.isCancelled && guide_id !== null
  const isPending = !guide_id
  const isScheduledOk = guide_id !== null

  useEffect(() => {
    setGuide_id(row.guide_id || null)
    setGuide_email_sent(row.guide_email_sent)
    setUds_id(row.uds_id)
    setConfirmation(row.guide_confirmation)
    setDetails(row.guide_details)
    setGuides_asked(row.guides_asked)
  }, [row])

  const { mutateAsync: addGuideToTourGroup } = useAddGuideToTourGroup()
  const { mutateAsync: removeGuideFromTourGroup } =
    useRemoveGuideFromTourGroup()
  const { mutateAsync: replaceGuideFromTourGroup } =
    useReplaceGuideFromTourGroup()
  const {
    mutateAsync: addConfirmationToTourGroup,
    isLoading: isAddingConfirmation,
  } = useAddConfirmationToTourGroup()
  const {
    mutateAsync: addGuideDetailsToTourGroup,
    isLoading: isAddingDetails,
  } = useAddGuideDetailsToTourGroup()
  const { mutateAsync: addTourGuidesInTourGroupGuidesAskedArray } =
    useAddTourGuidesInTourGroupGuidesAskedArray()

  const handleAddRemoveReplaceGuide = async (tour_group_id, tour_guide_id) => {
    try {
      const sameGuide = tour_guide_id === guide_id
      const shouldRemoveGuide = tour_guide_id === null

      if (shouldRemoveGuide && uds_id) {
        //uds has guide, remove
        await removeGuideFromTourGroup({
          id: tour_group_id,
          uds_id: uds_id,
        })
        setGuide_id(null)
        setUds_id(null)
        return
      }

      if (!shouldRemoveGuide && !uds_id) {
        //uds has no guide, add
        const response = await addGuideToTourGroup({
          id: tour_group_id,
          guide_id: tour_guide_id,
        })

        const { guide, uds_id: udsId } = response.data
        setGuide_id(guide)
        setUds_id(udsId)
        return
      }

      if (sameGuide && uds_id) {
        //uds has same guide, do nothing
        alert("Guide has already been assigned")
      }

      if (!sameGuide && uds_id) {
        //uds has different guide, replace
        //console.log(uds_id)
        const response = await replaceGuideFromTourGroup({
          id: tour_group_id, //tour group to add
          guide_id: tour_guide_id, // guide to add
          uds_id: uds_id, // uds to remove
        })
        const { guide, uds_id: udsId } = response.data
        setGuide_id(guide)
        setUds_id(udsId)
      }
    } catch (error) {
      alert(error.toString())
    }
  }

  const tour_guides = entities?.data?.staff.filter((staff) =>
    staff.roles.some((role) => role._id === "636d1329f2535b158a9acd20")
  )

  const tour_guides_sorted_by_included_in_guides_asked = tour_guides?.sort(
    (a, b) => {
      if (guides_asked.includes(a._id) && !guides_asked.includes(b._id)) {
        return -1
      }
      if (!guides_asked.includes(a._id) && guides_asked.includes(b._id)) {
        return 1
      }
      return 0
    }
  )
  const tour_guides_labels_and_values =
    tour_guides_sorted_by_included_in_guides_asked?.map((guide) => {
      return {
        label: guide.name,
        value: guide._id,
      }
    })

  //const sorted --> the ones where their id is in guides_asked go first

  const selected_tour_guides_from_guides_asked_with_labels_and_values =
    tour_guides
      ?.filter((guide) => guides_asked.includes(guide._id))
      .map((guide) => {
        return {
          label: guide.name,
          value: guide._id,
        }
      })

  if (tour_guides) {
    tour_guides.unshift({
      _id: null,
      name: "Not Assigned",
    })
  }
  const guide_name = tour_guides?.find((guide) => guide._id === guide_id)?.name
  const guide_email = tour_guides?.find((guide) => guide._id === guide_id)
    ?.contact?.email

  const guides_asked_names_commas = guides_asked
    ?.map((guide) => {
      return tour_guides?.find((g) => g._id === guide)?.name
    })
    .join(", ")

  const handleAddConfirmation = async (value) => {
    if (!userIsPermittedToEditGuidePlan) {
      alert("You are not permitted to edit the guide plan")
      return
    }
    try {
      await addConfirmationToTourGroup({
        id: row.tour_group_id,
        confirmation: value,
      })
      //optimistic update
      setConfirmation(value)
    } catch (error) {
      alert(error.toString())
    }
  }

  const handleAddDetails = async (value) => {
    if (!userIsPermittedToEditGuidePlan) {
      alert("You are not permitted to edit the guide plan")
      return
    }
    try {
      await addGuideDetailsToTourGroup({
        id: row.tour_group_id,
        details: value,
      })
      //optimistic update
      setDetails(value)
    } catch (error) {
      alert(error.toString())
    }
  }

  return (
    <>
      <TbodyTr
        key={row.tour_group_id}
        isCancelled={row.isCancelled}
        isScheduledOk={isScheduledOk}
        isCancelledWithGuide={isCancelledWithGuide}
      >
        {IsListSelectable ? (
          <TdCell
            style={{
              borderRight: "1px solid rgba(0, 0, 0, 0.1)",
            }}
          >
            <input
              title={
                row.guide_confirmation || row.isCancelled
                  ? "Cannot be selected"
                  : "Select"
              }
              style={{
                width: "17spx",
                height: "17px",
                margin: "auto",
                cursor: "pointer",
              }}
              type="checkbox"
              //disabled={isScheduledOk || row.isCancelled ? true : false}
              checked={selectedItems.includes(row.tour_group_id)}
              onChange={(e) => {
                if (!userIsPermittedToEditGuidePlan) {
                  alert("You are not permitted to edit the guide plan")
                  return
                }
                if (e.target.checked) {
                  setSelectedItems((prev) => [...prev, row.tour_group_id])
                } else {
                  setSelectedItems((prev) =>
                    prev.filter((id) => id !== row.tour_group_id)
                  )
                }
              }}
            />
          </TdCell>
        ) : (
          <TdCell
            style={{
              cursor: "pointer",
            }}
            onClick={() => {
              if (!userIsPermittedToEditGuidePlan) {
                alert("You are not permitted to edit the guide plan")
                return
              }
              setIsEmailModalShown(true)
            }}
            isScheduledOk={isScheduledOk}
            isPending={isPending}
            isCancelled={row.isCancelled}
            isCancelledWithGuide={isCancelledWithGuide}
            title={
              guide_email_sent ? "Guide email sent" : "Guide email not sent"
            }
          >
            {guide_email_sent ? (
              <Icon path={mdiEmailCheck} size={0.8} color={"darkgreen"} />
            ) : (
              <Icon path={mdiEmailOff} size={0.8} color={"indianred"} />
            )}
          </TdCell>
        )}
        <TdCell
          isScheduledOk={isScheduledOk}
          isPending={isPending}
          isCancelled={row.isCancelled}
          isCancelledWithGuide={isCancelledWithGuide}
          title={row.date}
        >
          {moment(row.date).format("DD/MM/YY ddd")}
        </TdCell>
        <TdCell
          isScheduledOk={isScheduledOk}
          isPending={isPending}
          isCancelled={row.isCancelled}
          isCancelledWithGuide={isCancelledWithGuide}
          title={row.time}
        >
          {row.time}
        </TdCell>

        <OverlayTrigger
          trigger="click"
          rootClose //close on click outside
          placement="auto"
          show={isGuidePopoverOpen}
          overlay={
            userIsPermittedToEditGuidePlan ? (
              <StyledPopover
                id="popover-basic"
                style={{
                  boxShadow: "2px 2px 4px 0 rgba(0, 0, 0, 0.1)",
                  position: "absolute",
                  //border: "none",
                }}
              >
                <CustomSelect
                  data={tour_guides.map((guide) => {
                    return {
                      label: guide.name,
                      value: guide._id,
                    }
                  })}
                  selected_id={guide_id}
                  onSelect={(tour_guide_id) => {
                    handleAddRemoveReplaceGuide(
                      row.tour_group_id,
                      tour_guide_id
                    )
                    setIsGuidePopoverOpen(false)
                  }}
                />
              </StyledPopover>
            ) : (
              <> </>
            )
          }
        >
          <ExtendedTdCell
            isScheduledOk={isScheduledOk}
            isPending={isPending}
            isCancelled={row.isCancelled}
            isCancelledWithGuide={isCancelledWithGuide}
            onClick={() => {
              if (!userIsPermittedToEditGuidePlan) {
                alert("You are not permitted to edit the guide plan")
                return
              }
              setIsGuidePopoverOpen(true)
            }}
          >
            <OutsideClickHandler
              onOutsideClick={() => {
                setIsGuidePopoverOpen(false)
              }}
              display="contents"
            >
              {guide_name}
            </OutsideClickHandler>
          </ExtendedTdCell>
        </OverlayTrigger>
        <OverlayTrigger
          trigger="click"
          rootClose //close on click outside
          placement="auto"
          show={isProposalsPopoverOpen}
          overlay={
            <StyledPopover
              id="popover-basic"
              style={{
                boxShadow: "2px 2px 4px 0 rgba(0, 0, 0, 0.1)",
                position: "absolute",
                //border: "none",
                padding: "10px",
              }}
            >
              <OutsideClickHandler
                onOutsideClick={() => {
                  setIsProposalsPopoverOpen(false)
                }}
                display="contents"
              >
                <SearchableMultiSelect
                  data={tour_guides_labels_and_values}
                  selectedData={
                    selected_tour_guides_from_guides_asked_with_labels_and_values
                  }
                  onSelect={async (data) => {
                    try {
                      const updated =
                        await addTourGuidesInTourGroupGuidesAskedArray({
                          id: row.tour_group_id,
                          guide_ids: data,
                        })
                      setGuides_asked(updated.data)
                    } catch (error) {
                      alert(error.toString())
                    }
                  }}
                  searchable
                />
              </OutsideClickHandler>
            </StyledPopover>
          }
        >
          <Tooltip
            title={guides_asked_names_commas}
            disableHoverListener={guides_asked_names_commas ? false : true}
            placement="top"
          >
            <ExtendedTdCell
              onClick={() => {
                if (!userIsPermittedToEditGuidePlan) {
                  alert("You are not permitted to edit the guide plan")
                  return
                }
                setIsProposalsPopoverOpen(true)
              }}
              isScheduledOk={isScheduledOk}
              isPending={isPending}
              isCancelled={row.isCancelled}
              isCancelledWithGuide={isCancelledWithGuide}
              //title={guides_asked_names_commas || "Guides proposed"}
            >
              {guides_asked_names_commas ? (
                <span
                  style={{
                    color: "indianred",
                  }}
                >
                  {guides_asked_names_commas}
                </span>
              ) : (
                "Add +"
              )}
            </ExtendedTdCell>
          </Tooltip>
        </OverlayTrigger>
        <TdCell
          isScheduledOk={isScheduledOk}
          isPending={isPending}
          isCancelled={row.isCancelled}
          isCancelledWithGuide={isCancelledWithGuide}
        >
          <EditableInput
            value={confirmation}
            tour_group_id={row.tour_group_id}
            placeholder="Confirmation"
            handleInput={handleAddConfirmation}
            isLoading={isAddingConfirmation}
          />
        </TdCell>
        <TdCell
          isScheduledOk={isScheduledOk}
          isPending={isPending}
          isCancelled={row.isCancelled}
          isCancelledWithGuide={isCancelledWithGuide}
        >
          <EditableInput
            value={details}
            tour_group_id={row.tour_group_id}
            placeholder="Details"
            handleInput={handleAddDetails}
            isLoading={isAddingDetails}
          />
        </TdCell>
        <TdCell
          isScheduledOk={isScheduledOk}
          isPending={isPending}
          isCancelled={row.isCancelled}
          isCancelledWithGuide={isCancelledWithGuide}
          title={`${row.product} - ${row.option}`}
          style={{
            textAlign: "left",
          }}
        >
          {`${row.product} - ${row.option}`}
        </TdCell>
        <ExtendedTdCell
          isScheduledOk={isScheduledOk}
          isPending={isPending}
          isCancelled={row.isCancelled}
          isCancelledWithGuide={isCancelledWithGuide}
          title={"See bookings"}
          onClick={() => {
            setShowTourGroupModal(true)
          }}
        >
          <div>
            <i className="fa-solid fa-arrow-up-right-from-square"></i>
            <span>SEE {row.bookingsCount} BOOKINGS</span>
          </div>
        </ExtendedTdCell>
        <TdCell
          isScheduledOk={isScheduledOk}
          isPending={isPending}
          isCancelled={row.isCancelled}
          isCancelledWithGuide={isCancelledWithGuide}
        >
          {row.seatsCount}
        </TdCell>
        <TdCell
          isScheduledOk={isScheduledOk}
          isPending={isPending}
          isCancelled={row.isCancelled}
          isCancelledWithGuide={isCancelledWithGuide}
          title={row.pickup_location}
          style={{
            textAlign: "left",
          }}
        >
          {row.pickup_location}
        </TdCell>
      </TbodyTr>
      {isEmailModalShown && (
        <SendGuideMailModal
          close={() => setIsEmailModalShown(false)}
          data={{
            tour_group_id: row.tour_group_id,
            date: row.date,
            guide_email: guide_email,
            guide_name: row.guidename,
            guide_afm: row.guide_afm,
            guide_amka: row.guide_amka,
            guide_reg_number: row.guide_reg_number,
            time: row.time,
            client_name: row.client_name,
            seats: row.seatsCount,
            meeting_point: row.pickup_location,
          }}
          setGuide_email_sent={setGuide_email_sent}
        />
      )}

      {isTourGroupModalShown && (
        <TourGroupModal
          close={() => setShowTourGroupModal(false)}
          id={row.tour_group_id}
        />
      )}
    </>
  )
};

export default CustomTr;
