import styled from "styled-components";
import React, {
  useState,
  useEffect,
  useRef,
  ReactElement,
  useImperativeHandle,
  forwardRef,
} from "react";
import { motion } from "framer-motion";
import OutsideClickHandler from "react-outside-click-handler";

//-------------------------------------------

const DropdownContainer = styled(motion.div)``;

const motionVariants = {
  visible: {
    opacity: 1,
    display: "flex",
    y: 0,
    transition: {
      duration: 0.1,
    },
  },
  hidden: {
    opacity: 0,
    display: "none",
    y: -8,
    transition: {
      duration: 0.1,
    },
  },
};

//-------------------------------------------

// Define the type for the ref object
interface CustomDropdownRef {
  close: () => void;
}

interface CustomDropdownProps {
  dropdownContent: ReactElement; //accepts only one child
  children: ReactElement; //accepts only one child
  isShown: (isShown: boolean) => void;
}

function recursivelyFindTheFirstScrollableParent(
  node: HTMLElement | null
): HTMLElement | null {
  if (!node) {
    return null;
  }

  if (!(node instanceof HTMLElement)) {
    return null;
  }

  const style = window.getComputedStyle(node);
  const isScrollable =
    style.overflow !== "visible" &&
    style.overflowY !== "hidden" &&
    node.scrollHeight > node.clientHeight;

  if (isScrollable) {
    return node;
  }

  return recursivelyFindTheFirstScrollableParent(node.parentElement);
}
//:NOTE ! BACKDROP FILTER ON PARENT DISRUPTS THE DROPDOWN POSITIONING !
//-------------------------------------------
const CustomDropdown: React.ForwardRefRenderFunction<
  CustomDropdownRef,
  CustomDropdownProps
> = ({ dropdownContent, children, isShown }, ref) => {
  const [dropdownPosition, setDropdownPosition] = useState({
    top: 0,
    left: 0,
    width: 0,
  });
  const [shown, setShown] = useState(false);
  const childRef = useRef<HTMLElement>(null);

  useImperativeHandle(ref, () => ({
    close: () => setShown(false),
  }));

  const clonedChildWithAdditionalProperties = React.cloneElement(
    children,
    {
      ...children.props, // Spread existing props first
      ref: childRef,
      style: {
        ...children.props.style, // Merge any additional styles
        // Other styles you wish to add
      },
      onClick: (e: any) => {
        // Ensure existing onClick handlers are not overwritten
        if (typeof children.props.onClick === "function") {
          children.props.onClick(e); // Call the original onClick handler if it exists
        }
        // Toggle the internal state only if shouldShow is not provided

        setShown((prevShown) => !prevShown);
      },
    },
    children.props.children,
    <OutsideClickHandler
      onOutsideClick={(e) => {
        // Cast e.target to a Node type
        const target = e.target as Node;
        if (childRef.current && !childRef.current.contains(target)) {
          setShown(false);
        }
      }}
    >
      <DropdownContainer
        onClick={(e) => {
          //prevents dropdown from closing when clicking on it
          e.stopPropagation();
        }}
        animate={shown ? "visible" : "hidden"}
        variants={motionVariants}
        initial="hidden"
        exit="hidden"
        style={{
          position: "fixed",
          top: `${dropdownPosition.top}px`,
          left: `${dropdownPosition.left}px`,
          minWidth: `${dropdownPosition.width}px`,
          maxWidth: `${dropdownPosition.width}px`,
          zIndex: 1000,
        }}
      >
        {shown && dropdownContent}
      </DropdownContainer>
    </OutsideClickHandler>
  );

  useEffect(() => {
    const updatePosition = () => {
      // Use window as the default scrollable parent
      let scrollParentRect = {
        top: 0,
        left: 0,
        scrollTop: window.scrollY,
        clientHeight: window.innerHeight,
      } as any;

      const scrollableParent = recursivelyFindTheFirstScrollableParent(
        childRef.current
      );

      if (scrollableParent) {
        // If a scrollable parent exists, use its dimensions and scroll position
        scrollParentRect = scrollableParent.getBoundingClientRect();
        scrollParentRect.scrollTop = scrollableParent.scrollTop;
        scrollParentRect.clientHeight = scrollableParent.clientHeight;
      }

      if (childRef.current) {
        const childRect = childRef.current.getBoundingClientRect();

        const childTopRelativeToScrollParent =
          childRect.top - scrollParentRect.top + scrollParentRect.scrollTop;
        const childBottomRelativeToScrollParent =
          childTopRelativeToScrollParent + childRect.height;

        // Check if the child element is within the visible area
        const isElementVisible =
          childBottomRelativeToScrollParent > scrollParentRect.scrollTop &&
          childTopRelativeToScrollParent <
            scrollParentRect.scrollTop + scrollParentRect.clientHeight;

        if (isElementVisible) {
          setDropdownPosition({
            top: childRect.bottom + 3, // 3px below the bottom
            left: childRect.left,
            width: childRect.width,
          });

        } else {
          setShown(false);
        }
      }
    };

    // Call updatePosition initially and on resize
    updatePosition();
    window.addEventListener("resize", updatePosition);

    // Set up a MutationObserver for DOM changes
    const observer = new MutationObserver(updatePosition);
    if (childRef.current) {
      observer.observe(childRef.current, {
        childList: true,
        subtree: true,
        attributes: true,
        characterData: true,
      });
    }

    // Find the scrollable parent and add a scroll listener
    const scrollableParent = recursivelyFindTheFirstScrollableParent(
      childRef.current
    );
    if (scrollableParent) {
      scrollableParent.addEventListener("scroll", updatePosition);
    }

    // Clean up all event listeners and the observer
    return () => {
      window.removeEventListener("resize", updatePosition);
      observer.disconnect();
      if (scrollableParent) {
        scrollableParent.removeEventListener("scroll", updatePosition);
      }
    };
  }, [childRef]); // Runs when childRef changes

  //inform parent component about the dropdown state
  useEffect(() => {
    if (isShown) {
      isShown(shown);
    }
  }, [shown, isShown]);

  //-------------------------------------------

  return clonedChildWithAdditionalProperties;
};

export default forwardRef(CustomDropdown);
