import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import DateSelection from "./DateSelection";
import { motion, AnimatePresence } from "framer-motion";

//---------------------------------------------------------------

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  background-color: whitesmoke;
  flex: 1;
  z-index: 9999;
`;

const HeaderBarButtonContainer = styled(motion.div)`
  display: flex;
  justify-content: center;
  align-items: center;
  .fa-arrows-rotate {
    font-size: 17px;
    //spin it half a turn on hover
    transform: rotate(0deg);
    transition: transform 0.13s ease-in-out;
    cursor: pointer;
    &:hover {
      transform: rotate(150deg);
    }
  }
  .header-button {
    color: #464646;
    font-size: 17px;
    cursor: pointer;
    &:hover {
      color: ${({ theme }) => theme.colors.blue};
    }
  }
`;

const closeIconAndDateVariants = {
  visible_icon: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.2,
    },
  },
  hidden_icon: {
    opacity: 0,
    y: 20,
    transition: {
      duration: 0.0,
    },
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 0.2,
    },
  },

  hidden: {
    opacity: 0,
    transition: {
      duration: 0.0,
    },
  },
};

const RoutingContainer = styled.div`
  margin-left: 10px;
  @media (max-width: 700px) {
    display: none;
  }
`;

//---------------------------------------------------------------

const TourGuidesBarContent = ({ date, setDate, handleDateChange, refetch }) => {
  const navigate = useNavigate();
  return (
    <Container>
      <AnimatePresence>
        <HeaderBarButtonContainer
          variants={closeIconAndDateVariants}
          initial="hidden"
          animate="visible"
          exit="hidden"
          onClick={() => navigate("/home")}
        >
          <i className="fa-solid fa-home header-button"></i>
          <RoutingContainer>
            <i
              style={{
                fontSize: "8px",
              }}
              className="fa-solid fa-angle-right"
            ></i>
            <span
              style={{
                fontSize: "10px",
                marginLeft: "5px",
              }}
            >
              Tour Guides Plan
            </span>
          </RoutingContainer>
        </HeaderBarButtonContainer>
      </AnimatePresence>
      <AnimatePresence>
        <motion.div
          variants={closeIconAndDateVariants}
          initial="hidden"
          animate="visible"
          exit="hidden"
          style={{
            position: "absolute",
            left: "50%",
            transform: "translateX(-50%)",
          }}
        >
          <DateSelection
            date={date}
            setDate={setDate}
            handleDateChange={handleDateChange}
          />
        </motion.div>
      </AnimatePresence>
      <AnimatePresence>
        <HeaderBarButtonContainer
          variants={closeIconAndDateVariants}
          initial="hidden"
          animate="visible"
          exit="hidden"
          onClick={() => {
            //refetch with react-query
            refetch();
          }}
        >
          <i className="fa-solid fa-arrows-rotate header-button"></i>
        </HeaderBarButtonContainer>
      </AnimatePresence>
    </Container>
  );
};

export default TourGuidesBarContent;
