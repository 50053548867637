import { useState, useEffect } from "react";
import {
  FieldWrapper,
  SectionWrapper,
  SectionContainer,
  FieldContainer,
  QrButtonOverlay,
  EmailTextarea,
  TextAreaOptionsButton,
  TextAreaOptionsItem,
  Input,
} from "../styled";
import { useFormikContext } from "formik";
import { useAxios } from "../../../../axiosProvider";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import moment from "moment";
import Popover from "react-bootstrap/Popover";
import EmailMessage from "../EmailMessage";
import CustomQrCode from "../CustomQrCode";
import { useFetchEntities, useSendEmail } from "../../../../reactQueryHooks";
import toast from "react-hot-toast";
import CustomMaterialSwitch from "../CustomMaterialSwitch"
const tour_info_page_url = "https://your-getaways-tour.com/?tour="

const emailDraftComposer = (draft, valuesObj) => {
  const composedDraft = draft.replace(/\[(\w+)\]/g, (match, key) => {
    return valuesObj[key] || match
  })
  return composedDraft
}

//---------------------------------------------------

const ClientInfoSection = () => {
  const { data: entities } = useFetchEntities()
  const { values, setFieldValue, handleChange, errors } = useFormikContext()
  const { user } = useAxios()
  const { isLoading: isSendEmailLoading, mutateAsync: sendEmail } =
    useSendEmail()
  const [isEmailFormOpen, setIsEmailFormOpen] = useState(false)
  const [copyEmailText, setCopyEmailText] = useState()
  const [isIMessageQrShown, setIMessageQrShown] = useState(false)
  const [isWhatsappQrShown, setWhatsappQrShown] = useState(false)
  const [isEmailQrShown, setEmailQrShown] = useState(false)
  const noEmails = values?.email_history?.length === 0

  const product = entities.data.products.find(
    (product) => product._id === values.product_id
  )
  const emailDrafts = entities?.data?.messageDrafts
  const [emailText, setEmailText] = useState("")
  const [withConfirmButton, setWithConfirmButton] = useState(true)
  const [emailObject, setEmailObject] = useState({
    text: "",
    client_name: "",
    platform_product_name: "",
    date: "",
    pickup_location_name: "",
    pickup_time: "",
    agent_name: "",
    map_url: "",
    tour_page: "",
    address: "",
    instructions: "",
    with_confirm_button: true,
  })

  useEffect(() => {
    setEmailText(
      emailDraftComposer(emailObject.text, {
        NAME: emailObject.client_name,
        TOUR_NAME: emailObject.platform_product_name,
        TOUR_DATE: emailObject.date,
        MEETING_POINT: emailObject.pickup_location_name,
        MEETING_TIME: emailObject.pickup_time,
        AGENT_NAME: emailObject.agent_name,
        MAP_URL: emailObject.map_url,
        TOUR_PAGE: emailObject.tour_page,
        ADDRESS: emailObject.address,
        INSTRUCTIONS: emailObject.instructions,
      })
    )
  }, [emailObject])

  useEffect(() => {
    if (copyEmailText) {
      navigator.clipboard.writeText(copyEmailText)
    }
  }, [copyEmailText])

  const confirmButton = withConfirmButton
    ? `
    
CLICK TO CONFIRM - ${tour_info_page_url}${values.ref}&confirmed=true`
    : ""

  //-------------------------------------------------------------------

  return (
    <SectionWrapper>
      <SectionContainer>
        <span className="form-header">Client info</span>
      </SectionContainer>
      <SectionContainer>
        <FieldWrapper>
          <FieldContainer
            title="Client name"
            style={{
              flex: 2,
              backgroundColor: values.client_name ? "#d2ffd2" : "#ffdcdd",
            }}
          >
            <Input
              type="text"
              placeholder="Client name"
              name="client_name"
              onChange={handleChange}
              value={values.client_name}
              autoComplete="off"
              spellCheck="false"
            />
          </FieldContainer>
          {product?.pricing_options?.map((pricingOption, index) => {
            return (
              <FieldContainer title={pricingOption} key={index}>
                <select
                  onChange={async (e) => {
                    await setFieldValue(
                      `tickets.${pricingOption}`,
                      parseInt(e.target.value)
                    )
                  }}
                  value={
                    values.tickets[pricingOption]
                      ? values.tickets[pricingOption]
                      : 0
                  }
                >
                  {Array.from({ length: 100 }, (v, i) => (
                    <option value={i} key={i}>
                      {i} {pricingOption}
                    </option>
                  ))}
                </select>
              </FieldContainer>
            )
          })}
        </FieldWrapper>
        <FieldWrapper>
          <FieldContainer
            title="Client email"
            style={{
              // backgroundColor: values.client_email
              //   ? "#d2ffd2"
              //   : "#ffdcdd",
              backgroundColor: values.client_email
                ? errors.client_email
                  ? "#ffdcdd"
                  : "#d2ffd2"
                : "whitesmoke",
            }}
          >
            <Input
              type="text"
              placeholder="Email @"
              name="client_email"
              onChange={handleChange}
              value={values.client_email}
              autoComplete="off"
              spellCheck="false"
              style={{
                textTransform: "none",
              }}
            />
          </FieldContainer>
          {values?._id && //only existing booking
            values.client_email && //only if email is set
            !errors.client_email && ( //only if email is valid
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip
                    style={{
                      position: "absolute",
                    }}
                  >
                    {isEmailFormOpen
                      ? "Close messaging form"
                      : "Open messaging form"}
                  </Tooltip>
                }
              >
                <FieldContainer
                  style={{ flex: 0, minWidth: "110px" }}
                  onClick={() => {
                    setIsEmailFormOpen(!isEmailFormOpen)
                    setEmailText("")
                  }}
                >
                  <i
                    className={`fa-solid fa-${
                      isEmailFormOpen ? "times" : "envelope"
                    } icon`}
                    style={{
                      color: isEmailFormOpen ? "indianred" : "#636363",
                      fontSize: isEmailFormOpen ? "20px" : "14px",
                    }}
                  ></i>
                </FieldContainer>
              </OverlayTrigger>
            )}
        </FieldWrapper>
        {isEmailFormOpen && (
          <>
            <FieldWrapper>
              <FieldContainer
                title="Select draft"
                // style={{
                //   backgroundColor: "#e9f4ff",
                // }}
              >
                <select
                  id="email-draft-select"
                  onChange={(e) => {
                    if (!e.target.value) {
                      setEmailText("")
                    } else {
                      setEmailObject({
                        text: JSON.parse(e.target.value).body,
                        html: JSON.parse(e.target.value).html,
                        client_name: values.client_name,
                        platform_product_name: product.platform_product_name,
                        date: moment(values.date).format("DD MMMM YYYY"),
                        pickup_location_name: values.pickup_location.name,
                        pickup_time:
                          values.pickup_time &&
                          moment(values.pickup_time, "HH:mm").format("hh:mm a"),
                        agent_name: user.name,
                        map_url: values.pickup_location.google_maps_url,
                        tour_page: `${tour_info_page_url}${values.ref}`,
                        address: values.pickup_location.address,
                        instructions: values.pickup_location.instructions,
                      })
                    }
                  }}
                >
                  <option value={""}>No draft selected</option>
                  {emailDrafts.map((draft, index) => (
                    <option value={JSON.stringify(draft)} key={index}>
                      {draft.title}
                    </option>
                  ))}
                </select>
              </FieldContainer>
            </FieldWrapper>

            <FieldWrapper>
              <div
                title="Email form"
                style={{
                  padding: "5px 5px 0 5px",
                  flexDirection: "column",
                  background: "white",
                  gap: "10px",
                  width: "100%",
                  borderRadius: "5px",
                }}
              >
                <div
                  style={{
                    color: "#298beb",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flex: 1,
                    width: "100%",
                    fontWeight: "bold",
                    letterSpacing: "0.3px",
                  }}
                >
                  MESSAGES HISTORY
                </div>
                {values?.email_history.map((email, index) => (
                  <EmailMessage key={index} email={email} />
                ))}

                {noEmails && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "14px",
                        color: "indianred",
                        userSelect: "none",
                      }}
                    >
                      Email history is empty
                    </span>
                  </div>
                )}

                <div
                  style={{
                    color: "#298beb",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flex: 1,
                    width: "100%",
                    fontWeight: "bold",
                    letterSpacing: "0.3px",
                  }}
                >
                  TYPE MESSAGE BELOW
                </div>
                <div
                  style={{
                    flex: 1,
                    width: "100%",
                    height: "100%",
                    position: "relative",
                  }}
                >
                  <EmailTextarea
                    placeholder="Type a message or select a draft"
                    autoComplete="off"
                    spellCheck="false"
                    autoCorrect="off"
                    autoCapitalize="off"
                    name="email_form"
                    onChange={(e) => {
                      setEmailText(e.target.value)
                    }}
                    value={emailText}
                    className="exclude-from-submit-to-enter"
                  ></EmailTextarea>
                  <CustomMaterialSwitch
                    label={
                      withConfirmButton
                        ? "With confirm button"
                        : "Without confirm button"
                    }
                    checked={withConfirmButton}
                    set={(e) => {
                      setWithConfirmButton(e.target.checked)
                    }}
                  />
                  <OverlayTrigger
                    rootClose
                    placement="left-start"
                    trigger="click"
                    overlay={
                      <Popover
                        id="popover-basic"
                        style={{
                          position: "absolute",
                        }}
                      >
                        <Popover.Body
                          style={{
                            padding: "5px",
                            minWidth: "150px",
                            display: "flex",
                            flexDirection: "column",
                            gap: "5px",
                            overflow: "hidden",
                          }}
                        >
                          <OverlayTrigger
                            placement="left"
                            overlay={
                              <Tooltip
                                style={{
                                  position: "absolute",
                                }}
                              >
                                Preview email
                              </Tooltip>
                            }
                          >
                            <TextAreaOptionsItem
                              onClick={() => {
                                const popupContent = `<!DOCTYPE html>
                                                      <html lang="en">
                                                      
                                                      <head>
                                                        <meta charset="UTF-8">
                                                        <meta name="viewport" content="width=device-width, initial-scale=1.0">
                                                        <link href="https://fonts.googleapis.com/css2?family=Lato:wght@400&display=swap" rel="stylesheet">
                                                        <script>
                                                          function resizeWindow() {
                                                            const body = document.body;
                                                            const width = body.scrollWidth;
                                                            const height = body.scrollHeight;
                                                      
                                                            // Resize the popup window to fit its content
                                                            //window.resizeTo(width ,height);
                                                          }
                                                      
                                                          //window.addEventListener("load", resizeWindow);
                                                        </script>
                                                      
                                                      </head>
                                                      
                                                      <body style="margin: 0; padding: 10px; background-color: whitesmoke; font-family: Arial, sans-serif; min-width:500px;">
                                                        <table width="100%" height="100%" cellpadding="0" cellspacing="0" border="0"
                                                          style="padding: 0; margin: 0; height: 100%;">
                                                          <tr>
                                                            <td valign="middle" align="center">
                                                              <div class="inner-container"
                                                                style="width: 100%; max-width: 600px; padding: 30px; box-sizing: border-box; border-radius: 3px; background-color: white; display: block;">
                                                                <div class="logo-row" style="text-align: center; display: inline-block;">
                                                                  <img id="logo" src="https://i.ibb.co/SmCF1z9/1.png" alt="logo" style="width: 50px; height: 50px; display: inline-block; margin-right: 5px;" />
                                                                  <img id="logo_text" src="https://i.ibb.co/hLPW68B/gg-logo-only-text.png" alt="logo" style="width: 200px; height: 50px; display: inline-block;" />
                                                                                                                          </div>
                                                                  <p id="msg_body"
                                                                    style="text-align: left; font-family: 'Lato', Arial, sans-serif; font-size: 15px; margin-top: 20px;">
                                                                    ${emailText.replace(
                                                                      /\n/g,
                                                                      "<br/>"
                                                                    )}
                                                  </p>
                                                                    <a onclick="window.alert('This button redirects to client booking portal and confirms instructions')"
                    style="display: inline-block; width: 100%; background-color: #588637; color: white; text-align: center; text-decoration: none; font-family: 'Lato', Arial, sans-serif; font-size: 16px; padding: 12px 0; border-radius: 3px; margin-top: 20px;">CLICK TO CONFIRM</a>
                                                                </div>
                                                            </td>
                                                          </tr>
                                                        </table>
                                                      </body>
                                                      
                                                      </html>`

                                if (emailText.length === 0) {
                                  alert("Email body is empty")
                                  return
                                }

                                const newWindow = window.open(
                                  "",
                                  "_blank",
                                  "width=700,height=900"
                                )
                                newWindow.document.write(popupContent)
                                newWindow.document.close()
                              }}
                            >
                              <i className="fa-solid fa-eye"></i>
                            </TextAreaOptionsItem>
                          </OverlayTrigger>
                          <OverlayTrigger
                            placement="left"
                            overlay={
                              <Tooltip
                                style={{
                                  position: "absolute",
                                }}
                              >
                                Copy text
                              </Tooltip>
                            }
                          >
                            <TextAreaOptionsItem
                              onClick={() => {
                                if (emailText.length === 0) {
                                  alert("Email body is empty")
                                  return
                                }

                                setCopyEmailText(emailText)
                              }}
                              onMouseLeave={() => setCopyEmailText(null)}
                            >
                              <i
                                style={{
                                  marginLeft: "5px",
                                  fontSize: "11px",
                                }}
                                className={
                                  copyEmailText
                                    ? "fas fa-check"
                                    : "fa-regular fa-copy"
                                }
                              ></i>
                            </TextAreaOptionsItem>
                          </OverlayTrigger>
                        </Popover.Body>
                      </Popover>
                    }
                  >
                    <TextAreaOptionsButton>
                      <i className="fa-solid fa-bars"></i>
                    </TextAreaOptionsButton>
                  </OverlayTrigger>
                </div>
              </div>
            </FieldWrapper>

            <FieldWrapper>
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip
                    style={{
                      position: "absolute",
                    }}
                  >
                    <span>Send SMS/iMessage</span>

                    {isIMessageQrShown && (
                      <CustomQrCode
                        phoneNumberOrEmail={values.client_phone.replace(
                          /[^0-9+]/g,
                          ""
                        )}
                        messageBody={`${emailText}${confirmButton}`}
                        platform={"sms"}
                      />
                    )}
                  </Tooltip>
                }
                onToggle={(isShown) => {
                  if (!isShown) {
                    setIMessageQrShown(false)
                  }
                }}
              >
                <FieldContainer
                  style={{
                    cursor: "pointer",
                    height: "60px",
                  }}
                  onClick={async () => {
                    if (window.confirm("Send SMS/iMessage?")) {
                      const phoneNumber = values.client_phone.replace(
                        /[^0-9+]/g,
                        ""
                      )
                      const deepLink = `sms:${phoneNumber}&body=${encodeURIComponent(
                        `${emailText}${confirmButton}`
                      )}`
                      window.open(deepLink, "_blank")
                    }
                  }}
                >
                  <i
                    style={{
                      color: "black",
                      fontSize: "20px",
                    }}
                    className="fa-regular fa-comment icon"
                  ></i>
                  <OverlayTrigger
                    placement="auto"
                    overlay={
                      <Tooltip
                        style={{
                          position: "absolute",
                        }}
                      >
                        <span>Show QR</span>
                      </Tooltip>
                    }
                  >
                    <QrButtonOverlay
                      onClick={(event) => {
                        event.stopPropagation()
                        setIMessageQrShown(true)
                      }}
                    >
                      <i className="fa-solid fa-qrcode"></i>
                    </QrButtonOverlay>
                  </OverlayTrigger>
                </FieldContainer>
              </OverlayTrigger>
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip
                    style={{
                      position: "absolute",
                    }}
                  >
                    <span>Send WhatsApp message</span>

                    {isWhatsappQrShown && (
                      <CustomQrCode
                        phoneNumberOrEmail={values.client_phone.replace(
                          /[^0-9+]/g,
                          ""
                        )}
                        messageBody={`${emailText}${confirmButton}`}
                        platform={"whatsapp"}
                      />
                    )}
                  </Tooltip>
                }
                onToggle={(isShown) => {
                  if (!isShown) {
                    setWhatsappQrShown(false)
                  }
                }}
              >
                <FieldContainer
                  style={{
                    cursor: "pointer",
                    height: "60px",
                  }}
                  onClick={async () => {
                    if (window.confirm("Send WhatsApp?")) {
                      //deep link to whatsapp
                      const num = values.client_phone.replace(/[^0-9+]/g, "")
                      const encodedEmailText = `${emailText}${confirmButton}`
                        .split("\n")
                        .map((line) => encodeURIComponent(line))
                        .join("%0A")
                      window.open(
                        `whatsapp://send?text=${encodedEmailText}&phone=+${num}&abid=+${num}`,
                        "_blank"
                      )
                    }
                  }}
                >
                  <i
                    style={{
                      color: "#009300",
                      fontSize: "20px",
                    }}
                    className="fa-brands fa-whatsapp icon"
                  ></i>
                  <OverlayTrigger
                    placement="auto"
                    overlay={
                      <Tooltip
                        style={{
                          position: "absolute",
                        }}
                      >
                        <span>Show QR</span>
                      </Tooltip>
                    }
                  >
                    <QrButtonOverlay
                      onClick={(event) => {
                        event.stopPropagation()
                        setWhatsappQrShown(true)
                      }}
                    >
                      <i className="fa-solid fa-qrcode"></i>
                    </QrButtonOverlay>
                  </OverlayTrigger>
                </FieldContainer>
              </OverlayTrigger>
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip
                    style={{
                      position: "absolute",
                    }}
                  >
                    <span>Send email</span>

                    {isEmailQrShown && (
                      <CustomQrCode
                        phoneNumberOrEmail={values.client_email}
                        messageBody={`${emailText}${confirmButton}`}
                        platform={"email"}
                      />
                    )}
                  </Tooltip>
                }
                onToggle={(isShown) => {
                  if (!isShown) {
                    setEmailQrShown(false)
                  }
                }}
              >
                <FieldContainer
                  style={{
                    cursor: "pointer",
                    height: "60px",
                  }}
                  onClick={async () => {
                    try {
                      if (window.confirm("Send email?")) {
                        const response = await sendEmail({
                          client_email: values.client_email,
                          message_body: emailText,
                          agent_id: user._id,
                          booking_id: values?._id || null,
                          with_confirm_button: withConfirmButton,
                        })

                        await setFieldValue("email_history", response.data)
                        toast.success("Email sent successfully")
                        await setFieldValue("client_messaged", true)
                      }
                    } catch (err) {
                      console.error(err)
                      toast.error("Email not sent")
                    }
                  }}
                >
                  {isSendEmailLoading ? (
                    <i
                      style={{
                        fontSize: "13px",
                        color: "black",
                      }}
                      className="fa-solid fa-circle-notch spinner icon"
                    ></i>
                  ) : (
                    <>
                      <i
                        style={{
                          color: "black",
                          fontSize: "20px",
                        }}
                        className="fa-solid fa-envelope icon"
                      ></i>
                      <OverlayTrigger
                        placement="auto"
                        overlay={
                          <Tooltip
                            style={{
                              position: "absolute",
                            }}
                          >
                            <span>Show QR</span>
                          </Tooltip>
                        }
                      >
                        <QrButtonOverlay
                          onClick={(event) => {
                            event.stopPropagation()
                            setEmailQrShown(true)
                          }}
                        >
                          <i className="fa-solid fa-qrcode"></i>
                        </QrButtonOverlay>
                      </OverlayTrigger>
                    </>
                  )}
                </FieldContainer>
              </OverlayTrigger>
            </FieldWrapper>
          </>
        )}
        ;
        <FieldWrapper>
          <FieldContainer title="Client telephone number">
            <Input
              type="text"
              placeholder="Tel ☎"
              name="client_phone"
              onChange={handleChange}
              value={values.client_phone}
              autoComplete="off"
              spellCheck="false"
            />
          </FieldContainer>
          <FieldContainer title="Client message status">
            <select
              onChange={async (e) => {
                if (e.target.value === "") {
                  await setFieldValue("client_messaged", false)
                } else {
                  await setFieldValue("client_messaged", e.target.value)
                }
              }}
              value={values.client_messaged}
            >
              <option value={false}>Confirmation message not sent</option>
              <option value={true}>Confirmation message sent</option>
            </select>
          </FieldContainer>
          <FieldContainer title="Client response status">
            <select
              onChange={async (e) => {
                await setFieldValue("client_response_status", e.target.value)
              }}
              value={values.client_response_status}
            >
              <option value="PENDING">Pending confirmation</option>
              <option value="CONFIRMED">Confirmed</option>
            </select>
          </FieldContainer>
          {/* <OverlayTrigger
                                  placement="top"
                                  overlay={
                                    <Tooltip
                                      style={{
                                        position: "absolute",
                                      }}
                                    >
                                      Get tour info url
                                    </Tooltip>
                                  }
                                >
                                  <FieldContainer
                                    style={{ flex: 0, minWidth: "60px" }}
                                  >
                                    <i className="fa-solid fa-circle-info icon"></i>
                                  </FieldContainer>
                                </OverlayTrigger> */}
        </FieldWrapper>
      </SectionContainer>
    </SectionWrapper>
  )
}

export default ClientInfoSection;
