import styled from "styled-components";
import { motion } from "framer-motion";
import {
  useFetchEntities,
  useAddOrDeleteVehicleIdToTourGroup,
  useAddOrDeleteVehiclePlatformEntryToTourGroup,
} from "../../../../reactQueryHooks";
import CustomSelect from "../../tour_guides/content/CustomSelect";
import Button from "@mui/material/Button";

//---------------------------------------------------------

const Modal = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(100, 100, 100, 0.8);
  border-radius: 5px;
  padding: 5px;
  overflow: hidden;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  user-select: none;
`;

const Wrapper = styled(motion.div)`
  min-width: 300px;
  max-width: 80%;
  max-height: 85%;
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const Container = styled(motion.div)`
  border-radius: 5px;
  padding: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
  overflow: hidden;
  background-color: white;
`;

const Header = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const CloseButton = styled.button`
  border: none;
  color: white;
  height: 35px;
  width: 35px;
  border-radius: 4px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: absolute;
  top: 5px;
  right: 5px;
  i {
    font-size: 20px;
  }
  &:hover {
    i {
      color: indianred;
    }
  }
`;

//---------------------------------------------------------

const AddVehicleModal = ({ close, tourGroup, shouldShowButton, refetch }) => {
  const { data: entities } = useFetchEntities();
  const { mutateAsync: addOrDeleteVehicleIdToTourGroup } =
    useAddOrDeleteVehicleIdToTourGroup();
  const { mutateAsync: addOrDeleteVehiclePlatformEntryToTourGroup } =
    useAddOrDeleteVehiclePlatformEntryToTourGroup();

  const vehicles = entities.data.vehicles;
  const vehicle = vehicles.find(
    (vehicle) => vehicle._id === tourGroup?.vehicle_id
  );

  //-----------------------------------

  return (
    <Modal onClick={(e) => e.stopPropagation()}>
      <CloseButton
        onClick={() => {
          close();
        }}
      >
        <i className="fas fa-times"></i>
      </CloseButton>
      <Wrapper
        initial={{ opacity: 0, scale: 0 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{
          opacity: { duration: 0.2 },
          scale: {
            //type: "spring",
            // stiffness: 300, // Adjust stiffness for more/less bounce
            // damping: 20, // Adjust damping for faster/slower settle
            // duration: 0.02,
          },
        }}
      >
        <Header>
          <span
            style={{
              color: "white",
              fontSize: "17px",
            }}
          >
            Add vehicle
          </span>
        </Header>
        <Container>
          <CustomSelect
            data={vehicles.map((vehicle) => {
              return {
                label: vehicle.plate,
                value: vehicle._id,
              };
            })}
            selected_id={tourGroup.vehicle_id}
            onSelect={async (vehicle_id) => {
              await addOrDeleteVehicleIdToTourGroup({
                tour_group_id: tourGroup._id,
                vehicle_id: vehicle_id,
              });
              refetch();
            }}
          />
          {shouldShowButton && (
            <Button
              style={{
                width: "100%",
              }}
              variant="contained"
              onClick={async () => {
                try {
                  await addOrDeleteVehiclePlatformEntryToTourGroup({
                    tour_group_id: tourGroup._id,
                    vehicle_platform_entry: tourGroup.vehicle_id,
                  });
                  refetch();
                } catch (error) {
                  console.log(error);
                  alert("Error adding platform entry to tour group");
                }
              }}
            >
              {`PLATFORM OK FOR ${vehicle?.plate}`}
            </Button>
          )}
        </Container>
      </Wrapper>
    </Modal>
  );
};

export default AddVehicleModal;
