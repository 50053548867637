import { useState } from "react";
import { motion } from "framer-motion";
import CustomSelect from "../../CustomSelect";
import styled from "styled-components";
import { findBestMatch } from "string-similarity";
import { useFormikContext } from "formik";

//---------------------------------------------------------

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`;

const Modal = styled(motion.div)`
  background-color: white;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  gap: 10px;
  max-width: 400px;
  height: 50%;
  overflow: hidden;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
`;

const TopContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 5px;
`;

const CloseButton = styled.button`
  background-color: whitesmoke;
  border: none;
  color: white;
  height: 30px;
  width: 30px;
  border-radius: 4px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  i {
    color: black;
    font-size: 15px;
  }
  &:hover {
    i {
      color: indianred;
    }
  }
`;

const Input = styled.input`
  flex: 1;
  height: 100%;
  //border: 1px solid lightgray;
  background-color: whitesmoke;
  border-radius: 4px;
  padding: 0 8px;
  /* text-align: center; */

  font-size: 13px;
`;

const ListContainer = styled.div`
  flex: 1;
  overflow: hidden;
  width: 100%;
  height: 100%;
`;

//---------------------------------------------------------

const PickupsListSelectionModal = ({ close, meetingPoints }) => {
  const { values, setFieldValue } = useFormikContext();

  const [searchInput, setSearchInput] = useState(
    values.pickup_location.name
      ? values.pickup_location.name
      : values.client_location
  );

  const selectedData = meetingPoints.filter(
    (point) => point.name === values.pickup_location.name
  );

  const handleInputChange = (event) => {
    setSearchInput(event.target.value);
  };

  const sortMeetingPoints = (searchTerm) => {
    const matches = meetingPoints.map((point) => ({
      ...point,
      similarity: findBestMatch(searchTerm.toLowerCase(), [
        point.name.toLowerCase(),
      ]).bestMatch.rating,
    }));
    matches.sort((a, b) => b.similarity - a.similarity);
    return matches;
  };

  const sortedMeetingPoints = sortMeetingPoints(searchInput);

  const onSelection = async (meetingPoint) => {
    await setFieldValue("pickup_location.name", meetingPoint.name);
    await setFieldValue("pickup_location.latitude", meetingPoint.latitude);
    await setFieldValue("pickup_location.longitude", meetingPoint.longitude);
    await setFieldValue(
      "pickup_location.google_maps_url",
      meetingPoint.google_maps_url
    );
    await setFieldValue("pickup_location.address", meetingPoint.address);
    await setFieldValue("pickup_location.img_url", meetingPoint.img_url);
    await setFieldValue(
      "pickup_location.instructions",
      meetingPoint.instructions
    );
    close();
  };

  return (
    <Wrapper>
      <Modal
        initial={{ opacity: 0, scale: 0 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{
          opacity: { duration: 0.2 },
          scale: { duration: 0.1 },
        }}
      >
        <TopContainer>
          <Input
            className="exclude-from-submit-to-enter"
            autoFocus
            placeholder="Search..."
            autoCapitalize="none"
            autoComplete="off"
            autoCorrect="off"
            spellCheck="false"
            value={searchInput}
            onChange={handleInputChange}
          />
          <CloseButton onClick={() => close()}>
            <i className="fas fa-times"></i>
          </CloseButton>
        </TopContainer>
        <ListContainer>
          <CustomSelect
            data={sortedMeetingPoints.map((meetingPoint) => {
              return {
                value: meetingPoint._id,
                label: meetingPoint.name,
              };
            })}
            selectedData={{
              value: selectedData[0]?._id,
              label: selectedData[0]?.name,
            }}
            onSelect={(selectedData) => {
              const meetingPoint = meetingPoints.find(
                (point) => point._id === selectedData.value
              );
              onSelection(meetingPoint);
            }}
          />
        </ListContainer>
      </Modal>
    </Wrapper>
  );
};

export default PickupsListSelectionModal;
