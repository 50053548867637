import styled from "styled-components";
import { motion, AnimatePresence } from "framer-motion";
import { useState } from "react";

//--------------------------------------------

const Container = styled.div`
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
  font-size: 12px;
  overflow: hidden;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: white;
  padding: 5px;
  border-radius: 3px;
  align-items: center;
  user-select: none;
  cursor: pointer;
  i {
    transform: ${({ isDropdownOpen }) =>
      isDropdownOpen ? "rotateX(180deg)" : "rotateX(0deg)"};
    transition: transform 0.3s ease-in-out;
    font-size: 12px;
    color: black;
  }
  &:hover {
    background-color: ${(props) => props.theme.colors.blue};
    color: white;
    select {
      color: white;
    }
    i {
      color: white;
    }
  }
`;

const ListContainer = styled(motion.div)`
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 3px;
  padding: 5px;
  overflow: hidden;
`;

//--------------------------------------------

const SelectContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 5px;
  overflow-y: auto;
  padding-right: 10px;
  ::-webkit-scrollbar {
    width: 3px;
  }
  ::-webkit-scrollbar-thumb {
    background: #91c9ff;
    /* border-radius: 50px; */
  }
`;

const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  user-select: none;
  cursor: default;
  .fa-trash {
    display: none;
    cursor: pointer;
  }
  :hover {
    div {
      opacity: 0.6;
    }
    .fa-trash {
      display: block;
    }
    .fa-check {
      display: none;
    }
  }
`;

//-------------------------------------------

const CustomSelect = ({ filters, selectedLabel, onSet, onDelete }) => {
  /*
DATA EXAMPLE:
[
    {
        label: Filter label /string,
        value: {
          products : array of ids,
          channels : array of ids,
        }
    },
]
*/

  return (
    <SelectContainer>
      {filters.map((item, index) => {
        const isSelected = item.label === selectedLabel;
        return (
          <RowContainer key={index} onClick={() => onSet(item)}>
            <div>{item.label}</div>

            {isSelected && (
              <i
                style={{
                  color: "darkgreen",
                }}
                className="fas fa-check"
              ></i>
            )}

            <i
              style={{ color: "indianred" }}
              className="fas fa-trash"
              onClick={(event) => {
                event.stopPropagation(); // Prevent event bubbling to parent container
                onDelete(item);
              }}
            ></i>
          </RowContainer>
        );
      })}
    </SelectContainer>
  );
};

//--------------------------------------------

const SaveFiltersDropdown = ({ filters, selectedLabel, onSet, onDelete }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const handleSelect = (filter) => {
    onSet(filter);
  };

  const handleDelete = (filter) => {
    onDelete(filter);
  };

  return (
    <Container>
      <Header
        onClick={() => setIsDropdownOpen(!isDropdownOpen)}
        isDropdownOpen={isDropdownOpen}
      >
        <span>Saved filters</span>
        <i className="fa-solid fa-angle-down"></i>
      </Header>
      <AnimatePresence>
        {isDropdownOpen && (
          <ListContainer
            initial={{ opacity: 0, y: -10 }}
            animate={{
              opacity: 1,
              y: 0,
              transition: {
                duration: 0.2,
              },
            }}
            //exit={{ opacity: 0, y: -10 }}
          >
            {filters.length > 0 ? (
              <CustomSelect
                filters={filters}
                selectedLabel={selectedLabel}
                onSet={handleSelect}
                onDelete={handleDelete}
              />
            ) : (
              <div
                style={{
                  padding: "10px",
                  textAlign: "center",
                  color: "indianred",
                }}
              >
                No saved filters
              </div>
            )}
          </ListContainer>
        )}
      </AnimatePresence>
    </Container>
  );
};

export default SaveFiltersDropdown;
