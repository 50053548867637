import * as XLSX from "xlsx";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import moment from "moment";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

//----------------------------------------------------------------------

const saveExcelFile = (buffer, filename) => {
  const blob = new Blob([buffer], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });
  const url = URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = url;
  link.download = filename;
  link.click();
  URL.revokeObjectURL(url);
};

const generateExcelFromDayPlanner = (title, bookings) => {
  const allSeatsCount = bookings.reduce((acc, booking) => {
    let seatsCount = Object.keys(booking.tickets)
      //.filter((key) => key.toLowerCase() !== "infant")
      .reduce((acc, key) => acc + booking.tickets[key], 0);
    if (booking.cancelled) seatsCount = 0;
    return acc + seatsCount;
  }, 0);

  const worksheet = XLSX.utils.aoa_to_sheet([
    [
      "#",
      "REF",
      "Client",
      "Seats",
      "Location",
      "Pickup",
      "Time",
      "Channel",
      "Status",
      "Notes",
    ],
    ...bookings.map((booking, index) => {
      const seatsCount = Object.keys(booking.tickets)
        //.filter((key) => key.toLowerCase() !== "infant")
        .reduce((acc, key) => acc + booking.tickets[key], 0);

      return [
        index + 1,
        booking.ref || "",
        booking.client_name || "",
        seatsCount,
        booking.client_location || "",
        booking.pickup_location?.name || "",
        booking.pickup_time || "",
        booking.channel.title || "",
        booking.client_response_status || "",
        booking.notes || "",
      ];
    }),
    ["", "", "", allSeatsCount, "", "", "", "", "", ""],
  ]);

  // Set cell styles for left alignment
  const range = XLSX.utils.decode_range(worksheet["!ref"]);
  for (let row = range.s.r; row <= range.e.r; row++) {
    for (let col = range.s.c; col <= range.e.c; col++) {
      const cellAddress = XLSX.utils.encode_cell({ r: row, c: col });
      const cell = worksheet[cellAddress];

      // Apply left alignment style
      cell.s = { alignment: { horizontal: "left" } };
    }
  }

  // Adjust column widths based on content
  const columnWidths = [];
  for (let col = range.s.c; col <= range.e.c; col++) {
    let maxLength = 0;
    for (let row = range.s.r; row <= range.e.r; row++) {
      const cellAddress = XLSX.utils.encode_cell({ r: row, c: col });
      const cell = worksheet[cellAddress];
      if (cell && cell.t === "s" && cell.v) {
        const cellValue = cell.v.toString();
        maxLength = Math.max(maxLength, cellValue.length);
      }
    }
    const width = maxLength > 0 ? Math.ceil((maxLength + 2) * 1.2) : 10;
    columnWidths[col] = { wch: width };
  }
  worksheet["!cols"] = columnWidths;

  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

  const excelBuffer = XLSX.write(workbook, {
    type: "buffer",
    bookType: "xlsx",
  });

  saveExcelFile(excelBuffer, `${title.replace(/ /g, "_")}.xlsx`);
};

const generateExcelFromBookingsManager = (bookings) => {
  // Create the initial worksheet with bookings data
  const worksheet = XLSX.utils.aoa_to_sheet([
    [
      "#",
      "CONFIRMATION #",
      "DATE",
      "CLIENT NAME",
      "PAX",
      "PRODUCT",
      "TEL",
      "EMAIL",
      "NET IN EURO",
    ],
    ...bookings.map((booking, index) => {
      let pax = Object.entries(booking.tickets)
        .map(([key, value]) => `${value} ${key}`)
        .join(", ")

      return [
        index + 1,
        booking.ref || "",
        moment(booking.date).format("DD MMM YYYY") || "",
        booking.client_name || "",
        pax,
        booking.product.platform_product_name || "",
        booking.client_phone || "N/A",
        booking.client_email || "N/A",
        booking.total_paid || "N/A",
      ]
    }),
  ])

  // Append the new rows to the worksheet
  const balanceDueRowIndex = bookings.length + 2 // Row index for "BALANCE DUE" (1-based index)
  worksheet[`I${balanceDueRowIndex}`] = { t: "s", v: "BALANCE DUE" }

  // Set the formula to calculate the sum of the "NET IN EURO" column
  const totalPaidFormula = `SUM(I2:I${balanceDueRowIndex - 1})`
  worksheet[`I${balanceDueRowIndex + 1}`] = { f: totalPaidFormula }

  // Update the range to include the new rows
  worksheet["!ref"] = `A1:I${balanceDueRowIndex + 1}`

  // Set cell styles for center alignment
  const range = XLSX.utils.decode_range(worksheet["!ref"])
  for (let row = range.s.r; row <= range.e.r; row++) {
    for (let col = range.s.c; col <= range.e.c; col++) {
      const cellAddress = XLSX.utils.encode_cell({ r: row, c: col })
      const cell = worksheet[cellAddress]

      // Apply center alignment style if cell exists
      if (cell) {
        cell.s = { alignment: { horizontal: "center" } }
      }
    }
  }

  // Adjust column widths based on content
  const columnWidths = []
  for (let col = range.s.c; col <= range.e.c; col++) {
    let maxLength = 0
    for (let row = range.s.r; row <= range.e.r; row++) {
      const cellAddress = XLSX.utils.encode_cell({ r: row, c: col })
      const cell = worksheet[cellAddress]
      if (cell && cell.v) {
        const cellValue = cell.v.toString()
        maxLength = Math.max(maxLength, cellValue.length)
      }
    }
    const width = maxLength > 0 ? Math.ceil((maxLength + 2) * 1.2) : 10
    columnWidths[col] = { wch: width }
  }
  worksheet["!cols"] = columnWidths

  // Create the workbook and append the worksheet
  const workbook = XLSX.utils.book_new()
  XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1")

  // Generate the Excel buffer and save the file
  const excelBuffer = XLSX.write(workbook, {
    type: "buffer",
    bookType: "xlsx",
  })

  saveExcelFile(excelBuffer, `${Date.now()}.xlsx`)
}

// const generateExcelFromBookingsManager = (bookings) => {
//   // Create the initial worksheet with bookings data
//   const worksheet = XLSX.utils.aoa_to_sheet([
//     [
//       "#",
//       "CONFIRMATION #",
//       "DATE",
//       "CLIENT NAME",
//       "PAX",
//       "PRODUCT",
//       "TEL",
//       "EMAIL",
//       "NET IN EURO",
//     ],
//     ...bookings.map((booking, index) => {
//       let pax = Object.entries(booking.tickets)
//         .map(([key, value]) => `${value} ${key}`)
//         .join(", ")

//       return [
//         index + 1,
//         booking.ref || "",
//         moment(booking.date).format("DD/MM/YYYY") || "",
//         booking.client_name || "",
//         pax,
//         booking.product.platform_product_name || "",
//         booking.client_phone || "N/A",
//         booking.client_email || "N/A",
//         booking.total_paid || "N/A",
//       ]
//     }),
//   ])

//   // Calculate the accumulated value of all bookings total_paid
//   const totalPaid = bookings.reduce(
//     (acc, booking) => acc + (parseFloat(booking.total_paid) || 0),
//     0
//   )

//   // Append the new rows to the worksheet
//   const balanceDueRowIndex = bookings.length + 2 // Row index for "BALANCE DUE" (1-based index)
//   worksheet[`I${balanceDueRowIndex}`] = { t: "s", v: "BALANCE DUE" }
//   worksheet[`I${balanceDueRowIndex + 1}`] = { t: "n", v: totalPaid }

//   // Update the range to include the new rows
//   worksheet["!ref"] = `A1:I${balanceDueRowIndex + 1}`

//   // Set cell styles for left alignment
//   const range = XLSX.utils.decode_range(worksheet["!ref"])
//   for (let row = range.s.r; row <= range.e.r; row++) {
//     for (let col = range.s.c; col <= range.e.c; col++) {
//       const cellAddress = XLSX.utils.encode_cell({ r: row, c: col })
//       const cell = worksheet[cellAddress]

//       // Apply left alignment style if cell exists
//       if (cell) {
//         cell.s = { alignment: { horizontal: "left" } }
//       }
//     }
//   }

//   // Adjust column widths based on content
//   const columnWidths = []
//   for (let col = range.s.c; col <= range.e.c; col++) {
//     let maxLength = 0
//     for (let row = range.s.r; row <= range.e.r; row++) {
//       const cellAddress = XLSX.utils.encode_cell({ r: row, c: col })
//       const cell = worksheet[cellAddress]
//       if (cell && cell.t === "s" && cell.v) {
//         const cellValue = cell.v.toString()
//         maxLength = Math.max(maxLength, cellValue.length)
//       }
//     }
//     const width = maxLength > 0 ? Math.ceil((maxLength + 2) * 1.2) : 10
//     columnWidths[col] = { wch: width }
//   }
//   worksheet["!cols"] = columnWidths

//   // Create the workbook and append the worksheet
//   const workbook = XLSX.utils.book_new()
//   XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1")

//   // Generate the Excel buffer and save the file
//   const excelBuffer = XLSX.write(workbook, {
//     type: "buffer",
//     bookType: "xlsx",
//   })

//   saveExcelFile(excelBuffer, `${Date.now()}.xlsx`)
// }


const generateExcelFromPortalLogs = (portalLogs) => {
  const worksheet = XLSX.utils.aoa_to_sheet([
    [
      "#",
      "Session timestamp",
      "Client Name",
      "Client TEL",
      "Booking REF",
      "Platform",
      "Browser",
      "Mobile Model",
      "Mobile Vendor",
      "OS Name",
      "OS Version",
    ],
    ...portalLogs.map((portalLog, index) => {
      return [
        index + 1,
        moment(portalLog.date_time).format("DD/MM/YYYY HH:mm:ss") || "",
        portalLog.client_name || "N/A",
        portalLog.client_phone || "N/A",
        portalLog.booking_ref || "N/A",
        portalLog.device_info.platform || "N/A",
        portalLog.device_info.browserName || "N/A",
        portalLog.device_info.mobileModel || "N/A",
        portalLog.device_info.mobileVendor || "N/A",
        portalLog.device_info.osName || "N/A",
        portalLog.device_info.osVersion || "N/A",
      ];
    }),
  ]);

  // Set cell styles for left alignment
  const range = XLSX.utils.decode_range(worksheet["!ref"]);
  for (let row = range.s.r; row <= range.e.r; row++) {
    for (let col = range.s.c; col <= range.e.c; col++) {
      const cellAddress = XLSX.utils.encode_cell({ r: row, c: col });
      const cell = worksheet[cellAddress];

      // Apply left alignment style
      cell.s = { alignment: { horizontal: "left" } };
    }
  }

  // Adjust column widths based on content
  const columnWidths = [];
  for (let col = range.s.c; col <= range.e.c; col++) {
    let maxLength = 0;
    for (let row = range.s.r; row <= range.e.r; row++) {
      const cellAddress = XLSX.utils.encode_cell({ r: row, c: col });
      const cell = worksheet[cellAddress];
      if (cell && cell.t === "s" && cell.v) {
        const cellValue = cell.v.toString();
        maxLength = Math.max(maxLength, cellValue.length);
      }
    }
    const width = maxLength > 0 ? Math.ceil((maxLength + 2) * 1.2) : 10;
    columnWidths[col] = { wch: width };
  }
  worksheet["!cols"] = columnWidths;

  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

  const excelBuffer = XLSX.write(workbook, {
    type: "buffer",
    bookType: "xlsx",
  });

  saveExcelFile(excelBuffer, `${Date.now()}.xlsx`);
};

const generateExcelFromTourGuidePlanner = (tourGroups) => {
  const worksheet = XLSX.utils.aoa_to_sheet([
    ["#", "DATE", "ACTIVITY", "TIME", "CONFIRMATION DATE"],
    ...tourGroups.map((tourGroup, index) => {
      return [
        index + 1,
        tourGroup.date || "",
        tourGroup.activity || "",
        tourGroup.time || "",
        tourGroup.guide_confirmation || "",
      ];
    }),
  ]);

  // Set cell styles for left alignment
  const range = XLSX.utils.decode_range(worksheet["!ref"]);
  for (let row = range.s.r; row <= range.e.r; row++) {
    for (let col = range.s.c; col <= range.e.c; col++) {
      const cellAddress = XLSX.utils.encode_cell({ r: row, c: col });
      const cell = worksheet[cellAddress];

      // Apply left alignment style
      cell.s = { alignment: { horizontal: "left" } };
    }
  }

  // Adjust column widths based on content
  const columnWidths = [];
  for (let col = range.s.c; col <= range.e.c; col++) {
    let maxLength = 0;
    for (let row = range.s.r; row <= range.e.r; row++) {
      const cellAddress = XLSX.utils.encode_cell({ r: row, c: col });
      const cell = worksheet[cellAddress];
      if (cell && cell.t === "s" && cell.v) {
        const cellValue = cell.v.toString();
        maxLength = Math.max(maxLength, cellValue.length);
      }
    }
    const width = maxLength > 0 ? Math.ceil((maxLength + 2) * 1.2) : 10;
    columnWidths[col] = { wch: width };
  }
  worksheet["!cols"] = columnWidths;

  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

  const excelBuffer = XLSX.write(workbook, {
    type: "buffer",
    bookType: "xlsx",
  });

  saveExcelFile(excelBuffer, `${Date.now()}.xlsx`);
};

const handleXlsxExportFromDayPlanner = (title, bookings) => {
  generateExcelFromDayPlanner(title, bookings);
};

const generatePDF = (title, bookings) => {
  console.log("bookings", bookings);

  const allSeatsCount = bookings.reduce((acc, booking) => {
    let seatsCount = Object.keys(booking.tickets)
      //.filter((key) => key.toLowerCase() !== "infant")
      .reduce((acc, key) => acc + booking.tickets[key], 0);
    if (booking.cancelled) seatsCount = 0;
    return acc + seatsCount;
  }, 0);

  const docDefinition = {
    pageOrientation: "landscape",
    content: [
      { text: title, style: "header" },
      {
        table: {
          headerRows: 1,
          body: [
            [
              "#",
              "REF",
              "Client",
              "Seats",
              "Location",
              "Pickup",
              "Time",
              "Channel",
              "Status",
              "Notes",
            ],
            ...bookings.map((booking, index) => {
              const seatsCount = Object.keys(booking.tickets)
                //.filter((key) => key.toLowerCase() !== "infant")
                .reduce((acc, key) => acc + booking.tickets[key], 0);

              return [
                index + 1,
                booking.ref || "",
                booking.client_name || "",
                seatsCount,
                booking.client_location || "",
                booking.pickup_location?.name || "",
                booking.pickup_time || "",
                booking.channel.title || "",
                booking.client_response_status || "",
                booking.notes || "",
              ];
            }),
            ["", "", "", allSeatsCount, "", "", "", "", "", ""],
          ],
        },
      },
    ],
    styles: {
      header: {
        fontSize: 18,
        bold: true,
        margin: [0, 0, 0, 10],
      },
    },
  };

  pdfMake.createPdf(docDefinition).download(`${title.replace(/ /g, "_")}.pdf`);
};

const handlePdfExport = (title, bookings) => {
  generatePDF(title, bookings);
};

export {
  handleXlsxExportFromDayPlanner,
  generateExcelFromBookingsManager,
  generateExcelFromTourGuidePlanner,
  generateExcelFromPortalLogs,
  handlePdfExport,
};
