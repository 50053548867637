import styled from "styled-components";
import { motion } from "framer-motion";
import {
  useFetchEntities,
  useAddTourGroupToUserSchedule,
  useRemoveTourGroupFromUserSchedule,
  useGetUserDaySchedulesThatIncludesTourGroup
} from "../../../../reactQueryHooks";
import CustomSectionedMultiSelectList from "./CustomSectionedMultiSelectList";
import { useAxios } from "../../../../axiosProvider";

//---------------------------------------------------------

const Modal = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(100, 100, 100, 0.8);
  border-radius: 5px;
  padding: 5px;
  overflow: hidden;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  user-select: none;
`;

const Wrapper = styled(motion.div)`
  min-width: 300px;
  max-width: 80%;
  max-height: 85%;
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const Container = styled(motion.div)`
  border-radius: 5px;
  padding: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
  overflow: hidden;
  background-color: white;
`;

const Header = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const CloseButton = styled.button`
  border: none;
  color: white;
  height: 35px;
  width: 35px;
  border-radius: 4px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: absolute;
  top: 5px;
  right: 5px;
  i {
    font-size: 20px;
  }
  &:hover {
    i {
      color: indianred;
    }
  }
`;

//---------------------------------------------------------

const AddAssigneesModal = ({ close, tourGroup, refetch }) => {
  const { data: entities } = useFetchEntities();
  const { data: assignees } = useGetUserDaySchedulesThatIncludesTourGroup(
    tourGroup._id
  );

  const { mutateAsync: addTourGroupToUserSchedule } =
    useAddTourGroupToUserSchedule();
  const { mutateAsync: removeTourGroupFromUserSchedule } =
    useRemoveTourGroupFromUserSchedule();
  const product = entities.data.products.find(
    (p) => p._id === tourGroup.product_id
  );

  const { user } = useAxios();
  const userIsAdmin = user.isAdmin;
  const userIsPermittedToEditGuidePlan =
    user.permissions.editGuidePlan || userIsAdmin;

  //ALL ROLES EXCEPT 636d1329f2535b158a9acd20 GUIDE if !userIsPermittedToEditGuidePlan alse include
  const productRoles = product.crewRoles.filter(
    (roleId) =>
      roleId !== "636d1329f2535b158a9acd20" || userIsPermittedToEditGuidePlan
  );

  //--- BUILD DATA ------------------------------
  const data = [];
  productRoles.forEach((roleId) => {
    const role = entities.data.roles.find((r) => r._id === roleId);

    const roleData = {
      section: {
        label: role.title,
        value: roleId,
      },
      data: entities.data.staff
        .filter((user) => user.roles.find((r) => r._id === roleId))
        .map((user) => {
          return {
            label: user.name,
            value: user._id,
          };
        }),
    };
    data.push(roleData);
  });

  //--- BUILD SELECTED DATA ------------------------------

  const selectedData = [];

  productRoles.forEach((roleId) => {
    const role = entities.data.roles.find((r) => r._id === roleId);

    const roleData = {
      section: {
        label: role.title,
        value: roleId,
      },
      data: [],
    };
    selectedData.push(roleData);
  });

  selectedData.forEach((section) => {
    //is there assignees for this section based on assignee.role ?
    const assigneesData = assignees.filter(
      (assignee) => assignee.role === section.section.value
    );
    if (assigneesData.length > 0) {
      //there is assignees for this section
      assigneesData.forEach((assignee) => {
        const assigneeData = entities.data.staff.find(
          (user) => user._id === assignee.id
        );
        section.data.push({
          label: assigneeData.name,
          value: assignee.id,
        });
      });
    }
  });

  //-----------------------------------

  const isSelected = (id) => {
    return assignees.some((assignee) => assignee.id === id);
  };

  //-----------------------------------

  return (
    <Modal onClick={(e) => e.stopPropagation()}>
      <CloseButton
        onClick={() => {
          close();
        }}
      >
        <i className="fas fa-times"></i>
      </CloseButton>
      <Wrapper
        initial={{ opacity: 0, scale: 0 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{
          opacity: { duration: 0.2 },
          scale: {
            //type: "spring",
            // stiffness: 300, // Adjust stiffness for more/less bounce
            // damping: 20, // Adjust damping for faster/slower settle
            // duration: 0.02,
          },
        }}
      >
        <Header>
          <span
            style={{
              color: "white",
              fontSize: "17px",
            }}
          >
            Select group assignees
          </span>
          W
        </Header>
        <Container>
          <CustomSectionedMultiSelectList
            data={data}
            selectedData={selectedData}
            onSelect={async (role_id, user_id) => {
              try {
                if (isSelected(user_id)) {
                  const selected = assignees.find(
                    (assignee) => assignee.id === user_id
                  );

                  await removeTourGroupFromUserSchedule({
                    tour_group_id: tourGroup._id,
                    schedule_id: selected.schedule_id,
                    assignee_id: user_id,
                  });
                  refetch();
                } else {
                  await addTourGroupToUserSchedule({
                    tour_group_id: tourGroup._id,
                    role_id: role_id,
                    assignee_id: user_id,
                  });
                  refetch();
                }
              } catch (error) {
                alert(error?.response?.data || "An error occurred");
              }
            }}
          />
        </Container>
      </Wrapper>
    </Modal>
  );
};

export default AddAssigneesModal;
