import { useAxios } from "../../../../axiosProvider";
import { Formik } from "formik";
import * as Yup from "yup";
import styled from "styled-components";
import { motion } from "framer-motion";
import Toggle from "react-toggle";
import {
  useAddNewAnnouncement,
  useUpdateAnnouncement,
  useDeleteAnnouncement,
} from "../../../../reactQueryHooks";
import BarLoader from "react-spinners/BarLoader";
import TextField from "@mui/material/TextField";

//---------------------------------------------------------

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`;

const Modal = styled(motion.div)`
  background-color: white;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  gap: 5px;
  width: 90%;
  max-width: 500px;
  max-height: 90%;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow-y: auto;
`;

const CloseContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`;

const CloseButton = styled.button`
  background-color: whitesmoke;
  border: none;
  color: white;
  height: 30px;
  width: 30px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  i {
    color: black;
    font-size: 15px;
  }
  &:hover {
    i {
      color: indianred;
    }
  }
`;
const RowsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 5px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
  width: 100%;
  height: 40px;
  //wrap content
  flex-wrap: wrap;
`;

const ItemContainer = styled.div<{
  isValidated?: boolean;
  isDropdownOpen?: boolean;
}>`
  flex: 1;
  height: 100%;
  border: 1px solid rgb(228, 228, 228);
  border-radius: 4px;
  background-color: ${(props) =>
    props.isValidated ? "#daffda" : "whitesmoke"};
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 15px;
  padding: 5px;
  font-size: 13px;

  .fa-angle-down {
    transform: ${({ isDropdownOpen }) =>
      isDropdownOpen ? "rotateX(180deg)" : "rotateX(0deg)"};
    transition: transform 0.3s ease-in-out;
    font-size: 12px;
    color: black;
  }
`;

const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  height: 40px;
  border: none;
  border-radius: 4px;
  background-color: #b4b4b4;
  color: white;
  padding: 0 8px;
  cursor: pointer;
  font-size: 13px;
  &:hover {
    filter: brightness(1.1);
  }
  &:focus {
    outline: none;
  }
  .spinner {
    animation: spin 1s linear infinite;
    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
  width: 100%;
`;

//---------------------------------------------------------

const AddOrEditAnnouncementModal = ({
  close,
  data,
  refetch,
}: {
  close: () => void;
  data: any;
  refetch: () => void;
}) => {
  const { user } = useAxios();

  const { mutateAsync: addNewAnnouncement, isLoading: isAddingNewUser } =
    useAddNewAnnouncement();

  const { mutateAsync: updateAnnouncement, isLoading: isUpdatingUser } =
    useUpdateAnnouncement();

  const { mutateAsync: deleteAnnouncement, isLoading: isDeletingUser } =
    useDeleteAnnouncement();

  return (
    <Formik
      validateOnMount
      enableReinitialize
      initialValues={{
        author: user?._id || "",
        title: data?.title || "",
        body: data?.body || "",
        critical: data?.critical || false,
        pinned: data?.pinned || false,
        shouldNotify: true,
      }}
      validationSchema={Yup.object({
        author: Yup.string(),
        title: Yup.string().required("Title required"),
        body: Yup.string().required("Announcement body required"),
        critical: Yup.boolean(),
        pinned: Yup.boolean(),
        shouldNotify: Yup.boolean(),
      })}
      onSubmit={async (values, { setSubmitting, setFieldValue }) => {
        try {
          if (data._id) {
            //UPDATE ANNOUNCEMENT
            const response = await updateAnnouncement({
              id: data._id,
              payload: values,
            } as any);

            //@ts-ignore
            alert(response?.data);
          } else {
            //NEW ANNOUNCEMENT
            const response = await addNewAnnouncement(values as any);
            //@ts-ignore
            alert(response?.data);
          }

          refetch();
          close();
        } catch (err) {
          //@ts-ignore
          if (err.response.data) {
            //@ts-ignore
            alert(err.response.data);
          } else {
            //@ts-ignore
            alert(err.toString());
          }
          console.log(err);
        }
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
        resetForm,
        isSubmitting,
        validateForm,
      }) => {
        return (
          <Wrapper>
            <Modal
              initial={{ opacity: 0, scale: 0 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{
                opacity: { duration: 0.2 },
                scale: {
                  //type: "spring",
                  // stiffness: 300, // Adjust stiffness for more/less bounce
                  // damping: 20, // Adjust damping for faster/slower settle
                  // duration: 0.02,
                },
              }}
            >
              <CloseContainer>
                <CloseButton onClick={close}>
                  <i className="fas fa-times"></i>
                </CloseButton>
              </CloseContainer>
              <ContentContainer>
                <RowsContainer>
                  {/* <ItemContainer isValidated={values.title}>
                      <Input
                        placeholder="Announcement subject"
                        name="title"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.title}
                      />
                    </ItemContainer> */}
                  <TextField
                    name={"title"}
                    label={"Announcement subject"}
                    //margin="dense"
                    type="text"
                    color="success"
                    value={values.title}
                    onChange={handleChange}
                    maxRows={Infinity}
                    error={errors.title ? true : false}
                    variant="filled"
                  />

                  <TextField
                    name={"body"}
                    label={"Announcement"}
                    //margin="dense"
                    multiline
                    rows={6}
                    type="text"
                    color="success"
                    value={values.body}
                    onChange={handleChange}
                    maxRows={Infinity}
                    error={errors.body ? true : false}
                    variant="filled"
                  />

                  <Row>
                    <ItemContainer
                      style={{
                        justifyContent: "space-between",
                      }}
                      title="Critical announcements are highlighted in red"
                    >
                      Critical
                      <Toggle
                        checked={values.critical}
                        onChange={(value) => {
                          setFieldValue("critical", value.target.checked);
                        }}
                      />
                    </ItemContainer>
                    <ItemContainer
                      style={{
                        justifyContent: "space-between",
                      }}
                      title="Pin announcement to the top of the list"
                    >
                      Pinned
                      <Toggle
                        checked={values.pinned}
                        onChange={(value) => {
                          setFieldValue("pinned", value.target.checked);
                        }}
                      />
                    </ItemContainer>
                    <ItemContainer
                      style={{
                        justifyContent: "space-between",
                      }}
                      title="Send notifications to staff ?"
                    >
                      Notifications
                      <Toggle
                        checked={values.shouldNotify}
                        onChange={(value) => {
                          setFieldValue("shouldNotify", value.target.checked);
                        }}
                      />
                    </ItemContainer>
                  </Row>
                </RowsContainer>
              </ContentContainer>
              <ButtonWrapper>
                <Button
                  style={{
                    backgroundColor: "#6eafbb",
                  }}
                  onClick={() => {
                    resetForm();
                  }}
                >
                  CLEAR
                </Button>
                <Button
                  style={{
                    backgroundColor: "#7ab87d",
                    flex: 4,
                  }}
                  title="Submit"
                  type="submit"
                  onClick={() => {
                    if (Object.keys(errors).length > 0) {
                      validateForm();
                      const errorsString = Object.values(errors).join("\n");
                      alert(errorsString);
                    } else {
                      if (
                        window.confirm(
                          "Submit ? \nPlease make sure all the data is correct"
                        )
                      ) {
                        handleSubmit();
                      }
                    }
                  }}
                >
                  {isAddingNewUser || isUpdatingUser ? (
                    <BarLoader color="#606060" loading={true} />
                  ) : (
                    "SUBMIT"
                  )}
                </Button>
              </ButtonWrapper>
              {data?._id && (
                <ButtonWrapper>
                  <Button
                    onClick={async () => {
                      try {
                        if (
                          window.confirm(
                            "Are you sure you wish to delete this announcement?"
                          )
                        ) {
                          const response = await deleteAnnouncement(data?._id);
                          //@ts-ignore
                          alert(response?.data);
                          refetch();
                          close();
                        }
                      } catch (error) {
                        alert(error?.toString());
                      }
                    }}
                    style={{ backgroundColor: "rgb(185,119,119)" }}
                  >
                    {isDeletingUser ? (
                      <BarLoader color="#606060" loading={true} />
                    ) : (
                      <i className="fas fa-trash"></i>
                    )}
                  </Button>
                </ButtonWrapper>
              )}
            </Modal>
          </Wrapper>
        );
      }}
    </Formik>
  );
};

export default AddOrEditAnnouncementModal;
