import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { useEffect, useState } from "react";
import Tooltip from "react-bootstrap/Tooltip";

//---------------------------------------------------------------

const CustomTd = ({ text }) => {
  const [copyText, setCopyText] = useState();

  useEffect(() => {
    if (copyText) {
      navigator.clipboard.writeText(copyText);
    }
  }, [copyText]);

  return (
    <OverlayTrigger
      placement="top"
      overlay={
        <Tooltip
          style={{
            position: "absolute",
            fontSize: "12px",
            cursor: "default",
          }}
        >
          <div>
            {copyText ? (
              <span>copied !</span>
            ) : (
              <>
                <span>copy {text}</span>
                <i
                  style={{
                    marginLeft: "5px",
                    fontSize: "11px",
                  }}
                  className="fa-regular fa-copy"
                ></i>
              </>
            )}
          </div>
        </Tooltip>
      }
    >
      <td
        onClick={(event) => {
          event.stopPropagation();
          setCopyText(text);
        }}
        onMouseLeave={() => setCopyText(null)}
        style={{
          textTransform: "uppercase",
        }}
      >
        {text}
      </td>
    </OverlayTrigger>
  );
};

export default CustomTd;
