import styled from "styled-components";
import { motion } from "framer-motion";

//----------------------------------------------------------------

const FormModal = styled(motion.div)`
  position: fixed;
  top: 0;
  padding: 5px;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(4px);
  background-color: rgba(0, 0, 0, 0.5);
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1071; //1070 is the bootstrap Overlay z-index (notifications)
`;

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  max-width: 1000px;
  * {
    font-size: 13px;
  }
  overflow: hidden;
  max-height: 100%;
`

const SpinnerContainer = styled.div`
  align-self: center;
`

const FormContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 5px;
  overflow: hidden;
`

const Form = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 0 5px 1px 0;
  overflow-y: auto;
`

const TopContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 915;
`

const FieldWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 5px;
  flex-wrap: wrap;
`

const SectionWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 6px;
  border-radius: 5px;
  border: 2px solid white;
  background: rgba(202, 204, 206, 0.4);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
`

const SectionContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 5px;
  .form-header {
    color: white;
    font-weight: bold;
    font-size: 1.1em;
  }
`

const QrButtonOverlay = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  padding: 7px;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  opacity: 0;
  border-radius: 0 5px 0 5px;
  &:hover {
    i {
      color: black;
    }
  }
  i {
    font-size: 20px;
    color: white;
  }
`

const FieldContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid white;
  background-color: whitesmoke;
  border-radius: 5px;
  min-height: 38px;
  position: relative;
  padding: 0 5px;
  cursor: pointer;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-align: center;
  }

  &:hover {
    background-color: white !important;
    box-shadow: 0 0px 10px rgba(0, 0, 0, 0.2);
    ${QrButtonOverlay} {
      opacity: 1;
    }
  }

  .react-datepicker-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    div {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
    }
  }
  input {
    flex: 1;
    height: 100%;
    text-align: center;
    align-self: center;
    background-color: transparent;
    font-family: "Roboto", sans-serif;
  }
  select {
    flex: 1;
    width: 100%;
    background: transparent;
    border: none;
    height: 100%;
    font-weight: 500;
    text-align: center;
    &:focus {
      outline: none;
    }
    text-overflow: ellipsis;
  }
  .icon {
    font-size: 14px;
    color: #636363;
  }

  .spinner {
    animation: spin 1s linear infinite;
    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
`

const NotesContainer = styled.div`
  flex: 1;
  border: 2px solid white;
  background-color: whitesmoke;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 5px;
`;

const AddNoteContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`;

const Input = styled.input`
  flex: 1;
  height: 100%;
  text-align: center;
  align-self: center;
  background-color: transparent;
  font-family: "Roboto", sans-serif;
  text-transform: capitalize;
  //PLACEHOLDER
  &::placeholder {
    text-transform: none;
  }
  //wrap with ellipsis
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  //background-color: white;
  //padding: 5px 0 0 0;
  gap: 5px;
  width: 100%;
  min-height: 50px;
  button {
    font-weight: 600;
  }
  //stick to bottom
  /* position: sticky;
  bottom: 5px;
  flex: 0; */
`;

const ResetButton = styled.button`
  background-color: ${({ theme }) => theme.colors.blue};
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;

  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    filter: brightness(0.9);
  }
`;
const DeleteButton = styled.button`
  background-color: ${({ theme }) => theme.colors.red};
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    filter: brightness(0.9);
  }
`;

const SubmitButton = styled.button`
  background-color: ${({ theme }) => theme.colors.green};
  flex: 3;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    filter: brightness(0.9);
  }
`;

const EmailTextarea = styled.textarea`
  width: 100%;
  height: 400px;
  resize: none;
  border: none;
  outline: none;
  padding: 10px;
  border-radius: 5px;
  font-family: "Roboto", sans-serif;
  background-color: #f1f1f1;
  overflow-y: overlay;
`;

const TextAreaOptionsButton = styled.div`
  background-color: ${({ theme }) => theme.colors.blue};
  width: 50px;
  height: 50px;
  border-radius: 3px;
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  opacity: 0.3;
  i {
    color: white;
    font-size: 20px;
  }
  &:hover {
    opacity: 1;
  }
  user-select: none;
`;

const TextAreaOptionsItem = styled.div`
  background-color: white;
  border-radius: 3px;
  min-height: 30px;
  width: 100%;
  padding: 0 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: none;
  cursor: pointer;
  &:hover {
    filter: brightness(0.9);
  }
  user-select: none;
`;

const PickupClearButton = styled.div`
  flex: 1;
  max-width: 100px;
  background: rgb(104, 179, 255);
  color: white;
  border: 2px solid white;
  cursor: pointer;
  min-height: 45px;
  padding: 0 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
`;

export {
  FormModal,
  Container,
  SpinnerContainer,
  FormContainer,
  Form,
  TopContainer,
  FieldWrapper,
  SectionWrapper,
  SectionContainer,
  QrButtonOverlay,
  FieldContainer,
  NotesContainer,
  AddNoteContainer,
  Input,
  ButtonsContainer,
  ResetButton,
  DeleteButton,
  SubmitButton,
  EmailTextarea,
  TextAreaOptionsButton,
  TextAreaOptionsItem,
  PickupClearButton,
};
