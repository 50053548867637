import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";

//---------------------------------------------------------------

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  flex: 1;
  background-color: whitesmoke;
`;

const HeaderBarButtonContainer = styled(motion.div)`
  display: flex;
  justify-content: center;
  align-items: center;
  .fa-arrows-rotate {
    font-size: 17px;
    //spin it half a turn on hover
    transform: rotate(0deg);
    transition: transform 0.13s ease-in-out;
    cursor: pointer;
    &:hover {
      transform: rotate(150deg);
    }
  }
  .header-button {
    color: #464646;
    font-size: 17px;
    cursor: pointer;
    &:hover {
      color: ${({ theme }) => theme.colors.blue};
    }
  }
`;

const variants = {
  visible_icon: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.2,
    },
  },
  hidden_icon: {
    opacity: 0,
    y: 20,
    transition: {
      duration: 0.0,
    },
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 0.2,
    },
  },

  hidden: {
    opacity: 0,
    transition: {
      duration: 0.0,
    },
  },
};

const RoutingContainer = styled.div`
  margin-left: 10px;
  @media (max-width: 700px) {
    display: none;
  }
`;

//---------------------------------------------------------------

const ServiceLogsBarContent = ({ isModalOpen, closeModal, refetch }) => {
  const navigate = useNavigate();
  return (
    <Container>
      <AnimatePresence>
        {!isModalOpen && (
          <HeaderBarButtonContainer
            variants={variants}
            initial="hidden"
            animate="visible"
            exit="hidden"
            onClick={() => {
              navigate("/home");
            }}
          >
            <i className="fa-solid fa-home header-button"></i>
            <RoutingContainer>
              <i
                style={{
                  fontSize: "8px",
                }}
                className="fa-solid fa-angle-right"
              ></i>
              <span
                style={{
                  fontSize: "10px",
                  marginLeft: "5px",
                }}
              >
                Fleet Management
              </span>
            </RoutingContainer>
          </HeaderBarButtonContainer>
        )}
      </AnimatePresence>
      <AnimatePresence>
        {isModalOpen && (
          <div
            style={{
              flex: 1,
            }}
          >
            <HeaderBarButtonContainer
              variants={variants}
              initial="hidden_icon"
              animate="visible_icon"
              exit="hidden_icon"
              onClick={() => closeModal()}
            >
              <i
                className={`fa-solid header-button fa-xmark`}
                style={{ fontSize: "24px" }}
              ></i>
            </HeaderBarButtonContainer>
          </div>
        )}
      </AnimatePresence>
      <AnimatePresence>
        {!isModalOpen && (
          <HeaderBarButtonContainer
            variants={variants}
            initial="hidden"
            animate="visible"
            exit="hidden"
            onClick={() => {
              //refetch with react-query
              refetch();
            }}
          >
            <i className="fa-solid fa-arrows-rotate header-button"></i>
          </HeaderBarButtonContainer>
        )}
      </AnimatePresence>
    </Container>
  );
};

export default ServiceLogsBarContent;
