import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import DateSelection from "./DateSelection";
import { motion, AnimatePresence } from "framer-motion";

//--------------------------------------------------------------

const Container = styled.div`
  display: flex;
  justify-content: ${({ isModalOpen }) =>
    isModalOpen ? "center" : "space-between"};
  align-items: center;
  padding: 0 10px;
  background-color: whitesmoke;
  flex: 1;
  z-index: 9999;
`;

const HeaderBarButtonContainer = styled(motion.div)`
  display: flex;
  justify-content: center;
  align-items: center;
  .fa-arrows-rotate {
    font-size: 17px;
    //spin it half a turn on hover
    transform: rotate(0deg);
    transition: transform 0.13s ease-in-out;
    cursor: pointer;
    &:hover {
      transform: rotate(150deg);
    }
  }
  .header-button {
    color: #464646;
    font-size: 17px;
    cursor: pointer;
    &:hover {
      color: ${({ theme }) => theme.colors.blue};
    }
  }
`;

const DateContainer = styled(motion.div)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  //gap: 10px;
  select {
    height: 100%;
    //flex: 1;
    background-color: transparent;
    text-align: center;
    color: #5b5f59;
    font-size: 11px;
    font-weight: 600;
    border: none;
    //no outline
    outline: none;
    &:hover {
      cursor: pointer;
      color: ${(props) => props.theme.colors.blue};
    }
  }
`;

const closeIconAndDateVariants = {
  visible_icon: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.2,
    },
  },
  hidden_icon: {
    opacity: 0,
    y: 20,
    transition: {
      duration: 0.0,
    },
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 0.2,
    },
  },

  hidden: {
    opacity: 0,
    transition: {
      duration: 0.0,
    },
  },
};

const RoutingContainer = styled.div`
  margin-left: 10px;
  @media (max-width: 700px) {
    display: none;
  }
`;

//--------------------------------------------------------------

const BookingsBarContent = ({
  isModalOpen,
  setIsModalOpen,
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  refetch,
}) => {
  const navigate = useNavigate();

  return (
    <Container isModalOpen={isModalOpen}>
      <AnimatePresence>
        {!isModalOpen && (
          <HeaderBarButtonContainer
            variants={closeIconAndDateVariants}
            initial="hidden"
            animate="visible"
            exit="hidden"
            onClick={() => navigate("/home")}
          >
            <i className="fa-solid fa-home header-button"></i>
            <RoutingContainer>
              <i
                style={{
                  fontSize: "8px",
                }}
                className="fa-solid fa-angle-right"
              ></i>
              <span
                style={{
                  fontSize: "10px",
                  marginLeft: "5px",
                }}
              >
                Bookings Manager
              </span>
            </RoutingContainer>
          </HeaderBarButtonContainer>
        )}
      </AnimatePresence>
      <AnimatePresence>
        {isModalOpen && (
          <div
            style={{
              flex: 1,
            }}
          >
            <HeaderBarButtonContainer
              variants={closeIconAndDateVariants}
              initial="hidden_icon"
              animate="visible_icon"
              exit="hidden_icon"
              onClick={() => setIsModalOpen(false)}
            >
              <i
                className={`fa-solid header-button fa-xmark`}
                style={{ fontSize: "24px" }}
              ></i>
            </HeaderBarButtonContainer>
          </div>
        )}
      </AnimatePresence>
      <AnimatePresence>
        {!isModalOpen && (
          <DateContainer
            variants={closeIconAndDateVariants}
            initial="hidden"
            animate="visible"
            exit="hidden"
            style={{
              position: "absolute",
              left: "50%",
              transform: "translateX(-50%)",
            }}
          >
            <DateSelection
              startDate={startDate}
              endDate={endDate}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
            />
            {/* <select>
              <option value="default">Event date</option>
              <option value="booking_date">Booking date</option>
            </select> */}
          </DateContainer>
        )}
      </AnimatePresence>
      <AnimatePresence>
        {!isModalOpen && (
          <HeaderBarButtonContainer
            variants={closeIconAndDateVariants}
            initial="hidden"
            animate="visible"
            exit="hidden"
            onClick={() => {
              //refetch with react-query
              refetch();
            }}
          >
            <i className="fa-solid fa-arrows-rotate header-button"></i>
          </HeaderBarButtonContainer>
        )}
      </AnimatePresence>
    </Container>
  );
};

export default BookingsBarContent;
