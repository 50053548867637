import { useState, useContext } from "react";
import styled from "styled-components";
import moment from "moment";
import {
  useMarkNotificationAsRead,
  useMarkNotificationAsUnread,
} from "../../../reactQueryHooks";
import BarLoader from "react-spinners/BarLoader";
import { AppContext } from "../../../App";
//------------------------------------------------------------------------

const NotificationItemContainerOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  opacity: 0;
  color: white;
  font-size: 12px;
`;

const OverlayTextContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
`;

const OverlayTextItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 5px;
  font-size: 10px;
  color: white;
  padding: 4px 7px;
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 3px;
  i {
    font-size: 11px;
  }
  user-select: none;
  cursor: pointer;
  &:hover {
    background-color: rgba(0, 0, 0, 0.8);
  }
`;

const NotificationItemContainer = styled.div`
  display: flex;
  position: relative;
  flex-direction: row;
  text-overflow: ellipsis;
  align-items: center;
  //min-height: 40px;
  width: 100%;
  flex: 1;
  max-width: 400px;
  padding: 3px 5px;
  background-color: ${({ isRead }) => (isRead ? "whitesmoke" : "#fee")};
  &:hover {
    ${NotificationItemContainerOverlay} {
      opacity: 1;
    }
  }
`;

const NotificationItemContentContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  gap: 5px;
`;

const NotificationItemIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  i {
    font-size: 18px;
  }
`;

const NotificationItemTitleContainer = styled.div`
  flex: 1;
`;

const NotificationItemTitle = styled.div`
  flex: 1;
  font-size: 12px;
`;

const NotificationItemDate = styled.div`
  font-size: 10px;
  color: #166cbf;
  margin-right: 5px;
`;

//------------------------------------------------------------------------

const NotificationItem = ({
  notification,
  refetchUnreadNotifications,
  refetchReadNotifications,
  user_id,
}) => {
  const { setBookingFormBookingId, setIsBookingFormOpen } =
    useContext(AppContext);

  const [isLoading, setIsLoading] = useState(false);
  const isRead = notification.data.getaways_suite.isReadBy.includes(user_id);
  const hasType = notification.data.getaways_suite.type;
  //const shouldBebold it true when the notification.getaways_suite.bookingDate exist and is the same day as today or the day after
  const shouldBebold =
    (notification.data.getaways_suite.bookingDate &&
      moment(notification.data.getaways_suite.bookingDate, "DD/MM/YYYY").isSame(
        moment(),
        "day"
      )) ||
    moment(notification.data.getaways_suite.bookingDate, "DD/MM/YYYY").isSame(
      moment().add(1, "day"),
      "day"
    );

  const { mutateAsync: markNotificationAsRead } = useMarkNotificationAsRead();
  const { mutateAsync: markNotificationAsUnread } =
    useMarkNotificationAsUnread();

  const handleMarkAsRead = async () => {
    try {
      setIsLoading(true);
      await markNotificationAsRead({
        user_id,
        id: notification._id,
      });
      await refetchUnreadNotifications();
      await refetchReadNotifications();
    } catch (error) {
      alert(error.toString());
    } finally {
      setIsLoading(false);
    }
  };

  const handleMarkAsUnread = async () => {
    try {
      setIsLoading(true);
      await markNotificationAsUnread({
        user_id,
        id: notification._id,
      });
      await refetchUnreadNotifications();
      await refetchReadNotifications();
    } catch (error) {
      alert(error.toString());
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <NotificationItemContainer isRead={isRead}>
      <NotificationItemContentContainer>
        {hasType && (
          <NotificationItemIconContainer>
            {notification.data.getaways_suite.type === "new_booking" && (
              <i
                style={{
                  color: "#50a0ee",
                }}
                className="fa-solid fa-calendar-check"
              ></i>
            )}

            {notification.data.getaways_suite.type === "booking_cancelled" && (
              <i
                style={{
                  color: "#cd7071",
                }}
                className="fa-solid fa-calendar-xmark"
              ></i>
            )}

            {notification.data.getaways_suite.type ===
              "booking_changed_date" && (
              <i
                style={{
                  color: "#e5b754",
                }}
                className="fa-solid fa-calendar-day"
              ></i>
            )}

            {notification.data.getaways_suite.type === "client_confirmed" && (
              <i
                style={{
                  color: "#57af57",
                }}
                className="fa-solid fa-thumbs-up"
              ></i>
            )}

            {notification.data.getaways_suite.type ===
              "client_updated_location" && (
              <i
                style={{
                  color: "#57af57",
                }}
                className="fa-solid fa-street-view"
              ></i>
            )}

            {notification.data.getaways_suite.type === "booking_updated" && (
              <i
                style={{
                  color: "#e5b754",
                }}
                className="fa-solid fa-pen-to-square"
              ></i>
            )}
          </NotificationItemIconContainer>
        )}
        <NotificationItemTitleContainer>
          <NotificationItemTitle
            style={{
              fontWeight: shouldBebold ? "bold" : "normal",
            }}
          >
            {notification.title}
          </NotificationItemTitle>
          <NotificationItemDate>
            {
              //if date is less than an hour ago, show "x minutes ago"
              //else show "DD/MM/YYYY HH:mm"
              moment(notification.date).isAfter(moment().subtract(1, "hour"))
                ? moment(notification.date).fromNow()
                : moment(notification.date).format("DD/MM/YYYY HH:mm")
            }
          </NotificationItemDate>
        </NotificationItemTitleContainer>
      </NotificationItemContentContainer>
      <NotificationItemContainerOverlay>
        <OverlayTextContainer>
          {isLoading && <BarLoader color={"#fff"} width={100} height={5} />}

          {!isLoading && (
            <OverlayTextItem
              onClick={() => {
                if (isRead) {
                  handleMarkAsUnread(notification._id);
                } else {
                  handleMarkAsRead(notification._id);
                }
              }}
            >
              {isRead ? "MARK AS UNREAD" : "MARK AS READ"}
              <i className="fa-regular fa-eye-slash"></i>
            </OverlayTextItem>
          )}

          {!isLoading && notification.data.getaways_suite?.id && (
            <OverlayTextItem
              onClick={() => {
                setBookingFormBookingId(notification.data.getaways_suite.id);
                setIsBookingFormOpen(true);
              }}
            >
              SEE BOOKING
              <i className="fa-solid fa-arrow-up-right-from-square"></i>
            </OverlayTextItem>
          )}
        </OverlayTextContainer>
      </NotificationItemContainerOverlay>
    </NotificationItemContainer>
  );
};

export default NotificationItem;
