import axios from "axios";

//-------------------------------------------

const downloadFilesAsZip = async (tourGroupId, fileName) => {
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  //const BASE_URL = "http://localhost:3000/";

  try {
    const response = await axios.get(
      `${BASE_URL}api/files/downloadZip/${tourGroupId}`,
      {
        responseType: "blob", // Ensure response is treated as a blob
      }
    );
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `${fileName}.zip`);
    document.body.appendChild(link);
    link.click();
  } catch (error) {
    console.error("Error downloading zip file:", error);
  }
};

export default downloadFilesAsZip;
