import { useState } from "react";
import {
  FieldWrapper,
  SectionWrapper,
  SectionContainer,
  FieldContainer,
  Input,
} from "../styled";
import { useFormikContext } from "formik";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useFetchEntities } from "../../../../reactQueryHooks";
import FindPickupLocationModal from "../FindPickupLocationModal";

//---------------------------------------------------

const ClientLocationSection = () => {
  const [showFindPickupLocationModal, setShowFindPickupLocationModal] =
    useState(false);
  const { data: entities } = useFetchEntities();
  const { values, setFieldValue, handleChange } = useFormikContext();
  const meetingPoints = entities?.data?.meetingPoints;

  //---------------------------------------------------

  return (
    <>
      <SectionWrapper>
        <SectionContainer>
          <span className="form-header">Client location</span>
        </SectionContainer>
        <SectionContainer>
          <FieldWrapper>
            <FieldContainer style={{ flex: 7 }} title="Client location">
              <Input
                type="text"
                placeholder="Client location"
                name="client_location"
                onChange={handleChange}
                value={values.client_location}
                autoComplete="off"
                spellCheck="false"
              />
            </FieldContainer>
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip
                  style={{
                    position: "absolute",
                  }}
                >
                  See it on the map
                  <i
                    style={{
                      marginLeft: "5px",
                      fontSize: "11px",
                    }}
                    className="fa-solid fa-location-dot"
                  ></i>
                </Tooltip>
              }
            >
              <FieldContainer
                onClick={() => {
                  if (values.client_location) {
                    window.open(
                      `https://www.google.com.sa/maps/search/${values.client_location}?hl=en`
                    )
                  } else {
                    alert("Address is empty")
                  }
                }}
                style={{ flex: 0, minWidth: "110px" }}
              >
                <i className="fa-solid fa-map-location-dot icon"></i>
              </FieldContainer>
            </OverlayTrigger>
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip
                  style={{
                    position: "absolute",
                  }}
                >
                  Search pickup location
                </Tooltip>
              }
            >
              <FieldContainer
                onClick={async () => {

       if (values.client_location) {
         await setFieldValue("pickup_location_name", values.client_location)
         await setFieldValue("pickup_location.latitude", "")
         await setFieldValue("pickup_location.longitude", "")
         await setFieldValue("pickup_location.google_maps_url", "")
         await setFieldValue("pickup_location.img_url", "")
         await setFieldValue("pickup_location.address", "")
         await setFieldValue("pickup_location.instructions", "")
         setShowFindPickupLocationModal(true)
       } else {
         alert("Address is empty")
       }


                }}
                style={{ flex: 0, minWidth: "110px" }}
              >
                <i className="fa-solid fa-magnifying-glass icon"></i>
              </FieldContainer>
            </OverlayTrigger>
          </FieldWrapper>
        </SectionContainer>
      </SectionWrapper>

      {showFindPickupLocationModal && (
        <FindPickupLocationModal
          close={() => setShowFindPickupLocationModal(false)}
          meetingPoints={meetingPoints}
        />
      )}
    </>
  )
};

export default ClientLocationSection;
