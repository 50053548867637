import styled from "styled-components";
import { useState, useEffect } from "react";
import moment from "moment";
import SelectionBar from "../../../selection_bar/SelectionBar";
import SchedulePlannerBarContent from "../bar/SchedulePlannerBarContent";
import SchedulePlannerContent from "./SchedulePlannerContent";
import { useFetchScheduleByDateRange } from "../../../../reactQueryHooks";
import { useQueryClient } from "react-query";
import "./style.css";

//----------------------------------------------------------------

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  user-select: none;
`;


//------------------------------------------------------------------------

const SchedulePlanner = () => {
  const [start_date, setStart_date] = useState(
    moment().startOf("month").format("YYYY-MM-DD")
  );

  const date_range = [];
  let current_date = moment(start_date);
  const end_of_month = moment(start_date).endOf("month");
  while (current_date <= end_of_month) {
    date_range.push(current_date.format("YYYY-MM-DD"));
    current_date = current_date.add(1, "days");
  }

  const [user_ids, setUser_ids] = useState([]);
  useEffect(() => {
    const local_user_ids = localStorage.getItem("user_ids");
    if (local_user_ids) {
      setUser_ids(JSON.parse(local_user_ids));
    }
  }, []);
  const queryClient = useQueryClient();

  const { refetch } = useFetchScheduleByDateRange(date_range, user_ids);

  //console.log("scheduleData", scheduleData);

  //--------------------

  const handleDateChange = (action) => {
    if (action === "next") {
      setStart_date(moment(start_date).add(1, "month").format("YYYY-MM-DD"));

      //we might have updated some calendar days optimistically one by one,if we dont invalidate..
      //..it will return from cache,and updated calendar days will take values before update
      queryClient.invalidateQueries("SCHEDULE_DATA_BY_DATE_RANGE");
    } else {
      setStart_date(
        moment(start_date).subtract(1, "month").format("YYYY-MM-DD")
      );
      queryClient.invalidateQueries("SCHEDULE_DATA_BY_DATE_RANGE");
    }
  };

  //------------------------------------------------------------------------

  //------------------------------------------------------------------------

  return (
    <Container>
      <SelectionBar>
        <SchedulePlannerBarContent
          date={start_date}
          setDate={(date) => {
            setStart_date(moment(date).startOf("month").format("YYYY-MM-DD"));
          }}
          handleDateChange={handleDateChange}
          refetch={refetch}
        />
      </SelectionBar>
      <SchedulePlannerContent start_date={start_date} />
    </Container>
  );
};

export default SchedulePlanner;
