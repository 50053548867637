import { useRef, useEffect } from "react"
import styled from "styled-components"
import { useFormContext } from "react-hook-form"
import { IFormValues } from "./initialValue";
import { motion, AnimatePresence } from "framer-motion";

//---------------------------------------------------------------------------

const ListItemContainer = styled.div<{
  isValid?: boolean;
  isSelected?: boolean;
}>`
  display: flex;
  gap: 10px;
  justify-content: space-between;
  align-items: center;
  font-size: 11px;
  color: ${(props) => (props.isValid ? "green" : "indianred")};
  background-color: ${(props) => (props.isSelected ? "#f5f5f5" : "white")};
  padding: 3px 5px;
  border-radius: 3px;
  //padding: 0 5px 0 0;
  cursor: pointer;
  &:hover {
    .list-item-text {
      filter: brightness(0.7);
    }
  }
`;

//---------------------------------------------------------------------------

interface ListItemProps {
  index: number;
  title: string;
  fieldName: keyof IFormValues;
  step: number;
  onClick: () => void;
}

const ListItem = ({
  index,
  title,
  fieldName,
  step,
  onClick,
}: ListItemProps) => {
  const {
    formState: { errors },
  } = useFormContext();
  const isValid = !errors[fieldName]
  //const isValid = watch(fieldName)

  const isSelected = step === index + 1
  const itemRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (isSelected && itemRef.current) {
      itemRef.current.scrollIntoView({ behavior: "smooth", block: "nearest" })
    }
  }, [isSelected])

  return (
    <ListItemContainer
      ref={itemRef}
      title={title}
      isValid={isValid}
      isSelected={isSelected}
      onClick={onClick}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          gap: 5,
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        <div
          style={{
            minWidth: "5px",
            maxWidth: "5px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <AnimatePresence>
            {isSelected && (
              <motion.i
                className="fa-solid fa-caret-right"
                initial={{ scale: 0 }}
                animate={{ scale: 1 }}
                exit={{ scale: 0, transition: { duration: 0.5 } }}
                transition={{ duration: 0.3 }}
              />
            )}
          </AnimatePresence>
        </div>

        <motion.span
          className="list-item-text"
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            //marginLeft: isSelected ? "3px" : "0",
          }}
          //   animate={{
          //     marginLeft: isSelected ? "3px" : "0",
          //     transition: { duration: 0.3 },
          //   }}
        >
          {title}
        </motion.span>
      </div>
      <AnimatePresence>
        {isValid && (
          <motion.i
            className="fa-regular fa-circle-check"
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            exit={{ scale: 0, transition: { duration: 0.5 } }}
            transition={{ duration: 0.3 }}
          />
        )}
      </AnimatePresence>
    </ListItemContainer>
  )
}
export default ListItem
