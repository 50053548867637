import styled from "styled-components"
import Header from "../components/header/Header"
import FleetManagementContent from "../components/bookings_manager/fleet_management/content/FleetManagementContent"
import { useNavigate } from "react-router-dom"

//------------------------------------------------------------------------

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`

const ContentContainer = styled.div`
  flex: 1;
  display: flex;
  overflow: hidden;
`

//------------------------------------------------------------------------

const FleetManagement = () => {
  const navigate = useNavigate()
  return (
    <Container>
      <Header
        text={"Fleet Management"}
        redirectFn={() => {
          navigate("/home")
        }}
      />
      <ContentContainer>
        <FleetManagementContent />
      </ContentContainer>
    </Container>
  )
}

export default FleetManagement
