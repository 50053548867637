import styled from "styled-components";
import { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import VehicleFiles from "./VehicleFiles";
import UpcomingServicesTable from "./UpcomingServicesTable";
import {
  useFetchEntities,
  useUpdateVehicle,
} from "../../../../reactQueryHooks";
import EditableCell from "./EditableCell";
import EditableCellSelect from "./EditableCellSelect";

//----------------------------------------------------------------

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: transparent;
  border-radius: 10px;
`;

const TableContainer = styled.div`
  flex: 1;
`;

const BottomContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

//----------------------------------------------------------------

const VehicleInfoPage = ({ vehicleId }) => {
  const [vehicle, setVehicle] = useState(null);
  const { data: entities, refetch: refetchEntities } = useFetchEntities();
  const [isPlateUpdating, setIsPlateUpdating] = useState(false);
  const [isTypeUpdating, setIsTypeUpdating] = useState(false);
  const [isColorUpdating, setIsColorUpdating] = useState(false);
  const [isGpsUpdating, setIsGpsUpdating] = useState(false);
  const [isPlatformEntryRequiredUpdating, setIsPlatformEntryRequiredUpdating] =
    useState(false);
    const [isMaxCapacityUpdating, setIsMaxCapacityUpdating] = useState(false);
    const [isScheduledServiceUpdating, setIsScheduledServiceUpdating] =
      useState(false);

    useEffect(() => {
      if (entities) {
        const vehicle = entities?.data?.vehicles?.find(
          (v) => v._id === vehicleId
        );
        setVehicle(vehicle);
      }
    }, [entities, vehicleId]);

    const { mutateAsync: updateVehicle } = useUpdateVehicle();

    const handlePlateUpdate = async (value) => {
      try {
        setIsPlateUpdating(true);
        const updated = await updateVehicle({
          id: vehicle._id,
          payload: {
            ...vehicle,
            plate: value.toUpperCase(),
          },
        });

        setVehicle(updated.data);

        refetchEntities();
      } catch (error) {
        console.log(error);
        alert("Error updating plate");
      } finally {
        setIsPlateUpdating(false);
      }
    };

    const handleTypeUpdate = async (value) => {
      try {
        setIsTypeUpdating(true);

        const updated = await updateVehicle({
          id: vehicle._id,
          payload: {
            ...vehicle,
            type: value,
          },
        });
        setVehicle(updated.data);
        refetchEntities();
      } catch (error) {
        console.log(error);
        alert("Error updating type");
      } finally {
        setIsTypeUpdating(false);
      }
    };

    const handleColorUpdate = async (value) => {
      try {
        setIsColorUpdating(true);

        const updated = await updateVehicle({
          id: vehicle._id,
          payload: { ...vehicle, color: value },
        });
        setVehicle(updated.data);
        refetchEntities();
      } catch (error) {
        console.log(error);
        alert("Error updating color");
      } finally {
        setIsColorUpdating(false);
      }
    };

    const handleGpsUpdate = async (value) => {
      try {
        setIsGpsUpdating(true);
        const updated = await updateVehicle({
          id: vehicle._id,
          payload: { ...vehicle, gps_tracker_uid: value },
        });
        setVehicle(updated.data);
        refetchEntities();
      } catch (error) {
        console.log(error);
        alert("Error updating gps tracker");
      } finally {
        setIsGpsUpdating(false);
      }
    };

    const handleUpdatePlatformEntryRequired = async (vehicleId, value) => {
      try {
        setIsPlatformEntryRequiredUpdating(true);
        const updated = await updateVehicle({
          id: vehicleId,
          payload: {
            ...vehicle,
            platform_entry_required: value,
          },
        });
        setVehicle(updated.data);
        refetchEntities();
      } catch (error) {
        console.log(error);
        alert("Error updating platform entry required");
      } finally {
        setIsPlatformEntryRequiredUpdating(false);
      }
    };

    const handleUpdateMaxCapacity = async (vehicleId, value) => {
      try {
        setIsMaxCapacityUpdating(true);
        const updated = await updateVehicle({
          id: vehicleId,
          payload: {
            ...vehicle,
            max_capacity: value,
          },
        });
        setVehicle(updated.data);
        refetchEntities();
      } catch (error) {
        console.log(error);
        alert("Error updating max capacity");
      } finally {
        setIsMaxCapacityUpdating(false);
      }
    };

    const handleAddScheduledService = async (value) => {
      try {
        setIsScheduledServiceUpdating(true);

        const updated = await updateVehicle({
          id: vehicle._id,
          payload: {
            ...vehicle,
            upcoming_scheduled_service: [
              ...vehicle.upcoming_scheduled_service,
              value,
            ],
          },
        });
        setVehicle(updated.data);
        refetchEntities();
      } catch (error) {
        console.log(error);
        alert("Error updating scheduled service");
      } finally {
        setIsScheduledServiceUpdating(false);
      }
    };

    const handleDeleteScheduledService = async (index) => {
      const newScheduledServices = vehicle.upcoming_scheduled_service.filter(
        (service, i) => i !== index
      );

      try {
        setIsScheduledServiceUpdating(true);
        await updateVehicle({
          id: vehicle._id,
          payload: {
            ...vehicle,
            upcoming_scheduled_service: newScheduledServices,
          },
        });

        refetchEntities();
      } catch (error) {
        console.log(error);
        alert("Error updating scheduled service");
      } finally {
        setIsScheduledServiceUpdating(false);
      }
    };

    return (
      <Container>
        <TableContainer>
          <DataTable
            columns={[
              {
                name: "PLATE",
                cell: (row) => (
                  <EditableCell
                    upperCase
                    shownValue={row.plate}
                    inputValue={row.plate}
                    inputType={"text"}
                    isLoading={isPlateUpdating}
                    onClickOk={handlePlateUpdate}
                  />
                ),
              },
              {
                name: "TYPE",
                cell: (row) => (
                  <EditableCell
                    shownValue={row.type}
                    inputValue={row.type}
                    inputType={"text"}
                    isLoading={isTypeUpdating}
                    onClickOk={handleTypeUpdate}
                  />
                ),
              },
            ]}
            dense
            data={[
              {
                ...vehicle,
              },
            ]}
            customStyles={{
              headRow: {
                style: {
                  fontWeight: "bold",
                  textAlign: "center",
                  border: "none",
                  color: "dodgerblue",
                  backgroundColor: "transparent",
                },
              },
              rows: {
                style: {
                  //border: "none",
                },
              },
            }}
          />
        </TableContainer>
        <TableContainer
          style={{
            marginBottom: "6px",
          }}
        >
          <DataTable
            columns={[
              {
                name: "COLOR",
                cell: (row) => (
                  <EditableCell
                    shownValue={row.color}
                    inputValue={row.color}
                    inputType={"text"}
                    isLoading={isColorUpdating}
                    onClickOk={handleColorUpdate}
                  />
                ),
              },
              {
                name: "GPS TRACKER",
                cell: (row) => (
                  <EditableCell
                    upperCase
                    shownValue={row.gps_tracker_uid}
                    inputValue={row.gps_tracker_uid}
                    inputType={"text"}
                    isLoading={isGpsUpdating}
                    onClickOk={handleGpsUpdate}
                  />
                ),
                style: {
                  //align right
                  textAlign: "right",
                },
              },
            ]}
            dense
            data={[
              {
                ...vehicle,
              },
            ]}
            customStyles={{
              headRow: {
                style: {
                  fontWeight: "bold",
                  textAlign: "center",
                  border: "none",
                  color: "dodgerblue",
                },
              },
              rows: {
                style: {
                  border: "none",
                },
              },
            }}
          />
        </TableContainer>
        <TableContainer
          style={{
            marginBottom: "6px",
          }}
        >
          <DataTable
            columns={[
              {
                name: "MAX CAPACITY",
                cell: (row) => (
                  <EditableCell
                    shownValue={row.max_capacity}
                    inputValue={row.max_capacity}
                    inputType={"number"}
                    isLoading={isMaxCapacityUpdating}
                    onClickOk={(value) => {
                      handleUpdateMaxCapacity(row._id, value);
                    }}
                  />
                ),
              },
              {
                name: "PLATFORM ENTRY REQUIRED",
                cell: (row) => (
                  <EditableCellSelect
                    data={[
                      { value: true, label: "YES" },
                      { value: false, label: "NO" },
                    ]}
                    shownValue={row?.platform_entry_required ? true : false}
                    isLoading={isPlatformEntryRequiredUpdating === row._id}
                    onChange={(value) => {
                      handleUpdatePlatformEntryRequired(row._id, value);
                    }}
                  />
                ),
              },
            ]}
            dense
            data={[
              {
                ...vehicle,
              },
            ]}
            customStyles={{
              headRow: {
                style: {
                  fontWeight: "bold",
                  textAlign: "center",
                  border: "none",
                  color: "dodgerblue",
                },
              },
              rows: {
                style: {
                  border: "none",
                },
              },
            }}
          />
        </TableContainer>
        <BottomContainer>
          <VehicleFiles vehicleId={vehicleId} />
          <UpcomingServicesTable
            vehicleId={vehicleId}
            addScheduledService={handleAddScheduledService}
            deleteScheduledService={handleDeleteScheduledService}
            isUpdating={isScheduledServiceUpdating}
          />
        </BottomContainer>
      </Container>
    );
};

export default VehicleInfoPage;
