import styled from "styled-components";
import AddMeetingPointModal from "./AddMeetingPointModal";
import { useState } from "react";
import MeetingPointsList from "./MeetingPointsList";
import { useFetchEntities } from "../../../../../reactQueryHooks";
import Button from "@mui/material/Button"
import { useAxios } from "../../../../../axiosProvider"

//-----------------------------------------------------------------------

const Container = styled.div`
  display: flex;
  flex: 1 0 450px;
  max-height: 500px;
  flex-direction: column;
  gap: 7px;
  background-color: white;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
  padding: 7px;
  border-radius: 5px;
  user-select: none;
  width: 100%;
  height: 100%;
`;

const ContentContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 7px;
  overflow-y: auto;
  position: relative;
`

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  color: rgb(35, 139, 243);
`

const AddButtonContainer = styled.div`
  width: 100%;
  min-height: 40px;
  border-radius: 5px;
`

//-----------------------------------------------------------------------

const MeetingPointsOptions = () => {
  const { data: entities } = useFetchEntities()
  const [showAddMeetingPointModal, setShowAddMeetingPointModal] =
    useState(false)
  const [meetingPointData, setMeetingPointData] = useState({})
  const { user } = useAxios()
  const userIsPermittedToEdit =
    user.isAdmin || (user.isModerator && user.permissions.addMeetingPoints)
  return (
    <Container>
      <TitleContainer>Meeting Points</TitleContainer>
      <ContentContainer>
        <MeetingPointsList
          meetingPoints={entities.data.meetingPoints}
          onSelect={(meetingPoint) => {
            if (userIsPermittedToEdit) {
              setShowAddMeetingPointModal(true)
              setMeetingPointData(meetingPoint)
            } else {
              alert("You don't have permission to edit meeting points")
            }
          }}
        />
        <AddButtonContainer>
          <Button
            sx={{
              width: "100%",
            }}
            variant="outlined"
            color="success"
            onClick={() => {
              if (userIsPermittedToEdit) {
                setShowAddMeetingPointModal(true)
                setMeetingPointData({})
              } else {
                alert("You don't have permission to add meeting points")
              }
            }}
            title="Add Meeting Point"
          >
            Add Meeting Point
          </Button>
        </AddButtonContainer>
      </ContentContainer>
      {showAddMeetingPointModal && (
        <AddMeetingPointModal
          close={() => setShowAddMeetingPointModal(false)}
          data={meetingPointData}
        />
      )}
    </Container>
  )
}

export default MeetingPointsOptions;
