import styled from "styled-components";
import { useState, useRef } from "react";
import stringSimilarity from "string-similarity";

//-------------------------------------------

const Container = styled.div`
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
  overflow: hidden;
  max-height: 300px;
`;

const RowContainer = styled.div`
  display: flex;
  font-size: 12px;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  min-height: 20px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  user-select: none;
  cursor: pointer;
  :hover {
    opacity: 0.6;
  }
`;

const TopContainer = styled.div`
  width: 100%;
  height: 40px;
  min-height: 40px;
`;

const Input = styled.input`
  flex: 1;
  width: 100%;
  height: 100%;
  // min-height: 40px;
  //border: 1px solid lightgray;
  background-color: whitesmoke;
  border-radius: 4px;
  padding: 0 8px;
  /* text-align: center; */

  font-size: 13px;
`;

const ListContainer = styled.div`
  flex: 1;
  overflow: auto;
  width: 100%;
  height: 100%;
  padding: 0 5px 0 0;
  overflow: auto;
  color: black;
`;

//---------------------------------------------------------------------------

const Row = ({ item, isSelected, onSelect }) => {
  return (
    <RowContainer
      onClick={(e) => {
        e.stopPropagation();
        onSelect(item.value);
      }}
    >
      <span>{item.label}</span>
      <i
        style={{
          color: "darkgreen",
          opacity: isSelected ? 1 : 0,
        }}
        className="fas fa-check"
      ></i>
    </RowContainer>
  );
};

//-------------------------------------------

const SearchableMultiSelect = ({
  data,
  selectedData,
  onSelect,
  searchable,
}) => {
  const [searchInput, setSearchInput] = useState("");
  const listRef = useRef();
  const handleInputChange = (event) => {
    setSearchInput(event.target.value);
    listRef.current.scrollTop = 0;
  };

  // Calculate the similarity scores
  const similarities = stringSimilarity.findBestMatch(
    searchInput.toLowerCase(),
    data.map((i) => i.label.toLowerCase()).length > 0
      ? data.map((i) => i.label.toLowerCase())
      : [""]
  );

  // Get the most similar strings
  const mostSimilarStrings = similarities.ratings.map((rating, index) => ({
    string: data.map((i) => i.label)[index],
    rating: rating.rating,
    value: data.map((i) => i.value)[index],
    label: data.map((i) => i.label)[index],
  }));

  // Sort by similarity score (higher score means more similar)
  const sorted = mostSimilarStrings.sort((a, b) => b.rating - a.rating);

  return (
    <Container>
      {searchable && (
        <TopContainer>
          <Input
            autoFocus
            placeholder="Search..."
            autoCapitalize="none"
            autoComplete="off"
            autoCorrect="off"
            spellCheck="false"
            value={searchInput}
            onChange={handleInputChange}
          />
        </TopContainer>
      )}
      <ListContainer ref={listRef}>
        {sorted.map((item) => {
          //uuidv4();
          const isSelected = selectedData
            .map((i) => i.value)
            .includes(item.value);

          return (
            <Row
              key={item.value}
              item={item}
              onSelect={(value) => {
                let newLocalSelectedData = selectedData.map((i) => i.value);
                if (newLocalSelectedData.includes(value)) {
                  newLocalSelectedData = newLocalSelectedData.filter(
                    (i) => i !== value
                  );
                } else {
                  newLocalSelectedData.push(value);
                }
                onSelect(newLocalSelectedData);
              }}
              isSelected={isSelected}
            />
          );
        })}
      </ListContainer>
    </Container>
  );
};

export default SearchableMultiSelect;
