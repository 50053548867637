import styled from "styled-components";
import LoginContentJWTAuth from "../components/login_screen/LoginContentJWTAuth";

//------------------------------------------------------------------------

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const ContentContainer = styled.div`
  flex: 1;
  display: flex;
`;

//------------------------------------------------------------------------

const LoginScreen = () => {
  return (
    <Container>
      <ContentContainer>
        <LoginContentJWTAuth />
      </ContentContainer>
    </Container>
  );
};

export default LoginScreen;
