import { ContentItem, ContentItemContent, ContentItemTitle } from "./styled";
import { useFetchEntities } from "../../../../reactQueryHooks";

//-------------------------------------------

const SortableTableInfoPax = ({ tourGroup }) => {
  const bookings = tourGroup?.bookings;
  const seatsCount = bookings?.reduce((acc, booking) => {
    let seatsCount = Object.keys(booking?.tickets)
      //.filter((key) => key.toLowerCase() !== "infant")
      .reduce((acc, key) => acc + booking.tickets[key], 0);
    if (booking.cancelled) seatsCount = 0;
    return acc + seatsCount;
  }, 0);

  const { data: entities } = useFetchEntities();
  const vehicle = entities?.data?.vehicles?.find(
    (vehicle) => vehicle._id === tourGroup?.vehicle_id
  );
  return (
    <ContentItem
      title="Group pax"
      //a fade in with a little left to right movement
      initial={{ opacity: 0, x: -5 }}
      animate={{ opacity: 1, x: 0 }}
      exit={{ opacity: 0, x: -5 }}
      transition={{ duration: 0.25, delay: 0.15 }}
    >
      <ContentItemTitle>Pax</ContentItemTitle>
      <ContentItemContent>
        <i className="fa-solid fa-user-group"></i>

        <span
          style={{
            fontWeight: "bold",
            color: "black",
          }}
        >
          {vehicle ? `${seatsCount} / ${vehicle?.max_capacity}` : seatsCount}
        </span>
      </ContentItemContent>
    </ContentItem>
  );
};

export default SortableTableInfoPax;
