import styled from "styled-components";
import { motion, AnimatePresence } from "framer-motion";
import { useState } from "react";
import DataTable from "react-data-table-component";
import EditableCell from "./EditableCell";
import {
  useFetchEntities,
  useUpdateVehicle,
} from "../../../../reactQueryHooks";
import ScheduleServiceForm from "./ScheduledServiceForm";
import moment from "moment";
import { useAxios } from "../../../../axiosProvider";

//----------------------------------------------------------------

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: whitesmoke;
  border-radius: 6px;
  gap: 5px;
  padding: 10px;
`;

const TopRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  user-select: none;
  &:hover {
    path {
      fill: black;
    }
  }
`;

const ArrowSvgContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  // if isFilesExpanded is false, rotate 90deg with transition 0.3s
  transform: rotate(
    ${({ isFilesExpanded }) => (isFilesExpanded ? "0deg" : "-90deg")}
  );
  transition: 0.2s;
  path {
    fill: #757575;
  }
`;

const BottomRow = styled(motion.div)`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 5px;
  border-radius: 10px;
  gap: 3px;
  overflow-y: scroll;
  max-height: 200px;
`;

const TableContainer = styled.div`
  width: 100%;
  border-bottom: 1px solid #e0e0e0;
`;

const AddButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  padding: 8px 0;
  border-radius: 4px;
  background-color: #e0e0e0;
  cursor: pointer;
  transition: 0.1s;
  &:hover {
    background-color: white;
  }
  i {
    font-size: 15px;
  }
  margin-top: 5px;
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: ${({ hasNoPlannedRepairs }) =>
    hasNoPlannedRepairs ? "space-between" : "flex-end"};
  align-items: center;
  font-weight: bold;
  font-size: 11px;
  //background: linear-gradient(whitesmoke, rgb(252, 252, 252));
  width: 100%;
  height: 37px;
  margin-top: 4px;
  padding-bottom: 5px;
`;

const AddRepairButton = styled.button`
  background-color: #679469;
  border: none;
  height: 100%;
  text-align: center;
  text-decoration: none;
  font-size: 12px;
  border-radius: 3px;
  padding: 0 7px;
  cursor: pointer;
  color: white;
  &:hover {
    color: white;
  }
`;

const DeleteButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  min-height: 100%;
  border-radius: 4px;
  background-color: white;
  cursor: pointer;
  i {
    font-size: 15px;
  }
  &:hover {
    i {
      color: indianred;
    }
  }
`;

//----------------------------------------------------------------

const UpcomingServicesTable = ({
  vehicleId,
  addScheduledService,
  deleteScheduledService,
  isUpdating,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isNewScheduledServiceFormOpen, setIsNewScheduledServiceFormOpen] =
    useState(false);
  const [isDateUpdating, setIsDateUpdating] = useState(false);
  const [isTimeUpdating, setIsTimeUpdating] = useState(false);
  const [isWorkshopUpdating, setIsWorkshopUpdating] = useState(false);
  const [isRepairUpdating, setIsRepairUpdating] = useState(false);
  const { data: entities, refetch: refetchEntities } = useFetchEntities();
  const vehicle = entities?.data?.vehicles?.find((v) => v._id === vehicleId);
  const hasScheduledServices = vehicle?.upcoming_scheduled_service?.length > 0;
  const { mutateAsync: updateVehicle } = useUpdateVehicle();

  const { user } = useAxios();
  const userIsAdmin = user.isAdmin
  const userIsPermittedToHandleVehicles = user.permissions.addNewVehicle || userIsAdmin;

  const handleDateUpdate = async (value, index) => {
    const new_upcoming_scheduled_service_array =
      vehicle.upcoming_scheduled_service.map((service, i) => {
        if (i === index) {
          return {
            ...service,
            date: value,
          };
        } else {
          return service;
        }
      });

    const new_vehicle = {
      ...vehicle,
      upcoming_scheduled_service: new_upcoming_scheduled_service_array,
    };

    try {
      setIsDateUpdating(true);
      await updateVehicle({
        id: vehicle._id,
        payload: {
          ...new_vehicle,
        },
      });
      refetchEntities();
    } catch (error) {
      console.log(error);
      alert("Error updating plate");
    } finally {
      setIsDateUpdating(false);
    }
  };

  const handleTimeUpdate = async (value, index) => {
    const new_upcoming_scheduled_service_array =
      vehicle.upcoming_scheduled_service.map((service, i) => {
        if (i === index) {
          return {
            ...service,
            time: value,
          };
        } else {
          return service;
        }
      });

    const new_vehicle = {
      ...vehicle,
      upcoming_scheduled_service: new_upcoming_scheduled_service_array,
    };

    try {
      setIsTimeUpdating(true);
      await updateVehicle({
        id: vehicle._id,
        payload: {
          ...new_vehicle,
        },
      });
      refetchEntities();
    } catch (error) {
      console.log(error);
      alert("Error updating plate");
    } finally {
      setIsTimeUpdating(false);
    }
  };

  const handleWorkshopUpdate = async (value, index) => {
    const new_upcoming_scheduled_service_array =
      vehicle.upcoming_scheduled_service.map((service, i) => {
        if (i === index) {
          return {
            ...service,
            workshop: value.toUpperCase(),
          };
        } else {
          return service;
        }
      });

    const new_vehicle = {
      ...vehicle,
      upcoming_scheduled_service: new_upcoming_scheduled_service_array,
    };

    try {
      setIsWorkshopUpdating(true);
      await updateVehicle({
        id: vehicle._id,
        payload: {
          ...new_vehicle,
        },
      });
      refetchEntities();
    } catch (error) {
      console.log(error);
      alert("Error updating plate");
    } finally {
      setIsWorkshopUpdating(false);
    }
  };

  const handleRepairUpdate = async (value, rowIndex, repairIndex) => {
    const new_vehicle = { ...vehicle };
    new_vehicle.upcoming_scheduled_service[rowIndex].planned_repairs[
      repairIndex
    ] = value;

    try {
      setIsRepairUpdating([rowIndex, repairIndex]);
      await updateVehicle({
        id: vehicle._id,
        payload: {
          ...new_vehicle,
        },
      });
      refetchEntities();
    } catch (error) {
      console.log(error);
      alert("Error updating plate");
    } finally {
      setIsRepairUpdating(false);
    }
  };

  const handleRepairDelete = async (rowIndex, repairIndex) => {
    const new_vehicle = { ...vehicle };
    new_vehicle.upcoming_scheduled_service[rowIndex].planned_repairs.splice(
      repairIndex,
      1
    );

    try {
      await updateVehicle({
        id: vehicle._id,
        payload: {
          ...new_vehicle,
        },
      });
      refetchEntities();
    } catch (error) {
      console.log(error);
      alert("Error updating plate");
    }
  };

  const handleRepairAdd = async (rowIndex, repairIndex) => {
    const new_vehicle = { ...vehicle };
    //ADD 1 REPAIR BELOW
    new_vehicle.upcoming_scheduled_service[rowIndex].planned_repairs.splice(
      repairIndex + 1,
      0,
      "REPAIR"
    );

    try {
      await updateVehicle({
        id: vehicle._id,
        payload: {
          ...new_vehicle,
        },
      });
      refetchEntities();
    } catch (error) {
      console.log(error);
      alert("Error updating plate");
    }
  };

  //------------------------------------------------------------------------------

  return (
    <Container>
      <TopRow
        onClick={() => {
          setIsExpanded(!isExpanded);
        }}
      >
        <ArrowSvgContainer isFilesExpanded={isExpanded}>
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M7.41 7.84L12 12.42l4.59-4.58L18 9.25l-6 6-6-6z"></path>
          </svg>
        </ArrowSvgContainer>
        <span
          style={{
            fontWeight: "bold",
            fontSize: "13px",
          }}
        >
          SCHEDULED SERVICE LIST
        </span>
      </TopRow>
      <AnimatePresence>
        {isExpanded && (
          <BottomRow
            initial={{ opacity: 0, y: -3 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -3 }}
            transition={{ duration: 0.2 }}
          >
            {hasScheduledServices ? (
              vehicle.upcoming_scheduled_service.map((service, index) => {
                const plannedRepairsData = service.planned_repairs.map(
                  (repair, index) => ({
                    id: index, // Add an ID or key to uniquely identify each row (optional)
                    planned_repairs: repair,
                  })
                );

                return (
                  <TableContainer key={index}>
                    <DataTable
                      columns={[
                        {
                          name: "DATE",
                          cell: (row) => (
                            <EditableCell
                              shownValue={moment(row.date).format("DD/MM/YYYY") === "Invalid date" ? "DATE NOT SET" : moment(row.date).format("DD/MM/YYYY")}
                              inputValue={row.date}
                              inputType={"date"}
                              isLoading={isDateUpdating}
                              onClickOk={(value) => {
                                handleDateUpdate(value, index);
                              }}
                            />
                          ),
                        },
                        {
                          name: "TIME",
                          cell: (row) => (
                            <EditableCell
                              shownValue={row.time || "TIME NOT SET"}
                              inputValue={row.time}
                              inputType={"time"}
                              isLoading={isTimeUpdating}
                              onClickOk={(value) => {
                                handleTimeUpdate(value, index);
                              }}
                            />
                          ),
                        },
                        {
                          name: "WORKSHOP",
                          cell: (row) => (
                            <EditableCell
                              upperCase
                              shownValue={row.workshop}
                              inputValue={row.workshop}
                              inputType={"text"}
                              isLoading={isWorkshopUpdating}
                              onClickOk={(value) => {
                                handleWorkshopUpdate(value, index);
                              }}
                            />
                          ),
                        },
                      ]}
                      dense
                      data={[
                        {
                          date: service.date,
                          time: service.time,
                          workshop: service.workshop,
                        },
                      ]}
                      customStyles={{
                        headRow: {
                          style: {
                            fontWeight: "bold",
                            textAlign: "center",
                            backgroundColor: "whitesmoke",
                            border: "none",
                            color: "dodgerblue",
                          },
                        },
                        rows: {
                          style: {
                            background: "whitesmoke",
                            border: "none",
                          },
                        },
                      }}
                    />
                    <DataTable
                      // noDataComponent={
                      //   <AddRepairButtonContainer>
                      //     <AddRepairButton>Add repair</AddRepairButton>
                      //   </AddRepairButtonContainer>
                      // }
                      noDataComponent={null}
                      columns={[
                        {
                          name: "PLANNED REPAIRS",
                          format: (row, index) =>
                            `#${index + 1} ${row.planned_repairs}`, // Include the index number before the row text
                          cell: (row, repairIndex) => (
                            <EditableCell
                              shownValue={`#${repairIndex + 1} ${
                                row.planned_repairs
                              }`}
                              onClickOk={(value) => {
                                handleRepairUpdate(value, index, repairIndex);
                              }}
                              onClickAdd={() => {
                                handleRepairAdd(index, repairIndex);
                              }}
                              onClickDelete={() => {
                                if (window.confirm("Delete this repair?")) {
                                  handleRepairDelete(index, repairIndex);
                                }
                              }}
                              inputValue={row.planned_repairs}
                              maxWidth={250}
                              inputType={"text"}
                              isLoading={
                                isRepairUpdating[0] === index &&
                                isRepairUpdating[1] === repairIndex
                              }
                            />
                          ),
                        },
                      ]}
                      dense
                      data={plannedRepairsData}
                      customStyles={{
                        headRow: {
                          style: {
                            fontWeight: "bold",
                            textAlign: "center",
                            backgroundColor: "whitesmoke",
                            border: "none",
                            color: "dodgerblue",
                          },
                        },
                        rows: {
                          style: {
                            background: "whitesmoke",
                            border: "none",
                          },
                        },
                      }}
                    />

                    <ButtonsContainer
                      hasNoPlannedRepairs={service.planned_repairs.length === 0}
                    >
                      {service.planned_repairs.length === 0 && (
                        <AddRepairButton
                          onClick={() => {
                            handleRepairAdd(index, -1);
                          }}
                        >
                          Add repair
                        </AddRepairButton>
                      )}
                      <DeleteButton
                        onClick={() => {
                          if (userIsPermittedToHandleVehicles) {
                            if (
                              window.confirm("Delete this scheduled service?")
                            ) {
                              deleteScheduledService(index);
                            }
                          } else {
                            alert('You do not have permission to handle vehicles')
                          }
                        }
                        }
                      >
                        <i className="fas fa-trash"></i>
                      </DeleteButton>
                    </ButtonsContainer>
                  </TableContainer>
                );
              })
            ) : (
              <span
                style={{
                  fontWeight: "bold",
                  fontSize: "13px",
                  color: "grey",
                }}
              >
                NO RECORDS
              </span>
            )}

            {isNewScheduledServiceFormOpen ? (
              <ScheduleServiceForm
                close={() => setIsNewScheduledServiceFormOpen(false)}
                update={(values) => {
                  addScheduledService(values);
                  setIsNewScheduledServiceFormOpen(false);
                }}
                isUpdating={isUpdating}
              />
            ) : (
                <AddButton onClick={() => {
                  if (userIsPermittedToHandleVehicles) {
                    setIsNewScheduledServiceFormOpen(true)
                  } else {
                    alert('You do not have permission to handle vehicles')
                  }
                }}>
                <i className="fas fa-plus"></i>
              </AddButton>
            )}
          </BottomRow>
        )}
      </AnimatePresence>
    </Container>
  );
};

export default UpcomingServicesTable;
