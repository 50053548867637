import styled from "styled-components";

//---------------------------------------------------------

const Container = styled.div<{
  heading: number;
  engine_on: boolean;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => (props.engine_on ? "#64a764" : "#e29171")};
  z-index: ${(props) => (props.engine_on ? 1 : 0)};
  border-radius: 100%;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.5);
  width: 28px;
  height: 28px;
  i {
    font-size: 18px;
    color: white;
    transform: rotate(${(props) => props.heading}deg);
  }
`;

//---------------------------------------------------------
 const VehicleMarker = ({
  heading,
  engine_on,
}: {
  heading: number;
  engine_on: boolean;
}) => {
  return (
    <Container heading={heading} engine_on={engine_on}>
      <i className="fa-solid fa-circle-up"></i>
    </Container>
  );
};

export default VehicleMarker;