import { useState, useContext } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import styled from "styled-components";
import "mapbox-gl/dist/mapbox-gl.css";
import { AppContext } from "../../../../../App";
import { motion } from "framer-motion";
import AddMeetingPointsMap from "./AddMeetingPointsMap";
import {
  useAddOrEditMeetingPoint,
  useDeleteMeetingPoint,
} from "../../../../../reactQueryHooks";
import BarLoader from "react-spinners/BarLoader";
import { useFetchEntities } from "../../../../../reactQueryHooks";
import { storage } from "../../../../../firebase";
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
// @ts-ignore
mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN as string;

//---------------------------------------------------------

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`;

const Modal = styled(motion.div)`
  background-color: white;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  gap: 10px;
  width: 90%;
  height: 90%;
  max-width: 650px;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 10px;
  height: 100%;
  overflow-y: auto;
  padding: 0 5px 05px 0;
`;

const CloseContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`;

const CloseButton = styled.button`
  background-color: whitesmoke;
  border: none;
  color: white;
  height: 30px;
  width: 30px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  i {
    color: black;
    font-size: 15px;
  }
  &:hover {
    i {
      color: indianred;
    }
  }
`;
const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 5px;
`;

const InputRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
  width: 100%;
  height: 40px;
`;

const Input = styled.input<{
  isValidated: boolean;
}>`
  flex: 1;
  height: 100%;
  //border: 1px solid lightgray;
  border-radius: 4px;
  background-color: ${(props) =>
    props.isValidated ? "#daffda" : "whitesmoke"};
  padding: 0 8px;
  font-size: 13px;
  border: 1px solid rgb(228, 228, 228);
  min-width: 0px;
`;

const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  height: 100%;
  border: none;
  border-radius: 4px;
  background-color: #b4b4b4;
  color: white;
  padding: 0 8px;
  cursor: pointer;
  font-size: 13px;
  &:hover {
    filter: brightness(1.1);
  }
  &:focus {
    outline: none;
  }
  .spinner {
    animation: spin 1s linear infinite;
    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
  width: 100%;
  min-height: 40px;
`;

// const SubmitButton = styled.button`
//   flex: 4;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   background-color: #7ab87d;
//   height: 100%;
//   border-radius: 4px;
//   font-size: 13px;
//   font-weight: 600;
//   color: white;
// `;

// const ClearButton = styled.button`
//   flex: 1;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   background-color: #6eafbb;
//   height: 100%;
//   border-radius: 4px;
//   font-size: 13px;
//   font-weight: 600;
//   color: white;
// `;

const FileInputContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background-color: whitesmoke;
  padding: 0 8px;
  border-radius: 4px;
  height: 100%;
  border: 1px solid rgb(228, 228, 228);
`;

//---------------------------------------------------------

const AddMeetingPointModal = ({
  close,
  data,
}: {
  close: () => void;
  data: any;
}) => {
  const [isPickupLocationFindLoading, setIsPickupLocationFindLoading] =
    useState(false);
  const [isUploadingFile, setIsUploadingFile] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const appContext = useContext(AppContext);
  const { googleInstance } = appContext;

  const { refetch } = useFetchEntities();

  const {
    mutateAsync: addOrEditMeetingPoint,
    isLoading: isAddingMeetingPoint,
  } = useAddOrEditMeetingPoint();

  const { mutateAsync: deleteMeetingPoint, isLoading: isDeleting } =
    useDeleteMeetingPoint();

  const handleFileUpload = async (file: any, meetingPointId: string) => {
    try {
      setIsUploadingFile(true);
      const storageRef = ref(storage, `/meeting_points/${meetingPointId}`);
      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          // Calculate the upload progress percentage
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setUploadProgress(progress);
        },
        (error) => {
          console.error("Error uploading file:", error);
          setIsUploadingFile(false);
        },
        () => {
          // Upload completed
          setIsUploadingFile(false);
        }
      );

      const uploadTaskSnapshot = await uploadTask;
      const downloadURL = await getDownloadURL(uploadTaskSnapshot.ref);

      return downloadURL;
    } catch (error) {
      console.error("Error uploading file:", error);
      alert(error?.toString());
      setIsUploadingFile(false);
    }
  };

  return (
    <Formik
      validateOnMount
      enableReinitialize
      initialValues={{
        id: data?._id || "",
        name: data?.name || "",
        latitude: data?.latitude || "",
        longitude: data?.longitude || "",
        google_maps_url: data?.google_maps_url || "",
        address: data?.address || "",
        img_url: data?.img_url || "",
        instructions: data?.instructions || "",
      }}
      validationSchema={Yup.object({
        name: Yup.string().required("Required"),
        latitude: Yup.number().required("Required"),
        longitude: Yup.number().required("Required"),
        google_maps_url: Yup.string().required("Required"),
        address: Yup.string(),
        img_url: Yup.string(),
        instructions: Yup.string().required("Required"),
      })}
      onSubmit={async (values, { setSubmitting, setFieldValue }) => {
        try {
          const response = await addOrEditMeetingPoint(values as any);
          //@ts-ignore
          alert(response?.data);
          refetch();
          close();
        } catch (error) {
          alert(error?.toString());
        }
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
        resetForm,
        isSubmitting,
        validateForm,
      }) => {
        const handleFindPickupLocation = async () => {
          try {
            if (!values.name) {
              alert(
                "Please enter a meeting point name (Hotel name or address)"
              );
              return;
            }

            if (!googleInstance) {
              alert("Google Maps API has trouble loading ! Please check logs");
              return;
            }
            setIsPickupLocationFindLoading(true);
            const geocoder = new googleInstance.Geocoder();
            geocoder.geocode(
              { address: values.name },
              (results: any, status: any) => {
                if (status === "OK") {
                  const formattedAddress = results[0].formatted_address;
                  const location = results[0].geometry.location;
                  setFieldValue("latitude", location.lat());
                  setFieldValue("longitude", location.lng());
                  const mapsUrl = `https://www.google.com/maps/place/${location.lat()},${location.lng()}`;
                  setFieldValue("google_maps_url", mapsUrl);
                  setFieldValue("address", formattedAddress);
                  setIsPickupLocationFindLoading(false);
                } else {
                  setIsPickupLocationFindLoading(false);
                  alert(status);
                }
              }
            );
          } catch (error: any) {
            setIsPickupLocationFindLoading(false);
            console.log(error);
            alert(error.toString());
          }
        };
        return (
          <Wrapper>
            <Modal
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{
                opacity: { duration: 0.2 },
              }}
            >
              <CloseContainer>
                <CloseButton onClick={close}>
                  <i className="fas fa-times"></i>
                </CloseButton>
              </CloseContainer>
              <ContentContainer>
                <InputWrapper>
                  <InputRow>
                    <Input
                      title="Name"
                      type="text"
                      name="name"
                      autoComplete="off"
                      spellCheck="false"
                      autoCorrect="off"
                      autoCapitalize="off"
                      placeholder="Meeting Point"
                      isValidated={values.name ? true : false}
                      value={values.name}
                      onChange={handleChange}
                    />
                    <Button
                      title="Search Location"
                      type="submit"
                      onClick={handleFindPickupLocation}
                      //onClick={() => handleSubmit()}
                    >
                      {isPickupLocationFindLoading ? (
                        <i
                          style={{
                            fontSize: "13px",
                          }}
                          className="fa-solid fa-circle-notch spinner icon"
                        ></i>
                      ) : (
                        <span>SEARCH</span>
                      )}
                    </Button>
                  </InputRow>
                  <InputRow>
                    <Input
                      title="Address"
                      placeholder="Address(Optional)"
                      name="address"
                      autoComplete="off"
                      autoCorrect="off"
                      autoCapitalize="off"
                      spellCheck="false"
                      isValidated={values.address ? true : false}
                      value={values.address}
                      onChange={handleChange}
                    />
                  </InputRow>
                  <InputRow>
                    <Input
                      title="Latitude"
                      type="number"
                      placeholder="Latitude"
                      name="latitude"
                      autoComplete="off"
                      autoCorrect="off"
                      autoCapitalize="off"
                      spellCheck="false"
                      isValidated={values.latitude ? true : false}
                      value={values.latitude}
                      onChange={(e) => {
                        const isInvalidLat =
                          Number(e.target.value) < -90 ||
                          Number(e.target.value) > 90;
                        if (isInvalidLat) {
                          alert(
                            "Please enter a valid latitude (between -90 and 90)"
                          );

                          setFieldValue("latitude", "");
                        } else {
                          setFieldValue("latitude", e.target.value);
                          setFieldValue(
                            "google_maps_url",
                            `https://www.google.com/maps/place/${e.target.value},${values.longitude}`
                          );
                        }
                      }}
                    />
                    <Input
                      title="Longitude"
                      type="number"
                      placeholder="Longitude"
                      name="longitude"
                      autoComplete="off"
                      autoCorrect="off"
                      autoCapitalize="off"
                      spellCheck="false"
                      isValidated={values.longitude ? true : false}
                      value={values.longitude}
                      onChange={(e) => {
                        const isInvalidLng =
                          Number(e.target.value) < -90 ||
                          Number(e.target.value) > 90;
                        if (isInvalidLng) {
                          alert(
                            "Please enter a valid longitude (between -90 and 90)"
                          );

                          setFieldValue("longitude", "");
                        } else {
                          setFieldValue("longitude", e.target.value);
                          setFieldValue(
                            "google_maps_url",
                            `https://www.google.com/maps/place/${values.latitude},${e.target.value}`
                          );
                        }
                      }}
                    />
                  </InputRow>
                  <InputRow>
                    <Input
                      title="Google Maps URL"
                      placeholder="Google Maps URL"
                      name="google_maps_url"
                      autoComplete="off"
                      autoCorrect="off"
                      autoCapitalize="off"
                      spellCheck="false"
                      isValidated={values.google_maps_url ? true : false}
                      value={values.google_maps_url}
                      onChange={handleChange}
                    />
                  </InputRow>
                  <InputRow>
                    <Input
                      title="Instructions"
                      placeholder="Pickup instructions"
                      name="instructions"
                      autoComplete="off"
                      autoCorrect="off"
                      autoCapitalize="off"
                      spellCheck="false"
                      isValidated={values.instructions ? true : false}
                      value={values.instructions}
                      onChange={handleChange}
                    />
                  </InputRow>
                  <InputRow>
                    <Input
                      title="Meeting point picture url"
                      placeholder="Meeting point picture url"
                      name="img_url"
                      autoComplete="off"
                      autoCorrect="off"
                      autoCapitalize="off"
                      spellCheck="false"
                      isValidated={values.img_url ? true : false}
                      value={values.img_url}
                      onChange={handleChange}
                    />
                  </InputRow>
                  {data?._id && (
                    <InputRow>
                      <FileInputContainer>
                        {isUploadingFile ? (
                          <span
                            style={{
                              fontWeight: "bold",
                              fontSize: "13px",
                            }}
                          >
                            {uploadProgress.toFixed(0)}% Uploading...
                          </span>
                        ) : (
                          <input
                            style={{
                              fontSize: "12px",
                            }}
                            type="file"
                            accept="image/*"
                            onChange={async (e) => {
                              const selectedFile = e.target.files?.[0];
                              try {
                                if (selectedFile) {
                                  const downloadUrl = await handleFileUpload(
                                    selectedFile,
                                    data?._id
                                  );
                                  setFieldValue("img_url", downloadUrl);
                                }
                              } catch (error) {
                                console.log(error);
                              }
                            }}
                          />
                        )}
                      </FileInputContainer>
                      {values.img_url && (
                        <Button
                          title="Show image"
                          onClick={() => {
                            window.open(values.img_url, "_blank");
                          }}
                        >
                          <i className="fas fa-image"></i>
                        </Button>
                      )}
                    </InputRow>
                  )}
                </InputWrapper>
                <AddMeetingPointsMap />
              </ContentContainer>
              <ButtonWrapper>
                <Button
                  style={{
                    backgroundColor: "#6eafbb",
                  }}
                  onClick={() => {
                    resetForm();
                  }}
                >
                  CLEAR
                </Button>
                <Button
                  style={{
                    backgroundColor: "#7ab87d",
                    flex: 4,
                  }}
                  title="Submit"
                  type="submit"
                  onClick={() => {
                    //if there is an error
                    if (Object.keys(errors).length > 0) {
                      validateForm();
                      alert("Please fill all required fields");
                    } else {
                      handleSubmit();
                    }
                  }}
                >
                  {isAddingMeetingPoint ? (
                    <BarLoader color="#606060" loading={true} />
                  ) : (
                    "SUBMIT"
                  )}
                </Button>
              </ButtonWrapper>
              {data?._id && (
                <ButtonWrapper>
                  <Button
                    onClick={async () => {
                      try {
                        if (
                          window.confirm(
                            "Are you sure you wish to delete this item?"
                          )
                        ) {
                          const response = await deleteMeetingPoint(data?._id);
                          //@ts-ignore
                          alert(response?.data);
                          refetch();
                          close();
                        }
                      } catch (error) {
                        alert(error?.toString());
                      }
                    }}
                    style={{ backgroundColor: "rgb(185,119,119)" }}
                  >
                    {isDeleting ? (
                      <BarLoader color="#606060" loading={true} />
                    ) : (
                      <i className="fas fa-trash"></i>
                    )}
                  </Button>
                </ButtonWrapper>
              )}
            </Modal>
          </Wrapper>
        );
      }}
    </Formik>
  );
};

export default AddMeetingPointModal;
