import { useState, useEffect } from "react";
import moment from "moment";
import styled from "styled-components";
import { useAxios } from "../../../axiosProvider";

//----------------------------------------------------------------------

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  //background-color: white;
  gap: 10px;
  opacity: 0;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  i {
    font-size: 16px;
    cursor: pointer;
    color: rgb(170 170 170);
  }
  &:hover {
    i {
      color: ${(props) => {
        if (props.title === "Add a note") {
          return "darkgreen";
        }
        if (props.title === "Edit note") {
          return "dodgerblue";
        }
        if (props.title === "Delete note") {
          return "indianred";
        }
        if (props.title === "Save note") {
          return "darkgreen";
        }
        if (props.title === "Cancel") {
          return "indianred";
        }
      }};
    }
  }
`;

const NoteContainer = styled.div`
  display: flex;
  flex-direction: column;
  //gap: 2px;
  background-color: white;
  padding: 5px;
  border-radius: 4px;
  //box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.75);
  //border: thin solid rgb(235 235 235);
  /* /border-left: 2px solid transparent; */
  border-left: 2px solid transparent;

  &:hover {
    //box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.75);
    //border-bottom: thin solid rgb(235 235 235);
    //border-left: 2px solid dodgerblue;
    border-left: 2px solid rgb(223 152 29);

    ${ButtonsContainer} {
      opacity: 1;
    }
  }
`;

const NoteBodyContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

const NoteBody = styled.div`
  //font-style: italic;
  flex: 1;
  // font-weight: 600;
`;

const NoteInput = styled.input`
  flex: 1;
  background-color: rgb(248 248 248);
  padding: 2px 5px;
  //border-radius: 4px;
  //wrap with ellipsis
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const Details = styled.div`
  font-size: 11px !important;
  color: dodgerblue;
`;

//----------------------------------------------------------------------

//moment(note.date).format("DD/MM/YYYY HH:mm"

const Note = ({
  note,
  shouldShowPlusButton,
  canBeEdited,
  onChange,
  isEditing,
  onDelete,
  onAdd,
}: {
  note: { author: string; body: string; date: string };
  shouldShowPlusButton: boolean;
  canBeEdited: boolean;
  onChange: any;
  isEditing: boolean;
  onDelete: any;
  onAdd: any;
}) => {
  const [isEditable, setIsEditable] = useState(isEditing);
  const [noteBody, setNoteBody] = useState(note.body);
  const { user } = useAxios();

  useEffect(() => {
    const handleKeyDown = (event: any) => {
      if (event.key === "Enter" || event.keyCode === 13) {
        setIsEditable(false);
        onChange({
          body: noteBody,
          author: user.name,
          date: new Date().toISOString(),
        });
      }
    };

    if (isEditable) {
      window.addEventListener("keydown", handleKeyDown);
    } else {
      //this way i prevent the event listener to be added multiple times
      //only the edited note will have the event listener
      window.removeEventListener("keydown", handleKeyDown);
    }

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [noteBody, onChange, user.name, setIsEditable, isEditable]);

  return (
    <NoteContainer>
      <NoteBodyContainer>
        {isEditable ? (
          <NoteInput
            className="exclude-from-submit-to-enter"
            autoFocus
            value={noteBody}
            onChange={(e) => setNoteBody(e.target.value)}
            placeholder="Add a note ..."
          />
        ) : (
          <NoteBody>{note.body.length ? note.body : "Empty note.."}</NoteBody>
        )}
        <ButtonsContainer>
          {isEditable ? (
            <>
              <ButtonContainer
                title="Save note"
                onClick={() => {
                  setIsEditable(false);
                  onChange({
                    body: noteBody,
                    author: user.name,
                    date: new Date().toISOString(),
                  });
                }}
              >
                <i className="fas fa-save"></i>
              </ButtonContainer>
              <ButtonContainer
                title="Cancel"
                onClick={() => {
                  setIsEditable(false);
                  setNoteBody(note.body);
                  note.body.length === 0 && onDelete();
                }}
              >
                <i className="fas fa-times"></i>
              </ButtonContainer>
            </>
          ) : (
            <>
              {canBeEdited && (
                <ButtonContainer
                  title="Edit note"
                  onClick={() => setIsEditable(true)}
                >
                  <i className="fas fa-edit"></i>
                </ButtonContainer>
              )}
              <ButtonContainer
                title="Delete note"
                onClick={() => {
                  onDelete();
                }}
              >
                <i className="fas fa-trash"></i>
              </ButtonContainer>
            </>
          )}
        </ButtonsContainer>
      </NoteBodyContainer>
      <Details>
        {note.author} - {moment(note.date).format("DD/MM/YYYY HH:mm")}
      </Details>
    </NoteContainer>
  );
};

export default Note;
