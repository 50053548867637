import { useState, useRef } from "react";
import styled from "styled-components";
import stringSimilarity from "string-similarity";

//---------------------------------------------------------

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  overflow: hidden;
`;

const TopContainer = styled.div`
  width: 100%;
  height: 30px;
`;

const Input = styled.input`
  flex: 1;
  width: 100%;
  height: 100%;
  //border: 1px solid lightgray;
  background-color: whitesmoke;
  border-radius: 4px;
  padding: 0 8px;
  /* text-align: center; */

  font-size: 13px;
`;

const ListContainer = styled.div`
  flex: 1;
  overflow: auto;
  width: 100%;
  height: 100%;
  padding: 0 5px 0 0;
  overflow: auto;
`;

const MeetingPoint = styled.div`
  font-size: 13px;
  padding: 3px 6px;
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    background-color: whitesmoke;
  }
`;

//---------------------------------------------------------

const MeetingPointsList = ({ onSelect, meetingPoints }) => {
  const [searchInput, setSearchInput] = useState("");
  const listRef = useRef();

  const handleInputChange = (event) => {
    setSearchInput(event.target.value);
    listRef.current.scrollTop = 0;
  };

  // Calculate the similarity scores
  const similarities = stringSimilarity.findBestMatch(
    searchInput.toLowerCase(),
    meetingPoints.map((mp) => mp.name.toLowerCase())
  );

  // Get the most similar strings
  const mostSimilarStrings = similarities.ratings.map((rating, index) => ({
    string: meetingPoints.map((mp) => mp.name)[index],
    rating: rating.rating,
    id: meetingPoints.map((mp) => mp._id)[index],
  }));

  // Sort by similarity score (higher score means more similar)
  const sorted = mostSimilarStrings.sort((a, b) => b.rating - a.rating);

  return (
    <Wrapper>
      <TopContainer>
        <Input
          autoFocus
          placeholder="Search..."
          autoCapitalize="none"
          autoComplete="off"
          autoCorrect="off"
          spellCheck="false"
          value={searchInput}
          onChange={handleInputChange}
        />
      </TopContainer>
      <ListContainer ref={listRef}>
        {sorted.map((mp, i) => {
          return (
            <MeetingPoint
              key={`${mp.id}_${i}`}
              onClick={() => {
                const meetingPoint = meetingPoints.find((i) => i._id === mp.id)
                onSelect(meetingPoint)
              }}
            >
              {mp.string}
            </MeetingPoint>
          )
        })}
      </ListContainer>
    </Wrapper>
  )
};

export default MeetingPointsList;
