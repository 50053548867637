import { useContext } from "react";
import styled from "styled-components";
import moment from "moment";
import { useFetchEntities } from "../../../../reactQueryHooks";
import { AppContext } from "../../../../App";

//------------------------------------------------------------------------------------------

const Table = styled.table`
  text-align: center;
  border-collapse: collapse;
  font-size: 11px;

  .booking_total_paid {
    //in less than 500px width
    @media (max-width: 500px) {
      display: none;
    }
  }

  .channel_title {
    //in less than 450px width
    @media (max-width: 450px) {
      display: none;
    }
  }

  .booking_notes {
    //in less than 400px width:
    @media (max-width: 400px) {
      display: none;
    }
  }

  cursor: default;
  thead {
    height: 35px;
    background-color: white;
    border-radius: 5px;
    position: sticky;
    top: 0;
    //z-index: 10;
    //bottom shadow
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.4);

    th {
      text-align: center;
      color: #313330;
      display: table-cell;
      font-family: "Roboto", sans-serif;
      height: 40px;
      font-weight: 600;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;

      select {
        height: 100%;
        color: #313330;
        font-weight: 600;
        font-family: "Roboto", sans-serif;
        background-color: transparent;
        text-align: center;
        border: none;
        outline: none;
        &:hover {
          cursor: pointer;
          color: ${(props) => props.theme.colors.blue};
        }
      }
    }
  }
`;

const BookingRow = styled.tr`
  //background-color: ${({ index }) => (index % 2 === 0 ? "white" : "#eee")};
  background-color: ${({ isEven }) => (isEven ? "white" : "whitesmoke")};
  height: 40px;
  cursor: pointer;
  text-transform: capitalize;
  &:hover {
    filter: brightness(0.9);
  }
  td {
    text-align: center;
    display: table-cell;
    max-width: 50px;
    padding: 0 3px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    color: ${({ isCancelled }) => (isCancelled ? "#ed3e42" : "black")};
    font-weight: ${({ isCancelled }) => (isCancelled ? "600" : "400")};
    /* background: blue; */
  }

  .td-content-wrapper {
    height: 100%;
    width: 100%;
    padding: 0;
    margin: 0;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    p {
      padding: 0;
      margin: 0;
      height: 100%;
      width: 100%;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    span {
      padding: 0;
      margin: 0;
      height: 100%;
      width: 100%;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    ol {
      padding: 0;
      margin: 0;
      list-style: none;
      height: 100%;
      width: 100%;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    li {
      padding: 0;
      margin: 0;
      list-style: none;
      height: 100%;
      width: 100%;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
`;

//------------------------------------------------------------------------------------------

const BookingsTable = ({
  bookings,
  handleAddOrEditBooking,
  showByEventDate,
}) => {
  const { data: entities } = useFetchEntities();
  const { setBookingFormBookingId, setIsBookingFormOpen } =
    useContext(AppContext);

  return (
    <Table>
      <thead>
        <tr>
          <th>REF</th>
          <th>
            <span
              style={{
                color: showByEventDate ? "#158cff" : "black",
              }}
            >
              Event Date{" "}
              {showByEventDate && <i className="fas fa-caret-down"></i>}
            </span>
          </th>
          <th>
            <span
              style={{
                color: showByEventDate ? "black" : "#158cff",
              }}
            >
              Booking Date{" "}
              {!showByEventDate && <i className="fas fa-caret-down"></i>}
            </span>
          </th>
          <th>Client</th>
          <th>Product</th>
          <th>Seats</th>
          <th>Location</th>
          <th className="channel_title">Channel</th>
          {/* <th>Author</th> */}
          <th className="booking_total_paid">Total €</th>
          <th className="booking_notes">Notes</th>
        </tr>
      </thead>
      <tbody>
        {bookings.map((booking, index) => {
          const seatsCount = Object.keys(booking.tickets)
            //.filter((key) => key.toLowerCase() !== "infant")
            .reduce((acc, key) => acc + booking.tickets[key], 0);

          const isLast = index === bookings.length - 1;
          const notesListComposedString = booking.notes_list.reduce(
            (acc, note) => {
              return acc + note.body + " ";
            },
            ""
          );
          const isEvenIndex = index % 2 === 0;

          const product = entities.data.products.find(
            (product) => product._id === booking.product_id
          );
          const channel = entities.data.channels.find(
            (channel) => channel._id === booking.channel_id
          )

          return (
            <BookingRow
              key={index}
              isLast={isLast}
              isEven={isEvenIndex}
              isCancelled={booking.cancelled === true}
              onClick={() => {
                setBookingFormBookingId(booking._id)
                setIsBookingFormOpen(true)
              }}
            >
              <td>{booking.ref}</td>
              <td>{moment(new Date(booking.date)).format("DD/MM/YYYY")}</td>
              <td>
                {moment(new Date(booking.booking_date)).format("DD/MM/YYYY")}
              </td>
              <td>{booking.client_name}</td>
              <td>{product.title}</td>
              <td>{seatsCount}</td>
              <td>{booking.client_location}</td>
              <td className="channel_title">{channel.title}</td>
              {/* <td>{booking.updated_at[0].author}</td> */}
              <td className="booking_total_paid">{booking.total_paid}</td>
              <td className="booking_notes">
                <div className="td-content-wrapper">
                  <span>{notesListComposedString}</span>
                </div>
              </td>
            </BookingRow>
          )
        })}
      </tbody>
    </Table>
  );
};

export default BookingsTable;
