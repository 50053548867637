import styled from "styled-components";
import { Formik } from "formik";

//----------------------------------------------------------------

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-color: rgb(252, 252, 252);
  margin-top: 5px;
  border-radius: 5px;
  padding: 10px;
  gap: 4px;
  //border: 1px solid #e0e0e0;
  //shadow
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
`;
const Row = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  gap: 4px;
  background-color: transparent;
  border-radius: 5px;
`;

const InputContainer = styled.div`
  flex: 1;
  height: 40px;
  gap: 4px;
  //background-color: rgb(237,237,237);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
`;

const ButtonContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 4px;
  background-color: transparent;
  border-radius: 5px;
`;

const Button = styled.button`
  height: 40px;
  padding: 0 10px;
  background-color: whitesmoke;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 40px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 12px;
  font-weight: bold;
  i {
    font-size: 13px;
  }
  &:hover {
    filter: brightness(0.9);
  }
`;

const DateInput = styled.input`
  flex: 1;
  font-size: 13px;
  background-color: whitesmoke;
  height: 100%;
  padding: 0 5px;
  //border: 1px solid #e0e0e0;
  border-radius: 5px;
`;

const Input = styled.input`
  flex: 1;
  font-size: 13px;
  background-color: whitesmoke;
  height: 100%;
  padding: 0 5px;
  //border: 1px solid #e0e0e0;
  border-radius: 5px;
`;

const TopContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  min-height: 40px;
  gap: 4px;
  background-color: transparent;
  border-radius: 5px;
`;

const CloseIconButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
  height: 100%;
  min-width: 40px;
  border-radius: 5px;
  background-color: rgb(227, 227, 227);
  cursor: pointer;
  i {
    font-size: 16px;
  }
  &:hover {
    i {
      color: indianred;
    }
  }
`;
const DeletePlannedRepairButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  padding: 0 10px;
  min-width: 40px;
  background-color: rgb(227, 227, 227);
  height: 100%;
  i {
    font-size: 13px;
    color: grey;
  }
  &:hover {
    i {
      color: indianred;
    }
  }
  cursor: pointer;
`;

//----------------------------------------------------------------

const ScheduleServiceForm = ({ close, update, isUpdating }) => {
  return (
    <Formik
      //validateOnMount
      enableReinitialize
      initialValues={{
        date: "",
        time: "",
        workshop: "",
        planned_repairs: [""],
      }}
      // validationSchema={formSchema}
      onSubmit={(values, { setSubmitting, setFieldValue }) => {
        update(values);
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
        resetForm,
        isSubmitting,
        validateForm,
      }) => {
        return (
          <Wrapper>
            <TopContainer>
              <span
                style={{
                  fontSize: "14px",
                  fontWeight: "bold",
                  color: "rgb(90,161,233)",
                }}
              >
                NEW SCHEDULED SERVICE
              </span>
              <CloseIconButton onClick={() => close()}>
                <i className="fas fa-times"></i>
              </CloseIconButton>
            </TopContainer>
            <Row>
              <InputContainer>
                <DateInput
                  type="date"
                  name="date"
                  value={values.date}
                  onChange={handleChange}
                />
              </InputContainer>
              <InputContainer>
                <DateInput
                  type="time"
                  name="time"
                  value={values.time}
                  onChange={handleChange}
                />
              </InputContainer>
              <InputContainer>
                <Input
                  type="text"
                  name="workshop"
                  placeholder="Workshop"
                  value={values.workshop}
                  onChange={handleChange}
                />
              </InputContainer>
            </Row>
            {values.planned_repairs.map((repair, index) => {
              return (
                <Row key={index}>
                  <InputContainer>
                    <Input
                      type="text"
                      name={`planned_repairs[${index}]`}
                      placeholder={`Planned repair #${index + 1}`}
                      value={repair}
                      onChange={handleChange}
                    />
                    <DeletePlannedRepairButton
                      type="button"
                      onClick={() => {
                        const newPlannedRepairs = [...values.planned_repairs];
                        newPlannedRepairs.splice(index, 1);
                        setFieldValue("planned_repairs", newPlannedRepairs);
                      }}
                    >
                      <i className="fas fa-trash"></i>
                    </DeletePlannedRepairButton>
                  </InputContainer>
                </Row>
              );
            })}
            <ButtonContainer>
              <Button
                type="button"
                style={{
                  backgroundColor: "rgb(190,212,239)",
                }}
                onClick={() => {
                  setFieldValue("planned_repairs", [
                    ...values.planned_repairs,
                    "",
                  ]);
                }}
              >
                +1 REPAIR
              </Button>
              {/* <Button
                                type="button"
                                style={{
                                    backgroundColor: "rgb(228,135,135)",
                                }}
                                onClick={() => close()}
                            >
                                <i className="fas fa-times"></i>
                            </Button> */}
              <Button
                type="button"
                style={{
                  backgroundColor: "rgb(176,241,176)",
                }}
                onClick={handleSubmit}
                disabled={isUpdating}
              >
                {isUpdating ? (
                  "Adding new.."
                ) : (
                  <i className="fas fa-floppy-disk"></i>
                )}
              </Button>
            </ButtonContainer>
          </Wrapper>
        );
      }}
    </Formik>
  );
};

export default ScheduleServiceForm;
