import { Formik } from "formik";
import * as Yup from "yup";
import styled from "styled-components";
import moment from "moment";
import StickyNote2Icon from "@mui/icons-material/StickyNote2";
import PushPinIcon from "@mui/icons-material/PushPin";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DoNotDisturbAltIcon from "@mui/icons-material/DoNotDisturbAlt";
import Tooltip from "@mui/material/Tooltip";
import { useAddOrUpdateNote, useDeleteNote } from "../../../reactQueryHooks";

//------------------------------------------------------------------------

const NoteItemContainerOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  opacity: 0;
  color: white;
  font-size: 12px;
  gap: 5px;

  span {
    user-select: none;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
    &.edit {
      &:hover {
        color: #7cb1e2;
      }
    }
    &.delete {
      &:hover {
        color: #e2958d;
      }
    }
  }
`;

const NoteItemContainer = styled.div`
  display: flex;
  position: relative;
  flex-direction: row;
  text-overflow: ellipsis;
  align-items: center;
  //min-height: 40px;
  width: 100%;
  flex: 1;

  padding: 3px;
  background-color: rgb(250 250 250);
  &:hover {
    ${NoteItemContainerOverlay} {
      opacity: 1;
    }
  }
`;

const NoteItemContentContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  gap: 5px;
`;

const NoteItemIconContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  align-items: center;
  padding: 5px;
  i {
    font-size: 18px;
  }
  .icon {
    cursor: pointer;
    /* &:hover {

    } */
  }
`;

const NoteItemTitleContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const NoteItemTitle = styled.div`
  flex: 1;
  font-size: 12px;
  white-space: pre-wrap;
  /* display: flex;
  align-items: center; */
`;

const NoteItemDate = styled.div`
  font-size: 10px;
  color: #166cbf;
`;

const TextArea = styled.textarea`
  width: 100%;
  height: 100%;
  min-height: 80px;
  border: none;
  resize: none;
  font-size: 12px;
  padding: 3px;

  background-color: white;
  &:focus {
    outline: none;
  }
  border-radius: 3px;
  padding: 4px 6px;
  font-size: 11px;
  box-shadow: inset 0 0 5px #eeeeee;
`;

//------------------------------------------------------------------------

const NotesItem = ({ note, remove, refetch }) => {
  const { mutateAsync: addOrUpdateNote } = useAddOrUpdateNote();
  const { mutateAsync: deleteNote } = useDeleteNote();

  return (
    <Formik
      validateOnMount
      enableReinitialize
      initialValues={{
        ...note,
      }}
      validationSchema={Yup.object({
        body: Yup.string().required("Note body is required"),
      })}
      onSubmit={async (values, { setSubmitting, setFieldValue }) => {
        setFieldValue("isNewForm", false);
        try {
          await addOrUpdateNote({
            id: values._id,
            payload: {
              ...values,
            },
          });
          setTimeout(() => {
            refetch();
          }, 100);
        } catch (e) {
          alert(e.toString());
        }
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
        resetForm,
        isSubmitting,
        validateForm,
      }) => {
        return (
          <NoteItemContainer>
            <NoteItemContentContainer>
              <NoteItemIconContainer>
                {!values.isNewForm && values.pinned && (
                  <PushPinIcon
                    style={{
                      color: "peru",
                      fontSize: "21px",
                    }}
                  />
                )}
                {!values.isNewForm && (
                  <StickyNote2Icon
                    style={{
                      color: "#4072A5",
                      fontSize: "20px",
                    }}
                  />
                )}
                {values.isNewForm && (
                  <Tooltip title="Pin note to top" placement="left">
                    <PushPinIcon
                      className="icon"
                      style={{
                        color: "peru",
                        fontSize: "21px",
                      }}
                      onClick={() => {
                        setFieldValue("pinned", !values.pinned);
                        setFieldValue("isNewForm", false);
                        setTimeout(() => {
                          handleSubmit();
                        }, 100);
                      }}
                    />
                  </Tooltip>
                )}
                {values.isNewForm && (
                  <Tooltip title="Save note" placement="left">
                    <CheckCircleIcon
                      className="icon"
                      style={{
                        color: "darkgreen",
                        fontSize: "20px",
                      }}
                      onClick={() => {
                        if (Object.keys(errors).length > 0) {
                          validateForm();
                          const errorsString = Object.values(errors).join("\n");
                          alert(errorsString);
                        } else {
                          handleSubmit();
                        }
                      }}
                    />
                  </Tooltip>
                )}
                {values.isNewForm && (
                  <Tooltip title="Close form" placement="left">
                    <DoNotDisturbAltIcon
                      className="icon"
                      style={{
                        color: "darkred",
                        fontSize: "20px",
                      }}
                      onClick={async () => {
                        setFieldValue("isNewForm", false);
                        if (values.isNewForm) {
                          resetForm();
                          remove();
                        }
                      }}
                    />
                  </Tooltip>
                )}
              </NoteItemIconContainer>
              <NoteItemTitleContainer>
                {values.isNewForm && (
                  <TextArea
                    placeholder="Enter note here..."
                    autoFocus
                    value={values.body}
                    onChange={(e) => setFieldValue("body", e.target.value)}
                  ></TextArea>
                )}
                {!values.isNewForm && (
                  <NoteItemTitle>{values.body}</NoteItemTitle>
                )}
                {!values.isNewForm && (
                  <NoteItemDate>
                    {moment(values.date).format("DD/MM/YYYY")}
                  </NoteItemDate>
                )}
              </NoteItemTitleContainer>
            </NoteItemContentContainer>
            <NoteItemContainerOverlay
              style={{
                display: values.isNewForm ? "none" : "flex",
              }}
            >
              <span
                className="edit"
                onClick={() => {
                  setFieldValue("isNewForm", true);
                }}
              >
                Edit
              </span>
              <span
                className="delete"
                onClick={async () => {
                  try {
                    if (!window.confirm("Delete this note?")) return;
                    await deleteNote(values._id);
                    refetch();
                  } catch (e) {
                    alert(e.toString());
                  }
                }}
              >
                Delete
              </span>
            </NoteItemContainerOverlay>
          </NoteItemContainer>
        );
      }}
    </Formik>
  );
};

export default NotesItem;
