import styled from "styled-components";
import Note from "./Note";
import { useAxios } from "../../../axiosProvider";

//----------------------------------------------------------------------

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  //background-color: whitesmoke;
`;

//----------------------------------------------------------------------

const NotesListInBookingForm = ({
  list,
  onChange,
}: {
  list: { author: string; body: string; date: string; isEditing: boolean }[];
  onChange: any;
}) => {
  const { user } = useAxios();
  const determineIfThereIsAnotherNoteBeingEdited = (index: number) => {
    for (let i = 0; i < list.length; i++) {
      if (i !== index && list[i].isEditing) {
        return true;
      }
    }
    return false;
  };
  return (
    <Container>
      {list.map((note, index) => {
        return (
          <Note
            key={note.date}
            canBeEdited={!determineIfThereIsAnotherNoteBeingEdited(index)}
            note={{ author: note.author, body: note.body, date: note.date }}
            shouldShowPlusButton={index === list.length - 1}
            onChange={(newNote: any) => {
              const newList = [...list];
              newList[index] = {
                ...newNote,
                isEditing: false,
              };
              onChange(newList);
            }}
            onDelete={() => {
              const newList = [...list];
              newList.splice(index, 1);
              onChange(newList);
            }}
            onAdd={() => {
              const newList = [...list];
              newList.push({
                body: "",
                author: user.name,
                date: new Date().toISOString(),
                isEditing: true,
              });
              onChange(newList);
            }}
            isEditing={note.isEditing}
          />
        );
      })}
    </Container>
  );
};

export default NotesListInBookingForm;
