import { useState, useEffect } from "react"
import {
  FieldWrapper,
  SectionWrapper,
  SectionContainer,
  FieldContainer,
  NotesContainer,
  AddNoteContainer,
  Input,
} from "../styled"
import { Button } from "@mui/material"
import NotesListInBookingForm from "../NotesListInBookingForm"
import { useFormikContext } from "formik"
import { useAxios } from "../../../../axiosProvider"
import { OverlayTrigger, Tooltip } from "react-bootstrap"
import moment from "moment"
import { useFetchEntities } from "../../../../reactQueryHooks"
const tour_info_page_url = "https://your-getaways-tour.com/?tour="

//---------------------------------------------------

const BookingInfoSection = () => {
  const [copyText, setCopyText] = useState()
  const { data: entities } = useFetchEntities()
  const { values, setFieldValue, handleChange } = useFormikContext()
  const { user } = useAxios()

  const product = entities.data.products.find(
    (product) => product._id === values.product_id
  )

  const sortedStartTimesByTime = product?.start_times.sort((a, b) => {
    // Sort the start times by the timeslot.time_slot
    if (a.time_slot < b.time_slot) {
      return -1
    } else if (a.time_slot > b.time_slot) {
      return 1
    } else {
      return 0
    }
  })

  useEffect(() => {
    if (copyText) {
      navigator.clipboard.writeText(copyText)
    }
  }, [copyText])

  //---------------------------------------------------

  return (
    <SectionWrapper>
      <SectionContainer>
        <span className="form-header">Booking info</span>
      </SectionContainer>
      <SectionContainer>
        <FieldWrapper>
          <FieldContainer
            title="Order number"
            style={{
              backgroundColor: values.order_number ? "#d2ffd2" : "#ffdcdd",
            }}
          >
            <Input
              type="text"
              placeholder="Order number"
              name="order_number"
              onChange={async (e) => {
                await setFieldValue(
                  "order_number",
                  e.target.value.toUpperCase()
                );
              }}
              value={values.order_number}
              autoComplete="off"
              spellCheck="false"
            />
          </FieldContainer>

          <FieldContainer
            title="Channel reference number"
            style={{
              backgroundColor: values.ref ? "#d2ffd2" : "#ffdcdd",
            }}
          >
            <Input
              type="text"
              placeholder="Channel REF #"
              name="ref"
              onChange={async (e) => {
                await setFieldValue("ref", e.target.value.toUpperCase());
              }}
              value={values.ref}
              autoComplete="off"
              spellCheck="false"
            />
          </FieldContainer>
          <FieldContainer title="Event date">
            <Input
              type="date"
              name="date"
              onChange={async (e) => {
                await setFieldValue(
                  "date",
                  moment(new Date(e.target.value)).format("YYYY-MM-DD")
                );
              }}
              value={moment(new Date(values.date)).format("YYYY-MM-DD")} //native html picker accepts only this format YYYY-MM-DD
            />
          </FieldContainer>
        </FieldWrapper>
        <FieldWrapper>
          <FieldContainer
            title="Booking product"
            style={{
              backgroundColor: values.product_id ? "#d2ffd2" : "#ffdcdd",
            }}
          >
            <select
              onChange={async (e) => {
                const product = entities.data.products.find(
                  (product) => product._id === e.target.value
                );
                if (e.target.value === "reset") {
                  await setFieldValue("product_id", "");
                  await setFieldValue("option_id", "");
                  await setFieldValue("product_time_slot", "");
                } else {
                  await setFieldValue("product_id", product._id);
                  await setFieldValue("option_id", "");
                  await setFieldValue("product_time_slot", "");
                }
              }}
              value={values.product_id}
            >
              <option value="reset">Select a product</option>
              {entities.data.products.map((product, index) => (
                <option value={product._id} key={index}>
                  {product.title}
                </option>
              ))}
            </select>
          </FieldContainer>
          {values.product_id && (
            <FieldContainer
              title="Product option"
              style={{
                backgroundColor: values.option_id ? "#d2ffd2" : "#ffdcdd",
                minWidth: "80px",
              }}
            >
              <select
                onChange={async (e) => {
                  if (e.target.value === "") {
                    await setFieldValue("option_id", "");
                    await setFieldValue("product_time_slot", "");
                  } else {
                    await setFieldValue("option_id", e.target.value);
                    await setFieldValue("product_time_slot", "");
                  }
                }}
                value={values.option_id}
              >
                <option value="">Select an option</option>
                {product?.options.map((option, index) => {
                  return (
                    <option value={option._id} key={index}>
                      {option.title}
                    </option>
                  );
                })}
              </select>
            </FieldContainer>
          )}
          {values.product_id && (
            <FieldContainer
              title="Product time slot"
              style={{
                backgroundColor: values.product_time_slot
                  ? "#d2ffd2"
                  : "#ffdcdd",
                minWidth: "80px",
              }}
            >
              <select
                onChange={async (e) => {
                  const start_time_id = e.target.value;
                  const startTime = product.start_times.find(
                    (startTime) => startTime._id === start_time_id
                  );

                  if (start_time_id === "") {
                    await setFieldValue("product_time_slot", "");
                    await setFieldValue("start_time_id", "");
                  } else {
                    await setFieldValue(
                      "product_time_slot",
                      startTime.time_slot
                    );
                    await setFieldValue("start_time_id", start_time_id);
                  }
                }}
                value={values.start_time_id}
              >
                <option value="">Select a time</option>
                {sortedStartTimesByTime.map((startTime) => {
                  return (
                    <option value={startTime._id} key={startTime._id}>
                      {startTime.time_slot} {startTime.label}
                    </option>
                  );
                })}
              </select>
            </FieldContainer>
          )}
        </FieldWrapper>
        {/* {product?.platform_product_name && (
          <FieldWrapper title="Platform product name">
            <FieldContainer
              style={{
                gap: "15px",
              }}
              onClick={() => {
                setCopyText(product?.platform_product_name)
              }}
              onMouseLeave={() => setCopyText(null)}
            >
              <span>{product?.platform_product_name}</span>
              <i
                style={{
                  marginLeft: "5px",
                  fontSize: "11px",
                  flex: 0,
                }}
                className={copyText ? "fas fa-check" : "fa-regular fa-copy"}
              ></i>
            </FieldContainer>
          </FieldWrapper>
        )} */}
        <FieldWrapper>
          <FieldContainer
            title="Booking channel"
            style={{
              backgroundColor: values.channel_id ? "#d2ffd2" : "#ffdcdd",
            }}
          >
            <select
              onChange={async (e) => {
                if (e.target.value === "") {
                  await setFieldValue("channel_id", "");
                } else {
                  await setFieldValue("channel_id", e.target.value);
                }
              }}
              value={values.channel_id}
            >
              <option value="">Select a channel</option>
              {entities.data.channels.map((channel) => (
                <option value={channel._id} key={channel._id}>
                  {channel.title}
                </option>
              ))}
            </select>
          </FieldContainer>
          <FieldContainer title="Booking group">
            <select
              onChange={async (e) => {
                await setFieldValue("group", parseInt(e.target.value));
              }}
              value={values.group}
            >
              {[...Array(10).keys()].map((number, index) => (
                <option value={number + 1} key={index}>
                  Group {number + 1}
                </option>
              ))}
            </select>
          </FieldContainer>
        </FieldWrapper>
        <FieldWrapper>
          <FieldContainer title="Booking total paid">
            <Input
              type="number"
              placeholder="Total paid €"
              name="total_paid"
              onChange={handleChange}
              value={values.total_paid}
              autoComplete="off"
              spellCheck="false"
            />
          </FieldContainer>
          <FieldContainer title="Booking status">
            <select
              onChange={async (e) => {
                await setFieldValue("cancelled", e.target.value);
              }}
              value={values.cancelled}
            >
              <option value={false}>Booking is valid</option>
              <option value={true}>Bookings is cancelled</option>
            </select>
          </FieldContainer>
          <FieldContainer title="Day planner inclusion">
            <select
              onChange={async (e) => {
                if (values.cancelled) {
                  await setFieldValue("planned", e.target.value);
                } else {
                  alert(
                    "You can't remove a valid booking from the day planner"
                  );
                }
              }}
              value={values.planned}
            >
              <option value={true}>Included in day planner</option>
              <option value={false}>Not included in day planner</option>
            </select>
          </FieldContainer>
        </FieldWrapper>

        {values?._id && (
          <FieldWrapper>
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip
                  style={{
                    position: "absolute",
                    fontSize: "12px",
                    cursor: "default",
                  }}
                >
                  <div>
                    <>
                      <span>{"Client's booking portal url"}</span>
                      <i
                        style={{
                          marginLeft: "5px",
                          fontSize: "11px",
                        }}
                        className={
                          copyText ? "fas fa-check" : "fa-regular fa-copy"
                        }
                      ></i>
                    </>
                  </div>
                </Tooltip>
              }
            >
              <FieldContainer
                style={{
                  flex: 1,
                }}
                title="Booking portal url"
                onClick={() =>
                  setCopyText(`${tour_info_page_url}${values.ref}`)
                }
                onMouseLeave={() => setCopyText(null)}
              >
                <span>{`${tour_info_page_url}${values.ref}`}</span>
              </FieldContainer>
            </OverlayTrigger>
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip
                  style={{
                    position: "absolute",
                    fontSize: "12px",
                    cursor: "default",
                  }}
                >
                  <div>
                    <>
                      <span>{"Client's booking portal confirmation link"}</span>
                      <i
                        style={{
                          marginLeft: "5px",
                          fontSize: "11px",
                        }}
                        className={
                          copyText ? "fas fa-check" : "fa-regular fa-copy"
                        }
                      ></i>
                    </>
                  </div>
                </Tooltip>
              }
            >
              <FieldContainer
                style={{
                  flex: 1,
                }}
                title="Booking portal confirmation link"
                onClick={() =>
                  setCopyText(
                    `CLICK HERE TO CONFIRM - ${tour_info_page_url}${values.ref}&confirmed=true`
                  )
                }
                onMouseLeave={() => setCopyText(null)}
              >
                <span>{`${tour_info_page_url}${values.ref}&confirmed=true`}</span>
              </FieldContainer>
            </OverlayTrigger>
          </FieldWrapper>
        )}

        <NotesContainer>
          {values?.notes_list.length ? (
            <NotesListInBookingForm
              list={values.notes_list}
              onChange={async (newNotesList) => {
                await setFieldValue("notes_list", newNotesList);
              }}
            />
          ) : null}

          <AddNoteContainer>
            <Button
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "5px",
              }}
              size="small"
              variant="contained"
              onClick={async () => {
                await setFieldValue("notes_list", [
                  ...values.notes_list,
                  {
                    author: user.name,
                    body: "",
                    date: new Date().toISOString(),
                    isEditing: true,
                  },
                ]);
              }}
            >
              <i
                style={{
                  fontSize: "10px",
                }}
                className="fas fa-circle-plus"
              ></i>
              <span
                style={{
                  fontSize: "10px",
                }}
              >
                Add a note
              </span>
            </Button>
          </AddNoteContainer>
        </NotesContainer>
      </SectionContainer>
    </SectionWrapper>
  );
}

export default BookingInfoSection
