import styled from "styled-components";
import SortableTableTourGroup from "../../day_planner_tour_groups/content/SortableTableTourGroup";

//---------------------------------------------------------

const Modal = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(100, 100, 100, 0.8);
  border-radius: 5px;
  padding: 5px;
  overflow: hidden;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
`;

const Container = styled.div`
  border-radius: 5px;
  padding: 5px;
  //height: 100%;
  width: 100%;
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
  overflow: hidden;
  font-size: 13px;
`;

const ContentContainer = styled.div`
  height: 100%;
  width: 100%;
  overflow-y: auto;
  padding: 0 5px;
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  height: 100%;
  width: 100%;
  padding: 5px;
  //background-color: white;
  border-radius: 5px;
`;

const CloseButton = styled.button`
  border: none;
  color: white;
  height: 35px;
  width: 35px;
  border-radius: 4px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: absolute;
  top: 5px;
  right: 5px;
  i {
    font-size: 20px;
  }
  &:hover {
    i {
      color: indianred;
    }
  }
`;

//---------------------------------------------------------

const TourGroupModal = ({ close, id }) => {
  //-----------------------------------

  return (
    <Modal>
      <CloseButton onClick={close}>
        <i className="fas fa-times"></i>
      </CloseButton>

      <Container
      // initial={{ opacity: 0, scale: 0 }}
      // animate={{ opacity: 1, scale: 1 }}
      // transition={{
      //   opacity: { duration: 0.2 },
      //   scale: {
      //     //type: "spring",
      //     // stiffness: 300, // Adjust stiffness for more/less bounce
      //     // damping: 20, // Adjust damping for faster/slower settle
      //     // duration: 0.02,
      //   },
      // }}
      >
        <ContentContainer>
          <FormContainer>
            <SortableTableTourGroup
              handleAddOrEditBooking={() => {
                alert(
                  "Please go to the Day Planner or Bookings Manager to edit a booking."
                );
              }}
              tourGroupId={id}
            />
          </FormContainer>
        </ContentContainer>
      </Container>
    </Modal>
  );
};

export default TourGroupModal;
