import styled from "styled-components";
import { useState, createContext } from "react";
import moment from "moment";
import Map from "../content/Map.tsx";
import BarLoader from "react-spinners/BarLoader";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import { useGetTasksByDate } from "../../../../reactQueryHooks";
import ScheduleTaskItem from "./ScheduleTaskItem";

//----------------------------------------------------------------

const Container = styled.div`
  flex: 1;
  width: 100%;
  display: flex;
  position: relative;
`;

const OptionsButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;

  ${(props) =>
    props.renderedInOwnPage
      ? `
      top: 20px;
      right: 20px;
    `
      : `
      bottom: 20px;
      left: 20px;
    `}

  background-color: ${(props) => props.theme.colors.blue};

  opacity: 0.6;
  &:hover {
    opacity: 1;
    transition: opacity 0.3s;
    @media (min-width: 1166px) {
      background-color: ${(props) => props.theme.colors.blue1};
      transition: background-color 0.3s;
    }
  }
  width: 50px;
  height: 50px;
  border-radius: 10px;
  font-size: 30px;
  border: none;
  cursor: pointer;

  i {
    font-size: 20px;
    color: white;
  }
  //shadow
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  z-index: 100;
`;

const LoadingDot = styled.div`
  width: 13px;
  height: 13px;
  border-radius: 100%;
  background-color: #50a150;
  box-shadow: 0 0 10px 0 white;
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 1;
`;

const PopoverContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: 300px;
  gap: 5px;
  overflow-y: overlay;
  ::-webkit-scrollbar {
    width: 3px;
  }
  ::-webkit-scrollbar-thumb {
    background: #91c9ff;
    //border-radius: 50px;
  }
`;

const PopoverItem = styled.div`
  background-color: whitesmoke;
  border-radius: 3px;
  min-height: 30px;
  max-height: 200px;
  min-width: 200px;
  width: 100%;
  font-size: 12px;
  padding: 0 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: none;
  cursor: pointer;
  &:hover {
    color: ${(props) => props.theme.colors.blue};
  }
`;

//------------------------------------------------------------------------

export const FleetTrackingContext = createContext();

const FleetTrackingContent = ({ renderedInOwnPage, date }) => {
  const [isFetchingTrackingData, setIsFetchingTrackingData] = useState(false);
  const [meetingPoints, setMeetingPoints] = useState({
    id: "",
    pickups: [],
  });

  const { data: tasksData, isLoading } = useGetTasksByDate(
    moment(date).format("YYYY-MM-DD")
  );
  const hasScheduleData = tasksData && tasksData.length > 0;
  const noScheduleData = tasksData && tasksData.length === 0;

  return (
    <FleetTrackingContext.Provider
      value={{
        isFetchingTrackingData,
        setIsFetchingTrackingData,
        meetingPoints,
      }}
    >
      <Container>
        {isFetchingTrackingData && <LoadingDot />}
        <Map />
        <OverlayTrigger
          //container={containerRef.current} //contain within the parent element (refresh page)
          trigger="click"
          rootClose //close on click outside
          placement={renderedInOwnPage ? "left-start" : "right-end"}
          overlay={
            <Popover
              id="popover-basic"
              style={{
                boxShadow: "2px 2px 4px 0 rgba(0, 0, 0, 0.1)",
                position: "absolute",
                //border: "none",
              }}
            >
              <Popover.Body
                style={{
                  padding: "5px",
                  display: "flex",
                  flexDirection: "column",
                  gap: "5px",
                  overflow: "hidden",
                }}
              >
                {isLoading && <BarLoader color="#50a150" />}
                {meetingPoints.pickups.length > 0 && (
                  <PopoverItem
                    onClick={() => {
                      setMeetingPoints({
                        id: "",
                        pickups: [],
                      });
                    }}
                    style={{ color: "indianred" }}
                  >
                    Remove meeting points
                  </PopoverItem>
                )}
                {hasScheduleData && (
                  <PopoverContent>
                    {tasksData.map((task, index) => (
                      <span
                        key={task._id}
                        onClick={() => {
                          setMeetingPoints({
                            id: task._id,
                            pickups: task.pickups,
                          });
                        }}
                      >
                        <ScheduleTaskItem
                          task={task}
                          isSelected={meetingPoints.id === task._id}
                        />
                      </span>
                    ))}
                  </PopoverContent>
                )}
                {noScheduleData && (
                  <span
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      fontSize: "12px",
                      color: "indianred",
                    }}
                  >
                    No tasks found
                  </span>
                )}
              </Popover.Body>
            </Popover>
          }

          // transition={false}
        >
          <OptionsButton renderedInOwnPage={renderedInOwnPage}>
            <i className="fa-solid fa-bars"></i>
          </OptionsButton>
        </OverlayTrigger>
      </Container>
    </FleetTrackingContext.Provider>
  );
};

export default FleetTrackingContent;
