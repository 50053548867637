import styled from "styled-components";
import moment from "moment";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import { useFetchEntities } from "../../reactQueryHooks";

//----------------------------------------------------------

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  //margin-right: auto; //sits on the left of the grid
  * {
    user-select: none;
  }
  z-index: 2;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
  overflow: hidden;
`;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 11px;
  font-weight: 600;
  padding: 0 8px;
  background-color: #e6e6e6;
  width: 50px;
  height: 30px;
  @media (max-width: 360px) {
    width: 40px;
    height: 25px;
    font-size: 12px;
  }
  border-radius: 5px;
  overflow: hidden;
`;

const StyledPopover = styled(Popover)`
  max-width: 92vw;
  max-height: 500px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 3px;
  border-radius: 3px;
  gap: 3px;
  overflow: hidden;
`;

const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  width: 100%;
  overflow-y: auto;
  gap: 3px;
`;

const ItemContainer = styled.div`
  border-bottom: ${(props) => (props.isLast ? "none" : "1px solid lightgray")}; 
  width: 100%;
  border-radius: 5px 5px 0 0;
  &:hover {
    background-color: rgb(246, 246, 246);
  }
  max-width: 200px;
`;

const ItemContentContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  cursor: default;
`;

const ContentItemContainer = styled.div`
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
  padding: 0 5px 0 0;
  font-size: 14px;
  i {
    font-size: 11px;
    color: rgb(139, 139, 139);
  }
  //wrap text
  span {  
    white-space: nowrap;  
    overflow: hidden; 
    text-overflow: ellipsis;  
  }
`;

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  max-width: 20px;
  min-width: 20px;
  height: 100%;
`;

//------------------------------------------------------

const ScheduledServiceItem = ({ scheduledService, isLast }) => {
  console.log(scheduledService);
  return (
    <ItemContainer
      title={scheduledService.planned_repairs.join(", ")}
      isLast={isLast}
    >
      <ItemContentContainer>
        <ContentItemContainer>
          <IconContainer>
            <i className="fa-solid fa-car"></i>
          </IconContainer>
          <span>{scheduledService.plate}</span>
        </ContentItemContainer>
        <ContentItemContainer>
          <IconContainer>
            <i className="fa-solid fa-calendar-day"></i>
          </IconContainer>
          <span
            style={{
              color: moment(scheduledService.date).format("DD/MM/YYYY") === "Invalid date" ? "indianred" : "black"
            }}
          >{moment(scheduledService.date).format("DD/MM/YYYY") === "Invalid date" ? "DATE NOT SET" : moment(scheduledService.date).format("DD/MM/YYYY")}</span>
        </ContentItemContainer>
        <ContentItemContainer>
          <IconContainer>
            <i className="fa-solid fa-clock"></i>
          </IconContainer>
          <span style={{
            color: scheduledService.time ? "black" : "indianred"
          }}>{scheduledService.time || "TIME NOT SET"}</span>
        </ContentItemContainer>
        <ContentItemContainer>
          <IconContainer>
            <i className="fa-solid fa-shop"></i>
          </IconContainer>
          <span>{scheduledService.workshop}</span>
        </ContentItemContainer>
        {scheduledService.planned_repairs.map((repair, index) => {
          return (
            <ContentItemContainer key={index}>
              <IconContainer>
                <i className="fa-solid fa-hammer"></i>
              </IconContainer>
              <span>{repair}</span>
            </ContentItemContainer>
          )
        })}
      </ItemContentContainer>
    </ItemContainer>
  );
};

//------------------------------------------------------

const VehicleServiceNotifications = () => {
  const { data: entities } = useFetchEntities();

  const flattenedAndSortedScheduledServices = entities?.data?.vehicles
    ?.flatMap((vehicle) =>
      vehicle.upcoming_scheduled_service.map((scheduledService) => ({
        ...scheduledService,
        plate: vehicle.plate,
      }))
    )
    .sort((a, b) => new Date(b.date) - new Date(a.date));

  return (
    <OverlayTrigger
      placement="left-start"
      trigger={"click"}
      rootClose
      overlay={
        <StyledPopover>
          <ListContainer>
            {flattenedAndSortedScheduledServices?.map(
              (scheduledService, index) => {
                return (
                  <ScheduledServiceItem
                    key={index}
                    scheduledService={scheduledService}
                    isLast={
                      index === flattenedAndSortedScheduledServices.length - 1
                    }
                  />
                );
              }
            )}
          </ListContainer>
        </StyledPopover>
      }
    >
      <Wrapper title="Scheduled service">
        <Container>
          <i
            style={{
              color: "#4072A5",
            }}
            className="fa-solid fa-screwdriver-wrench"
          ></i>

          {flattenedAndSortedScheduledServices?.length > 0 ? (
            <span
              style={{
                color: "indianred",
              }}
            >
              {flattenedAndSortedScheduledServices?.length}
            </span>
          ) : (
            <i
              style={{
                color: "darkgreen",
              }}
              className="fa-solid fa-check"
            ></i>
          )}
        </Container>
      </Wrapper>
    </OverlayTrigger>
  );
};

export default VehicleServiceNotifications;
