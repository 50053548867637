import { useState, useEffect, useCallback } from "react";
import { useGetOnlineSessions, useGetOfflineSessionsPaginated } from "../../../../reactQueryHooks";
import { generateExcelFromPortalLogs } from "../../day_planner_tour_groups/content/file_utilities";
import styled, { keyframes } from "styled-components";
import SelectionBar from "../../../selection_bar/SelectionBar";
import BarLoader from "react-spinners/BarLoader";
import BookingPortalLogsBarContent from "../bar/BookingPortalLogsBarContent";
import SessionItem from "./SessionItem";
import Tooltip from "@mui/material/Tooltip";
import io from "socket.io-client";
const socketTourInfo = io("https://getaways-tour-info.herokuapp.com", {
  transports: ["websocket", "polling", "flashsocket"],
});

// const socketTourInfoDev = io("http://localhost:3001", {
//   transports: ["websocket", "polling", "flashsocket"],
// });

//----------------------------------------------------------------

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const ContentWrapper = styled.div`
  padding: 10px;
  flex: 1;
  //background-color: red;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  gap: 5px;
`;

const OnlineContentContainer = styled.div`
  padding: 10px;
  background-color: gainsboro;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  width: 100%;
  max-width: 800px;
  max-height: 50%;
  overflow: auto;
  gap: 5px;
`;

const ContentContainer = styled.div`
  padding: 10px;
  background-color: gainsboro;
  border-radius: 10px;
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: hidden;
  max-width: 800px;
  gap: 10px;
`;

const ListWrapper = styled.div`
  flex: 1;
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
`;

const ListContainer = styled.div`
  flex: 1;
  width: 100%;
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  gap: 1px;
  padding: 0 1px 0 0;
`;

const LoaderOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(5px);
`;

// Keyframes for blinking effect
const blink = keyframes`
  0% { opacity: 0.2; }
  50% { opacity: 1; }
  100% { opacity: 0.2; }
`;

// Styled component for the online status
const OnlineStatus = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;
`;

// Styled component for the blinking dot
const BlinkingDot = styled.span`
  width: 10px;
  height: 10px;
  background-color: green;
  border-radius: 50%;
  margin-left: 5px;
  animation: ${blink} 1s linear infinite;
`;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 5px;
  width: 100%;
  span {
    font-size: 12px;
    font-weight: 600;
    //wrap with ellipsis
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  //input type checkbox
  input[type="checkbox"] {
    width: 16px;
    height: 16px;
    cursor: pointer;
  }

  select {
    cursor: pointer;
    font-size: 12px;
    border: none;
    outline: none;
    padding: 2px;
  }
`;

const OptionsButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 100;
  /* @media (min-width: 1332px) {
    opacity: 1;
  } */
`;

const OptionsButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.colors.blue};
  opacity: 0.3;
  &:hover {
    opacity: 1;
    filter: brightness(1.1);
    transition: opacity 0.2s;
    transition: filter 0.2s;
  }
  width: 50px;
  height: 50px;
  border-radius: 10px;
  font-size: 30px;
  border: none;
  cursor: pointer;

  i {
    font-size: 20px;
    color: white;
  }
  //shadow
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  z-index: 100;
`;

//------------------------------------------------------------------------

const OnlineIndicator = () => (
  <OnlineStatus>
    ONLINE <BlinkingDot />
  </OnlineStatus>
);

const BookingPortalLogsContent = () => {
  const [isUnique, setIsUnique] = useState(false);
  const [actionSelected, setActionSelected] = useState(null);
  const { data: onlineSessionsData } = useGetOnlineSessions();
  const {
    data: offlineSessions,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    isLoading,
    isRefetching,
    refetch,
  } = useGetOfflineSessionsPaginated({
    unique: isUnique,
    action: actionSelected,
  });

  useEffect(() => {
    // subscribe socket listener on mount
    socketTourInfo.on(`tour_info/session_open`, (data) => {
      refetch();
    });
    socketTourInfo.on(`tour_info/session_killed`, (data) => {
      refetch();
    });

    // unsubscribe socket on unmount (cleanup)
    return () => {
      socketTourInfo.off(`tour_info/session_open`);
      socketTourInfo.off(`tour_info/session_killed`);
    };
  }, [refetch]);

  useEffect(() => {
    refetch();
  }, [isUnique, actionSelected, refetch]);

  //------------------------------------------------------------------------

  const handleScroll = useCallback(
    (e) => {
      const bottom =
        e.target.scrollHeight - e.target.scrollTop <=
        e.target.clientHeight + 30; // 10 px before the bottom
      if (bottom && hasNextPage) {
        fetchNextPage();
      }
    },
    [hasNextPage, fetchNextPage]
  );

  const allData = offlineSessions?.pages?.flatMap((page) => page.data.docs);

  useEffect(() => {
    const element = document.getElementById("content-container");
    element.addEventListener("scroll", handleScroll);

    return () => element.removeEventListener("scroll", handleScroll);
  }, [hasNextPage, fetchNextPage, handleScroll]);

  //------------------------------------------------------------------------

  return (
    <Container>
      <SelectionBar>
        <BookingPortalLogsBarContent refetch={refetch} />
      </SelectionBar>
      <ContentWrapper>
        <OnlineContentContainer>
          <OnlineIndicator />
          {onlineSessionsData?.data?.length > 0 ? (
            onlineSessionsData.data.map((session, index) => {
              return <SessionItem key={session._id} session={session} />;
            })
          ) : (
            <div
              style={{
                color: "dodgerblue",
                textAlign: "center",
                fontSize: "12px",
                fontWeight: "600",
              }}
            >
              No online sessions
            </div>
          )}
        </OnlineContentContainer>
        <ContentContainer>
          <InfoContainer>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                gap: "5px",
              }}
            >
              <span>Unique sessions</span>
              <input
                type="checkbox"
                checked={isUnique}
                onChange={(e) => setIsUnique(e.target.checked)}
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                gap: "5px",
              }}
            >
              <span>Filter by actions</span>
              <select
                onChange={(e) => {
                  if (e.target.value === "all") {
                    setActionSelected(null);
                  } else {
                    setActionSelected(e.target.value);
                    setIsUnique(false);
                  }
                }}
              >
                <option value="all">All</option>
                <option value="SCROLLED_TO_BOTTOM">Scrolled to bottom</option>
                <option value="VISIT_REVIEW_LINK">Clicked review page</option>
                <option value="PROMO_PAGE_VISIT">Clicked promo page</option>
                <option value="VISIT_SIM_LINK">Clicked sim page</option>
              </select>
            </div>
          </InfoContainer>
          <ListWrapper>
            <ListContainer id="content-container">
              {(isFetchingNextPage || isLoading || isRefetching) && (
                <LoaderOverlay>
                  <BarLoader color="#606060" loading={true} />
                </LoaderOverlay>
              )}
              {allData?.length > 0 &&
                allData.map((session, index) => {
                  return <SessionItem key={session._id} session={session} />;
                })}

              <OptionsButtonsContainer>
                {/* <Tooltip title="Filters" placement="left">
                <OptionsButton>
                  <i className="fa-solid fa-filter"></i>
                </OptionsButton>
              </Tooltip> */}

                <Tooltip title="Generate excel file" placement="left">
                  <OptionsButton
                    onClick={() => {
                      if (window.confirm("Generate excel file ?")) {
                        generateExcelFromPortalLogs(allData);
                      }
                    }}
                  >
                    <i className="fa-solid fa-file-excel"></i>
                  </OptionsButton>
                </Tooltip>
              </OptionsButtonsContainer>
            </ListContainer>
          </ListWrapper>
          {allData?.length && !hasNextPage && (
            <div style={{ color: "grey", textAlign: "center" }}>
              No more logs to show
            </div>
          )}
          <InfoContainer>
            <span>
              Total sessions:{" "}
              <span
                style={{
                  color: "dodgerblue",
                  fontWeight: "600",
                }}
              >
                {offlineSessions?.pages[0]?.data?.totalDocs}
              </span>
            </span>
            <span>
              Page:{" "}
              <span
                style={{
                  color: "dodgerblue",
                  fontWeight: "600",
                }}
              >{`${offlineSessions?.pageParams?.length || 1}/${
                offlineSessions?.pages[0]?.data?.totalPages
              }`}</span>
            </span>
          </InfoContainer>
        </ContentContainer>
      </ContentWrapper>
    </Container>
  );
};

export default BookingPortalLogsContent;
