import { useState, useEffect } from "react";
import styled from "styled-components";
import { useAxios } from "../../../../axiosProvider";
import Tooltip from "@mui/material/Tooltip";

//------------------------------------------------------------------------------------------

const EditOverlay = styled.div`
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;

  i {
    color: white;
    font-size: 15px;
  }
  backdrop-filter: blur(1px);
`;

const EditableInputContainer = styled.div`
  position: relative;
  height: 40px;
  min-height: 100%;
  flex: 1;
  width: 100%;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .fa-times {
    color: grey;
    padding: 0 10px;
    font-size: 16px;
    height: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      color: indianred;
    }
  }
  &:hover {
    ${EditOverlay} {
      opacity: 1;
    }
  }
  input {
    height: 100%;
    min-height: 100%;
    flex: 1;
    width: 100%;
    background-color: transparent;
    white-space: nowrap; /* Prevents the text from wrapping onto the next line */
    overflow: hidden; /* Hides text that overflows the cell */
    text-overflow: ellipsis; /* Displays an ellipsis when text is hidden */
    width: auto;
    padding: 0 !important;
    margin: 0 !important;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .fa-check {
    font-size: 15px;
  }
`;

//------------------------------------------------------------------------------------------

const EditableInput = ({ value, placeholder, isLoading, handleInput }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [inputValue, setInputValue] = useState(value);

  const { user } = useAxios();
  const userIsAdmin = user.isAdmin;
  const userIsPermittedToEditGuidePlan =
    user.permissions.editGuidePlan || userIsAdmin;

  const handleKeyDown = (event) => {
    // Check if the Enter key was pressed
    if (event.key === "Enter") {
      handleInput(inputValue);
      setIsEditing(false);
    }
  };

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  return (
    <EditableInputContainer
      onClick={(e) => {
        if (userIsPermittedToEditGuidePlan) {
          e.stopPropagation();
          setIsEditing(true);
        } else {
          alert("You do not have permission to edit guide plan");
          e.stopPropagation();
        }
      }}
    >
      {isEditing ? (
        <>
          <input
            placeholder={placeholder}
            autoFocus
            type="text"
            value={isLoading ? "updating..." : inputValue}
            onChange={(e) => {
              setInputValue(e.target.value);
            }}
            onKeyDown={handleKeyDown}
          />
          <i
            className="fas fa-times"
            onClick={(e) => {
              e.stopPropagation();
              setIsEditing(false);
            }}
          ></i>
        </>
      ) : (
        <>
          <div
            style={{
              //wrap text with ellipsis
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              width: "100%",
            }}
          >
            {isLoading ? "updating..." : value}
          </div>
          <Tooltip
            title={value}
            //disableHoverListener={guides_asked_names_commas ? false : true}
            placement="top"
          >
            <EditOverlay>
              <i className="fas fa-edit"></i>
            </EditOverlay>
          </Tooltip>
        </>
      )}
    </EditableInputContainer>
  );
};

export default EditableInput;
