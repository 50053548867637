import moment from "moment";
import { useState } from "react";
import styled from "styled-components";
import { ThreeDots } from "react-loader-spinner";
import { useSendGuideEmail } from "../../../../reactQueryHooks";
import { motion } from "framer-motion";
import validator from 'validator';
import _ from "lodash";

//---------------------------------------------------------

const Modal = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(100, 100, 100, 0.8);
  border-radius: 5px;
  padding: 5px;
  overflow: hidden;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
`;

const Container = styled(motion.div)`
  border-radius: 5px;
  padding: 5px;
  height: 100%;
  width: 100%;
  max-width: 500px;
  max-height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
  overflow: hidden;
  font-size: 13px;
`;

const ContentContainer = styled.div`
  height: 100%;
  width: 100%;
  overflow-y: auto;
  padding: 0 5px;
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  height: 100%;
  width: 100%;
  padding: 5px;
  background-color: white;
  border-radius: 5px;
`;

const Button = styled.button`
  border: none;
  outline: none;
  flex: 1;
  border-radius: 4px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: lab(100 0 -0.03);
  padding: 0 5px;
`;

const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
  min-height: 35px;
`;

const CloseButton = styled.button`
  border: none;
  color: white;
  height: 35px;
  width: 35px;
  border-radius: 4px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: absolute;
  top: 5px;
  right: 5px;
  i {
    font-size: 20px;
  }
  &:hover {
    i {
      color: indianred;
    }
  }
`;

const TextAreaContainer = styled.div`
  height: 100%;
  width: 100%;
  flex: 1;
  border-radius: 4px;
  background-color: whitesmoke;
`;

const TextArea = styled.textarea`
  background-color: transparent;
  width: 100%;
  height: 100%;
  padding: 8px;
  resize: none;
  font-size: 13px;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.00938em;
  color: rgba(0, 0, 0, 0.87);
  border: none;
  outline: none;
`;

const EmailInput = styled.input`
  background-color: transparent;
  width: 100%;
  padding: 8px;
  font-size: 13px;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.00938em;
  color: rgba(0, 0, 0, 0.87);
  border: none;
  outline: none;
  border-radius: 4px;
  background-color: whitesmoke;
`;

//---------------------------------------------------------

const SendGuideMailModal = ({ close, data, setGuide_email_sent }) => {
  const [email, setEmail] = useState(data.guide_email);
  const [mailBody, setMailBody] =
    useState(`ΜΕ ΤΗΝ ΠΑΡΟΥΣΑ ΣΑΣ ΑΝΑΘΕΤΟΥΜΕ ΤΗΝ ΞΕΝΑΓΗΣΗ ΤΩΝ ΠΕΛΑΤΩΝ ΜΑΣ ΜΕ ΤΑ ΠΑΡΑΚΑΤΩ ΣΤΟΙΧΕΙΑ

ΕΝΤΟΛΕΑΣ: GETAWAYS TRAVEL AND TOURISM PRIVATE COMPANY
ΜΗΤΕ: 0206Ε60000651701
ΟΝΟΜΑ ΞΕΝΑΓΟΥ: ${data.guide_name}
ΑΦΜ ΞΕΝΑΓΟΥ: ${data.guide_afm}
ΑΜΚΑ ΞΕΝΑΓΟΥ: ${data.guide_amka}
ΑΡΙΘΜΟΣ ΜΗΤΡΩΟΥ ΞΕΝΑΓΟΥ: ${data.guide_reg_number}
ΗΜΕΡΟΜΗΝΙΑ ΞΕΝΑΓΗΣΗΣ: ${moment(data.date).format("DD/MM/YYYY")}
ΕΝΑΡΞΗ ΞΕΝΑΓΗΣΗΣ: ${data.time} 
ΟΝΟΜΑ ΠΕΛΑΤΗ: ${_.startCase(data.client_name)}
ΑΡΙΘΜΟΣ ΑΤΟΜΩΝ: ${data.seats}
ΣΗΜΕΙΟ ΣΥΝΑΝΤΗΣΗΣ: ${data.meeting_point}
ΔΙΑΡΚΕΙΑ ΞΕΝΑΓΗΣΗΣ: 5 HOURS

ΜΕ ΕΚΤΙΜΗΣΗ

ΧΡΗΣΤΟΣ ΓΕΩΡΓΙΑΔΗΣ
MANAGING DIRECTOR
GETAWAYS GREECE TRAVEL AND TOURISM PRIVATE COMPANY IKE
www.getawaysgreece.com`);

  //----------------------------------------

  const { isLoading: isSendGuideEmailLoading, mutateAsync: sendGuideEmail } =
    useSendGuideEmail();

  const handleSendGuideEmail = async () => {
    try {
      if (!email) {
        alert("Please enter an email");
        return;
      }

      if (!validator.isEmail(email)) {
        alert("Please enter a valid email");
        return;
      }

      if (
        window.confirm(`Are you sure you want to send the email to ${email}?`)
      ) {
        const response = await sendGuideEmail({
          guide_email: email,
          message_body: mailBody,
          tour_group_id: data.tour_group_id,
        });

        setGuide_email_sent(true);

        alert(response.data)
      }
    } catch (error) {
      console.log(error);
      alert(error.toString());
    }
  };

  //-----------------------------------

  return (
    <Modal>
      <CloseButton onClick={close}>
        <i className="fas fa-times"></i>
      </CloseButton>

      <Container
        initial={{ opacity: 0, scale: 0 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{
          opacity: { duration: 0.2 },
          scale: {
            //type: "spring",
            // stiffness: 300, // Adjust stiffness for more/less bounce
            // damping: 20, // Adjust damping for faster/slower settle
            // duration: 0.02,
          },
        }}
      >
        <ContentContainer>
          <FormContainer>
            <TextAreaContainer>
              <TextArea
                value={mailBody}
                onChange={(e) => setMailBody(e.target.value)}
              />
            </TextAreaContainer>
            <RowContainer>
              <EmailInput
                placeholder="Email"
                type="email"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
            </RowContainer>
            <RowContainer>
              <Button
                type="button"
                style={{
                  backgroundColor: "rgb(85,165,85)",
                }}
                onClick={handleSendGuideEmail}
              >
                {isSendGuideEmailLoading ? (
                  <ThreeDots
                    height="30"
                    width="30"
                    radius="9"
                    color="whitesmoke"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={true}
                  />
                ) : (
                  "SEND EMAIL"
                )}
              </Button>
            </RowContainer>
          </FormContainer>
        </ContentContainer>
      </Container>
    </Modal>
  );
};

export default SendGuideMailModal;