import { useFetchEntities } from "../../../../reactQueryHooks";

//------------------------------------------- 

const Staff = ({ staff }) => {
  return staff.map((person, index) => {
    return (
      <span
        key={person._id}
        style={{
          marginRight: "5px",
        }}
      >
        <span
          style={{
            color: person.reported ? "rgb(41 121 41)" : "black",
          }}
        >
          {person.name}
        </span>

        <span
          style={{
            color: person.reported ? "rgb(41 121 41)" : "black",
          }}
        >{` (${person.role})`}</span>

        {person.reported && (
          <i
            style={{
              marginLeft: "2px",
              fontSize: "10px",
              color: "rgb(41 121 41)",
            }}
            className="fa-solid fa-check"
          ></i>
        )}
      </span>
    );
  });
};

const CrewHeader = ({ assignees, vehicle }) => {
  const { data: entities } = useFetchEntities();
  const staffArray = [];

  for (const assignee of assignees) {
    const staff = entities.data.staff.find(
      (staff) => staff._id === assignee.user_id
    );
    const role = entities.data.roles.find(
      (role) => role._id === assignee.role_id
    );
    if (staff) {
      staffArray.push({
        _id: staff._id,
        name: staff.name,
        reported: assignee.reported,
        role: role.title,
      });
    }
  }

  return (
    <span
      style={{
        //wrap with ellipsis
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
      }}
    >
      <div className="names">
        <Staff staff={staffArray} />
        <i className="fa-solid fa-bus names"></i>
        <span className="names">
          {vehicle && <span style={{ marginLeft: "5px" }}>{vehicle}</span>}
          {!vehicle && (
            <span
              className="names"
              style={{ marginLeft: "5px", color: "indianred" }}
            >
              N/A
            </span>
          )}
        </span>
      </div>

      <span className="names_hidden">
        <i className="see">see task</i>
      </span>
    </span>
  );
};

export default CrewHeader;
