import { useState } from "react";
import {
  FieldWrapper,
  SectionWrapper,
  SectionContainer,
  FieldContainer,
  Input,
} from "../styled"
import { useFormikContext } from "formik";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useFetchEntities } from "../../../../reactQueryHooks";
import PickupsListSelectionModal from "../PickupsListSelectionModal";
import { Button } from "@mui/material"

const validationColors = (p_name, self) => {
  if (!p_name) {
    return "whitesmoke"
  }

  if (self) {
    return "#d2ffd2"
  } else {
    return "#ffdcdd"
  }
}

//---------------------------------------------------

const PickupLocationSection = () => {
  const [showPickupSelectionListModal, setShowPickupSelectionListModal] =
    useState(false)
  const { data: entities } = useFetchEntities()
  const { values, setFieldValue, handleChange } = useFormikContext()
  const meetingPoints = entities?.data?.meetingPoints

  //---------------------------------------------------

  return (
    <>
      <SectionWrapper>
        <SectionContainer>
          <span className="form-header">Pickup location</span>
        </SectionContainer>
        <SectionContainer>
          <FieldWrapper>
            <FieldContainer
              style={{
                justifyContent: "space-between",
                paddingRight: "9px",
              }}
              title="Select pickup from list"
              onClick={() => setShowPickupSelectionListModal(true)}
            >
              <i
                style={{
                  opacity: 0,
                }}
                className="fa-solid fa-chevron-down"
              ></i>
              <span>
                {values.pickup_location.name
                  ? values.pickup_location.name
                  : "Select pickup from list"}
              </span>
              <i className="fa-solid fa-chevron-down"></i>
            </FieldContainer>
            <FieldContainer title="Address">
              <Input
                type="text"
                placeholder="Address"
                name="pickup_location.address"
                onChange={handleChange}
                value={values.pickup_location.address}
                autoComplete="off"
                spellCheck="false"
              />
            </FieldContainer>
          </FieldWrapper>

          <FieldWrapper>
            <FieldContainer
              title="Pickup location"
              style={{
                flex: 7,
                backgroundColor: !values.pickup_location.name
                  ? "whitesmoke"
                  : values.pickup_location.name.length > 0
                  ? "#d2ffd2"
                  : "#ffdcdd",
              }}
            >
              <Input
                type="text"
                placeholder="Pickup"
                name="pickup_location.name"
                onChange={handleChange}
                value={values.pickup_location.name}
                autoComplete="off"
                spellCheck="false"
              />
            </FieldContainer>

            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip
                  style={{
                    position: "absolute",
                  }}
                >
                  See it on the map
                  <i
                    style={{
                      marginLeft: "5px",
                      fontSize: "11px",
                    }}
                    className="fa-solid fa-location-dot"
                  ></i>
                </Tooltip>
              }
            >
              <FieldContainer
                onClick={() => {
                  window.open(
                    `https://www.google.com.sa/maps/place/${values.pickup_location.latitude},${values.pickup_location.longitude}?hl=en`
                  );
                }}
                style={{ flex: 0, minWidth: "110px" }}
              >
                <i className="fa-solid fa-map-location-dot icon"></i>
              </FieldContainer>
            </OverlayTrigger>
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip
                  style={{
                    position: "absolute",
                  }}
                >
                  Pickup time
                  <i
                    style={{
                      marginLeft: "5px",
                      fontSize: "11px",
                    }}
                    className="fa-solid fa-clock"
                  ></i>
                </Tooltip>
              }
            >
              <FieldContainer
                style={{
                  minWidth: "110px",
                }}
              >
                <Input
                  autoFocus
                  type="time"
                  placeholder="Pickup time"
                  name="pickup_time"
                  onChange={handleChange}
                  value={values.pickup_time}
                  autoComplete="off"
                  autoCorrect="off"
                  autoCapitalize="off"
                  spellCheck="false"
                />
              </FieldContainer>
            </OverlayTrigger>
          </FieldWrapper>
          <FieldWrapper>
            <FieldContainer
              title="Latitude"
              style={{
                flex: 1,
                backgroundColor: validationColors(
                  values.pickup_location.name,
                  values.pickup_location.latitude
                ),
              }}
            >
              <Input
                placeholder="Latitude"
                name="pickup_location.latitude"
                onChange={async (e) => {
                  await setFieldValue(
                    "pickup_location.latitude",
                    e.target.value
                  );
                  await setFieldValue(
                    "pickup_location.google_maps_url",
                    `https://www.google.com/maps/place/${e.target.value},${values.pickup_location.longitude}`
                  );
                }}
                value={values.pickup_location.latitude}
                autoComplete="off"
                spellCheck="false"
              />
            </FieldContainer>
            <FieldContainer
              title="Longitude"
              style={{
                flex: 1,
                backgroundColor: validationColors(
                  values.pickup_location.name,
                  values.pickup_location.longitude
                ),
              }}
            >
              <Input
                placeholder="Longitude"
                name="pickup_location.longitude"
                onChange={async (e) => {
                  await setFieldValue(
                    "pickup_location.longitude",
                    e.target.value
                  );
                  await setFieldValue(
                    "pickup_location.google_maps_url",
                    `https://www.google.com/maps/place/${values.pickup_location.latitude},${e.target.value}`
                  );
                }}
                value={values.pickup_location.longitude}
                autoComplete="off"
                autoCorrect="off"
                autoCapitalize="off"
                spellCheck="false"
              />
            </FieldContainer>
          </FieldWrapper>
          <FieldWrapper>
            <FieldContainer
              title="Map url"
              style={{
                flex: 1,
                backgroundColor: validationColors(
                  values.pickup_location.name,
                  values.pickup_location.google_maps_url
                ),
              }}
            >
              <Input
                style={{
                  //capitalize: "none",
                  textTransform: "none",
                }}
                type="text"
                placeholder="Map url"
                name="pickup_location.google_maps_url"
                onChange={handleChange}
                value={values.pickup_location.google_maps_url}
                autoComplete="off"
                autoCapitalize="off"
                autoCorrect="off"
                spellCheck="false"
              />
            </FieldContainer>
            <FieldContainer
              title="Image url"
              style={{
                flex: 1,
              }}
            >
              <Input
                style={{
                  //capitalize: "none",
                  textTransform: "none",
                }}
                type="text"
                placeholder="Photo url"
                name="pickup_location.img_url"
                onChange={handleChange}
                value={values.pickup_location.img_url}
                autoComplete="off"
                autoCapitalize="off"
                autoCorrect="off"
                spellCheck="false"
              />
            </FieldContainer>
          </FieldWrapper>
          <FieldContainer
            title="Instructions"
            style={{
              flex: 1,
              backgroundColor: validationColors(
                values.pickup_location.name,
                values.pickup_location.instructions
              ),
            }}
          >
            <Input
              style={{
                textTransform: "none",
              }}
              type="text"
              placeholder="Instructions"
              name="pickup_location.instructions"
              onChange={handleChange}
              value={values.pickup_location.instructions}
              autoComplete="off"
              autoCapitalize="off"
              autoCorrect="off"
              spellCheck="false"
            />
          </FieldContainer>
          <FieldWrapper>
            <Button
              style={{
                fontSize: "10px",
              }}
              color="warning"
              size="small"
              variant="contained"
              onClick={async () => {
                await setFieldValue("pickup_time", "");
              }}
            >
              CLEAR PICKUP TIME
            </Button>
            <Button
              style={{
                fontSize: "10px",
              }}
              color="warning"
              size="small"
              variant="contained"
              onClick={async () => {
                await setFieldValue("pickup_location.name", "");
                await setFieldValue("pickup_time", "");
                await setFieldValue("pickup_location.latitude", "");
                await setFieldValue("pickup_location.longitude", "");
                await setFieldValue("pickup_location.google_maps_url", "");
                await setFieldValue("pickup_location.img_url", "");
                await setFieldValue("pickup_location.address", "");
                await setFieldValue("pickup_location.instructions", "");
              }}
            >
              CLEAR PICKUP LOCATION
            </Button>
          </FieldWrapper>
        </SectionContainer>
      </SectionWrapper>

      {showPickupSelectionListModal && (
        <PickupsListSelectionModal
          close={() => setShowPickupSelectionListModal(false)}
          meetingPoints={meetingPoints}
        />
      )}
    </>
  );
}

export default PickupLocationSection;
