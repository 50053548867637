import styled from "styled-components";
import { useState, useEffect } from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import BarLoader from "react-spinners/BarLoader";
import StickyNote2Icon from "@mui/icons-material/StickyNote2";
import Tooltip from "@mui/material/Tooltip";
import PostAddIcon from "@mui/icons-material/PostAdd";
import { useGetNotes } from "../../../reactQueryHooks";
import NotesItem from "./NotesItem";
import { useAxios } from "../../../axiosProvider";

//----------------------------------------------------------

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  //margin-right: auto; //sits on the left of the grid
  * {
    user-select: none;
  }
  z-index: 2;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
`;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  //gap: 5px;
  align-items: center;
  font-size: 11px;
  font-weight: 600;
  padding: 0 8px;
  background-color: #e6e6e6;
  width: 50px;
  height: 30px;
  @media (max-width: 360px) {
    width: 40px;
    height: 25px;
    font-size: 12px;
  }
  border-radius: 5px;
`;

const StyledPopover = styled(Popover)`
  min-width: 400px;
  max-width: 400px;
  max-height: 500px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 3px;
  border-radius: 3px;
  gap: 3px;
`;

const AddNoteButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 30px;
  border: none;
  font-size: 12px;
  cursor: pointer;
  padding: 0 6px;
  background-color: #e9ffe9;
  &:hover {
    opacity: 0.8;
  }
`;

const NotificationLoadMoreButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 30px;
  border: none;
  font-size: 12px;
  &:hover {
    color: #166bbd;
  }
  background-color: #e6f2ff;
  //margin-top: 3px;
`;

const NotificationsListContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  padding: 0 3px 0 0;
  gap: 3px;
`;

//------------------------------------------------------

const Notes = () => {
  const { user } = useAxios();
  const [allData, setAllData] = useState([]);

  const {
    data: notes,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    refetch,
  } = useGetNotes(user._id);

  useEffect(() => {
    if (notes) {
      setAllData(notes?.pages?.flatMap((page) => page.data.docs));
    }
  }, [notes]);

  return (
    <OverlayTrigger
      placement="bottom-end"
      trigger={"click"}
      rootClose
      overlay={
        <StyledPopover>
          <Tooltip title="Add a note" placement="top">
            <AddNoteButton
              onClick={() => {
                const hasNewForm = allData.some((note) => note.isNewForm);
                if (!hasNewForm) {
                  setAllData([
                    {
                      isNewForm: true,
                      author_id: user._id,
                      body: "",
                    },
                    ...allData,
                  ]);
                }
              }}
            >
              <PostAddIcon
                style={{
                  color: "rgb(77 77 77)",
                  fontSize: "17px",
                }}
              />
            </AddNoteButton>
          </Tooltip>

          {allData?.length > 0 && (
            <NotificationsListContainer>
              {allData.map((note, index) => {
                return (
                  <NotesItem
                    key={index}
                    note={note}
                    refetch={refetch}
                    remove={() => {
                      //remove the item with isNewForm = true from the list
                      const filteredData = allData.filter(
                        (item) => item.isNewForm !== true
                      );
                      setAllData(filteredData);
                    }}
                  />
                );
              })}
            </NotificationsListContainer>
          )}

          {hasNextPage && (
            <NotificationLoadMoreButton onClick={fetchNextPage}>
              {isFetchingNextPage ? (
                <BarLoader
                  color={"#b1b1b1"}
                  loading={true}
                  width={100}
                  height={5}
                />
              ) : (
                "Load more"
              )}
            </NotificationLoadMoreButton>
          )}
        </StyledPopover>
      }
    >
      <Wrapper title="Notes">
        <Container>
          <StickyNote2Icon
            style={{
              color: "#4072A5",
              fontSize: "14px",
            }}
          />
          {allData?.length > 0 ? (
            <span
              style={{
                color: "indianred",
              }}
            >
              {allData?.length}
            </span>
          ) : (
            <i
              style={{
                color: "darkgreen",
              }}
              className="fa-solid fa-check"
            ></i>
          )}
        </Container>
      </Wrapper>
    </OverlayTrigger>
  );
};

export default Notes;
