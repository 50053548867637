const NoteList = ({ notesArray }) => {
  return (
    <ol
      style={{
        margin: "0",
        paddingLeft: "25px",
      }}
    >
      {notesArray?.map((note, index) => (
        <li
          key={index}
          style={{
            borderBottom: "1px solid lightgrey",
            margin: "0",
            padding: "0",
          }}
        >
          {/* <span
            style={{
              fontWeight: "bold",
            }}
          >
            {note.author} @ {moment(note.date).format("DD/MM/YYYY HH:mm")} :{" "}
          </span> */}
          <span
            style={{
              fontStyle: "italic",
            }}
          >
            {note.body}
          </span>
        </li>
      ))}
    </ol>
  );
};

export default NoteList;
