import { useState, useRef, forwardRef, useImperativeHandle } from "react";
import styled from "styled-components";
import { RotatingLines } from "react-loader-spinner";
import { useGetFilesPaginated } from "@/reactQueryHooks";
import { useDebounce } from "use-debounce";
import FileListItem from "./FileListItem";

//---------------------------------------------------------

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  overflow: hidden;
  flex: 1;
`;

const TopContainer = styled.div`
  width: 100%;
  height: 30px;
  display: flex;
  flex-direction: row;
  gap: 5px;
`;

const Input = styled.input`
  flex: 1;
  width: 100%;
  height: 100%;
  background-color: whitesmoke;
  border-radius: 4px;
  padding: 0 8px;
  font-size: 13px;
`;

const ListContainer = styled.div`
  flex: 1;
  overflow: auto;
  width: 100%;
  height: 100%;
  padding: 0 5px 0 0;
  overflow: auto;
`;

const Select = styled.select`
  height: 30px;
  font-size: 11px;
  border-radius: 4px;
  padding: 0 3px;
  cursor: pointer;
  background-color: whitesmoke;
  border: none;
  outline: none;
`;

//---------------------------------------------------------

const FilesList = forwardRef((_, ref) => {
  const [dataPage, setDataPage] = useState<number>(1);
  const [searchInput, setSearchInput] = useState<string>("");
  const [debouncedSearchTerm] = useDebounce(searchInput, 400);
  const [type, setType] = useState<string>("");

  useImperativeHandle(ref, () => ({
    reset: () => {
      setDataPage(1);
      setSearchInput("");
      setType("");
      refetch();
    },
  }));

  const { data, isFetching, refetch } = useGetFilesPaginated(
    dataPage,
    debouncedSearchTerm,
    type
  );
  const totalPages = data?.totalPages || 1;

  const scrollToTop = () => {
    if (listRef.current) {
      listRef.current.scrollTop = 0;
    }
  };

  const listRef = useRef<HTMLDivElement | null>(null);
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchInput(event.target.value);
    setDataPage(1);
    scrollToTop();
  };

  //---------------------------------------------------------

  return (
    <Wrapper>
      <TopContainer>
        <Input
          autoFocus
          placeholder="Search..."
          autoCapitalize="none"
          autoComplete="off"
          autoCorrect="off"
          spellCheck="false"
          value={searchInput}
          onChange={handleInputChange}
        />
        <Select
          onChange={(e) => {
            setType(e.target.value);
            setDataPage(1);
            scrollToTop();
          }}
          value={type}
        >
          <option value="">All file types</option>
          <option value="image">Image</option>
          <option value="document">Document</option>
        </Select>
        <Select
          onChange={(e) => {
            setDataPage(Number(e.target.value));
            scrollToTop();
          }}
          value={dataPage}
        >
          {Array.from({ length: totalPages }, (_, i) => i + 1).map((page) => {
            return (
              <option key={page} value={page}>
                Page {page} / {totalPages}
              </option>
            );
          })}
        </Select>
      </TopContainer>

      {isFetching && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <RotatingLines width="15" strokeColor="black" />
        </div>
      )}

      {data?.docs?.length > 0 && !isFetching && (
        <ListContainer ref={listRef}>
          {data.docs.map((file: any) => {
            return <FileListItem key={file._id} file={file} />;
          })}
        </ListContainer>
      )}

      {data?.docs?.length === 0 && !isFetching && (
        <span
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            color: "indianred",
            fontSize: "12px",
          }}
        >
          No files found
        </span>
      )}
    </Wrapper>
  );
});

export default FilesList;
