import styled from "styled-components";
import { memo } from "react";
import { useFetchEntities } from "../../../../reactQueryHooks";

//--------------------------------------------

const Container = styled.div`
  background-color: ${({ isSelected }) =>
    isSelected ? "#c7fcc7" : "whitesmoke"};
  width: 100%;
  border-radius: 5px;
  //box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.2);
  //on hover make EditOverlay visible
  &:hover {
    filter: brightness(0.9);
  }
  cursor: pointer;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 3px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
  padding: 5px;
`;

//--------------------------------------------

const ScheduleTaskItem = ({ task, isSelected }) => {
  const { data: entities } = useFetchEntities();
  const product = entities?.data?.products.find(
    (product) => product._id === task.product
  );
  const vehicle = entities?.data?.vehicles.find(
    (vehicle) => vehicle._id === task.vehicle_id
  );

  return (
    <Container isSelected={isSelected}>
      <Content>
        <Row>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "10px",
                fontWeight: "bold",
              }}
            >
              <i
                style={{ color: "#63b2ff", fontSize: "13px" }}
                className="fa-solid fa-star"
              ></i>
              {product.title}
            </div>
          </div>
        </Row>
        {task.vehicle && (
          <Row>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <i
                style={{ color: "#63b2ff", fontSize: "14px" }}
                className="fa-solid fa-car"
              ></i>
              {vehicle.plate}
            </div>
          </Row>
        )}

        {task.assignees.map((assignee, index) => {
          const assigneeData = entities?.data?.staff.find(
            (staff) => staff._id === assignee.user_id
          );
          return (
            <Row key={index}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <i
                    style={{ color: "#63b2ff", fontSize: "14px" }}
                    className="fa-solid fa-user"
                  ></i>
                  {assigneeData.name}
                </div>
              </div>
            </Row>
          );
        })}
      </Content>
    </Container>
  );
};

export default memo(ScheduleTaskItem);
