import { useEffect } from "react";
import moment from "moment";
import styled from "styled-components";
import { useState } from "react";
import { useGetBookingPortalSessionsByRef } from "../../../reactQueryHooks";

//------------------------------------------

const Container = styled.div`
  display: flex;
  flex-direction: column;
  color: white;
  background: rgb(71, 71, 71);
  border-radius: 5px;
  padding: 7px;
  border: 2px solid white;
  user-select: none;
  gap: 5px;
`;

const TopContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px 2px;
  cursor: pointer;
`;

const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: rgb(88, 88, 88);
  padding: 5px;
  border-radius: 5px;
  gap: 5px;
`;

const SessionContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5px;
  //border: 2px solid white;
  border-radius: 5px;
  background: rgb(71, 71, 71);
  &:hover {
    filter: brightness(0.9);
  }
  gap: 2px;
`;

const SessionInfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
  flex-wrap: wrap;
`;

const SessionInfoItem = styled.div`
  display: flex;
  flex-direction: row;
  gap: 2px;
  background: rgb(100, 100, 100);
  padding: 0 4px;
  border-radius: 5px;
`;

const SessionActionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

//------------------------------------------

const BookingPortalLogs = ({ bookingRef }) => {

  const [isExpanded, setIsExpanded] = useState(false);
  const {
    data: portalSessions,
    isFetching,
    isError,
    error,
    refetch,
  } = useGetBookingPortalSessionsByRef(bookingRef);

  useEffect(() => {
    if (isExpanded) refetch();
  }, [refetch, isExpanded]);

  return (
    <Container>
      <TopContainer onClick={() => setIsExpanded((prev) => !prev)}>
        <div>Booking portal sessions</div>
        <div>{isExpanded ? "▲" : "▼"}</div>
      </TopContainer>
      {isExpanded && (
        <ListContainer>
          {isFetching && <span>Loading sessions...</span>}
          {isError && <span>Error: {error.toString()}</span>}
          {!isFetching && !isError && portalSessions.data.length === 0 && (
            <span>No sessions found</span>
          )}
          {!isFetching &&
            !isError &&
            portalSessions.data.length > 0 &&
            portalSessions.data.map((session) => {
              return (
                <SessionContainer key={session._id}>
                  <SessionInfoContainer>
                    <SessionInfoItem>
                      <div>Platform</div>
                      <div
                        style={{
                          color: "rgb(200, 200, 255)",
                          textTransform: "capitalize",
                        }}
                      >
                        {session.device_info.platform}
                      </div>
                    </SessionInfoItem>
                    <SessionInfoItem>
                      <div>OS</div>
                      <div
                        style={{
                          color: "rgb(200, 200, 255)",
                          textTransform: "capitalize",
                        }}
                      >
                        {session.device_info.osName}
                      </div>
                    </SessionInfoItem>
                    <SessionInfoItem>
                      <div>Version</div>
                      <div
                        style={{
                          color: "rgb(200, 200, 255)",
                          textTransform: "capitalize",
                        }}
                      >
                        {session.device_info.osVersion}
                      </div>
                    </SessionInfoItem>
                    <SessionInfoItem>
                      <div>Browser</div>
                      <div
                        style={{
                          color: "rgb(200, 200, 255)",
                          textTransform: "capitalize",
                        }}
                      >
                        {session.device_info.browserName}
                      </div>
                    </SessionInfoItem>
                    <SessionInfoItem>
                      <div>Mobile vendor</div>
                      <div
                        style={{
                          color: "rgb(200, 200, 255)",
                          textTransform: "capitalize",
                        }}
                      >
                        {session.device_info.mobileVendor}
                      </div>
                    </SessionInfoItem>
                    <SessionInfoItem>
                      <div>Model</div>
                      <div
                        style={{
                          color: "rgb(200, 200, 255)",
                          textTransform: "capitalize",
                        }}
                      >
                        {session.device_info.mobileModel}
                      </div>
                    </SessionInfoItem>
                  </SessionInfoContainer>
                  {session.session_actions.map((action) => {
                    return (
                      <SessionActionsContainer key={action._id}>
                        <div
                          style={{
                            color: "rgb(200, 200, 255)",
                            textTransform: "capitalize",
                          }}
                        >
                          {action.user_action}
                        </div>
                        <div>
                          {moment(action.date_time).format(
                            "DD/MM/YYYY HH:mm:ss"
                          )}
                        </div>
                      </SessionActionsContainer>
                    );
                  })}
                </SessionContainer>
              );
            })}
        </ListContainer>
      )}
    </Container>
  );
};

export default BookingPortalLogs;
