import styled from "styled-components";
import moment from "moment";
import {
  useFetchEntities,
  useDeleteAnnouncement,
} from "../../../../reactQueryHooks";

//----------------------------------------------------------------

const OptionsOverlay = styled.div`
  opacity: 0;
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  background-color: rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(2px);
`;

const AnnouncementItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: white;
  padding: 5px;
  gap: 5px;
  border-radius: 5px;
  max-height: 100px;
  box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.1);
  position: relative;
  i {
    color: #bdbdbd;
    font-size: 12px;
  }
  &:hover {
    ${OptionsOverlay} {
      opacity: 1;
    }
  }
`;

const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  background-color: whitesmoke;
  border: thin solid #eceff1;
  padding: 5px;
  border-radius: 5px;
  gap: 5px;
`;

const ContentItem = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12px;
  //background-color: whitesmoke;

  div {
    flex: 1;
    display: flex;
    flex-direction: row;
    gap: 5px;
    align-items: center;
    //border-bottom: 1px solid lightgrey;
    //border-left: 1px solid lightgrey;
    padding: 0 2px;
  }

  //first div
  div:first-child {
    border-bottom: 1px solid lightgrey;
  }
  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const OptionsItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 40px;
  border-radius: 100%;
  background-color: white;
  cursor: pointer;
  i {
    font-size: 12px;
  }
  .fa-edit {
    color: dodgerblue;
  }
  .fa-trash {
    color: indianred;
  }
`;

//------------------------------------------------------------------------
const AnnouncementItem = ({ announcement, handleOpenModal, refetch }) => {
  const { data: entities } = useFetchEntities();

  const userData = entities.data.staff.find(
    (user) => user._id === announcement.author
  );

  const { mutateAsync: deleteAnnouncement } = useDeleteAnnouncement();

  return (
    <AnnouncementItemContainer>
      <Content>
        <ContentItem>
          <div>
            <i className="fa-regular fa-calendar"></i>
            <span>{moment(announcement.date).format("DD MMM YYYY")}</span>
          </div>
          <div>
            <i className="fas fa-user"></i>
            <span>{userData?.name || "-"}</span>
          </div>
        </ContentItem>
        <ContentItem>
          <div>
            <i className="fa-regular fa-envelope"></i>
            <span>{announcement.title}</span>
          </div>

          <div
            style={
              {
                // display: "flex",
                // justifyContent: "flex-end",
              }
            }
          >
            {/* {announcement.critical && (
              <span
                style={{
                  color: "indianred",
                }}
              >
                CRITICAL
              </span>
            )} */}
            {/* <i
              style={{
                color: announcement.critical ? "indianred" : "grey",
              }}
              className="fa-solid fa-circle-exclamation"
            ></i>
            <i
              style={{
                color: announcement.pinned ? "darkgreen" : "grey",
              }}
              className="fa-solid fa-thumbtack"
            ></i> */}
            <i className="fa-solid fa-quote-left"></i>
            <span
              style={{
                fontStyle: "italic",
              }}
            >
              {announcement.body.slice(0, 20) + "..."}
            </span>
          </div>
        </ContentItem>
      </Content>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: "5px",
        }}
      >
        <i
          style={{
            color: announcement.critical ? "indianred" : "grey",
          }}
          className="fa-solid fa-circle-exclamation"
        ></i>
        <i
          style={{
            color: announcement.pinned ? "darkgreen" : "grey",
          }}
          className="fa-solid fa-thumbtack"
        ></i>
      </div>
      <OptionsOverlay>
        <OptionsItem
          title="Edit announcement"
          onClick={() => {
            handleOpenModal(announcement);
          }}
        >
          <i className="fas fa-edit"></i>
        </OptionsItem>
        <OptionsItem
          title="Delete announcement"
          onClick={async () => {
            try {
              if (
                window.confirm(
                  "Are you sure you wish to delete this announcement?"
                )
              ) {
                const response = await deleteAnnouncement(announcement?._id);
                //@ts-ignore
                alert(response?.data);
                refetch();
              }
            } catch (error) {
              alert(error?.toString());
            }
          }}
        >
          <i className="fas fa-trash"></i>
        </OptionsItem>
      </OptionsOverlay>
    </AnnouncementItemContainer>
  );
};

export default AnnouncementItem;
