import styled from "styled-components";
import { Formik } from "formik";
import * as Yup from "yup";
import { motion } from "framer-motion";
import {
  useFetchEntities,
  useAddNewServiceLogEntry,
} from "../../../../reactQueryHooks";

//--------------------------------------------------------------------------------------------

const Wrapper = styled(motion.div)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: center;
  width: 65%;
  max-width: 450px;
  border-radius: 5px;
  padding: 5px;
  gap: 5px;
`;

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
  padding: 5px;
  background-color: white;
  border-radius: 5px;
`;

const Row = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  gap: 5px;
  border-radius: 5px;
  flex-wrap: wrap;
`;

const InputContainer = styled.div`
  flex: 1;
  border-radius: 3px;
  height: 35px;
  background-color: whitesmoke;
  font-size: 12px;
  select {
    flex: 1;
    width: 100%;
    background: transparent;
    border: none;
    height: 100%;
    font-size: 13px;
    font-weight: 500;
    text-align: center;
    &:focus {
      outline: none;
    }
    text-overflow: ellipsis;
  }
`;

const Input = styled.input`
  flex: 1;
  width: 100%;
  height: 100%;
  padding: 5px;
  border: none;
  text-transform: uppercase;
  font-size: 13px;
  border-radius: 3px;
`;

const HeaderRow = styled.div`
  flex: 1;
  padding: 0 2px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: white;
  font-size: 15px;
`;

const ButtonsRow = styled.div`
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  min-height: 40px;
`;

const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  outline: none;
  :hover {
    filter: brightness(0.95);
  }
  i {
    font-size: 17px;
  }
  .fa-spinner {
    font-size: 14px;
  }
`;

//----------------------------------------------------------------------------------

const AddNewServiceLogModal = ({ closeModal, vehicleId, refetch }) => {
  const { data: entities } = useFetchEntities();
  const { mutateAsync: addNewServiceLogEntry, isLoading } =
    useAddNewServiceLogEntry();

  const vehicles = entities?.data?.vehicles;
  const staff = entities?.data?.staff;

  const handleAddNewServiceLog = async (values) => {
    try {
      await addNewServiceLogEntry(values);
      refetch();
      closeModal();
    } catch (err) {
      // closeForm();
      if (err.response.data) {
        alert(err.response.data);
      } else {
        alert(err.toString());
      }
      console.log(err);
    }
  };

  return (
    <Formik
      validateOnMount
      //enableReinitialize
      initialValues={{
        vehicle_id: vehicleId,
        assignee: "",
        workshop: "",
        date: "",
        odometer: "",
        cost: "",
        notes: "",
        repairs: [""],
      }}
      validationSchema={Yup.object({
        vehicle_id: Yup.string().required("Vehicle is required"),
        assignee: Yup.string().required("Assignee is required"),
        workshop: Yup.string().required("Workshop is required"),
        date: Yup.string().required("Date is required"),
        odometer: Yup.string().required("Odometer is required"),
        cost: Yup.string().required("Cost is required"),
        notes: Yup.string(),
        repairs: Yup.array().of(Yup.string()),
      })}
      onSubmit={(values, { setSubmitting, setFieldValue }) => {
        handleAddNewServiceLog({
          ...values,
          workshop: values.workshop.toUpperCase(),
        });
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
        resetForm,
        isSubmitting,
        validateForm,
      }) => {
        return (
          <Wrapper
            initial={{ opacity: 0, scale: 0 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{
              opacity: { duration: 0.2 },
              scale: { duration: 0.1 },
            }}
          >
            <HeaderRow>New service record</HeaderRow>
            <Container>
              <Row>
                <InputContainer>
                  <select
                    style={{
                      backgroundColor: errors.vehicle_id
                        ? "rgb(255,232,232)"
                        : "rgb(233,255,233)",
                    }}
                    onChange={(e) => {
                      if (e.target.value === "reset") {
                        setFieldValue("vehicle_id", "");
                      } else {
                        setFieldValue("vehicle_id", e.target.value);
                      }
                    }}
                    value={values.vehicle_id}
                  >
                    <option value="reset">Select a vehicle</option>
                    {vehicles.map((vehicle, index) => (
                      <option value={vehicle._id} key={index}>
                        {vehicle.plate}
                      </option>
                    ))}
                  </select>
                </InputContainer>
                <InputContainer>
                  <select
                    style={{
                      backgroundColor: errors.assignee
                        ? "rgb(255,232,232)"
                        : "rgb(233,255,233)",
                    }}
                    onChange={(e) => {
                      if (e.target.value === "reset") {
                        setFieldValue("assignee", "");
                      } else {
                        setFieldValue("assignee", e.target.value);
                      }
                    }}
                    value={values.assignee}
                  >
                    <option value="reset">Select assignee</option>
                    {staff.map((staffMember, index) => (
                      <option value={staffMember._id} key={index}>
                        {staffMember.name}
                      </option>
                    ))}
                  </select>
                </InputContainer>
              </Row>
              <Row>
                <InputContainer>
                  <Input
                    type="date"
                    style={{
                      backgroundColor: errors.date
                        ? "rgb(255,232,232)"
                        : "rgb(233,255,233)",
                    }}
                    placeholder="Date"
                    name="date"
                    value={values.date}
                    onChange={handleChange}
                  />
                </InputContainer>
                <InputContainer>
                  <Input
                    style={{
                      backgroundColor: errors.workshop
                        ? "rgb(255,232,232)"
                        : "rgb(233,255,233)",
                    }}
                    placeholder="WORKSHOP"
                    name="workshop"
                    value={values.workshop}
                    onChange={handleChange}
                  />
                </InputContainer>
              </Row>
              <Row>
                <InputContainer>
                  <Input
                    style={{
                      backgroundColor: errors.odometer
                        ? "rgb(255,232,232)"
                        : "rgb(233,255,233)",
                    }}
                    placeholder="ODOMETER"
                    name="odometer"
                    value={values.odometer}
                    onChange={handleChange}
                  />
                </InputContainer>
                <InputContainer>
                  <Input
                    style={{
                      backgroundColor: errors.cost
                        ? "rgb(255,232,232)"
                        : "rgb(233,255,233)",
                    }}
                    placeholder="cost"
                    name="cost"
                    value={values.cost}
                    onChange={handleChange}
                  />
                </InputContainer>
              </Row>
              {values.repairs.map((repair, index) => (
                <Row key={index}>
                  <InputContainer>
                    <Input
                      style={{
                        backgroundColor: "whitesmoke",
                      }}
                      placeholder={`Repair #${index + 1}`}
                      name={`repairs[${index}]`}
                      value={repair}
                      onChange={(event) => {
                        const newRepairs = [...values.repairs];
                        newRepairs[index] = event.target.value.toUpperCase();
                        setFieldValue("repairs", newRepairs);
                      }}
                    />
                  </InputContainer>
                </Row>
              ))}
            </Container>
            <ButtonsRow>
              <Button
                style={{
                  height: "40px",
                  padding: "0 10px",
                  backgroundColor: "rgb(142,185,228)",
                }}
                type="button"
                onClick={() => {
                  setFieldValue("repairs", [...values.repairs, ""]);
                }}
              >
                ADD REPAIR
              </Button>
              <Button
                style={{
                  width: "50px",
                  height: "40px",
                  backgroundColor: "rgb(129, 151, 129)",
                }}
                type="button"
                onClick={() => {
                  //if there are errors alert them
                  if (Object.keys(errors).length > 0) {
                    alert("Please fill all the required fields");
                  } else {
                    //if there are no errors, submit the form
                    handleSubmit();
                  }
                }}
              >
                {isLoading ? (
                  <i className="fas fa-spinner fa-spin"></i>
                ) : (
                  <i className="fas fa-floppy-disk"></i>
                )}
              </Button>
            </ButtonsRow>
          </Wrapper>
        );
      }}
    </Formik>
  );
};

export default AddNewServiceLogModal;
