import styled from 'styled-components';
import { useState, useEffect, useCallback, useRef } from 'react';
import { motion } from "framer-motion";
import stringSimilarity from "string-similarity";
import { v4 as uuidv4 } from "uuid";

//---------------------------------------------------------

const Container = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: 5px;
  user-select: none;
`;

const InputContainer = styled.div`
width: 100%;
background-color: white;
`

const Input = styled.input`
width: 100%;    
min-height: 35px;   
border: none;   
padding: 5px;
background-color: whitesmoke;
border-radius: 5px;
font-size: 14px;
`

const ListContainer = styled.div`
height: 100%;
overflow-y: scroll;
padding: 0 5px 0 0 ;
display: flex;
flex-direction: column;
`

const ListSection = styled.div`
display: flex;  
flex-direction: column; 
gap: 3px;
`

const StickyHeader = styled.div`
position: sticky;   
top: 0; 
background-color: rgb(215 239 250);    
display: flex;
justify-content: center;    
font-weight: bold;
padding: 5px;
border-radius: 5px;
z-index: 1;
margin-bottom: 3px;
`

const ListItem = styled.div`
    display: flex;  
    justify-content: space-between; 
    /* margin-bottom: 5px; */
    align-items: center;
    padding: 5px;   
    border-radius: 5px;
    cursor: pointer;
    color: ${({ isSelectedInSection, isSelectedInOtherSection }) => {
        if (isSelectedInSection) return "white"
        return "black";
    }}; 
    
   background-color: ${({ isSelectedInSection, isSelectedInOtherSection }) => {
        if (isSelectedInSection) return "rgb(101 173 101)"
        if (isSelectedInOtherSection) return "rgb(252 182 182)";
        return "white";
    }};
    &:hover {
    filter: brightness(0.9);
    }
 `

//---------------------------------------------------------

const ListItemComponent = ({ item, onSelect, isSelectedInSection, isSelectedInOtherSection, setErrorMessageShown }) => {
    return (
        <ListItem
            title={isSelectedInSection ? "Remove" : isSelectedInOtherSection ? "Selected in different section" : "Select"}
            onClick={() => {
                if (!isSelectedInOtherSection) {
                    onSelect();
                } else {
                    setErrorMessageShown(true);
                }
            }}
            isSelectedInSection={isSelectedInSection}
            isSelectedInOtherSection={isSelectedInOtherSection}
        >
            {item.label}
            {isSelectedInOtherSection && <i className='fas fa-exclamation-triangle' style={{ color: "indianred" }}></i>}
            {isSelectedInSection && <i className='fas fa-check' style={{ color: "white" }}></i>}
        </ListItem>
    );
};

const CustomSectionedMultiSelectList = ({ data, selectedData, onSelect }) => {
    const [searchInput, setSearchInput] = useState("");
    const [sortedData, setSortedData] = useState(data);
    const [errorMessageShown, setErrorMessageShown] = useState(false);

    useEffect(() => {
        if (errorMessageShown) {
            setTimeout(() => {
                setErrorMessageShown(false);
            }, 1500);
        }
    }, [errorMessageShown]);






    const listRef = useRef(null);
    const performSearch = useCallback((searchString) => {
        listRef.current.scrollTop = 0;//scroll list to top
        let categorySimilarities = [];

        // Sort within each category
        const sortedWithinCategories = data.map(category => {
            const similarities = stringSimilarity.findBestMatch(
                searchString.toLowerCase(),
                category.data.map(item => item.label.toLowerCase())
            );

            const sortedItems = category.data
                .map((item, index) => ({ ...item, rating: similarities.ratings[index].rating }))
                .sort((a, b) => b.rating - a.rating);

            return { ...category, data: sortedItems };
        });

        // Sort categories based on top similarity
        categorySimilarities = sortedWithinCategories.map(category => ({
            label: category.section.label,
            topRating: category.data[0]?.rating || 0
        }));

        categorySimilarities.sort((a, b) => b.topRating - a.topRating);

        let finalSortedData = categorySimilarities.map(category => {
            return sortedWithinCategories.find(cat => cat.section.label === category.label);
        });

        setSortedData(finalSortedData);
    }, [data]);


    useEffect(() => {
        if (searchInput === "") {
            setSortedData(data);
        } else {
            performSearch(searchInput);
        }
    }, [searchInput, data, performSearch]);



    const handleInputChange = (event) => {
        setSearchInput(event.target.value);
        performSearch(event.target.value);
    };

    function isSelectedInSameSection(sectionValue, itemValue) {
        return selectedData.some(section =>
            section.section.value === sectionValue &&
            section.data.some(item => item.value === itemValue)
        );
    }

    function isSelectedInOtherSection(sectionValue, itemValue) {
        return selectedData.some(section =>
            section.section.value !== sectionValue &&
            section.data.some(item => item.value === itemValue)
        );
    }
    return (
        <Container>
            <InputContainer>
                <Input
                    placeholder="Search..."
                    type="text"
                    value={searchInput}
                    onChange={handleInputChange}
                />
            </InputContainer>
            {errorMessageShown && <motion.span
                style={{
                    flex: 1,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    color: "indianred",
                }}
                //a small fade and an a small motion from down to up
                initial={{ opacity: 0, y: 5 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: 5 }}
                transition={{ duration: 0.2 }}
            >Selected in different section</motion.span>}
            <ListContainer ref={listRef}>
                {sortedData.map(section => {
                    return <ListSection key={uuidv4()}>
                        <StickyHeader>{section.section.label}</StickyHeader>
                        {section.data.map(item => {
                            return <ListItemComponent
                                key={uuidv4()}
                                item={item}
                                onSelect={() => onSelect(section.section.value, item.value)}
                                isSelectedInSection={isSelectedInSameSection(section.section.value, item.value)}
                                isSelectedInOtherSection={isSelectedInOtherSection(section.section.value, item.value)}
                                setErrorMessageShown={setErrorMessageShown}
                            />
                        }
                        )}
                    </ListSection>
                }
                )}
            </ListContainer>
        </Container>
    );
};

export default CustomSectionedMultiSelectList;

