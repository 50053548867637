import styled from "styled-components";
import ProductsList from "./ProductsList";
import { useState, createContext, useContext } from "react";
import { useAxios } from "../../../../../axiosProvider";
import _ from "lodash";
import {
  useFetchEntities,
  useAddNewProductAndReturnId,
} from "../../../../../reactQueryHooks";
//import AddProductsModal from "./AddProductsModal";
import ProductFormWrapperModal from "./form/wrapper/ProductFormWrapperModal";
import { AnimatePresence } from "framer-motion";
import LoadingButton from "@mui/lab/LoadingButton";

//-----------------------------------------------------------------------

const Container = styled.div`
  display: flex;
  flex: 1 0 450px;
  max-height: 500px;
  flex-direction: column;
  gap: 7px;
  background-color: white;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
  padding: 7px;
  border-radius: 5px;
  user-select: none;
  width: 100%;
  height: 100%;
`;

const ContentContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 7px;
  overflow-y: auto;
  position: relative;
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  color: rgb(35, 139, 243);
`;

const AddButtonContainer = styled.div`
  width: 100%;
  min-height: 40px;
  border-radius: 5px;
`;

//-----------------------------------------------------------------------

const ProductContext = createContext({
  close: () => {},
});

export const useProductModal = () => {
  return useContext(ProductContext);
};

const ProductsOptions = () => {
  const { data: entities } = useFetchEntities();
  const products = _.sortBy(entities?.data?.products, ["index"]);
  const [showAddProductsModal, setShowAddProductsModal] = useState(false);
  const [productId, setProductId] = useState(null);
  const { user } = useAxios();
  const userIsPermittedToEdit =
    user.isAdmin || (user.isModerator && user.permissions.addNewActivities);

  const {
    mutateAsync: addNewProductAndReturnId,
    isLoading: isAddingNewProduct,
  } = useAddNewProductAndReturnId();

  return (
    <Container>
      <TitleContainer>Products</TitleContainer>
      <ContentContainer>
        {entities?.data && (
          <ProductsList
            products={products}
            onSelect={(productId) => {
              if (userIsPermittedToEdit) {
                setProductId(productId);
                setShowAddProductsModal(true);
              } else {
                alert("You have no permission to edit products");
              }
            }}
          />
        )}
        <AddButtonContainer>
          <LoadingButton
            fullWidth
            variant="outlined"
            color="success"
            loading={isAddingNewProduct}
            loadingPosition="center"
            onClick={async () => {
              try {
                if (userIsPermittedToEdit) {
                  const id = await addNewProductAndReturnId();
                  setProductId(id.data);
                  setShowAddProductsModal(true);
                } else {
                  alert("You have no permission to add new products");
                }
              } catch (e) {
                console.log(e);
                alert("Error adding new product");
              }
            }}
            title="Add Product"
          >
            Add Product
          </LoadingButton>
        </AddButtonContainer>
      </ContentContainer>
      <AnimatePresence>
        {showAddProductsModal && (
          <ProductContext.Provider
            value={{
              close: () => setShowAddProductsModal(false),
            }}
          >
            <ProductFormWrapperModal id={productId} />
          </ProductContext.Provider>
        )}
      </AnimatePresence>
    </Container>
  );
};

export default ProductsOptions;
