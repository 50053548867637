import { useEffect } from "react";
import styled from "styled-components";
import ProfileIcon from "./ProfileIcon";
import OpenSessions from "./OpenSessions";
import VehicleServiceNotifications from "./VehicleServiceNotifications";
import Notifications from "./notifications/Notifications";
import Notes from "./notes/Notes";
import logo from "../../../src/logo.png";
import toast from "react-hot-toast";
import Search from "./search/Search";
import { useFetchEntities, useGetOnlineSessions } from "../../reactQueryHooks";
import { useLocation } from "react-router-dom";
import io from "socket.io-client";
const socketTourInfo = io("https://getaways-tour-info.herokuapp.com", {
  transports: ["websocket", "polling", "flashsocket"],
});
//------------------------------------------------------------------

const HeaderContainer = styled.div`
  display: flex;
  position: relative;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  padding: 10px 15px;
  box-shadow: ${({ isHome }) =>
    isHome ? "0px 2px 5px -1px rgba(0, 0, 0, 0.2);" : "none"};
  z-index: 901;
  user-select: none;
`;

const LogoWrapper = styled.div`
  //flex: 1;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  //less than 500px display none
  @media (max-width: 500px) {
    display: none;
  }
`;

const LogoContainer = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Logo = styled.img`
  display: flex;
  height: 28px;
  width: 28px;
  margin-right: 8px;
  @media (max-width: 400px) {
    height: 38px;
    width: 38px;
  }
  //spin
  animation: spin 30s linear infinite;
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  //text shadow
`;

const LogoText = styled.div`
  display: flex;
  font-weight: 700;
  color: #5a5c69;
  font-size: 13px;
`;

const NotificationsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
`;

//------------------------------------------------------------------

const Header = ({ text, redirectFn }) => {
  const { data: openSessions, refetch: refetchOfflineSessions } =
    useGetOnlineSessions();
  const { data: entities } = useFetchEntities();
  const flattenedScheduledServices = entities?.data?.vehicles?.flatMap(
    (vehicle) =>
      vehicle.upcoming_scheduled_service.map((scheduledService) => ({
        ...scheduledService,
        plate: vehicle.plate,
      }))
  );
  const hasScheduledServices = flattenedScheduledServices?.length > 0;
  const hasOpenSessions = openSessions?.data?.length > 0;

  const location = useLocation();
  const isHome = location.pathname === "/home";

  useEffect(() => {
    // subscribe socket listener on mount
    socketTourInfo.on(`tour_info/session_open`, (data) => {
      toast.success(
        `${data.client_name} (${data.booking_ref}) is online in booking portal`,
        {
          position: "bottom",
          icon: "📱",
        }
      );
      refetchOfflineSessions();
    });
    socketTourInfo.on(`tour_info/session_killed`, (data) => {
      // toast.error(`Session killed`, {
      //   position: "bottom",
      //   //icon: "📱",
      // });
      refetchOfflineSessions();
    });

    // unsubscribe socket on unmount (cleanup)
    return () => {
      socketTourInfo.off(`tour_info/session_open`);
      socketTourInfo.off(`tour_info/session_killed`);
    };
  }, [refetchOfflineSessions]);

  //------------------------------------------------------
  return (
    <HeaderContainer isHome={isHome}>
      <ProfileIcon />
      {isHome && (
        <LogoWrapper>
          <LogoContainer
            onClick={() => {
              redirectFn();
            }}
          >
            <Logo src={logo} />

            <LogoText>
              <span>{text}</span>
            </LogoText>
          </LogoContainer>
        </LogoWrapper>
      )}
      {!isHome && <Search />}

      <NotificationsContainer>
        {hasOpenSessions && <OpenSessions />}
        {hasScheduledServices && <VehicleServiceNotifications />}
        <Notes />
        <Notifications />
      </NotificationsContainer>
    </HeaderContainer>
  );
};

export default Header;
