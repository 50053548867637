import moment from "moment";

export const getDefaultBookingData = (user) => {
  return {
    _id: null,
    ref: "",
    booking_date: moment().format("YYYY-MM-DD"),
    date: moment().format("YYYY-MM-DD"),
    product_id: "",
    option_id: "",
    start_time_id: "",
    product_time_slot: "",
    client_name: "",
    client_email: "",
    client_phone: "",
    tickets: {},
    billing_codes: [],
    client_location: "",
    pickup_location: {
      name: "",
      latitude: "",
      longitude: "",
      google_maps_url: "",
      address: "",
      img_url: "",
      instructions: "",
    },
    pickup_time: "",
    channel: null,
    client_messaged: false,
    planned: true,
    cancelled: false,
    client_response_status: "PENDING",
    notes_list: [],
    group: 1,
    total_paid: 0,
    order_number: "",
    email_history: [],
    updated_at: [
      {
        author: user?.name || "default author",
        date: moment(new Date()).format("YYYY-MM-DD HH:mm"),
      },
    ],
  }
};
