import styled from "styled-components"
import { useState, useEffect } from "react"
import { useGetCalendarNotes } from "../../../reactQueryHooks"
import CalendarNote from "./CalendarNote"
import Button from "@mui/material/Button"
import { RotatingLines } from "react-loader-spinner"
import { useAxios } from "../../../axiosProvider"

//----------------------------------------------------------------

const Container = styled.div`
  background-color: white;
  padding: 7px;
  margin: 5px 5px 5px 0;
  border-radius: 5px;
  box-shadow: 0px 0px 5px 0 rgba(0, 0, 0, 0.3);
  overflow: hidden;
  min-width: 250px;
  max-width: 250px;
  //less than 1200px display none
  @media (max-width: 900px) {
    position: absolute;
    right: 0;
    bottom: 0;
    height: 98%;
    z-index: 1000;
    //border radius only top
    border-radius: 5px 0 0px 5px;
  }
`
const Content = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 7px;
  overflow-y: auto;
  padding: 4px;
`

//----------------------------------------------------------------

const CalendarNotesContent = ({ date }) => {
  const [allData, setAllData] = useState([])
  const { user } = useAxios()
  const {
    data: notes,
    // hasNextPage,
    // fetchNextPage,
    // isFetchingNextPage,
    refetch,
    isLoading,
  } = useGetCalendarNotes({
    date: date,
    user_id: user._id,
  })

  //let allData = notes?.pages?.flatMap((page) => page.data.docs)

  const alreadyHasNewNote = allData?.some((note) => !note._id)

  useEffect(() => {
    if (notes) {
      setAllData(notes?.pages?.flatMap((page) => page.data.docs))
    }
  }, [notes])

  return (
    <Container>
      <Content>
        {!alreadyHasNewNote && (
          <Button
            variant="outlined"
            onClick={() => {
              if (!alreadyHasNewNote) {
                setAllData((prevData) => [
                  {
                    _id: null,
                    body: "",
                    date: date,
                    author_id: user._id,
                    public: false,
                  },
                  ...prevData,
                ])
              } else {
                alert("You already have a new note")
              }
            }}
          >
            ADD CALENDAR NOTE
          </Button>
        )}
        {allData?.map((note) => (
          <CalendarNote key={note._id} note={note} refetch={refetch} />
        ))}

        {allData?.length === 0 && (
          <span
            style={{
              color: "gray",
              fontSize: "12px",
              textAlign: "center",
              marginTop: "10px",
            }}
          >
            No calendar notes for this date
          </span>
        )}

        {isLoading && (
          <div
            style={{
              width: "100%",
              height: "100px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <RotatingLines height="12" width="12" strokeColor="grey" />
          </div>
        )}
      </Content>
    </Container>
  )
}

export default CalendarNotesContent
