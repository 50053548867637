import styled from "styled-components";
import { motion, AnimatePresence } from "framer-motion";
import { useState, useEffect, useRef } from "react";
import { storage } from "../../../../firebase";
import {
  ref,
  listAll,
  getDownloadURL,
  uploadBytesResumable,
  deleteObject,
} from "firebase/storage";
import { useAxios } from "../../../../axiosProvider";

//----------------------------------------------------------------

const FilesContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: whitesmoke;
  border-radius: 6px;
  gap: 5px;
  padding: 10px;
`;

const FilesTopRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  user-select: none;
  &:hover {
    path {
      fill: black;
    }
  }
`;

const ArrowSvgContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  // if isFilesExpanded is false, rotate 90deg with transition 0.3s
  transform: rotate(
    ${({ isFilesExpanded }) => (isFilesExpanded ? "0deg" : "-90deg")}
  );
  transition: 0.2s;
  path {
    fill: #757575;
  }
`;

const FilesBottomRow = styled(motion.div)`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 5px;
  border-radius: 10px;
  gap: 3px;
`;

const DeleteIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  i {
    font-size: 14px;
    color: indianred;
    display: none;
  }
`;

const FileNameRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  &:hover {
    .fa-trash-alt {
      display: block;
    }
    color: dodgerblue;
  }
`;

const FilenameDownIconContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  font-size: 14px;
  cursor: pointer;
  user-select: none;

  i {
    font-size: 13px;
  }
`;

const FileInputContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: 5px;
`;

//----------------------------------------------------------------

const VehicleFiles = ({ vehicleId }) => {
  const [isFilesExpanded, setIsFilesExpanded] = useState(false);
  const [isFetchingFiles, setIsFetchingFiles] = useState(false);
  const [isUploadingFile, setIsUploadingFile] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [folderFilesUrls, setFolderFilesUrls] = useState([]);
  const fileInputRef = useRef(null)

  const { user } = useAxios();
  const userIsAdmin = user.isAdmin
  const userIsPermittedToHandleVehicles = user.permissions.addNewVehicle || userIsAdmin;

  useEffect(() => {
    const getFiles = async () => {
      try {
        const storageRef = ref(storage, `/vehicles/${vehicleId}`);
        const result = await listAll(storageRef);
        const urlPromises = result.items.map(async (imageRef) => {
          const downloadURL = await getDownloadURL(imageRef);
          const filename = imageRef.name;
          return { url: downloadURL, filename };
        });
        return Promise.all(urlPromises);
      } catch (error) {
        console.error("Error occurred:", error);
      }
    };

    const getUrls = async () => {
      try {
        setIsFetchingFiles(true);
        const urls = await getFiles();
        setFolderFilesUrls(urls);
      } catch (error) {
        console.error("Error occurred:", error);
        alert("Error fetching files");
      } finally {
        setIsFetchingFiles(false);
      }
    };

    getUrls();
  }, [vehicleId]);

  // Handle file upload using uploadBytesResumable
  const handleFileUpload = async (file) => {
    if (file) {
      try {
        setIsUploadingFile(true); // Set isUploadingFile to true while the file is being uploaded

        // Get the reference to the file in Firebase Storage
        const storageRef = ref(storage, `/vehicles/${vehicleId}/${file.name}`);

        // Create a resumable upload task
        const uploadTask = uploadBytesResumable(storageRef, file);

        // Listen for state changes, such as progress updates
        uploadTask.on(
          "state_changed",
          (snapshot) => {
            // Calculate the upload progress percentage
            const progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            setUploadProgress(progress);
          },
          (error) => {
            console.error("Error uploading file:", error);
            setIsUploadingFile(false); // Reset isUploadingFile state in case of an error
          },
          () => {
            // Upload completed
            setIsUploadingFile(false); // Reset isUploadingFile state after successful upload
          }
        );

        // Await the completion of the upload
        const uploadTaskSnapshot = await uploadTask;

        // Get the download URL of the uploaded file
        const downloadURL = await getDownloadURL(uploadTaskSnapshot.ref);

        // Add the uploaded file URL and filename to the folderFilesUrls state
        setFolderFilesUrls((prevUrls) => [
          ...prevUrls,
          { url: downloadURL, filename: file.name },
        ]);
      } catch (error) {
        console.error("Error uploading file:", error);
        setIsUploadingFile(false); // Reset isUploadingFile state in case of an error
      }
    }
  };

  const handleDeleteFile = async (filename) => {
    try {
      // Get the reference to the file in Firebase Storage
      const storageRef = ref(storage, `/vehicles/${vehicleId}/${filename}`);

      // Delete the file
      await deleteObject(storageRef);

      // Remove the file from the folderFilesUrls state
      setFolderFilesUrls((prevUrls) =>
        prevUrls.filter((file) => file.filename !== filename)
      );
    } catch (error) {
      console.error("Error deleting file:", error);
      alert("Error deleting file");
    }
  };

  const noFolderOrFiles = !folderFilesUrls || folderFilesUrls.length === 0;

  return (
    <FilesContainer>
      <FilesTopRow
        onClick={() => {
          setIsFilesExpanded(!isFilesExpanded);
        }}
      >
        <ArrowSvgContainer isFilesExpanded={isFilesExpanded}>
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M7.41 7.84L12 12.42l4.59-4.58L18 9.25l-6 6-6-6z"></path>
          </svg>
        </ArrowSvgContainer>
        <span
          style={{
            fontWeight: "bold",
            fontSize: "13px",
          }}
        >
          FILES
        </span>
      </FilesTopRow>
      <AnimatePresence>
        {isFilesExpanded && (
          <FilesBottomRow
            initial={{ opacity: 0, y: -3 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -3 }}
            transition={{ duration: 0.2 }}
          >
            {!isFetchingFiles && noFolderOrFiles ? (
              <span
                style={{
                  fontWeight: "bold",
                  fontSize: "13px",
                  color: "grey",
                }}
              >
                EMPTY FOLDER
              </span>
            ) : (
              folderFilesUrls.map((file, index) => {
                return (
                  <FileNameRow key={index}>
                    <FilenameDownIconContainer
                      onClick={() => {
                        window.open(file.url, "_blank");
                      }}
                    >
                      <span>{file.filename}</span>
                      <i className="fas fa-download"></i>
                    </FilenameDownIconContainer>
                    <DeleteIconContainer
                      onClick={() => {
                        if (userIsPermittedToHandleVehicles) {
                          if (window.confirm("Delete this file?")) {
                            handleDeleteFile(file.filename);
                          }
                        } else {
                          alert('You do not have permission to handle vehicles')
                        }
                      }}
                    >
                      <i className="fas fa-trash-alt"></i>
                    </DeleteIconContainer>
                  </FileNameRow>
                );
              })
            )}

            {isFetchingFiles && (
              <span
                style={{
                  fontSize: "11px",
                }}
              >
                FETCHING FILES...
              </span>
            )}

            <FileInputContainer>
              {isUploadingFile ? (
                <span
                  style={{
                    fontWeight: "bold",
                    fontSize: "13px",
                  }}
                >
                  {uploadProgress.toFixed(0)}% Uploading...
                </span>
              ) : (
                <input
                    ref={fileInputRef}
                  style={{
                    fontSize: "12px",
                  }}
                  type="file"
                  accept="*"
                    onClick={(e) => {
                      if (userIsPermittedToHandleVehicles) {
                        fileInputRef.current.click();
                      } else {
                        alert('You do not have permission to handle vehicles')
                        e.preventDefault()
                      }
                    }}
                  onChange={(e) => handleFileUpload(e.target.files[0])}
                />
              )}
            </FileInputContainer>
          </FilesBottomRow>
        )}
      </AnimatePresence>
    </FilesContainer>
  );
};

export default VehicleFiles;
