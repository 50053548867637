import styled from "styled-components";
import { useState, useEffect } from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import BarLoader from "react-spinners/BarLoader";
import {
  useGetUnreadNotifications,
  useGetReadNotifications,
  useMarkAllNotificationsAsRead,
} from "../../../reactQueryHooks";
import io from "socket.io-client";
//import msg_notification_2 from "./msg_notification_2.wav";
import NotificationItem from "./NotificationItem";
import { useAxios } from "../../../axiosProvider";
import toast from "react-hot-toast";
//const notificationSound = new Audio(msg_notification_2);
// const socketTestBookingsServer = io("http://localhost:3000");
// const socketTestTourInfoServer = io("http://localhost:3001");
const socketBookings = io("https://getaways-bookings.herokuapp.com", {
  transports: ["websocket"],
});
const socketTourInfo = io("https://getaways-tour-info.herokuapp.com", {
  transports: ["websocket"],
});

//----------------------------------------------------------

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  //margin-right: auto; //sits on the left of the grid
  * {
    user-select: none;
  }
  z-index: 2;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
`;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  //gap: 5px;
  align-items: center;
  font-size: 11px;
  font-weight: 600;
  padding: 0 8px;
  background-color: #e6e6e6;
  width: 50px;
  height: 30px;
  @media (max-width: 360px) {
    width: 40px;
    height: 25px;
    font-size: 12px;
  }
  border-radius: 5px;
`;

const StyledPopover = styled(Popover)`
  max-width: 92vw;
  max-height: 500px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 3px;
  border-radius: 3px;
  gap: 3px;
`;

const NotificationMarkAllButton = styled.button`
  display: flex;
  justify-content: ${({ isMarkAllAsReadLoading }) =>
    isMarkAllAsReadLoading ? "center" : "space-between"};
  align-items: center;

  min-height: 30px;
  border: none;
  font-size: 12px;
  color: darkgreen;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
  padding: 0 6px;
  i {
    font-size: 10px;
  }
  background-color: #e9ffe9;
  //margin-bottom: 3px;
`;

const NotificationLoadMoreButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 30px;
  border: none;
  font-size: 12px;
  &:hover {
    color: #166bbd;
  }
  background-color: #e6f2ff;
  //margin-top: 3px;
`;

const NotificationsListContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  width: 100%;
  overflow-y: scroll;
  padding: 0 3px 0 0;
  gap: 3px;
`;

//------------------------------------------------------

const Notifications = () => {
  const [notifications, setNotifications] = useState([]);
  const [isMarkingAllAsRead, setIsMarkingAllAsRead] = useState(false);
  const { user } = useAxios();

  const {
    data: unreadNotifications,
    isLoading: isLoadingUnreadNotifications,
    refetch: refetchUnreadNotifications,
  } = useGetUnreadNotifications(user._id);

  const {
    data: readNotifications,
    hasNextPage: hasNextPageRead,
    fetchNextPage: fetchNextPageRead,
    isFetchingNextPage: isFetchingNextPageRead,
    isLoading: isLoadingRead,
    refetch: refetchReadNotifications,
  } = useGetReadNotifications(user._id);

  const unreadNotificationsAmount = unreadNotifications?.data?.length;
  const HasNoNotifications = notifications.length === 0;

  useEffect(() => {
    if (unreadNotifications && readNotifications)
      setNotifications([
        ...unreadNotifications.data,
        ...readNotifications?.pages?.flatMap((page) => page.data.docs),
      ]);
  }, [unreadNotifications, readNotifications]);

  const { mutateAsync: markAllNotificationsAsRead } =
    useMarkAllNotificationsAsRead();

  const handleMarkAllAsRead = async () => {
    try {
      setIsMarkingAllAsRead(true);
      await markAllNotificationsAsRead(user._id);
      await refetchUnreadNotifications();
      await refetchReadNotifications();
    } catch (error) {
      alert(error.toString());
    } finally {
      setIsMarkingAllAsRead(false);
    }
  };

  useEffect(() => {
    // subscribe socket listener on mount
    socketBookings.on(`getaways_suite/refresh_notifications`, async (data) => {
      await refetchUnreadNotifications();
      await refetchReadNotifications();
      //notificationSound.play();
      toast.success("You have a new notification!");
    });

    socketTourInfo.on(`tour_info/refresh_notifications`, async (data) => {
      await refetchUnreadNotifications();
      await refetchReadNotifications();
      //notificationSound.play();
      toast.success("You have a new notification!");
    });

    // unsubscribe socket on unmount (cleanup)
    return () => {
      socketBookings.off(`getaways_suite/refresh_notifications`);
      socketTourInfo.off(`tour_info/refresh_notifications`);
    };
  }, [refetchReadNotifications, refetchUnreadNotifications]);

  if (isLoadingUnreadNotifications || isLoadingRead) {
    return (
      <Wrapper>
        <Container>
          <BarLoader color={"#a5a5a5"} loading={true} />
        </Container>
      </Wrapper>
    );
  }

  return (
    <OverlayTrigger
      placement="bottom-end"
      trigger={"click"}
      rootClose
      //show={false}
      overlay={
        <StyledPopover>
          {unreadNotificationsAmount > 0 && (
            <NotificationMarkAllButton
              onClick={handleMarkAllAsRead}
              isMarkAllAsReadLoading={isMarkingAllAsRead}
            >
              {isMarkingAllAsRead ? (
                <div
                  style={{
                    flex: 1,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <BarLoader color={"#a5a5a5"} loading={true} />
                </div>
              ) : (
                <>
                  <i
                    style={{
                      opacity: 0,
                    }}
                    className="fa-solid fa-check"
                  ></i>
                  <span>Mark all as read</span>
                  <i className="fa-solid fa-check"></i>
                </>
              )}
            </NotificationMarkAllButton>
          )}
          <NotificationsListContainer>
            {HasNoNotifications ? (
              <span
                style={{
                  overflow: "hidden",
                }}
              >
                0 NOTIFICATIONS
              </span>
            ) : (
              notifications.map((notification, index) => {
                return (
                  <NotificationItem
                    key={index}
                    notification={notification}
                    refetchUnreadNotifications={refetchUnreadNotifications}
                    refetchReadNotifications={refetchReadNotifications}
                    user_id={user._id}
                  />
                );
              })
            )}
          </NotificationsListContainer>
          {hasNextPageRead && (
            <NotificationLoadMoreButton onClick={fetchNextPageRead}>
              {isFetchingNextPageRead ? (
                <BarLoader
                  color={"#b1b1b1"}
                  loading={true}
                  width={100}
                  height={5}
                />
              ) : (
                "Load more"
              )}
            </NotificationLoadMoreButton>
          )}
        </StyledPopover>
      }
    >
      <Wrapper title="Notifications">
        <Container>
          <i
            style={{
              color: "#4072A5",
            }}
            className="fa-solid fa-bell"
          ></i>

          {unreadNotificationsAmount > 0 ? (
            <span
              style={{
                color: "indianred",
              }}
            >
              {unreadNotificationsAmount}
            </span>
          ) : (
            <i
              style={{
                color: "darkgreen",
              }}
              className="fa-solid fa-check"
            ></i>
          )}
        </Container>
      </Wrapper>
    </OverlayTrigger>
  );
};

export default Notifications;
