import styled from "styled-components";
import { useState, useEffect, useRef } from "react";
import moment from "moment";
import BarLoader from "react-spinners/BarLoader";
import {
  useFetchEntities,
  useFetchScheduleByDateRange,
} from "../../../../reactQueryHooks";
import CalendarBox from "./CalendarBox";
import CustomDropdown from "../../settings/content/users/CustomDropdown";
import SearchableMultiSelect from "../../settings/content/users/SearchableMultiSelect";
import React from "react";
import "./style.css";

//----------------------------------------------------------------

const ContentWrapper = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  padding: 5px;
  overflow: hidden;
`;

const StaffSelectBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 5px;
  //min-width: 250px;
  height: 100%;
  color: #1a7cdb;
  position: ${({ noUsersSelected }) => (noUsersSelected ? "none" : "sticky")};
  top: 0;
  text-shadow: ${({ noUsersSelected }) =>
    noUsersSelected ? "0px 2px 5px rgba(0, 0, 0, 0.1)" : "none"};
  user-select: none;
  cursor: pointer;
  font-size: 11px;

  .fa-angle-down {
    transform: ${({ isDropdownOpen }) =>
      isDropdownOpen ? "rotateX(180deg)" : "rotateX(0deg)"};
    transition: transform 0.3s ease-in-out, color 0.3s ease-in-out;
    font-size: 10px;
  }
`;

const SpinnerContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: center;
`;

const UncheckContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  font-size: 11px;
  font-weight: bold;
  cursor: pointer;
  color: #1a7cdb;
  i {
    font-size: 13px;
  }

  &:hover {
    filter: brightness(0.5);
  }
`;

//------------------------------------------------------------------------

const SchedulePlannerContent = ({ start_date }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const { data: entities, isFetching: isFetchingEntities } = useFetchEntities();

  const staff = entities.data.staff
    .map((user) => {
      return {
        value: user._id,
        label: user.name,
      };
    })
    .sort((a, b) => a.label.localeCompare(b.label));

  const date_range = [];
  let current_date = moment(start_date);
  const end_of_month = moment(start_date).endOf("month");
  while (current_date <= end_of_month) {
    date_range.push(current_date.format("YYYY-MM-DD"));
    current_date = current_date.add(1, "days");
  }

  const [user_ids, setUser_ids] = useState([]);
  useEffect(() => {
    const local_user_ids = localStorage.getItem("user_ids");
    if (local_user_ids) {
      setUser_ids(JSON.parse(local_user_ids));
    }
  }, []);
  const noUsersSelected = user_ids.length === 0;

  const selectedStaff =
    user_ids
      .map((id) => {
        const user = staff.find((user) => user.value === id);
        return {
          value: id,
          label: user.label,
        };
      })
      .sort((a, b) => a.label.localeCompare(b.label)) || [];

  const {
    data: scheduleData,
    isRefetching,
    isFetching: isFetchingSchedule,
  } = useFetchScheduleByDateRange(date_range, user_ids);


  //--------------------

  const onStaffSelect = (user_ids) => {
    setUser_ids(user_ids);
    localStorage.setItem("user_ids", JSON.stringify(user_ids));
  };

  //------------------------------------------------------------------------

  const listRef = useRef(null);
  const currentDayRef = useRef(null);
  useEffect(() => {
    if (currentDayRef.current) {
      currentDayRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "center",
      });
    } else {
      if (listRef.current) {
        listRef.current.scrollLeft = 0;
      }
    }
  }, [scheduleData, isRefetching]);

  //------------------------------------------------------------------------

  const [hoveredRow, setHoveredRow] = useState(null);
  const [hoveredCol, setHoveredCol] = useState(null);

  return (
    <ContentWrapper>
      {!isFetchingEntities &&
        !isFetchingSchedule &&
        !isRefetching &&
        !noUsersSelected && (
          <div
            ref={listRef}
            className="grid-container"
            style={{
              gridTemplateColumns: `110px repeat(${date_range.length}, 120px)`,
              gridTemplateRows: `35px repeat(${selectedStaff.length}, 55px)`,
            }}
          >
            <CustomDropdown
              isShown={(isShown) => {
                setIsDropdownOpen(isShown);
              }}
              dropdownContent={
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    height: "100%",
                    minWidth: "220px",
                    padding: "10px",
                    backgroundColor: "white",
                    borderRadius: "5px",
                    boxShadow: "0 0 5px 0 rgba(0, 0, 0, 0.2)",
                    gap: "10px",
                  }}
                >
                  <UncheckContainer
                    onClick={() => {
                      setUser_ids([]);
                      localStorage.setItem("user_ids", JSON.stringify([]));
                    }}
                  >
                    <span>Uncheck all</span>
                    <i className="fa-solid fa-list-check"></i>
                  </UncheckContainer>
                  <SearchableMultiSelect
                    data={staff}
                    selectedData={selectedStaff}
                    onSelect={(data) => {
                      onStaffSelect(data);
                    }}
                    searchable
                  />
                </div>
              }
            >
              <div className="cell sticky-col sticky-row top-left">
                <StaffSelectBox
                  id="select-name"
                  noUsersSelected={noUsersSelected}
                  isDropdownOpen={isDropdownOpen}
                >
                  <span>Select staff</span>
                  <i className="fas fa-angle-down"></i>
                </StaffSelectBox>
              </div>
            </CustomDropdown>

            {date_range.map((date, colIndex) => {
              const currentDay = moment().format("YYYY-MM-DD");
              const isCurrentDay =
                moment(date).format("YYYY-MM-DD") === currentDay;
              return (
                <div
                  ref={isCurrentDay ? currentDayRef : null}
                  style={{
                    backgroundColor: isCurrentDay
                      ? "rgb(221 207 173)"
                      : "#fcfcfc",
                  }}
                  key={colIndex}
                  className={`cell header-cell sticky-row ${
                    hoveredCol === colIndex ? "highlight" : ""
                  }`}
                >
                  {moment(date).format("ddd, DD MMM YY")}
                </div>
              );
            })}
            {selectedStaff.map((user, rowIndex) => (
              <React.Fragment key={rowIndex}>
                <div
                  className={`cell sticky-col staff-name-sticky-col ${
                    hoveredRow === user.value ? "highlight" : ""
                  }`}
                >
                  <span className="staff-name">{user.label}</span>
                </div>
                {date_range.map((date, colIndex) => {
                  const hasSchedule = scheduleData?.find(
                    (schedule) =>
                      schedule.user === user.value && schedule.date === date
                  );

                  return (
                    <div
                      onMouseEnter={() => {
                        setHoveredRow(user.value)
                        setHoveredCol(colIndex)
                      }}
                      onMouseLeave={() => {
                        setHoveredRow(null)
                        setHoveredCol(null)
                      }}
                      key={colIndex}
                      className="cell calendar-box"
                    >
                      <CalendarBox
                        key={`${user.value}-${date}`}
                        date={date}
                        user={user}
                        schedule={hasSchedule || null}
                      />
                    </div>
                  )
                })}
              </React.Fragment>
            ))}
          </div>
        )}
      {(isFetchingEntities || isFetchingSchedule || isRefetching) && (
        <SpinnerContainer>
          <BarLoader color="#606060" loading={true} />
        </SpinnerContainer>
      )}
      {noUsersSelected &&
        !isFetchingEntities &&
        !isFetchingSchedule &&
        !isRefetching && (
          <SpinnerContainer>
            <CustomDropdown
              isShown={(isShown) => {
                setIsDropdownOpen(isShown);
              }}
              dropdownContent={
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    height: "100%",
                    maxWidth: "300px",
                    minWidth: "200px",
                    padding: "10px",
                    backgroundColor: "white",
                    borderRadius: "5px",
                    boxShadow: "0 0 5px 0 rgba(0, 0, 0, 0.2)",
                    gap: "10px",
                  }}
                >
                  <SearchableMultiSelect
                    data={staff}
                    selectedData={selectedStaff}
                    onSelect={(data) => {
                      onStaffSelect(data);
                    }}
                    searchable
                  />
                </div>
              }
            >
              <StaffSelectBox
                style={{
                  width: "auto",
                }}
                id="select-name"
                noUsersSelected={noUsersSelected}
                isDropdownOpen={isDropdownOpen}
              >
                <span>Select staff</span>
                <i className="fas fa-angle-down"></i>
              </StaffSelectBox>
            </CustomDropdown>
          </SpinnerContainer>
        )}
    </ContentWrapper>
  );
};

export default SchedulePlannerContent;
