import styled from "styled-components";

//-------------------------------------------

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 5px;
  overflow-y: auto;
  padding-right: 10px;
  ::-webkit-scrollbar {
    width: 3px;
  }
  ::-webkit-scrollbar-thumb {
    background: #91c9ff;
    /* border-radius: 50px; */
  }
`;

const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  user-select: none;
  cursor: default;
  :hover {
    div {
      opacity: 0.6;
    }
  }
`;

//-------------------------------------------

const MultiSelect = ({ data, selectedData, onSelect }) => {
  /*
DATA EXAMPLE:
[
    {
        label: "Monday",
        value: "monday"
    },
    {   
        label: "Tuesday",   
        value: "tuesday"
    },
    
]
*/

  const onSelection = (selection) => {
    const isSelected = selectedData
      .map((item) => item.value)
      .includes(selection.value);

    if (isSelected) {
      const newSelectedData = selectedData.filter(
        (item) => item.value !== selection.value
      );
      onSelect(newSelectedData);
    } else {
      const newSelectedData = [
        ...selectedData,
        { label: selection.label, value: selection.value },
      ];
      onSelect(newSelectedData);
    }
  };

  return (
    <Container>
      {data.map((item, index) => {
        const isSelected = selectedData
          .map((item) => item.value)
          .includes(item.value);

        return (
          <RowContainer key={index} onClick={() => onSelection(item)}>
            <div>{item.label}</div>
            <i
              style={{
                color: "darkgreen",
                opacity: isSelected ? 1 : 0,
              }}
              className="fas fa-check"
            ></i>
          </RowContainer>
        );
      })}
    </Container>
  );
};

export default MultiSelect;
