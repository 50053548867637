import { useContext } from "react";
import styled from "styled-components";
import { FilesContext } from "./FilesOptions";
import { useAxios } from "@/axiosProvider";

//---------------------------------------------------------

const FileContainer = styled.div`
  font-size: 12px;
  padding: 3px 6px;
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    background-color: whitesmoke;
  }
`;

//---------------------------------------------------------

function truncateFileName(fileName: string, maxLength = 35) {
  const fileExtension = fileName.substring(fileName.lastIndexOf("."));
  const nameWithoutExtension = fileName.substring(0, fileName.lastIndexOf("."));

  if (nameWithoutExtension.length > maxLength - fileExtension.length) {
    return (
      nameWithoutExtension.substring(0, maxLength - fileExtension.length) +
      "..." +
      fileExtension
    );
  }

  return fileName;
}

const FileListItem = ({ file }: any) => {
  const { setFilesData, setFilesManagerModalShown } = useContext(FilesContext);
  const { user } = useAxios();
  const userIsAdmin = user.isAdmin;
  const userIsPermittedToUploadFiles =
    user.permissions.uploadFiles || userIsAdmin;

  const truncatedFileName = truncateFileName(file.name);
  return (
    <FileContainer
      onClick={() => {
        if (userIsPermittedToUploadFiles) {
          setFilesData([
            {
              ...file,
              url: `${process.env.REACT_APP_FILES_BASE_URL}${file._id}`,
            },
          ]);
          setFilesManagerModalShown(true);
        } else {
          alert("You don't have permission to add/edit files");
        }
      }}
    >
      {truncatedFileName}
    </FileContainer>
  );
};

export default FileListItem;
