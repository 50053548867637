import { useState, useEffect, useCallback } from "react";
import { useGetAnnouncements } from "../../../../reactQueryHooks";
import styled from "styled-components";
import SelectionBar from "../../../selection_bar/SelectionBar";
import BarLoader from "react-spinners/BarLoader";
import AnnouncementsBarContent from "../bar/AnnouncementsBarContent";
import AnnouncementItem from "./AnnouncementItem";
import AddOrEditAnnouncementModal from "./AddOrEditAnnouncementModal";
import { v4 as uuidv4 } from "uuid";
import { useAxios } from "../../../../axiosProvider";

//----------------------------------------------------------------

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const ContentWrapper = styled.div`
  padding: 10px;
  flex: 1;
  //background-color: red;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
`;

const ContentContainer = styled.div`
  padding: 0 10px 0 0;
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: auto;
  max-width: 500px;
  gap: 5px;
`;

const LoaderOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(5px);
`;

const AddButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 20px;
  right: 20px;
  background-color: ${(props) => props.theme.colors.green};
  @media (min-width: 1332px) {
    opacity: 1;
  }

  opacity: 0.3;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  font-size: 30px;
  border: none;
  cursor: pointer;
  &:hover {
    opacity: 1;
    filter: brightness(1.1);
    transition: opacity 0.2s;
    transition: filter 0.2s;
  }
  i {
    font-size: 20px;
    color: white;
  }

  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  z-index: 100;
`;

//------------------------------------------------------------------------

const AnnouncementsContent = () => {
  const [announcements, setAnnouncements] = useState([]);
  const [modalData, setModalData] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { user } = useAxios();

  const userPermittedToAddNewAnnouncements =
    user.permissions.publishAnnouncements;

  const {
    data: announcementsData,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    isLoading,
    isRefetching,
    refetch,
  } = useGetAnnouncements();

  const handleScroll = useCallback(
    (e) => {
      const bottom =
        e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
      if (bottom && hasNextPage) {
        fetchNextPage();
      }
    },
    [hasNextPage, fetchNextPage]
  );

  useEffect(() => {
    if (announcementsData)
      setAnnouncements([
        ...announcementsData?.pages?.flatMap((page) => page.data.docs),
      ]);
    //eslint-disable-next-line
  }, [announcementsData]);

  useEffect(() => {
    const element = document.getElementById("content-container");
    element.addEventListener("scroll", handleScroll);

    return () => element.removeEventListener("scroll", handleScroll);
  }, [hasNextPage, fetchNextPage, handleScroll]);

  const handleOpenModal = (data) => {
    setModalData(data);
    setIsModalOpen(true);
  };

  //------------------------------------------------------------------------

  return (
    <Container>
      <SelectionBar>
        <AnnouncementsBarContent isModalOpen={isModalOpen} refetch={refetch} />
      </SelectionBar>
      <ContentWrapper>
        <ContentContainer id="content-container">
          {announcements.map((announcement, index) => {
            return (
              <AnnouncementItem
                key={uuidv4()}
                announcement={announcement}
                handleOpenModal={handleOpenModal}
                refetch={refetch}
              />
            );
          })}
          {announcements.length && !hasNextPage && (
            <div style={{ color: "grey", textAlign: "center" }}>
              No more announcements to show
            </div>
          )}
        </ContentContainer>
        {(isFetchingNextPage || isLoading || isRefetching) && (
          <LoaderOverlay>
            <BarLoader color="#606060" loading={true} />
          </LoaderOverlay>
        )}
        {isModalOpen && (
          <AddOrEditAnnouncementModal
            data={modalData}
            close={() => {
              setModalData({});
              setIsModalOpen(false);
            }}
            refetch={refetch}
          />
        )}

        {!isModalOpen && (
          <AddButton
            onClick={() => {
              if (userPermittedToAddNewAnnouncements) {
                setModalData({});
                setIsModalOpen(true);
              } else {
                alert("You do not have permission to add new announcements");
              }
            }}
          >
            <i className="fa-solid fa-plus"></i>
          </AddButton>
        )}
      </ContentWrapper>
    </Container>
  );
};

export default AnnouncementsContent;
