import { useState, useEffect } from "react"
import { ContentItem, ContentItemContent, ContentItemTitle } from "./styled"
import {
  useFetchTask,
  useFetchEntities,
  useGetUserDaySchedulesThatIncludesTourGroup,
} from "../../../../reactQueryHooks"
import CrewHeader from "./CrewHeader"
import OverlayTrigger from "react-bootstrap/OverlayTrigger"
import Tooltip from "@mui/material/Tooltip"
import DifferencesTable from "./DifferencesTable"
import _ from "lodash"
import { v4 as uuidv4 } from "uuid"
import { useAxios } from "../../../../axiosProvider"
import ScheduleTaskForm from "../../schedule_task_form/ScheduleTaskForm"
import io from "socket.io-client"
const socket = io("https://getaways-bookings.herokuapp.com", {
  transports: ["websocket"],
})

//-------------------------------------------

const SortableTableInfoTask = ({ tourGroup, refetch }) => {
  const [instanceId] = useState(uuidv4())
  const { data: entities } = useFetchEntities()
  const { user } = useAxios()
  const [isNewTaskModalOpen, setIsNewTaskModalOpen] = useState(false)
  const [taskFormData, setTaskFormData] = useState({})
  const { data: assignees } = useGetUserDaySchedulesThatIncludesTourGroup(
    tourGroup._id
  )

  const {
    data: task,
    isFetching: isFetchingTask,
    refetch: refetchTask,
  } = useFetchTask(tourGroup._id)

  const hasTask = !!task?.data
  const hasVehicle =
    task?.data?.vehicle_id !== undefined && task?.data?.vehicle_id !== null
  const vehicle_plate = entities?.data?.vehicles?.find(
    (vehicle) => vehicle._id === task?.data?.vehicle_id
  )?.plate

  //-------------------------------------------

  const bookingsGroupedByPickup = _.groupBy(
    tourGroup.bookings,
    (booking) => booking?.pickup_location?.name
  )

  const pickups = Object.keys(bookingsGroupedByPickup).map((pickupLocation) => {
    const guests = bookingsGroupedByPickup[pickupLocation]
      .filter((booking) => !booking.cancelled)
      .map((booking) => {
        const count = Object.keys(booking.tickets)
          //.filter((key) => key.toLowerCase() !== "infant")
          .reduce((acc, key) => {
            return acc + booking.tickets[key]
          }, 0)

        return {
          name: booking.client_name,
          count: count,
        }
      })

    const pickupTime = bookingsGroupedByPickup[pickupLocation][0].pickup_time
    const details = task?.data?.pickups?.find(
      (pickup) => pickup.meeting_point === pickupLocation
    )?.details

    return {
      meeting_point: pickupLocation ? pickupLocation : "",
      time: pickupTime,
      details: details ? details : "",
      lat: bookingsGroupedByPickup[pickupLocation][0]?.pickup_location?.latitude
        ? bookingsGroupedByPickup[pickupLocation][0]?.pickup_location?.latitude
        : null,
      lon: bookingsGroupedByPickup[pickupLocation][0]?.pickup_location
        ?.longitude
        ? bookingsGroupedByPickup[pickupLocation][0]?.pickup_location?.longitude
        : null,
      guests: guests,
    }
  })

  const pickupsToCompare = pickups.map((pickup) => {
    return {
      details: pickup.details,
      guests: pickup.guests.map((guest) => {
        return {
          name: guest.name,
          count: guest.count,
        }
      }),
      lat: pickup.lat,
      lon: pickup.lon,
      meeting_point: pickup.meeting_point,
      time: pickup.time,
    }
  })

  const taskPickupsToCompare = task?.data?.pickups?.map((pickup) => {
    return {
      details: pickup.details,
      guests: pickup.guests.map((guest) => {
        return {
          name: guest.name,
          count: guest.count,
        }
      }),
      lat: pickup.lat,
      lon: pickup.lon,
      meeting_point: pickup.meeting_point,
      time: pickup.time,
    }
  })

  const arePickupsEqual = _.isEqual(taskPickupsToCompare, pickupsToCompare)

  //-------------------------------------------

  const userIsPermittedToAddNewTask =
    user.isAdmin || (user.isModerator && user.permissions.addNewTasks)

  const handleAddOrEditTask = (data) => {
    setTaskFormData(data)
    setIsNewTaskModalOpen(true)
  }

  const shouldAlertTaskDifference = hasTask && !arePickupsEqual

  const hasAssignees = assignees?.length
  const shouldAlertNoTask = hasAssignees && !hasTask

  useEffect(() => {
    const handleRefreshTasks = (data) => {
      if (data.instance_id !== instanceId) {
        refetchTask()
      }
    }
    socket.on(
      `getaways_suite/refresh_tasks/${tourGroup._id}`,
      handleRefreshTasks
    )

    return () => {
      socket.off(
        `getaways_suite/refresh_tasks/${tourGroup._id}`,
        handleRefreshTasks
      )
    }
  }, [tourGroup._id, instanceId, refetchTask])

  //-------------------------------------------

  return (
    <ContentItem
      style={{
        border:
          shouldAlertTaskDifference || shouldAlertNoTask
            ? "2px solid indianred"
            : "none",
      }}
      initial={{ opacity: 0, x: -5 }}
      animate={{ opacity: 1, x: 0 }}
      exit={{ opacity: 0, x: -5 }}
      transition={{ duration: 0.25, delay: 0.45 }}
      title="Add/Update task"
      onClick={() => {
        if (!userIsPermittedToAddNewTask) {
          alert("You don't have permission to add new tasks")
          return
        }

        const tourGroupAssignees = assignees.map((assignee) => {
          return {
            user_id: assignee.id,
            role_id: assignee.role,
          }
        })

        const payload = {
          id: task?.data?._id,
          date: tourGroup.date,
          product: tourGroup.product_id,
          option_id: tourGroup.option_id,
          assignees: task?.data?.assignees || tourGroupAssignees,
          vehicle_id: task?.data?.vehicle_id || tourGroup.vehicle_id,
          details: task?.data?.details || "",
          author_id: task?.data?.author_id,
          pickups,
          tour_group_id: tourGroup._id,
        }
        handleAddOrEditTask(payload)
      }}
    >
      <ContentItemTitle>Task</ContentItemTitle>
      <ContentItemContent>
        <i className="fa-solid fa-calendar-check"></i>
        {!hasTask && !hasAssignees && !isFetchingTask && (
          <span
            style={{
              color: "indianred",
              fontWeight: "bold",
              display: "flex",
              alignItems: "center",
              gap: "5px",
            }}
          >
            Not assigned
          </span>
        )}

        {!hasTask && hasAssignees && !isFetchingTask ? (
          <span
            style={{
              color: "indianred",
              fontWeight: "bold",
              display: "flex",
              alignItems: "center",
              gap: "5px",
            }}
          >
            Not assigned
            <Tooltip title="Task has assignees but no task" placement="top">
              <i
                style={{ color: "indianred", fontSize: "12px" }}
                className="fa-solid fa-triangle-exclamation"
              ></i>
            </Tooltip>
          </span>
        ) : null}
        {hasTask && !isFetchingTask && (
          <>
            <CrewHeader
              assignees={task.data.assignees}
              vehicle={hasVehicle && vehicle_plate}
            />
            {!isFetchingTask && shouldAlertTaskDifference && (
              <OverlayTrigger
                placement="auto-start"
                //show={true}
                overlay={
                  <div style={{ position: "absolute" }}>
                    <DifferencesTable
                      plan={pickups}
                      task={task?.data.pickups}
                    />
                  </div>
                }
              >
                <i
                  style={{ color: "indianred", fontSize: "12px" }}
                  title="Task is not updated"
                  className="fa-solid fa-triangle-exclamation"
                ></i>
              </OverlayTrigger>
            )}
          </>
        )}
        {isFetchingTask && (
          <span
            style={{
              color: "dodgerblue",
            }}
          >
            Loading task...
          </span>
        )}
      </ContentItemContent>
      {isNewTaskModalOpen && (
        <ScheduleTaskForm
          isNew={!hasTask}
          data={taskFormData}
          refetch={refetch}
          instanceId={instanceId}
          tourGroupId={tourGroup._id}
          closeForm={() => setIsNewTaskModalOpen(false)}
        />
      )}
    </ContentItem>
  )
}

export default SortableTableInfoTask
