import { useState, useRef, useEffect } from "react"
import styled from "styled-components"
import stringSimilarity from "string-similarity"
import $ from "jquery"
import { useAxios } from "../../../../../axiosProvider"
import toast from "react-hot-toast"
import { ThreeDots } from "react-loader-spinner"
import {
  useFetchEntities,
  useSortProducts,
} from "../../../../../reactQueryHooks"

//---------------------------------------------------------

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
  height: 100%;
  overflow: hidden;
`

const TopContainer = styled.div`
  width: 100%;
  height: 30px;
`

const Input = styled.input`
  flex: 1;
  width: 100%;
  height: 100%;
  //border: 1px solid lightgray;
  background-color: whitesmoke;
  border-radius: 4px;
  padding: 0 8px;
  /* text-align: center; */

  font-size: 13px;
`

const ListContainer = styled.div`
  flex: 1;
  width: 100%;
  height: 100%;
  padding: 0 5px 0 0;
  overflow-y: auto;
  overflow-x: hidden;
`

const Product = styled.div`
  font-size: 13px;
  padding: 3px 6px;
  border-radius: 4px;
  cursor: row-resize;
  &:hover {
    background-color: whitesmoke;
  }
`

//---------------------------------------------------------

const ProductsList = ({ onSelect, products }) => {
  const { refetch } = useFetchEntities()
  const [searchInput, setSearchInput] = useState("")
  const { user } = useAxios()
  const listRef = useRef()
  const handleInputChange = (event) => {
    setSearchInput(event.target.value)
    listRef.current.scrollTop = 0
  }
  const userIsAdmin = user.isAdmin
  const userIsPermittedToSortProducts =
    user.permissions.addNewActivities || userIsAdmin

  // Calculate the similarity scores
  const similarities = stringSimilarity.findBestMatch(
    searchInput.toLowerCase(),
    products.map((mp) => mp?.title?.toLowerCase() || "")
  )

  // Get the most similar strings
  const mostSimilarStrings = similarities.ratings.map((rating, index) => {
    const product = products.find(
      (mp) => mp._id === products.map((mp) => mp._id)[index]
    )
    const productIsCompleted = product?.isCompleted
    const productTitle =
      product?.title || `BOKUN #${product?.bokun_product_code}` //a product cannot be saved or updated without a bokun_product_code

    return {
      string: productTitle,
      rating: rating.rating,
      id: product._id,
      //index : should be product.index not rating.index
      index: product.index,
      isCompleted: productIsCompleted,
    }
  })

  // Sort by similarity score (higher score means more similar)
  const sorted = mostSimilarStrings.sort((a, b) => b.rating - a.rating)

  const { mutateAsync: sortProducts, isLoading: isSortingProducts } =
    useSortProducts()

  useEffect(() => {
    if (listRef.current) {
      $(listRef.current).sortable({
        //handle: ".sorting-overlay",
        //scrollSensitivity: 1,
        scrollSpeed: 4,
        containment: ".list-container",
        start: function (event, ui) {
          if (!userIsPermittedToSortProducts) {
            alert("You are not permitted to sort bookings")
            return
          }
        },
        update: async (event, ui) => {
          try {
            const ids = $(event.target).sortable("toArray")
            const response = await sortProducts(ids)
            toast.success(response?.data)
            refetch()
          } catch (e) {
            alert(e.toString())
          }
        },
      })
    }
  }, [userIsPermittedToSortProducts, sortProducts, refetch])

  //--------------------------------------------------------------------

  return (
    <Wrapper>
      <TopContainer>
        <Input
          autoFocus
          placeholder="Search..."
          autoCapitalize="none"
          autoComplete="off"
          autoCorrect="off"
          spellCheck="false"
          value={searchInput}
          onChange={handleInputChange}
        />
      </TopContainer>
      <ListContainer ref={listRef} className="list-container">
        {isSortingProducts && (
          <div
            style={{
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <ThreeDots
              height="30"
              width="30"
              radius="9"
              color="grey"
              ariaLabel="three-dots-loading"
              wrapperStyle={{}}
              wrapperClassName=""
              visible={true}
            />
          </div>
        )}

        {!isSortingProducts &&
          sorted.map((item, index) => {
            return (
              <Product
                className="sorting-overlay"
                key={`${item.id}_${index}`}
                id={item.id}
                data-index={index}
                onClick={() => {
                  onSelect(item.id)
                }}
              >
                <span
                  style={{
                    color: "dodgerblue",
                  }}
                >
                  #{item.index}{" "}
                </span>
                <span
                  style={{
                    color: item.isCompleted ? "black" : "indianred",
                    fontWeight: item.isCompleted ? "normal" : "bold",
                  }}
                >
                  {item.string}
                  {item.isCompleted ? (
                    <i
                      style={{
                        marginLeft: "7px",
                        color: "green",
                      }}
                      className="fas fa-check"
                    ></i>
                  ) : (
                    " - INCOMPLETE"
                  )}
                </span>
              </Product>
            )
          })}
      </ListContainer>
    </Wrapper>
  )
}

export default ProductsList
