import { memo } from "react"
import Switch from "@mui/material/Switch"

//---------------------------------------------------------------------------

const CustomMaterialSwitch = memo(
  ({ checked, label, set }: { checked: boolean; label: string; set: any }) => {
    return (
      <div>
        <Switch checked={checked} size="small" onChange={set} />
        <span style={{ fontSize: "0.8rem" }}>{label}</span>
      </div>
    )
  }
)

export default CustomMaterialSwitch
