import styled from "styled-components";
import moment from "moment";
import Tooltip from "@mui/material/Tooltip";
import { useFormikContext } from "formik";
import { useFetchEntities } from "../../../../reactQueryHooks";

//----------------------------------------------------------

export type TourArrangement = {
  product_id: string
  option_id: string
  guide_id: string | null
  date: string | number
  time: string
  start_time_id: string
  index: number
}

const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  width: 100%;
  padding: 0 5px 0 0;
`

const ListItemButtonContainer = styled.div`
  display: none;
  justify-content: center;
  align-items: center;
  gap: 5px;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(1px);
  padding: 0 10px;
  width: 50%;
  height: 100%;
  left: 0;

  button {
    transition: filter 0.3s ease;
    &:hover {
      filter: brightness(0.7);
    }
  }
`

const ListItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  position: relative;
  padding: 3px 0;
  //border: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  //border-radius: 5px;
  font-size: 11px;
  /* div {
    flex: 1;
    //wrap with ellipsis
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  } */

  div {
    flex: 1;
    //wrap with ellipsis
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &:hover {
    ${ListItemButtonContainer} {
      display: flex;
    }
  }
`

//----------------------------------------------------------

const AddTourArrangementActionListItem = ({
  tourArrangement,
  tourArrangementList,
  setTourArrangementList,
  isSubmitting,
  isSubmitted,
  isSubmittedError,
  index,
}: {
  tourArrangement: TourArrangement
  tourArrangementList: TourArrangement[]
  setTourArrangementList: (tourArrangementList: TourArrangement[]) => void
  isSubmitting: Boolean
  isSubmitted: Boolean
  isSubmittedError: Boolean | string
  index: number
}) => {
  const { data: entities } = useFetchEntities() as any
  const { setFieldValue, validateForm } = useFormikContext()

  const product = entities.data.products.find(
    (product: any) => product._id === tourArrangement.product_id
  )
  const guide = entities.data.staff.find(
    (guide: any) => guide._id === tourArrangement.guide_id
  )

  return (
    <ListItem>
      <div>{moment(tourArrangement.date).format("DD/MM/YY ddd")}</div>
      <div>{tourArrangement.time}</div>
      <div>{product?.title}</div>
      <div
        style={{
          color: guide?.name ? "black" : "indianred",
        }}
      >
        {guide?.name || "Guide not provided"}
      </div>
      {isSubmitting && (
        <div
          style={{
            color: "dodgerblue",
          }}
        >
          Submitting...
        </div>
      )}
      {isSubmitted && (
        <div
          style={{
            color: "darkgreen",
          }}
        >
          Submitted
        </div>
      )}
      {isSubmittedError && typeof isSubmittedError === "string" && (
        <Tooltip title={isSubmittedError}>
          <div
            style={{
              color: "indianred",
            }}
          >
            {isSubmittedError}
          </div>
        </Tooltip>
      )}
      {!isSubmitting && (
        <ListItemButtonContainer>
          <button
            style={{
              color: "rgb(123 189 255)",
            }}
            onClick={() => {
              setFieldValue("product", product?._id)
              setFieldValue("date", tourArrangement.date)
              setFieldValue("time", tourArrangement.time)
              setFieldValue("guide_id", tourArrangement.guide_id)
              setFieldValue("index", tourArrangement.index)

              //remove the selected tour arrangement from the list
              const filteredTourArrangementList = tourArrangementList.filter(
                (_, i) => i !== index
              )
              setTourArrangementList(filteredTourArrangementList)
              setTimeout(() => {
                validateForm()
              }, 50)
            }}
          >
            Edit
          </button>
          <button
            style={{
              color: "white",
            }}
            onClick={() => {
              //add one like the selected tour arrangement to the list
              const newTourArrangementList = [...tourArrangementList]
              newTourArrangementList.splice(index, 0, tourArrangement)
              setTourArrangementList(newTourArrangementList)
              setTimeout(() => {
                validateForm()
              }, 50)
            }}
          >
            Duplicate
          </button>
          <button
            style={{
              color: "indianred",
            }}
            onClick={() => {
              //remove the selected tour arrangement from the list
              const filteredTourArrangementList = tourArrangementList.filter(
                (_, i) => i !== index
              )
              setTourArrangementList(filteredTourArrangementList)
              setTimeout(() => {
                validateForm()
              }, 50)
            }}
          >
            Remove
          </button>
        </ListItemButtonContainer>
      )}
    </ListItem>
  )
}

const AddTourArrangementActionList = ({
  tourArrangementList,
  setTourArrangementList,
  submittingItems,
  submittedItems,
  submittedErrorItems,
}: {
  tourArrangementList: TourArrangement[];
  setTourArrangementList: (tourArrangementList: TourArrangement[]) => void;
  submittingItems: Boolean[];
  submittedItems: Boolean[];
  submittedErrorItems: Array<boolean | string>;
}) => {
  return (
    <ListContainer>
      {tourArrangementList.length ? (
        tourArrangementList.map((tourArrangement, index) => {
          return (
            <AddTourArrangementActionListItem
              key={index}
              tourArrangement={tourArrangement}
              tourArrangementList={tourArrangementList}
              setTourArrangementList={setTourArrangementList}
              isSubmitting={submittingItems[index]}
              isSubmitted={submittedItems[index]}
              isSubmittedError={submittedErrorItems[index]}
              index={index}
            />
          );
        })
      ) : (
        <div
          style={{
            color: "indianred",
            textAlign: "center",
            fontSize: "12px",
          }}
        >
          Add a tour assignment
        </div>
      )}
    </ListContainer>
  );
};

export default AddTourArrangementActionList;
