import styled from "styled-components";
import DatePicker from "react-datepicker";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";

//----------------------------------------------------------

const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: center;
  justify-content: center;
  /* padding: 6px 10px; */
  background-color: transparent;

  input {
    text-align: center;
    height: 30px;
    background-color: #fcfcfc;
    border-radius: 5px;
    border: 1px solid #eee;
    //padding: 0 10px;
    width: 160px;
    margin: 0 5px;
    //font-family
    font-family: "Roboto", sans-serif;
    cursor: default;
    //box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.1);
  }
`;

const ArrowContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  //background-color: indianred;
  &:hover {
    span {
      color: dodgerblue;
    }
  }
  cursor: pointer;
`;

const SingleDateSelection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  /* margin-right: 10px; */
  .fa-angle-left,
  .fa-angle-right {
    font-size: 25px;
    color: #5a5c69;
  }
  input {
    font-size: 12px;
  }
`;

const DateSelection = ({ date, setDate, handleDateChange }) => {
  return (
    <Container>
      <SingleDateSelection>
        <ArrowContainer onClick={() => handleDateChange("previous")}>
          <span className="fa-solid fa-angle-left"></span>
        </ArrowContainer>
        <DatePicker
          portalId="root-portal" //id for css
          onChange={(date) => setDate(date)}
          value={moment(date).format("DD MMM YYYY (dddd)")}
        />
        <ArrowContainer onClick={() => handleDateChange("next")}>
          <span className="fa-solid fa-angle-right"></span>
        </ArrowContainer>
      </SingleDateSelection>
    </Container>
  );
};

export default DateSelection;
