import { useState, useEffect } from "react";
import VehicleMarker from "./VehicleMarker";
import ReactDOMServer from "react-dom/server";
import mapboxgl, { LngLat } from "mapbox-gl";
import styled from "styled-components";
import { useGetVehicleTrackingData } from "../../../../reactQueryHooks";

//----------------------------------------------

const PopupContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  padding: 1px 5px 0 5px;
  //shadow

  span {
    padding: 0;
    margin: 0;
  }
`;

const PopupItem = styled.div`
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
  i {
    font-size: 12px;
  }
`;

const Popup = ({ plate, speed }: { plate: string; speed: number }) => {
  return (
    <PopupContainer>
      <PopupItem>
        <i className="fa-solid fa-car"></i>
        <span>{plate}</span>
      </PopupItem>
      <PopupItem>
        <i className="fa-solid fa-tachometer"></i>
        <span>{speed} km/h</span>
      </PopupItem>
    </PopupContainer>
  );
};

//----------------------------------------------

const MovingMarkers = ({
  map,
  setIsFetchingTrackingData,
}: {
  map: mapboxgl.Map;
  setIsFetchingTrackingData: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [markers, setMarkers] = useState<mapboxgl.Marker[]>([]);

  const {
    data: trackingData,
    isRefetching,
    refetch,
  } = useGetVehicleTrackingData();

  //TRACKER SENDS UPDATE EVERY 30 SECONDS
  useEffect(() => {
    let interval = setInterval(() => {
      refetch();
    }, 3000);
    return () => clearInterval(interval);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setIsFetchingTrackingData(isRefetching);
    // eslint-disable-next-line
  }, [isRefetching]);

  useEffect(() => {
    if (trackingData) {
      for (const vehicle of trackingData as any) {
        const { plate, engine_on } = vehicle;
        const { latitude, longitude, heading, speed } = vehicle.position;
        const latLng = new LngLat(longitude, latitude);
        const customVehicleMarker = document.createElement("div");
        customVehicleMarker.innerHTML = ReactDOMServer.renderToString(
          <VehicleMarker heading={heading} engine_on={engine_on} />
        );
        const popupToHTML = ReactDOMServer.renderToString(
          <Popup plate={plate} speed={speed} />
        );

        for (const marker of markers) {
          if (marker) {
            marker.remove();
          }
        }

        const newMarker = new mapboxgl.Marker({ element: customVehicleMarker })
          .setLngLat(latLng)
          .addTo(map)
          .setPopup(
            new mapboxgl.Popup({
              offset: 20,
              closeButton: false,
              closeOnClick: false,
              closeOnMove: false,
              className: "popup",
            }).setHTML(popupToHTML)
          );
        // newMarker.getPopup().addTo(map); //adds it on load
        //the above but add  Popup component
        newMarker.togglePopup();
        setMarkers((prev) => [...prev, newMarker]);
      }
    }
    // eslint-disable-next-line
  }, [trackingData]);

  // useEffect(() => {
  //   if (map && latLng) {
  //     // Add vehicle marker
  //     const customVehicleMarker = document.createElement("div");
  //     customVehicleMarker.innerHTML = ReactDOMServer.renderToString(
  //       <VehicleMarker heading={heading} />
  //     );
  //     const popupToHTML = ReactDOMServer.renderToString(
  //       <Popup plate={plate} eta={eta} />
  //     );

  //     if (marker) marker.remove();
  //     const newMarker = new mapboxgl.Marker({ element: customVehicleMarker })
  //       .setLngLat(latLng)
  //       .addTo(map)
  //       .setPopup(
  //         new mapboxgl.Popup({
  //           offset: 25,
  //           closeButton: false,
  //           className: "popup",
  //         }).setHTML(popupToHTML)
  //       );
  //     // newMarker.getPopup().addTo(map); //adds it on load
  //     //the above but add  Popup component
  //     newMarker.togglePopup();

  //     //map.setCenter(latLng as LngLatLike);
  //     setMarker(newMarker);
  //   }
  // }, [latLng]);
  return null;
};

export default MovingMarkers;
