import { useEffect, useContext } from "react";
import { FilesContext } from "../FilesOptions";
import { RotatingLines } from "react-loader-spinner";
import styled from "styled-components";
import ImageWithFallback from "./ImageWithFallback";
import { TextField } from "@mui/material";
import Button from "@mui/material/Button";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  useUploadFileToMongoDbAndReturnId,
  useUpdateFile,
  useDeleteFile,
} from "@/reactQueryHooks";
import * as Yup from "yup";
import { IFile } from "../FilesOptions";

//---------------------------------------------------------

const FileContainer = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 12px;
  padding: 10px;
  border-radius: 5px;
  background-color: white;
  gap: 5px;
  width: 300px;
  &:hover {
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
  }
`;

const PreviewOverlay = styled.div`
  position: absolute;
  top: 10px;
  left: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  color: white;
  font-size: 20px;
  display: none;
  &:hover {
    display: flex;
  }
  cursor: pointer;
`;

const PreviewContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  background-color: whitesmoke; //leave for showing background for icons
  height: 200px;
  //width: 100%;
  i {
    font-size: 30px;
  }
  //hover
  &:hover {
    ${PreviewOverlay} {
      display: flex;
    }
  }
  overflow: hidden;
  iframe {
    border: none;
    overflow: hidden;
  }
`;

const InfoContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 5px;
  //background-color: whitesmoke;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
  width: 100%;
`;

//---------------------------------------------------------

const getFileIcon = (file: any) => {
  const type = file.type.toLowerCase();

  if (type.includes("image")) {
    return <ImageWithFallback file={file} />;
  } else {
    return (
      <iframe
        title="file preview"
        src={file.url}
        width="100%"
        height="100%"
      ></iframe>
    );
  }
};

const FileCard = ({ file, index }: { file: IFile; index: number }) => {
  const fileIcon = getFileIcon(file);
  const sizeInMB = file.size / 1024 / 1024;
  const sizeInMBFixed = sizeInMB.toFixed(2);

  const {
    mutateAsync: uploadFilesToMongoDbAndReturnId,
    isLoading: isUploadingFiletoMongoDb,
  } = useUploadFileToMongoDbAndReturnId();
  const { mutateAsync: updateFile, isLoading: isUpdatingFile } =
    useUpdateFile();
  const { mutateAsync: deleteFile, isLoading: isDeletingFile } =
    useDeleteFile();

  const { filesData, setFilesData } = useContext(FilesContext);

  //----------------- LOCAL FORM ------------------------------

  const validationSchema: Yup.ObjectSchema<IFile> = Yup.object({
    description: Yup.string().required(),
  }) as any;

  const methods = useForm<IFile>({
    defaultValues: {
      _id: file?._id,
      description: file.description,
      name: file.name,
      type: file.type,
      size: file.size,
      url: file.url,
      data: file.data,
    },
    resolver: yupResolver(validationSchema),
    mode: "onChange",
  });

  const {
    trigger,
    watch,
    setValue,
    reset,
    //resetField,
    formState: { errors: localFormErrors, isValid: isLocalFormValid },
  } = methods;
  const fileId = watch("_id");
  const url = watch("url");
  const isNewFile = !fileId;
  const localDescriptionValue = watch("description");

  useEffect(() => {
    trigger();
  }, [trigger]);

  return (
    <FileContainer>
      <PreviewContainer>
        {fileIcon}

        <a href={url} target="_blank" rel="noreferrer">
          <PreviewOverlay title="Open in new tab">
            <OpenInNewIcon />
          </PreviewOverlay>
        </a>
      </PreviewContainer>
      <InfoContainer>
        <TextField
          sx={{
            "& .MuiFormLabel-root[data-shrink='false']": {
              //the label when the input is empty and not focused
              fontSize: 13,
            },
          }}
          slotProps={{
            htmlInput: {
              style: { fontSize: 12, backgroundColor: "white" },
            },
          }}
          margin="none"
          size="small"
          label="Description"
          variant="filled"
          fullWidth
          autoFocus
          type="text"
          error={localFormErrors.description ? true : false}
          color="success"
          placeholder="Enter a description.."
          value={localDescriptionValue}
          onChange={(e) => {
            setValue("description", e.target.value, {
              shouldValidate: true,
            });
            trigger();
          }}
        />
        <TextField
          slotProps={{
            htmlInput: { style: { fontSize: 12, backgroundColor: "white" } },
          }}
          margin="none"
          size="small"
          label="File Name"
          value={file.name}
          variant="filled"
          fullWidth
          disabled
        />
        <TextField
          slotProps={{
            htmlInput: { style: { fontSize: 12, backgroundColor: "white" } },
          }}
          margin="none"
          size="small"
          label="File Type"
          value={file.type}
          variant="filled"
          fullWidth
          disabled
        />
        <TextField
          slotProps={{
            htmlInput: { style: { fontSize: 12, backgroundColor: "white" } },
          }}
          margin="none"
          size="small"
          label="File Size"
          value={`${sizeInMBFixed} MB`}
          variant="filled"
          fullWidth
          disabled
        />
      </InfoContainer>
      <ButtonContainer>
        <Button
          variant="contained"
          size="small"
          onClick={() => {
            reset();
            trigger();
          }}
        >
          RESET
        </Button>
        <Button
          variant="contained"
          color="error"
          size="small"
          onClick={async () => {
            try {
              if (isNewFile) {
                const newFilesData = [...filesData];
                newFilesData.splice(index, 1);
                setFilesData(newFilesData);
              } else {
                await deleteFile(fileId as any);
                const newFilesData = [...filesData];
                newFilesData.splice(index, 1);
                setFilesData(newFilesData);
              }
            } catch (error) {
              console.error(error);
              alert("An error occurred. Please try again");
            }
          }}
          disabled={isDeletingFile}
        >
          {isDeletingFile ? (
            <RotatingLines width="12" strokeColor="black" />
          ) : isNewFile ? (
            "REMOVE"
          ) : (
            "DELETE"
          )}
        </Button>
        <Button
          variant="contained"
          color="success"
          size="small"
          sx={{ flex: 1 }}
          type="submit"
          disabled={
            !isLocalFormValid ||
            isUpdatingFile ||
            isUploadingFiletoMongoDb ||
            isDeletingFile
          }
          onClick={async () => {
            try {
              if (isNewFile) {
                const payload = {
                  name: file.name,
                  type: file.type,
                  size: file.size,
                  description: localDescriptionValue,
                };
                const formData = new FormData();
                formData.append("file", file.data as File);
                formData.append("payload", JSON.stringify(payload));
                const newFileId = (await uploadFilesToMongoDbAndReturnId(
                  formData
                )) as any;
                setValue("_id", newFileId?.data, {
                  shouldValidate: true,
                });
                setValue(
                  "url",
                  `${process.env.REACT_APP_FILES_BASE_URL}${newFileId?.data}`,
                  {
                    shouldValidate: true,
                  }
                );
              } else {
                await updateFile({
                  id: fileId,
                  payload: {
                    description: localDescriptionValue,
                    // name: file.name, //provided by the file input
                    // type: file.type, //provided by the file input
                    // size: file.size, //provided by the file input
                  },
                } as any);
              }
            } catch (error) {
              console.error(error);
              alert("An error occurred. Please try again");
            }
          }}
        >
          {isUpdatingFile || isUploadingFiletoMongoDb ? (
            <RotatingLines width="12" strokeColor="black" />
          ) : isNewFile ? (
            "ADD FILE"
          ) : (
            "UPDATE FILE"
          )}
        </Button>
      </ButtonContainer>
    </FileContainer>
  );
};

export default FileCard;
