// Define interfaces for the nested objects and arrays
export interface Option {
  _id: string;
  title: string;
  bokun_code: string;
  is_private: boolean;
  is_guided: boolean;
  pickup_included: boolean;
  requires_vehicle: boolean;
  requires_platform_entry: boolean;
  description: string;
  meeting_point_id?: string;
}

export interface TimeSlot {
  _id: string;
  time_slot: string;
  isDefaultPickupTime: boolean;
  label: string;
  bokun_start_time_id: string;
}

export interface Location {
  address: string
  latitude: number
  longitude: number
}

export interface ProductPicture {
  file_id: string;
  caption: string;
  alt: string;
  description: string;
  data?: File; //temporary - we store the file data here to upload to the server later
}

// Main interface for form values
export interface IFormValues {
  _id: string;
  bokun_product_code: string;
  title: string;
  platform_product_name: string;
  guide_assignment_identifier: string;
  product_short_description: string;
  product_full_description: string;
  pricing_options: string[];
  options: Option[];
  start_times: TimeSlot[];
  tour_types: string[];
  tour_categories: string[];
  activity_level: string;
  crewGroups: string[];
  crewRoles: string[];
  additional_info: string[];
  highlights: string[];
  special_instructions: string[];
  inclusions: string[];
  exclusions: string[];
  destinations: string[];
  tour_duration: string;
  tour_duration_type: string;
  review_link: string;
  affiliate_link: string;
  market_price: number;
  location: Location;
  product_pictures: ProductPicture[];
  isAvailableInPlan: boolean;
  settings: string;
  suggested_products: string[];
  isPublished: boolean;
  disclaimers: string[];
}

// Initial form values
export const placeholderValues: IFormValues = {
  _id: "",
  bokun_product_code: "",
  title: "",
  platform_product_name: "",
  guide_assignment_identifier: "",
  product_short_description: "",
  product_full_description: "",
  pricing_options: [],
  options: [],
  start_times: [],
  tour_types: [],
  tour_categories: [],
  activity_level: "",
  crewGroups: [],
  crewRoles: [],
  additional_info: [],
  highlights: [],
  special_instructions: [],
  inclusions: [],
  exclusions: [],
  destinations: [],
  tour_duration: "",
  tour_duration_type: "",
  review_link: "",
  affiliate_link: "",
  market_price: 0,
  location: {
    address: "Athens, Greece",
    latitude: 37.975379384130804,
    longitude: 23.738300169553295,
  },
  product_pictures: [],
  isAvailableInPlan: false,
  settings: "this_is_to_satisfy_the_type_checking",
  suggested_products: [],
  isPublished: false,
  disclaimers: [],
};

// //generate initial values for the form - if there is data from the API, use it, otherwise use the placeholder values
// export const initialValues = (
//   apiData: Partial<IFormValues> // Allow partial data from the API
// ): IFormValues => {
//   return Object.entries(placeholderValues).reduce((acc, [key, value]) => {
//     const typedKey = key as keyof IFormValues
//     //@ts-ignore
//     acc[typedKey] = apiData[typedKey] || value
//     return acc
//   }, {} as IFormValues)
// }

// Function to safely update a value in the target object
const updateValue = <T, K extends keyof T>(
  target: T,
  key: K,
  value: T[K]
): T => {
  return {
    ...target,
    [key]: value,
  }
}

// Generate initial values for the form - if there is data from the API, use it, otherwise use the placeholder values
export const initialValues = (
  apiData: Partial<IFormValues> // Allow partial data from the API
): IFormValues => {
  return Object.entries(placeholderValues).reduce<IFormValues>(
    (acc, [key, value]) => {
      const typedKey = key as keyof IFormValues
      const newValue = apiData[typedKey] || value
      return updateValue(acc, typedKey, newValue)
    },
    {} as IFormValues
  )
}
