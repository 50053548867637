import { useState } from "react"
import { RotatingLines } from "react-loader-spinner"
import styled from "styled-components"
import HideImageIcon from "@mui/icons-material/HideImage"

//--------------------------------------------------

const ImagePreview = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
`

//--------------------------------------------------

const ImageWithFallback = ({ file }: any) => {
  const [isLoading, setIsLoading] = useState(true)
  const [hasError, setHasError] = useState(false)

  const handleImageLoad = () => {
    setIsLoading(false)
  }

  const handleImageError = () => {
    setIsLoading(false)
    setHasError(true)
  }

  return (
    <>
      {isLoading && <RotatingLines width="12" strokeColor="black" />}

      {!isLoading && hasError && (
        <HideImageIcon style={{ fontSize: 30, color: "black" }} />
      )}

      {!hasError && (
        <ImagePreview
          src={file.url || ""}
          alt={file.name}
          onLoad={handleImageLoad}
          onError={handleImageError}
          style={{ display: isLoading ? "none" : "block" }} // Hide image until fully loaded
        />
      )}
    </>
  )
}

export default ImageWithFallback
