import { useState, useEffect } from "react";
import styled from "styled-components";
import moment from "moment";
import { useAxios } from "../../../axiosProvider";
import logo from "./gg_logo.png";

//------------------------------------------------------------------------------------------

const ChatMessageContainer = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  flex-direction: column;
  gap: 1px;
  align-items: ${({ isOwnMessage }) =>
    isOwnMessage ? "flex-end" : "flex-start"};
  justify-content: center;
`;

const MessageRow = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: ${({ isOwnMessage }) =>
    isOwnMessage ? "flex-end" : "flex-start"};
  align-items: center;
  overflow: hidden;
`;

const MessageContainer = styled.div`
  display: flex;
  max-width: 65%;
  flex-direction: column;
  gap: 5px;
  padding: 6px;
  background: ${({ theme, isOwnMessage }) =>
    isOwnMessage ? theme.colors.whatsAppGreen : "#f1f1f1"};
  border-radius: 5px;
  border: 1.5px solid #e7e7e7;
  position: relative;
  overflow: hidden;
`;

const MessageText = styled.pre`
  // <pre> tag preserves the formatting and line breaks in text
  white-space: pre-wrap; // Ensures the text wraps within the container
  font-family: inherit; // Inherit font-family from parent elements
  font-style: italic;
  margin: 0; // Reset default margin of the <pre> element
  overflow-x: hidden;
`;

const MessageInfoRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
  justify-content: center;
  align-items: flex-end;
  font-size: 11.5px;
`;

const MessageInfoName = styled.div`
  color: dodgerblue;
`;

const MessageInfoDate = styled.div`
  color: #8e8e8e;
`;

const CopyMessageOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  opacity: 0;
  transition: opacity 0.1s ease-in-out;
  border-radius: 5px;
  ${MessageContainer}:hover & {
    opacity: 1;
  }
  i {
    color: white;
    font-size: 18px;
  }
`;

const FakeConfirmButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 40px;
  background: #588637;
  color: white;
  font-weight: bold;
  border-radius: 5px;
`;

//------------------------------------------------------------------------------------------

const EmailMessage = ({ email }) => {
  const { user } = useAxios();
  const isOwnMessage = email.author._id === user._id;
  const [copyMessage, setCopyMessage] = useState(false);

  const isHtml = /<\/?[a-z][\s\S]*>/i.test(email.message); // Regular expression to check for HTML tags

  useEffect(() => {
    if (copyMessage) {
      navigator.clipboard.writeText(email.message);
    }
  }, [copyMessage, email.message]);

  return (
    <ChatMessageContainer isOwnMessage={isOwnMessage}>
      <MessageRow isOwnMessage={isOwnMessage}>
        <MessageContainer isOwnMessage={isOwnMessage}>
          {isHtml && (
            <img
              style={{
                width: "100%",
                height: "50px",
                objectFit: "contain",
              }}
              alt="logo"
              src={logo}
            ></img>
          )}
          {isHtml ? (
            <MessageText dangerouslySetInnerHTML={{ __html: email.message }} />
          ) : (
            <MessageText>
              <span>{email.message}</span>
            </MessageText>
          )}
          {isHtml && <FakeConfirmButton>CONFIRM</FakeConfirmButton>}
          <CopyMessageOverlay
            onClick={() => setCopyMessage(email.message)}
            onMouseLeave={() => setCopyMessage(false)}
          >
            <i className={`fas fa-${copyMessage ? "check" : "copy"}`}></i>
          </CopyMessageOverlay>
        </MessageContainer>
      </MessageRow>
      <MessageInfoRow>
        <MessageInfoName>
          {isOwnMessage ? "You" : email.author.name}
        </MessageInfoName>
        <MessageInfoDate>
          {moment(email.date).format("DD/MM/YY HH:mm")}
        </MessageInfoDate>
      </MessageInfoRow>
    </ChatMessageContainer>
  );
};

export default EmailMessage;
