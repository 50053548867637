import styled from "styled-components";
import Header from "../components/header/Header";
import BookingsContent from "../components/bookings_manager/bookings/content/BookingsContent";
import BarLoader from "react-spinners/BarLoader";
import { useFetchEntities } from "../reactQueryHooks";
import { useNavigate } from "react-router-dom";

//----------------------------------------------------------------

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const ContentContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const SpinnerContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: center;
`;

//----------------------------------------------------------------

const Bookings = () => {
  const navigate = useNavigate();
  const { data: entities, isFetching: isFetchingEntities } = useFetchEntities();
  return (
    <Container>
      <Header
        text={"Bookings"}
        redirectFn={() => {
          navigate("/home");
        }}
      />
      <ContentContainer>
        {isFetchingEntities && (
          <SpinnerContainer>
            <BarLoader color="#606060" loading={true} />
          </SpinnerContainer>
        )}
        {entities && <BookingsContent />}
      </ContentContainer>
    </Container>
  );
};

export default Bookings;
