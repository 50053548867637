import styled from "styled-components";
import { useState, useEffect, useRef } from "react"
import moment from "moment"

//------------------------------------------

const Container = styled.div`
  display: flex;
  flex-direction: column;
  color: white;
  background: rgb(71, 71, 71);
  border-radius: 5px;
  padding: 7px;
  border: 2px solid white;
  user-select: none;
  gap: 5px;
`

const TopContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px 2px;
  cursor: pointer;
`

const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: rgb(88, 88, 88);
  padding: 4px 7px;
  border-radius: 5px;
  gap: 5px;
`

const HistoryItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 5px;
  border-radius: 5px;
  &:hover {
    background: rgb(71, 71, 71);
  }
`

const HistoryChangeItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 5px;
  border-radius: 5px;
  border: 1px solid rgb(160 160 160);
  //on hover border white
  &:hover {
    border: 1px solid white;
  }
`

const ChangeButton = styled.span`
  color: rgb(131 189 247);
  font-size: 12px;
  cursor: pointer;
  // on hover underline
  &:hover {
    text-decoration: underline;
  }
`

//------------------------------------------

const HistoryItem = ({ historyObj }) => {
  const [isExpanded, setIsExpanded] = useState(false)
  const [isHovered, setIsHovered] = useState(false)

  const timeoutRef = useRef(null)

  const handleMouseEnter = () => {
    setIsHovered(true)
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
    }
  }

  const handleMouseLeave = () => {
    setIsHovered(false)
  }

  useEffect(() => {
    if (isExpanded && !isHovered) {
      timeoutRef.current = setTimeout(() => {
        setIsExpanded(false)
      }, 3000)
    }
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current)
      }
    }
  }, [isExpanded, isHovered])

  const changes = historyObj?.changes?.filter(
    (change) => typeof change.after === "string"
  )
  return (
    <HistoryItemContainer
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      style={isExpanded ? { background: "rgb(71, 71, 71)" } : {}}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: "5px",
        }}
      >
        <span>
          {moment(historyObj.date).format("lll")} - {historyObj.author}
        </span>
        {changes?.length ? (
          isExpanded ? (
            <ChangeButton
              onClick={() => setIsExpanded((prev) => !prev)}
            >{`(hide changes)`}</ChangeButton>
          ) : (
            <ChangeButton
              onClick={() => setIsExpanded((prev) => !prev)}
            >{`(see ${changes.length} changes)`}</ChangeButton>
          )
        ) : null}
      </div>
      {isExpanded && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "5px",
            padding: "5px",
          }}
        >
          {changes.map((change, id) => (
            <HistoryChangeItem key={change.path}>
              <span
                style={{
                  fontWeight: "bold",
                  fontSize: "14px",
                }}
              >
                {`Field: ${change.path}`}
              </span>

              <span>{`Before: ${change.before}`}</span>
              <span>{`After: ${change.after}`}</span>
            </HistoryChangeItem>
          ))}
        </div>
      )}
    </HistoryItemContainer>
  )
}

const BookingHistory = ({ bookingHistory }) => {
  const [isExpanded, setIsExpanded] = useState(false)
  return (
    <Container>
      <TopContainer onClick={() => setIsExpanded((prev) => !prev)}>
        <div>History</div>
        <div>{isExpanded ? "▲" : "▼"}</div>
      </TopContainer>
      {isExpanded && (
        <ListContainer>
          {bookingHistory.map((historyObj, id) => (
            <HistoryItem key={id} historyObj={historyObj} />
          ))}
        </ListContainer>
      )}
    </Container>
  )
}

export default BookingHistory;
