import moment from "moment";
import styled from "styled-components";
import { motion } from "framer-motion";

//------------------------------------------

const SessionContainer = styled(motion.div)`
  font-size: 12px;
  color: white;
  display: flex;
  flex-direction: column;
  padding: 5px;
  background: rgb(109 109 109);
  gap: 2px;
`;

const SessionInfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
  flex-wrap: wrap;
`;

const SessionInfoItem = styled.div`
  display: flex;
  flex-direction: row;
  gap: 2px;
  background: rgb(100, 100, 100);
  padding: 1px 4px;
`;

const SessionActionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

//------------------------------------------

const ActionsList = ({ session }) => {
  return (
    <SessionContainer
      key={session._id}
      //a small downwards animation when expanding
      initial={{ y: -3, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      exit={{ y: -3, opacity: 0 }} // Move upwards and fade out
      transition={{ duration: 0.15 }}
    >
      <SessionInfoContainer>
        <SessionInfoItem>
          <div>Platform</div>
          <div
            style={{
              color: "rgb(200, 200, 255)",
              textTransform: "capitalize",
            }}
          >
            {session.device_info.platform}
          </div>
        </SessionInfoItem>
        <SessionInfoItem>
          <div>OS</div>
          <div
            style={{
              color: "rgb(200, 200, 255)",
              textTransform: "capitalize",
            }}
          >
            {session.device_info.osName}
          </div>
        </SessionInfoItem>
        <SessionInfoItem>
          <div>Version</div>
          <div
            style={{
              color: "rgb(200, 200, 255)",
              textTransform: "capitalize",
            }}
          >
            {session.device_info.osVersion}
          </div>
        </SessionInfoItem>
        <SessionInfoItem>
          <div>Browser</div>
          <div
            style={{
              color: "rgb(200, 200, 255)",
              textTransform: "capitalize",
            }}
          >
            {session.device_info.browserName}
          </div>
        </SessionInfoItem>
        <SessionInfoItem>
          <div>Mobile vendor</div>
          <div
            style={{
              color: "rgb(200, 200, 255)",
              textTransform: "capitalize",
            }}
          >
            {session.device_info.mobileVendor}
          </div>
        </SessionInfoItem>
        <SessionInfoItem>
          <div>Model</div>
          <div
            style={{
              color: "rgb(200, 200, 255)",
              textTransform: "capitalize",
            }}
          >
            {session.device_info.mobileModel}
          </div>
        </SessionInfoItem>
      </SessionInfoContainer>
      {session.session_actions.map((action) => {
        return (
          <SessionActionsContainer key={action._id}>
            <div
              style={{
                color: "rgb(200, 200, 255)",
                textTransform: "capitalize",
              }}
            >
              {action.user_action}
            </div>
            <div>{moment(action.date_time).format("DD/MM/YYYY HH:mm:ss")}</div>
          </SessionActionsContainer>
        );
      })}
    </SessionContainer>
  );
};

export default ActionsList;
