import FormGroup from "@mui/material/FormGroup"
import FormControlLabel from "@mui/material/FormControlLabel"
import Switch from "@mui/material/Switch"
import Typography from "@mui/material/Typography"
import { alpha, styled } from "@mui/material/styles"
import { green } from "@mui/material/colors"

const PinkSwitch = styled(Switch)(({ theme }) => ({
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: green[600],
    "&:hover": {
      backgroundColor: alpha(green[600], theme.palette.action.hoverOpacity),
    },
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: "grey",
  },
  fontSize: "10px",
}))

//---------------------------------------------------------------------------

const CustomMaterialSwitch = ({
  checked,
  label,
  set,
}: {
  checked: boolean
  label: string
  set: any
}) => {
  return (
    <FormGroup
      sx={
        {
          // marginLeft:1.4,
        }
      }
    >
      <FormControlLabel
        control={<PinkSwitch checked={checked} />}
        label={
          <Typography
            sx={{ fontSize: 13, color: checked ? "darkgreen" : "indianred" }}
          >
            {label}
          </Typography>
        }
        onChange={set}
        color="warning"
        componentsProps={{ typography: { variant: "h6" } }}
      />
    </FormGroup>
  )
}

export default CustomMaterialSwitch
