import styled from "styled-components";
import { Formik } from "formik";
import * as Yup from "yup";
import { motion } from "framer-motion";
import {
  useFetchEntities,
  useAddNewVehicle,
} from "../../../../reactQueryHooks";
import CustomMaterialSwitch from "../../settings/content/products/form/components/CustomMaterialSwitch"

//--------------------------------------------------------------------------------------------

const Wrapper = styled(motion.div)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: center;
  width: 65%;
  max-width: 450px;
  border-radius: 5px;
  padding: 5px;
  gap: 5px;
`;

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
  padding: 5px;
  background-color: white;
  border-radius: 5px;
`;

const Row = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  gap: 5px;
  border-radius: 5px;
  flex-wrap: wrap;
`;

const InputContainer = styled.div`
  flex: 1;
  border-radius: 3px;
  height: 35px;
  background-color: whitesmoke;
`;

const Input = styled.input`
  flex: 1;
  width: 100%;
  height: 100%;
  padding: 5px;
  border: none;
  text-transform: uppercase;
  font-size: 13px;
  border-radius: 3px;
`;

const HeaderRow = styled.div`
  flex: 1;
  padding: 0 2px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: white;
  font-size: 15px;
`;

const ButtonsRow = styled.div`
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  min-height: 40px;
`;

const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(129, 151, 129);
  color: white;
  border: none;
  border-radius: 5px;
  width: 50px;
  height: 40px;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  outline: none;
  :hover {
    filter: brightness(0.95);
  }
  i {
    font-size: 17px;
  }
  .fa-spinner {
    font-size: 14px;
  }
`;

//----------------------------------------------------------------------------------

const AddNewVehicleModal = ({ closeModal }) => {
  const { refetch } = useFetchEntities();
  const { mutateAsync: addNewVehicle, isLoading } = useAddNewVehicle();

  return (
    <Formik
      validateOnMount
      //enableReinitialize
      initialValues={{
        plate: "",
        type: "",
        color: "",
        max_capacity: "",
        gps_tracker_uid: "",
        platform_entry_required: false,
      }}
      validationSchema={Yup.object({
        plate: Yup.string().required("Plate is required"),
        type: Yup.string().required("Type is required"),
        color: Yup.string().required("Color is required"),
        //required and is not 0
        max_capacity: Yup.number()
          .required("Capacity is required")
          .min(1, "Capacity must be greater than 0"),
        gps_tracker_uid: Yup.string(),
        platform_entry_required: Yup.boolean(),
      })}
      onSubmit={async (values) => {
        try {
          await addNewVehicle({
            plate: values.plate.toUpperCase(),
            type: values.type.toUpperCase(),
            color: values.color.toUpperCase(),
            gps_tracker_uid: values.gps_tracker_uid.toUpperCase(),
            platform_entry_required: values.platform_entry_required,
          });
          refetch();
          closeModal();
        } catch (err) {
          if (err.response.data) {
            alert(err.response.data);
          } else {
            alert(err.toString());
          }
          console.log(err);
        }
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
        resetForm,
        isSubmitting,
        validateForm,
      }) => {
        return (
          <Wrapper
            initial={{ opacity: 0, scale: 0 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{
              opacity: { duration: 0.2 },
              scale: { duration: 0.1 },
            }}
          >
            <HeaderRow>New Vehicle</HeaderRow>
            <Container>
              <Row>
                <InputContainer>
                  <Input
                    maxLength={12}
                    style={{
                      backgroundColor: errors.plate
                        ? "rgb(255,232,232)"
                        : "rgb(233,255,233)",
                    }}
                    placeholder="Plate"
                    name="plate"
                    value={values.plate}
                    onChange={handleChange}
                  />
                </InputContainer>
                <InputContainer>
                  <Input
                    maxLength={30}
                    style={{
                      backgroundColor: errors.type
                        ? "rgb(255,232,232)"
                        : "rgb(233,255,233)",
                    }}
                    placeholder="Type"
                    name="type"
                    value={values.type}
                    onChange={handleChange}
                  />
                </InputContainer>
                <InputContainer>
                  <Input
                    maxLength={12}
                    style={{
                      backgroundColor: errors.max_capacity
                        ? "rgb(255,232,232)"
                        : "rgb(233,255,233)",
                    }}
                    placeholder="Capacity"
                    type="number"
                    name="max_capacity"
                    min={0}
                    value={values.max_capacity}
                    onChange={handleChange}
                  />
                </InputContainer>
              </Row>
              <Row>
                <InputContainer>
                  <Input
                    maxLength={10}
                    style={{
                      backgroundColor: errors.color
                        ? "rgb(255,232,232)"
                        : "rgb(233,255,233)",
                    }}
                    placeholder="Color"
                    name="color"
                    value={values.color}
                    onChange={handleChange}
                  />
                </InputContainer>
                <InputContainer>
                  <Input
                    maxLength={10}
                    style={{
                      backgroundColor: "whitesmoke",
                    }}
                    placeholder="GPR TRACKER UID"
                    name="gps_tracker_uid"
                    value={values.gps_tracker_uid}
                    onChange={handleChange}
                  />
                </InputContainer>
              </Row>
              <Row>
                <CustomMaterialSwitch
                  label={"Platform Entry Required"}
                  checked={values.platform_entry_required}
                  set={(e) => {
                    setFieldValue("platform_entry_required", e.target.checked);
                  }}
                />
              </Row>
            </Container>
            <ButtonsRow>
              <Button
                type="button"
                onClick={() => {
                  //if there are errors alert them
                  if (Object.keys(errors).length > 0) {
                    alert("Please fill all the required fields");
                  } else {
                    //if there are no errors, submit the form
                    handleSubmit();
                  }
                }}
              >
                {isLoading ? (
                  <i className="fas fa-spinner fa-spin"></i>
                ) : (
                  <i className="fas fa-floppy-disk"></i>
                )}
              </Button>
            </ButtonsRow>
          </Wrapper>
        );
      }}
    </Formik>
  );
};

export default AddNewVehicleModal;
