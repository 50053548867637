import { useEffect, useRef, useState } from "react";
import { useFormikContext } from "formik";
import styled from "styled-components";
// import BarLoader from "react-spinners/BarLoader";
//import mapboxgl, { LngLat } from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";


//---------------------------------------------------------

const MapWrapper = styled.div`
  min-width: 100%;
  height: 100%;
  min-height: 300px;
  border-radius: 5px;
  border: 1px solid lightgray;
  display: flex;
  justify-content: center;
  align-items: center;
  .spinner {
    animation: spin 1s linear infinite;
    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
`;

const MapContainer = styled.div`
  flex: 1;
  width: 100%;
  height: 100%;
`;

//---------------------------------------------------------

const AddMeetingPointsMap = () => {
  const {
    values,
    setFieldValue,
  }: {
    values: any;
    setFieldValue: any;
  } = useFormikContext();

  const mapContainerRef = useRef<HTMLDivElement | null>(null);
  const [map, setMap] = useState<mapboxgl.Map>();
  const marker = useRef<mapboxgl.Marker | null>(null);

  useEffect(() => {
    if (mapContainerRef.current) {
      if (!map) {
        //@ts-ignore
        const newMap = new mapboxgl.Map({
          container: mapContainerRef.current,
          style: "mapbox://styles/mapbox/streets-v11",
          center: [23.7275, 37.9838],
          zoom: 15,
        });
        setMap(newMap);
      }

      if (map) {
        //@ts-ignore
        marker.current = new mapboxgl.Marker({
          draggable: true,
        })
          .setLngLat([23.7275, 37.9838])
          .setPopup(
            //@ts-ignore
            new mapboxgl.Popup({ offset: 25 }).setHTML(`Drag to position`)
          )
          .addTo(map)
          .togglePopup();
      }

      if (marker.current) {
        marker.current.on("dragend", () => {
          const newLngLat = marker.current?.getLngLat();
          const newLatitude = newLngLat?.lat;
          const newLongitude = newLngLat?.lng;

          setFieldValue("latitude", newLatitude);
          setFieldValue("longitude", newLongitude);
          setFieldValue(
            "google_maps_url",
            `https://www.google.com/maps/place/${newLatitude},${newLongitude}`
          );
          marker.current?.togglePopup();
        });
      }
    }

    return () => {
      if (map) {
        map.remove();
      }
    };
  }, [setFieldValue, map]);

  // Update marker position  and map center when latitude or longitude changes
  useEffect(() => {
    const hasLatLng = values.latitude && values.longitude;

    if (marker.current && hasLatLng) {
      marker.current.setLngLat([values.longitude, values.latitude]);
      map?.flyTo({
        center: [values.longitude, values.latitude],
      });
    }
  }, [values.latitude, values.longitude, map]);

  return (
    <MapWrapper>
      <MapContainer ref={mapContainerRef} />
    </MapWrapper>
  );
};

export default AddMeetingPointsMap;

// useEffect(() => {
//   if (map) {
//     const latitude = values.latitude ? Number(values.latitude) : 37.9838;
//     const longitude = values.longitude ? Number(values.longitude) : 23.7275;

//     // @ts-ignore
//     const location = new mapboxgl.Marker({
//       draggable: true,
//     }).setLngLat([longitude, latitude]);

//     location.addTo(newMap);

//     // Add a 'dragend' event listener to the marker
//     location.on("dragend", () => {
//       const newLngLat = location.getLngLat();
//       const newLatitude = newLngLat.lat;
//       const newLongitude = newLngLat.lng;

//       setFieldValue("latitude", newLatitude);
//       setFieldValue("longitude", newLongitude);
//       setFieldValue(
//         "google_maps_url",
//         `https://www.google.com/maps/place/${newLatitude},${newLongitude}`
//       );
//     });

//     return () => newMap.remove();
//   }
// }, [values.latitude, values.longitude, setFieldValue]);
