import { useState, useRef, useEffect, useCallback } from "react"
import { ContentItem, ContentItemContent, ContentItemTitle } from "./styled"
import { storage } from "../../../../firebase"
import { v4 as uuidv4 } from "uuid"
import {
  ref,
  uploadBytesResumable,
  listAll,
  getDownloadURL,
  deleteObject,
} from "firebase/storage"
import { useAxios } from "../../../../axiosProvider"
import { RotatingLines } from "react-loader-spinner"
import NavButtonWithCustomMenu from "./NavButtonWithCustomMenu"
import styled from "styled-components"
import Button from "@mui/material/Button"
import downloadFilesAsZip from "./downloadFolderFromFirebase"
import {
  useFetchEntities,
  useTriggerRefreshFilesInTourGroup,
} from "../../../../reactQueryHooks"
import moment from "moment"
import io from "socket.io-client"
  const socket = io("https://getaways-bookings.herokuapp.com", {
    transports: ["websocket"],
  })

  //-------------------------------------------

  const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding: 5px;
    width: 100%;
    height: 100%;
    overflow: hidden;
  `

  const FilesContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    padding: 0 2px 0 0;
    max-height: 200px;
  `

  const FileContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    padding: 5px;
    border-radius: 3px;
    background-color: #ebebeb;
    cursor: pointer;

    font-size: 11px;
    .filename {
      &:hover {
        color: dodgerblue;

        /* Target the adjacent .fa-download element */
        & + .icons-container .fa-download {
          color: dodgerblue;
        }
      }
    }
    .fa-trash-alt {
      &:hover {
        color: indianred;
      }
    }
  `

  const IconsContainer = styled.div`
    display: flex;
    gap: 10px;
    i {
      font-size: 10px;
      cursor: pointer;
    }
  `

  const ButtonsContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
  `

  //-------------------------------------------

  const SortableTableInfoFiles = ({ tourGroup }) => {
    const [instanceId] = useState(uuidv4())
    const { mutateAsync: triggerRefreshFilesInTourGroup } =
      useTriggerRefreshFilesInTourGroup()

    const { data: entities } = useFetchEntities()
    const product = entities.data.products.find(
      (product) => product._id === tourGroup.product_id
    )
    const date = moment(tourGroup.date).format("DD-MM-YYYY")
    const fileName = `${product?.title} ${date} ${tourGroup.time} #${tourGroup.index}`
    const [isFetchingFiles, setIsFetchingFiles] = useState(false)
    const [folderFilesUrls, setFolderFilesUrls] = useState([])
    const [isUploadingFile, setIsUploadingFile] = useState(false)
    const [isDownloadingFiles, setIsDownloadingFiles] = useState(false)
    const [isDeletingAllFiles, setIsDeletingAllFiles] = useState(false)
    const [uploadProgress, setUploadProgress] = useState(0)
    const fileInputRef = useRef(null)

    const { user } = useAxios()
    const userIsAdmin = user.isAdmin
    const userIsPermittedToUploadFiles =
      user.permissions.uploadFiles || userIsAdmin

    //-------------------------------------------

    const handleFilesUpload = async (files) => {
      if (files && files.length > 0) {
        try {
          setIsUploadingFile(true) // Set isUploadingFile to true while the files are being uploaded

          // Array to store all upload tasks
          const uploadPromises = []

          // Total bytes to track overall progress
          let totalBytes = 0

          // Calculate total bytes of all files
          for (let i = 0; i < files.length; i++) {
            totalBytes += files[i].size
          }

          // Variable to track total bytes uploaded
          let bytesUploaded = 0

          // Function to handle progress event
          const handleProgress = (snapshot, file) => {
            // Update bytesUploaded to track overall progress
            bytesUploaded += snapshot.bytesTransferred

            // Calculate overall progress based on bytesUploaded and totalBytes
            const overallProgress = (bytesUploaded / totalBytes) * 100

            // Set upload progress state to the overall progress
            setUploadProgress(overallProgress)
          }

          // Loop through each selected file and create upload tasks
          for (let i = 0; i < files.length; i++) {
            const file = files[i]
            const storageRef = ref(
              storage,
              `/tour_groups/${tourGroup._id}/${file.name}`
            )

            // Create a resumable upload task for each file and push it to the uploadPromises array
            const uploadTask = uploadBytesResumable(storageRef, file)

            // Listen for state changes, such as progress updates, for each upload task
            uploadTask.on(
              "state_changed",
              (snapshot) => handleProgress(snapshot, file),
              (error) => {
                console.error("Error uploading file:", error)
                setIsUploadingFile(false) // Reset isUploadingFile state in case of an error
              }
            )

            // Push the upload task to the uploadPromises array
            uploadPromises.push(uploadTask)
          }

          // Wait for all upload tasks to complete using Promise.all
          await Promise.all(uploadPromises)

          // After all uploads are completed, reset isUploadingFile state
          setIsUploadingFile(false)
          await triggerRefreshFilesInTourGroup({
            instance_id: instanceId,
            tour_group_id: tourGroup._id,
          })
        } catch (error) {
          console.error("Error uploading files:", error)
          setIsUploadingFile(false) // Reset isUploadingFile state in case of an error
        }
      }
    }

    const getFiles = useCallback(async () => {
      try {
        const storageRef = ref(storage, `/tour_groups/${tourGroup._id}`)
        const result = await listAll(storageRef)
        const urlPromises = result.items.map(async (imageRef) => {
          const downloadURL = await getDownloadURL(imageRef)
          const filename = imageRef.name
          return { url: downloadURL, filename }
        })
        return Promise.all(urlPromises)
      } catch (error) {
        console.error("Error occurred:", error)
      }
    }, [tourGroup])

    const getUrls = useCallback(async () => {
      try {
        setIsFetchingFiles(true)
        const urls = await getFiles()
        setFolderFilesUrls(urls)
      } catch (error) {
        console.error("Error occurred:", error)
        alert("Error fetching files")
      } finally {
        setIsFetchingFiles(false)
      }
    }, [getFiles])

    const handleDeleteFile = async (filename) => {
      try {
        // Get the reference to the file in Firebase Storage
        const storageRef = ref(
          storage,
          `/tour_groups/${tourGroup._id}/${filename}`
        )

        // Delete the file
        await deleteObject(storageRef)

        // Remove the file from the folderFilesUrls state
        setFolderFilesUrls((prevUrls) =>
          prevUrls.filter((file) => file.filename !== filename)
        )
        await triggerRefreshFilesInTourGroup({
          instance_id: instanceId,
          tour_group_id: tourGroup._id,
        })
      } catch (error) {
        console.error("Error deleting file:", error)
        alert("Error deleting file")
      }
    }

    const handleDeleteAllFiles = async () => {
      try {
        // Iterate over each file in folderFilesUrls and delete them
        await Promise.all(
          folderFilesUrls.map(async (file) => {
            // Get the reference to each file in Firebase Storage
            const storageRef = ref(
              storage,
              `/tour_groups/${tourGroup._id}/${file.filename}`
            )

            // Delete the file
            await deleteObject(storageRef)
          })
        )

        // After deleting all files, clear the folderFilesUrls state
        setFolderFilesUrls([])
        await triggerRefreshFilesInTourGroup({
          instance_id: instanceId,
          tour_group_id: tourGroup._id,
        })
      } catch (error) {
        console.error("Error deleting files:", error)
        alert("Error deleting files")
      }
    }

    const noFolderOrFiles = !folderFilesUrls || folderFilesUrls.length === 0

    useEffect(() => {
      getUrls()
    }, [getUrls, isUploadingFile])

    useEffect(() => {
      const handleRefreshFiles = (data) => {
        if (data.instance_id !== instanceId) {
          getUrls()
        }
      }

      socket.on(
        `getaways_suite/refresh_files/${tourGroup._id}`,
        handleRefreshFiles
      )

      return () => {
        socket.off(
          `getaways_suite/refresh_files/${tourGroup._id}`,
          handleRefreshFiles
        )
      }
    }, [getUrls, tourGroup, instanceId, triggerRefreshFilesInTourGroup])

    //-------------------------------------------
    return (
      <NavButtonWithCustomMenu
        title={
          <ContentItem
            title="Upload"
            initial={{ opacity: 0, x: -5 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: -5 }}
            transition={{ duration: 0.25, delay: 0.25 }}
            // onClick={() => {
            //   if (userIsPermittedToUploadFiles) {
            //     fileInputRef.current.click();
            //   } else {
            //     alert("You do not have permission to upload files");
            //   }
            // }}
          >
            <ContentItemTitle>Files</ContentItemTitle>
            <ContentItemContent>
              <i className="fa-solid fa-file"></i>
              <span>See files</span>
              <span
                style={{
                  color: noFolderOrFiles ? "indianred" : "darkgreen",
                }}
              >
                {isFetchingFiles ? (
                  <RotatingLines
                    width="13"
                    strokeColor="grey"
                    //speed
                    strokeWidth={4}
                  />
                ) : (
                  `(${folderFilesUrls?.length})`
                )}
              </span>
              {isUploadingFile ? (
                <span
                  style={{
                    fontWeight: "bold",
                    fontSize: "10px",
                  }}
                >
                  {uploadProgress.toFixed(0)}% Uploading...
                </span>
              ) : (
                <input
                  ref={fileInputRef}
                  style={{
                    display: "none",
                  }}
                  type="file"
                  accept="*"
                  multiple
                  onChange={(e) => {
                    handleFilesUpload(e.target.files)
                  }}
                />
              )}
            </ContentItemContent>
          </ContentItem>
        }
      >
        <Wrapper>
          {!noFolderOrFiles && (
            <FilesContainer>
              {folderFilesUrls.map((file, index) => {
                return (
                  <FileContainer key={file.filename}>
                    <span
                      title="Download file"
                      onClick={(event) => {
                        event.stopPropagation()
                        window.open(file.url, "_blank")
                      }}
                      className="filename"
                    >
                      {file.filename}
                    </span>
                    <IconsContainer className="icons-container">
                      <i className="fas fa-download"></i>
                      <i
                        title="Delete file"
                        onClick={() => {
                          if (userIsPermittedToUploadFiles) {
                            if (window.confirm("Delete this file?")) {
                              handleDeleteFile(file.filename)
                            }
                          } else {
                            alert("You do not have permission to delete files")
                          }
                        }}
                        className="fas fa-trash-alt"
                      ></i>
                    </IconsContainer>
                  </FileContainer>
                )
              })}
            </FilesContainer>
          )}
          <ButtonsContainer>
            <Button
              variant="contained"
              style={{
                backgroundColor: "cornflowerblue",
                color: "white",
                fontSize: "10px",
                fontWeight: "bold",
              }}
              onClick={() => {
                if (userIsPermittedToUploadFiles) {
                  fileInputRef.current.click()
                } else {
                  alert("You do not have permission to upload files")
                }
              }}
            >
              UPLOAD FILES
            </Button>
            {!noFolderOrFiles && (
              <Button
                variant="contained"
                style={{
                  backgroundColor: "grey",
                  color: "white",
                  fontSize: "10px",
                  fontWeight: "bold",
                }}
                onClick={async (event) => {
                  event.stopPropagation()
                  try {
                    if (userIsPermittedToUploadFiles) {
                      setIsDownloadingFiles(true)
                      await downloadFilesAsZip(tourGroup._id, fileName)
                    } else {
                      alert("You do not have permission to download files")
                    }
                  } catch (e) {
                    console.log(e)
                    alert("Error downloading files")
                  } finally {
                    setIsDownloadingFiles(false)
                  }
                }}
              >
                {isDownloadingFiles ? (
                  <RotatingLines
                    width="17.5"
                    strokeColor="white"
                    //speed
                    strokeWidth={4}
                  />
                ) : (
                  "DOWNLOAD ALL FILES"
                )}
              </Button>
            )}
            {!noFolderOrFiles && (
              <Button
                variant="contained"
                style={{
                  backgroundColor: "rgb(231 140 140)",
                  color: "white",
                  fontSize: "10px",
                  fontWeight: "bold",
                }}
                onClick={async (event) => {
                  event.stopPropagation()
                  if (!userIsPermittedToUploadFiles) {
                    alert("You do not have permission to delete files")
                    return
                  }
                  if (window.confirm("Delete all files?")) {
                    try {
                      setIsDeletingAllFiles(true)
                      await handleDeleteAllFiles()
                    } catch (e) {
                      console.log(e)
                      alert("Error deleting files")
                    } finally {
                      setIsDeletingAllFiles(false)
                    }
                  }
                }}
              >
                {isDeletingAllFiles ? (
                  <RotatingLines
                    width="13"
                    strokeColor="white"
                    //speed
                    strokeWidth={4}
                  />
                ) : (
                  "DELETE ALL FILES"
                )}
              </Button>
            )}
          </ButtonsContainer>
        </Wrapper>
      </NavButtonWithCustomMenu>
    )
  }

export default SortableTableInfoFiles
