import { useState } from "react";
import moment from "moment";
import styled from "styled-components";
import Button from "@mui/material/Button";
import { useFetchEntities } from "../../../../reactQueryHooks";
import { motion } from "framer-motion";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import SearchableMultiSelect from "../../settings/content/users/SearchableMultiSelect";
import { useAddTourGuidesInMultipleTourGroupsGuidesAskedArray } from "../../../../reactQueryHooks";

//---------------------------------------------------------

const Modal = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(100, 100, 100, 0.8);
  border-radius: 5px;
  padding: 5px;
  overflow: hidden;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
`;

const Container = styled(motion.div)`
  border-radius: 5px;
  padding: 5px;
  height: 100%;
  width: 100%;
  max-width: 500px;
  max-height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
  overflow: hidden;
  font-size: 13px;
`;

const ContentContainer = styled.div`
  height: 100%;
  width: 100%;
  overflow-y: auto;
  padding: 0 5px;
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  height: 100%;
  width: 100%;
  padding: 5px;
  background-color: white;
  border-radius: 5px;
`;

const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
  min-height: 35px;
`;

const CloseButton = styled.button`
  border: none;
  color: white;
  height: 35px;
  width: 35px;
  border-radius: 4px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: absolute;
  top: 5px;
  right: 5px;
  i {
    font-size: 20px;
  }
  &:hover {
    i {
      color: indianred;
    }
  }
`;

//---------------------------------------------------------

const GuideProposedModal = ({ close, refetch, data }) => {
  const [selected, setSelected] = useState([]);
  const { data: entities } = useFetchEntities();
  const group_ids = data.map((row) => row._id);

  const { mutateAsync: addGuidesToTourGroups, isLoading } =
    useAddTourGuidesInMultipleTourGroupsGuidesAskedArray();

  const tour_guides = entities?.data?.staff.filter((staff) =>
    staff.roles.some((role) => role._id === "636d1329f2535b158a9acd20")
  );

  const tour_guides_sorted_by_included_in_guides_asked = tour_guides?.sort(
    (a, b) => {
      if (selected.includes(a._id) && !selected.includes(b._id)) {
        return -1;
      }
      if (!selected.includes(a._id) && selected.includes(b._id)) {
        return 1;
      }
      return 0;
    }
  );
  const tour_guides_labels_and_values =
    tour_guides_sorted_by_included_in_guides_asked?.map((guide) => {
      return {
        label: guide.name,
        value: guide._id,
      };
    });

  //const sorted --> the ones where their id is in guides_asked go first

  const selected_tour_guides_from_guides_asked_with_labels_and_values =
    tour_guides
      ?.filter((guide) => selected.includes(guide._id))
      .map((guide) => {
        return {
          label: guide.name,
          value: guide._id,
        };
      });

  //-----------------------------------

  return (
    <Modal>
      <CloseButton onClick={close}>
        <i className="fas fa-times"></i>
      </CloseButton>

      <Container
        initial={{ opacity: 0, scale: 0 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{
          opacity: { duration: 0.2 },
          scale: {
            //type: "spring",
            // stiffness: 300, // Adjust stiffness for more/less bounce
            // damping: 20, // Adjust damping for faster/slower settle
            // duration: 0.02,
          },
        }}
      >
        <ContentContainer>
          <FormContainer>
            <RowContainer>
              <SearchableMultiSelect
                data={tour_guides_labels_and_values}
                selectedData={
                  selected_tour_guides_from_guides_asked_with_labels_and_values
                }
                onSelect={async (data) => {
                  setSelected(data);
                  // try {
                  //   const updated =
                  //     await addTourGuidesInTourGroupGuidesAskedArray({
                  //       id: row.tour_group_id,
                  //       guide_ids: data,
                  //     });
                  //   setGuides_asked(updated.data);
                  // } catch (error) {
                  //   alert(error.toString());
                  // }
                }}
                searchable
              />
            </RowContainer>
            <TableContainer component={Paper}>
              <Table
                aria-label="simple table"
                size="small"
                stickyHeader
                sx={{
                  //striped rows
                  "& tbody tr:nth-of-type(odd)": {
                    backgroundColor: "whitesmoke",
                  },
                  "& td": {
                    fontSize: "12px",
                    padding: "5px",
                    textAlign: "left",
                  },
                  "& th": {
                    fontSize: "12px",
                  },
                }}
              >
                <TableHead>
                  <TableRow>
                    <TableCell>Date</TableCell>
                    <TableCell>Product</TableCell>
                    <TableCell>Time</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.map((row) => {
                    const product = entities.data.products.find(
                      (product) => product._id === row.product_id
                    );
                    return (
                      <TableRow
                        key={row._id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell align="right">
                          {moment(row.date).format("DD/MM/YYYY ddd")}
                        </TableCell>
                        <TableCell align="right">{product.title}</TableCell>
                        <TableCell align="right">{row.time}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <Button
              variant="contained"
              onClick={async () => {
                try {
                  //if (selected.length === 0) return and alert
                  if (selected.length === 0) {
                    alert("Please select at least one guide");
                    return;
                  }

                  await addGuidesToTourGroups({
                    tour_group_ids: group_ids,
                    guide_ids: selected,
                  });
                  refetch();
                } catch (error) {
                  alert(error.toString());
                }
              }}
            >
              {isLoading ? "Submitting..." : "SUBMIT"}
            </Button>
          </FormContainer>
        </ContentContainer>
      </Container>
    </Modal>
  );
};

export default GuideProposedModal;
