import { useState } from "react"
import styled from "styled-components"
import OutsideClickHandler from "react-outside-click-handler"
import {
  handlePdfExport,
  handleXlsxExportFromDayPlanner,
} from "./file_utilities"
import OverlayTrigger from "react-bootstrap/OverlayTrigger"
import Popover from "react-bootstrap/Popover"
import TimerOffIcon from "@mui/icons-material/TimerOff"
import TimerIcon from "@mui/icons-material/Timer"
import moment from "moment"
import { motion } from "framer-motion"
import {
  useFetchEntities,
  useSwapTourGroupsResources,
  useRemovePickupTimesFromAllBookingsOfTourGroup,
  useAddPickupTimeToAllBookingsOfTourGroup,
  useGetUserDaySchedulesThatIncludesTourGroup,
} from "../../../../reactQueryHooks"
import { useAxios } from "../../../../axiosProvider"
import SendOlpMailsModal from "./SendOlpMailsModal"
import PickupsMapModal from "./PickupsMapModal"

//----------------------------------------------------------------

const Button = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: silver;
  opacity: 0.4;
  transition: opacity 0.2s ease-in-out;
  &:hover {
    opacity: 1;
  }

  i {
    font-size: 15px;
    color: white;
  }
  z-index: 100;
`

const PopoverContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3px;
  padding: 7px;
`

const PopoverBtn = styled(motion.button)`
  background-color: whitesmoke;
  padding: 0px 7px;
  border-radius: 3px;
  min-height: 30px;
  font-size: 12px;
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: flex-start;
  border: none;
  cursor: pointer;
  &:hover {
    color: ${(props) => props.theme.colors.blue};
  }
`

const variants = {
  hidden: {
    opacity: 0,
    y: -10,
  },
  visible: {
    opacity: 1,
    y: 0,
  },
  exit: {
    opacity: 0,
    y: -10,
  },
}

//----------------------------------------------------------------

const SortableTableOptionsMenu = ({
  ids = [],
  tourGroup,
  refetchPage,
  refetchTourGroup,
}) => {
  const { data: entities } = useFetchEntities()
  const [showOverlay, setShowOverlay] = useState(false)
  const [pickupTime, setPickupTime] = useState("")
  const [isSendOlpModalShown, setIsSendOlpModalShown] = useState(false)
  const [showPickupsMapModal, setShowPickupsMapModal] = useState(false)

  const {
    mutateAsync: swapTourGroupsResources,
    //isLoading: isSwappingResources,
  } = useSwapTourGroupsResources()

  const { mutateAsync: removeAllPickupTimes } =
    useRemovePickupTimesFromAllBookingsOfTourGroup()
  // const result = await mutateAsync(tour_group_id)

  const { mutateAsync: addPickupTimeToAllBookings } =
    useAddPickupTimeToAllBookingsOfTourGroup()
  // const result = await mutateAsync({ tour_group_id, pickup_time })

  //----------------------------------------------------------------

  const { data: assignees } = useGetUserDaySchedulesThatIncludesTourGroup(
    tourGroup._id
  )
  const { user } = useAxios()
  const userIsAdmin = user.isAdmin
  const userIsPermittedToSendOlpEmails =
    user.permissions.sendOlpEmail || userIsAdmin
  const userIsPermittedToUpdateBookings =
    user.permissions.updateBookings || userIsAdmin

  //---------------------- OLP EMAIL -------------------------------

  const drivers = assignees?.filter(
    (assignee) => assignee.role === "636d1318f2535b158a9acd08"
  )

  const driver = drivers?.length
    ? entities?.data?.staff?.find((staff) => staff._id === drivers[0]?.id)
    : null

  const driver_name = driver?.name
  const driver_id = driver?._id
  const driver_id_number = driver?.id_number
  const vehicle_id = tourGroup?.vehicle_id
  const vehicle = entities?.data?.vehicles?.find(
    (vehicle) => vehicle._id === vehicle_id
  )
  const vehicle_plate = vehicle?.plate
  const vehicle_type = vehicle?.type

  const seatsCount = tourGroup.bookings?.reduce((acc, booking) => {
    let seatsCount = Object.keys(booking?.tickets)
      //.filter((key) => key.toLowerCase() !== "infant")
      .reduce((acc, key) => acc + booking.tickets[key], 0)
    if (booking.cancelled) seatsCount = 0
    return acc + seatsCount
  }, 0)

  const bookingsWithChannelAndProduct = tourGroup.bookings.map((booking) => {
    const product = entities?.data?.products?.find(
      (product) => product._id === booking.product_id
    )
    const channel = entities?.data?.channels?.find(
      (channel) => channel._id === booking.channel_id
    )
    return { ...booking, product, channel }
  })

  const handleClickOutside = (e) => {
    const exceptionedIds = ["time_input"]
    if (!exceptionedIds.includes(e.target.id)) {
      setShowOverlay(false)
    }
  }

  //----------------------------------------------------------------

  return (
    <OutsideClickHandler onOutsideClick={handleClickOutside}>
      <OverlayTrigger
        //container={containerRef.current} //contain within the parent element (refresh page)
        show={showOverlay}
        rootClose //close on click outside
        //arrowprops={{ style: { display: "none" } }}
        placement="bottom-end"
        overlay={
          <Popover
            id="popover-basic"
            style={{
              //visible shadow
              boxShadow: "2px 2px 4px 0 rgba(0, 0, 0, 0.1)",
              position: "absolute",
              maxWidth: "none",
            }}
          >
            {/* <Popover.Header>Menu</Popover.Header> */}

            <PopoverContent>
              <PopoverBtn
                variants={variants}
                initial="hidden"
                animate={{
                  opacity: 1,
                  y: 0,
                  transition: {
                    delay: 0.2,
                  },
                }}
                onClick={async () => {
                  try {
                    if (userIsPermittedToUpdateBookings) {
                      await removeAllPickupTimes(tourGroup._id)
                      refetchTourGroup()
                    } else {
                      alert("You don't have permission")
                    }
                  } catch (e) {
                    console.log(e)
                    alert("Error swapping resources")
                  }
                }}
              >
                <TimerOffIcon
                  sx={{
                    fontSize: 15,
                  }}
                />
                <span>Remove all pickup times</span>
              </PopoverBtn>
              <PopoverBtn
                variants={variants}
                initial="hidden"
                animate={{
                  opacity: 1,
                  y: 0,
                  transition: {
                    delay: 0.22,
                  },
                }}
              >
                <TimerIcon
                  sx={{
                    fontSize: 15,
                  }}
                />
                <span
                  style={{
                    flex: 1,
                  }}
                  onClick={async () => {
                    const isValidTime = moment(
                      pickupTime,
                      "HH:mm",
                      true
                    ).isValid()
                    try {
                      if (!isValidTime) {
                        alert("Invalid time")
                        return
                      }

                      if (userIsPermittedToUpdateBookings) {
                        await addPickupTimeToAllBookings({
                          tour_group_id: tourGroup._id,
                          pickup_time: pickupTime,
                        })
                        refetchTourGroup()
                      } else {
                        alert("You don't have permission")
                      }
                    } catch (e) {
                      console.log(e)
                      alert("Error swapping resources")
                    }
                  }}
                >
                  Add pickup time to all bookings
                </span>
                <input
                  id="time_input"
                  style={{
                    border: "none",
                    fontSize: "12px",
                    textAlign: "center",
                    backgroundColor: "transparent",
                  }}
                  type="time"
                  autoFocus
                  onChange={(e) => {
                    const pickup_time = e.target.value
                    setPickupTime(pickup_time)
                  }}
                />
              </PopoverBtn>
              <PopoverBtn
                variants={variants}
                initial="hidden"
                animate={{
                  opacity: 1,
                  y: 0,
                  transition: {
                    delay: 0.24,
                  },
                }}
                onClick={() => {
                  if (userIsPermittedToSendOlpEmails) {
                    setIsSendOlpModalShown(true)
                    setShowOverlay(false)
                  } else {
                    alert("You don't have permission to send OLP emails")
                  }
                }}
              >
                <i className="fa-solid fa-anchor"></i>
                <span>Send OLP Permissions</span>
              </PopoverBtn>
              <PopoverBtn
                variants={variants}
                initial="hidden"
                animate={{
                  opacity: 1,
                  y: 0,
                  transition: {
                    delay: 0.26,
                  },
                }}
                onClick={() => {
                  handlePdfExport(
                    `${bookingsWithChannelAndProduct[0].product.title} @ ${tourGroup.bookings[0].product_time_slot}`,
                    bookingsWithChannelAndProduct
                  )
                }}
              >
                <i className="fa-solid fa-file-pdf"></i>
                <span>Export to PDF</span>
              </PopoverBtn>
              <PopoverBtn
                variants={variants}
                initial="hidden"
                animate={{
                  opacity: 1,
                  y: 0,
                  transition: {
                    delay: 0.28,
                  },
                }}
                onClick={() => {
                  handleXlsxExportFromDayPlanner(
                    `${bookingsWithChannelAndProduct[0].product.title} @ ${tourGroup.bookings[0].product_time_slot}`,
                    bookingsWithChannelAndProduct
                  )
                }}
              >
                <i className="fa-solid fa-file-excel"></i>
                <span>Export to XLSX</span>
              </PopoverBtn>
              <PopoverBtn
                variants={variants}
                initial="hidden"
                animate={{
                  opacity: 1,
                  y: 0,
                  transition: {
                    delay: 0.3,
                  },
                }}
                onClick={() => {
                  setShowPickupsMapModal(true)
                }}
              >
                <i className="fa-solid fa-map-location-dot"></i>
                <span>See pickups plan</span>
              </PopoverBtn>
              {ids.map((id, index) => (
                <PopoverBtn
                  key={id.tour_group_id}
                  variants={variants}
                  initial="hidden"
                  animate={{
                    opacity: 1,
                    y: 0,
                    transition: {
                      delay: 0.32 + index * 0.02,
                    },
                  }}
                  onClick={async () => {
                    try {
                      if (userIsPermittedToUpdateBookings) {
                        await swapTourGroupsResources([
                          tourGroup._id,
                          id.tour_group_id,
                        ])
                        refetchPage()
                      } else {
                        alert("You don't have permission")
                      }
                    } catch (e) {
                      console.log(e)
                      alert("Error swapping resources")
                    }
                  }}
                >
                  <i className="fa-solid fa-arrow-right-arrow-left"></i>
                  <span>Swap resources with group #{id.group_number}</span>
                </PopoverBtn>
              ))}
            </PopoverContent>
          </Popover>
        }
      >
        <Button onClick={() => setShowOverlay(!showOverlay)}>
          <i className={`fa-solid fa-ellipsis-vertical`}></i>
        </Button>
      </OverlayTrigger>
      {isSendOlpModalShown && (
        <SendOlpMailsModal
          close={() => setIsSendOlpModalShown(false)}
          data={{
            date: tourGroup.date,
            time: tourGroup.time,
            driver_name: driver_name,
            driver_id: driver_id,
            driver_id_number: driver_id_number,
            vehicle_id: vehicle_id,
            vehicle_plate: vehicle_plate,
            vehicle_type: vehicle_type,
            client_names: [tourGroup.bookings[0]?.client_name],
            client_count: seatsCount,
          }}
        />
      )}
      {showPickupsMapModal && (
        <PickupsMapModal
          close={() => setShowPickupsMapModal(false)}
          tourGroupId={tourGroup._id}
        />
      )}
    </OutsideClickHandler>
  )
}

export default SortableTableOptionsMenu
