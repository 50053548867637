import styled from "styled-components";
import { useEffect, useRef } from "react";
import $ from "jquery";
import "jquery-ui-bundle";
import "jquery-ui-bundle/jquery-ui.css";
import PickupItem from "./PickupItem";
import { useFormikContext } from "formik";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

//---------------------------------------------------

const PickupsList = ({
  meetingPoints,
  isSortingPickups,
  setIsSortingPickups,
}) => {
  const pickupsRef = useRef(null);

  const {
    values: { pickups },
    setFieldValue,
  } = useFormikContext();

  useEffect(() => {
    $(pickupsRef.current).sortable({
      handle: ".sorting-overlay",
      start: function (event, ui) {
        //store old index of element being dragged,in order to be accessible in update function
        ui.item.data("oldIndex", ui.item.index());
      },
      update: function (event, ui) {
        // oldIndex comes from start function
        // const oldIndex = ui.item.data("oldIndex");
        // newIndex is the index of the element after it has been dropped
        // const newIndex = ui.item.index();
        const ids = $(event.target).sortable("toArray"); // get the ids of all elements in the list --> id  = JSON.stringify(pickup)
        const allIdsParsed = ids.map((id) => JSON.parse(id)); // parse all ids

        // console.log("allIdsParsed", allIdsParsed);
        setFieldValue("pickups", [...allIdsParsed]);
        setIsSortingPickups(false);
        //we disable the moving by returning false because we have already updated the pickups state..
        //and we have already changed the order of the pickups in the DOM
        return false;
      },
    });
  }, [setFieldValue, setIsSortingPickups]);

  return (
    <Container ref={pickupsRef}>
      {pickups.map((pickup, pickupIndex) => {
        return (
          <PickupItem
            key={pickupIndex}
            pickup={pickup}
            setPickup={(pickup) => {
              //copy pickups array
              let newPickups = [...pickups];
              //replace pickup at pickupIndex with new pickup
              newPickups[pickupIndex] = pickup;
              //update pickups
              setFieldValue("pickups", [...newPickups]);
            }}
            meetingPoints={meetingPoints}
            deletePickup={() => {
              setFieldValue("pickups", [
                ...pickups.filter((pickup, index) => index !== pickupIndex),
              ]);
            }}
            isSortingPickups={isSortingPickups}
          />
        );
      })}
    </Container>
  );
};

export default PickupsList;
