import { ContentItem, ContentItemContent, ContentItemTitle } from "./styled";
import { useAxios } from "../../../../axiosProvider";

//-------------------------------------------

const SortableTableInfoNotes = ({ add }) => {

  const { user } = useAxios();
  const userIsAdmin = user.isAdmin
  const userIsPermittedToAddNotes = user.permissions.addNotes || userIsAdmin;
  return (
    <ContentItem
      title="Add a note"
      initial={{ opacity: 0, x: -5 }}
      animate={{ opacity: 1, x: 0 }}
      exit={{ opacity: 0, x: -5 }}
      transition={{ duration: 0.25, delay: 0.3 }}
      onClick={() => {
        if (userIsPermittedToAddNotes) {
          add()
        } else {
          alert('You do not have permission to add notes')
        }
      }}
    >
      <ContentItemTitle>Notes</ContentItemTitle>
      <ContentItemContent>
        <i className="fas fa-circle-plus"></i>
        <span>Add a note</span>
      </ContentItemContent>
    </ContentItem>
  );
};

export default SortableTableInfoNotes;
