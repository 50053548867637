export const theme = {
  colors: {
    green: "#7aa579",
    green1: "#92c591",
    lightgreen: "#c7e0c7",
    red: "#b37374",
    red1: "#d9999a",
    red2: "#c16062",
    blue: "#7596b7",
    blue1: "#7aade0",
    lavender: "#CFAEDA",
    lightblue: "#e3eef1",
    orange: "#ff944d",
    orange2: "#cda04e",
    lightgrey: "#c8beb3",
    grey: "#94806b",
    yellow: "#bba976",
    lightyellow: "#ffffcc",
    whatsAppWhite: "#f5f5f5",
    whatsAppGreen: "#daf5bf",
    whatsAppBlack: "#2d2d2d",
  },
};
