import styled from "styled-components";
import { useState, useEffect } from "react";
import SelectionBar from "../../../selection_bar/SelectionBar";
import ServiceLogsBarContent from "../bar/ServiceLogsBarContent";
import {
  useFetchServiceLogEntries,
  useFetchEntities,
} from "../../../../reactQueryHooks";
import VehicleInfo from "./VehicleInfo";
import ServiceLogsFeedTable from "./ServiceLogFeedTable";
import AddNewVehicleModal from "./AddNewVehicleModal";
import AddNewServiceLogModal from "./AddNewServiceLogModal";
import Map from "./Map";

//----------------------------------------------------------------

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const ContentWrapper = styled.div`
  flex: 1;
  //background-color: red;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  overflow: hidden;
`;

const ContentContainer = styled.div`
  padding: 10px;
  @media (max-width: 1000px) {
    flex: 1;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    overflow: auto;
  }
  //more than 1000px
  @media (min-width: 1000px) {
    flex: 1;
    display: grid;
    width: 100%;
    height: 100%;
    grid-template-rows: minmax(0, 50%) minmax(0, 50%); /* Each row is at least 50% of the parent height */
    grid-template-columns: minmax(0, 50%) minmax(0, 50%); /* Each column is at least 50% of the parent width */
  }
  gap: 10px;
`;

const VehicleInfoContainer = styled.div`
  width: 100%;
  height: 100%;
  grid-row: 1;
  grid-column: 1;
  padding: 10px;
  background-color: rgb(251, 249, 249);
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  @media (max-width: 1000px) {
    max-height: 500px;
  }
`;

const ServiceFeedContainer = styled.div`
  grid-row: 2;
  grid-column: 1;
  padding: 10px;
  background-color: rgb(251, 249, 249);
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  @media (max-width: 1000px) {
    max-height: 500px;
  }
`;

const MapContainer = styled.div`
  width: 100%;
  height: 100%;
  min-height: 500px;
  grid-row: 1/3;
  grid-column: 2;
  padding: 10px;
  background-color: rgb(251, 249, 249);
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const FormModal = styled.div`
  position: absolute;
  top: 0;
  padding: 5px;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(2px);
  background-color: rgba(0, 0, 0, 0.5);
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
`;

//------------------------------------------------------------------------

const FleetManagementContent = () => {
  const [isVehicleModalOpen, setIsVehicleModalOpen] = useState(false);
  const [isServiceLogModalOpen, setIsServiceLogModalOpen] = useState(false);
  const [vehicleId, setVehicleId] = useState("all");
  const {
    isFetching: isFetchingEntities,
    isRefetching: isRefetchingEntities,
    refetch: refetchEntities,
  } = useFetchEntities();

  const {
    data: serviceLogEntries,
    isFetching,
    isRefetching,
    //fetchNextPage,
    refetch: refetchServiceLogEntries,
  } = useFetchServiceLogEntries(vehicleId);

  const isLoading =
    isFetching || isRefetching || isFetchingEntities || isRefetchingEntities;

  useEffect(() => {
    refetchServiceLogEntries();
  }, [vehicleId, refetchServiceLogEntries]);

  //------------------------------------------------------------------------

  return (
    <Container>
      <SelectionBar>
        <ServiceLogsBarContent
          isModalOpen={isVehicleModalOpen || isServiceLogModalOpen}
          closeModal={() => {
            setIsVehicleModalOpen(false);
            setIsServiceLogModalOpen(false);
          }}
          refetch={() => {
            refetchServiceLogEntries();
            refetchEntities();
          }}
        />
      </SelectionBar>
      <ContentWrapper>
        <ContentContainer>
          <VehicleInfoContainer>
            <VehicleInfo
              vehicleId={vehicleId}
              setVehicleId={setVehicleId}
              openModal={() => {
                setIsVehicleModalOpen(true);
              }}
            />
          </VehicleInfoContainer>
          <ServiceFeedContainer>
            <ServiceLogsFeedTable
              serviceLogEntries={serviceLogEntries}
              isLoading={isLoading}
              refetch={() => {
                refetchServiceLogEntries();
              }}
              openModal={() => {
                setIsServiceLogModalOpen(true);
              }}
            />
          </ServiceFeedContainer>
          <MapContainer>
            <Map />
          </MapContainer>
        </ContentContainer>
        {isVehicleModalOpen && (
          <FormModal>
            <AddNewVehicleModal
              closeModal={() => {
                setIsVehicleModalOpen(false);
              }}
            />
          </FormModal>
        )}

        {isServiceLogModalOpen && (
          <FormModal>
            <AddNewServiceLogModal
              vehicleId={vehicleId === "all" ? "" : vehicleId}
              refetch={() => {
                refetchServiceLogEntries();
                refetchEntities();
              }}
              closeModal={() => {
                setIsServiceLogModalOpen(false);
              }}
            />
          </FormModal>
        )}
      </ContentWrapper>
    </Container>
  );
};

export default FleetManagementContent;
