import { useState, useEffect } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import moment from "moment";
import DataTable from "react-data-table-component";
import {
  useFetchEntities,
  useUpdateServiceLogEntry,
  useDeleteServiceLogEntry,
} from "../../../../reactQueryHooks";
import ServiceLogFiles from "./ServiceLogFiles";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import OutsideClickHandler from "react-outside-click-handler";
import EditableCell from "./EditableCell";
import EditableCellSelect from "./EditableCellSelect";
import { useAxios } from "../../../../axiosProvider";

//---------------------------------------------------------------

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  grid-row: 1;
  grid-column: 2;
  height: 100%;
  overflow: hidden;
  gap: 10px;
`;

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 10px;
  background-color: white;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
`;

const TopRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const MenuButton = styled(motion.div)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 13px;
  border-radius: 10px;
  cursor: pointer;
  &:hover {
    background-color: #e6e6e6;
  }
`;

const NoData = styled.div`
  flex: 1;
  height: 130px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  font-size: 14px;
  font-weight: bold;
  color: indianred;
`;

// const FetchMoreContainer = styled.div`
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   min-height: 35px;
//   cursor: pointer;
//   &:hover {
//     span {
//       color: ${(props) => props.theme.colors.blue};
//     }
//   }
//   span {
//     font-size: 13px;
//   }
// `;

const DeleteButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  padding: 8px 0;
  margin-bottom: 10px;
  border-radius: 4px;
  background-color: white;
  cursor: pointer;
  i {
    font-size: 15px;
  }
  &:hover {
    i {
      color: indianred;
    }
  }
`;

const TableContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: whitesmoke;
  overflow: hidden;
  border-bottom: 1px solid #c9c9c9;
`;

const FilesContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

const SpinnerContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: center;
  height: 100%;
  i {
    color: #e6e6e6;
    font-size: 50px;
    animation: spin 2s linear infinite;
  }
`;

const PopoverContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 5px;
  min-width: 150px;
`;

const PopoverBtn = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(239, 239, 239);
  border-radius: 5px;
  padding: 0 10px;
  height: 35px;
  cursor: pointer;
  &:hover {
    filter: brightness(0.9);
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-weight: bold;
  font-size: 11px;
  background: whitesmoke;
  width: 100%;
  height: 35px;
  padding: 0 10px;
`;

const AddRepairButton = styled.button`
  background-color: #679469;
  border: none;
  height: 100%;
  text-align: center;
  text-decoration: none;
  font-size: 12px;
  border-radius: 3px;
  padding: 0 7px;
  cursor: pointer;
  color: whitesmoke;
  &:hover {
    color: white;
  }
`;

//------------------------------------------------------------------------

const ServiceLogsFeedTable = ({
  serviceLogEntries,
  isLoading,
  openModal,
  refetch,
}) => {
  const { data: entities, isFetching: isFetchingEntities } = useFetchEntities();
  const [data, setData] = useState([]);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [isPlateUpdating, setIsPlateUpdating] = useState(false);
  const [isDateUpdating, setIsDateUpdating] = useState(false);
  const [isDriverUpdating, setIsDriverUpdating] = useState(false);
  const [isCostUpdating, setIsCostUpdating] = useState(false);
  const [isKmUpdating, setIsKmUpdating] = useState(false);
  const [isWorkshopUpdating, setIsWorkshopUpdating] = useState(false);
  const [isNotesUpdating, setIsNotesUpdating] = useState(false);
  const [isRepairUpdating, setIsRepairUpdating] = useState(false);

  const { user } = useAxios();
  const userIsAdmin = user.isAdmin
  const userIsPermittedToHandleServiceRecords = user.permissions.handleServiceRecords || userIsAdmin;

  const staff = entities?.data?.staff.map((user) => ({
    value: user._id,
    label: user.name,
  }));

  const vehicles = entities?.data?.vehicles.map((vehicle) => ({
    value: vehicle._id,
    label: vehicle.plate,
  }));

  useEffect(() => {
    if (serviceLogEntries) {
      setData(serviceLogEntries.pages.flatMap((page) => page.data.docs));
    }
  }, [serviceLogEntries]);

  const { mutateAsync: updateServiceLogEntry } = useUpdateServiceLogEntry();
  const { mutateAsync: deleteServiceLogEntry } = useDeleteServiceLogEntry();

  const handleDeleteEntry = async (id) => {
    try {
      await deleteServiceLogEntry(id);
      refetch();
    } catch (error) {
      console.log(error);
      alert("Error deleting entry", error.toString());
    }
  };

  const handleUpdatePlate = async (entryId, vehicle_id) => {
    try {
      setIsPlateUpdating(entryId);

      const updatedEntry = await updateServiceLogEntry({
        id: entryId,
        payload: {
          vehicle_id: vehicle_id,
        },
      });

      const updatedData = data.map((entry) => {
        if (entry._id === entryId) {
          return {
            ...entry,
            vehicle_id: updatedEntry.data.vehicle_id,
          };
        }
        return entry;
      });
      setData(updatedData);

      setIsPlateUpdating(false);
    } catch (error) {
      console.log(error);
      alert("Error updating plate", error.toString());
      setIsPlateUpdating(false);
    } finally {
      setIsPlateUpdating(false);
    }
  };

  const handleUpdateDate = async (entryId, date) => {
    try {
      setIsDateUpdating(entryId);

      const updatedEntry = await updateServiceLogEntry({
        id: entryId,
        payload: {
          date,
        },
      });

      const updatedData = data.map((entry) => {
        if (entry._id === entryId) {
          return {
            ...entry,
            date: updatedEntry.data.date,
          };
        }
        return entry;
      });
      setData(updatedData);

      setIsDateUpdating(false);
    } catch (error) {
      console.log(error);
      alert("Error updating date", error.toString());
      setIsDateUpdating(false);
    } finally {
      setIsDateUpdating(false);
    }
  };

  const handleUpdateDriver = async (entryId, driverId) => {
    try {
      setIsDriverUpdating(entryId);

      const updatedEntry = await updateServiceLogEntry({
        id: entryId,
        payload: {
          assignee: driverId,
        },
      });

      const updatedData = data.map((entry) => {
        if (entry._id === entryId) {
          return {
            ...entry,
            assignee: updatedEntry.data.assignee,
          };
        }
        return entry;
      });
      setData(updatedData);

      setIsDriverUpdating(false);
    } catch (error) {
      console.log(error);
      alert("Error updating driver", error.toString());
      setIsDriverUpdating(false);
    } finally {
      setIsDriverUpdating(false);
    }
  };

  const handleUpdateCost = async (entryId, cost) => {
    try {
      setIsCostUpdating(entryId);

      const updatedEntry = await updateServiceLogEntry({
        id: entryId,
        payload: {
          cost,
        },
      });

      const updatedData = data.map((entry) => {
        if (entry._id === entryId) {
          return {
            ...entry,
            cost: updatedEntry.data.cost,
          };
        }
        return entry;
      });
      setData(updatedData);

      setIsCostUpdating(false);
    } catch (error) {
      console.log(error);
      alert("Error updating cost", error.toString());
      setIsCostUpdating(false);
    } finally {
      setIsCostUpdating(false);
    }
  };

  const handleUpdateKm = async (entryId, km) => {
    try {
      setIsKmUpdating(entryId);

      const updatedEntry = await updateServiceLogEntry({
        id: entryId,
        payload: {
          odometer: km,
        },
      });

      const updatedData = data.map((entry) => {
        if (entry._id === entryId) {
          return {
            ...entry,
            odometer: updatedEntry.data.odometer,
          };
        }
        return entry;
      });
      setData(updatedData);

      setIsKmUpdating(false);
    } catch (error) {
      console.log(error);
      alert("Error updating km", error.toString());
      setIsKmUpdating(false);
    } finally {
      setIsKmUpdating(false);
    }
  };

  const handleUpdateWorkshop = async (entryId, workshop) => {
    try {
      setIsWorkshopUpdating(entryId);

      const updatedEntry = await updateServiceLogEntry({
        id: entryId,
        payload: {
          workshop,
        },
      });

      const updatedData = data.map((entry) => {
        if (entry._id === entryId) {
          return {
            ...entry,
            workshop: updatedEntry.data.workshop,
          };
        }
        return entry;
      });
      setData(updatedData);

      setIsWorkshopUpdating(false);
    } catch (error) {
      console.log(error);
      alert("Error updating workshop", error.toString());
      setIsWorkshopUpdating(false);
    } finally {
      setIsWorkshopUpdating(false);
    }
  };

  const handleUpdateNotes = async (entryId, notes) => {
    try {
      setIsNotesUpdating(entryId);

      const updatedEntry = await updateServiceLogEntry({
        id: entryId,
        payload: {
          notes,
        },
      });

      const updatedData = data.map((entry) => {
        if (entry._id === entryId) {
          return {
            ...entry,
            notes: updatedEntry.data.notes,
          };
        }
        return entry;
      });
      setData(updatedData);

      setIsNotesUpdating(false);
    } catch (error) {
      console.log(error);
      alert("Error updating notes", error.toString());
      setIsNotesUpdating(false);
    } finally {
      setIsNotesUpdating(false);
    }
  };

  const handleRepairUpdate = async (value, entryId, repairIndex) => {
    try {
      setIsRepairUpdating([entryId, repairIndex]);

      const newRepairs = data.find((entry) => entry._id === entryId).repairs;
      newRepairs[repairIndex] = value;

      const updatedEntry = await updateServiceLogEntry({
        id: entryId,
        payload: {
          repairs: newRepairs,
        },
      });

      const updatedData = data.map((entry) => {
        if (entry._id === entryId) {
          return {
            ...entry,
            repairs: updatedEntry.data.repairs,
          };
        }
        return entry;
      });
      setData(updatedData);

      setIsRepairUpdating(false);
    } catch (error) {
      console.log(error);
      alert("Error updating repair", error.toString());
      setIsRepairUpdating(false);
    } finally {
      setIsRepairUpdating(false);
    }
  };

  const handleRepairAdd = async (entryId, repairIndex) => {
    try {
      const newRepairs = data.find((entry) => entry._id === entryId).repairs;
      newRepairs.splice(repairIndex + 1, 0, "REPAIR");

      const updatedEntry = await updateServiceLogEntry({
        id: entryId,
        payload: {
          repairs: newRepairs,
        },
      });

      const updatedData = data.map((entry) => {
        if (entry._id === entryId) {
          return {
            ...entry,
            repairs: updatedEntry.data.repairs,
          };
        }

        return entry;
      });
      setData(updatedData);
    } catch (error) {
      console.log(error);
      alert("Error updating repair", error.toString());
    }
  };

  const handleRepairDelete = async (entryId, repairIndex) => {
    try {
      const newRepairs = data.find((entry) => entry._id === entryId).repairs;
      newRepairs.splice(repairIndex, 1);

      const updatedEntry = await updateServiceLogEntry({
        id: entryId,
        payload: {
          repairs: newRepairs,
        },
      });

      const updatedData = data.map((entry) => {
        if (entry._id === entryId) {
          return {
            ...entry,
            repairs: updatedEntry.data.repairs,
          };
        }

        return entry;
      });
      setData(updatedData);
    } catch (error) {
      console.log(error);
      alert("Error updating repair", error.toString());
    }
  };

  //------------------------------------------------------------------------

  const renderExpandableRow = (row) => {
    const plate_date = [
      {
        name: "PLATE",
        cell: (row) => {
          const vehicle = entities?.data?.vehicles.find(
            (vehicle) => vehicle._id === row.vehicle_id
          );

          return (
            <EditableCellSelect
              data={vehicles}
              shownValue={vehicle._id}
              isLoading={isPlateUpdating === row._id}
              onChange={(value) => {
                handleUpdatePlate(row._id, value);
              }}
            />
          );
        },
      },
      {
        name: "DATE",
        cell: (row) => (
          <EditableCell
            shownValue={moment(row.date).format("DD/MM/YYYY")}
            inputValue={row.date}
            inputType={"date"}
            isLoading={isDateUpdating === row._id}
            onClickOk={(value) => {
              handleUpdateDate(row._id, value);
            }}
          />
        ),
      },
    ];

    const driver_cost = [
      {
        name: "DRIVER",
        // cell: (row) => {
        //   const driver = entities?.data?.staff.find(
        //     (user) => user._id === row.assignee
        //   );
        //   return driver?.name || "-";
        // },
        cell: (row) => {
          const driver = entities?.data?.staff.find(
            (user) => user._id === row.assignee
          );

          return (
            <EditableCellSelect
              data={staff}
              shownValue={driver._id}
              isLoading={isDriverUpdating === row._id}
              onChange={(value) => {
                handleUpdateDriver(row._id, value);
              }}
            />
          );
        },
      },
      {
        name: "COST",
        cell: (row) => (
          <EditableCell
            shownValue={`${row.cost} €`}
            inputValue={row.cost}
            inputType={"text"}
            isLoading={isCostUpdating === row._id}
            onClickOk={(value) => {
              handleUpdateCost(row._id, value);
            }}
          />
        ),
      },
    ];

    const km_workshop = [
      {
        name: "KM",
        cell: (row) => (
          <EditableCell
            shownValue={`${row.odometer} KM`}
            inputValue={row.odometer}
            inputType={"text"}
            isLoading={isKmUpdating === row._id}
            onClickOk={(value) => {
              handleUpdateKm(row._id, value);
            }}
          />
        ),
      },
      {
        name: "WORKSHOP",
        cell: (row) => (
          <EditableCell
            shownValue={row.workshop}
            inputValue={row.workshop}
            upperCase
            inputType={"text"}
            isLoading={isWorkshopUpdating === row._id}
            onClickOk={(value) => {
              handleUpdateWorkshop(row._id, value.toUpperCase());
            }}
          />
        ),
      },
    ];

    const repairsColumns = [
      {
        name: "REPAIRS",
        cell: (row, repairIndex) => {
          const repair = row.replace(/\[(.*?)\]/, "");
          const entryId = row.match(/\[(.*?)\]/)[1];

          return (
            <EditableCell
              shownValue={`#${repairIndex + 1} ${repair}`}
              onClickOk={(value) => {
                handleRepairUpdate(value, entryId, repairIndex);
                console.log(value);
              }}
              onClickAdd={() => {
                handleRepairAdd(entryId, repairIndex);
              }}
              onClickDelete={() => {
                if (window.confirm("Delete this repair?")) {
                  handleRepairDelete(entryId, repairIndex);
                }
              }}
              inputValue={repair}
              maxWidth={250}
              inputType={"text"}
              isLoading={
                isRepairUpdating[0] === entryId &&
                isRepairUpdating[1] === repairIndex
              }
            />
          );
        },
      },
    ];

    const notes = [
      {
        name: "NOTES",
        cell: (row) => (
          <EditableCell
            shownValue={row.notes}
            inputValue={row.notes}
            inputType={"text"}
            isLoading={isNotesUpdating === row._id}
            onClickOk={(value) => {
              handleUpdateNotes(row._id, value);
            }}
          />
        ),
      },
    ];

    return (
      <TableContainer>
        <DataTable
          columns={plate_date}
          dense
          data={[{ ...row.data }]}
          customStyles={{
            headRow: {
              style: {
                fontWeight: "bold",
                textAlign: "center",
                backgroundColor: "whitesmoke",
                border: "none",
                color: "dodgerblue",
              },
            },
            rows: {
              style: {
                background: "whitesmoke",
                border: "none",
              },
            },
          }}
        />
        <DataTable
          columns={driver_cost}
          dense
          data={[
            {
              ...row.data,
            },
          ]}
          customStyles={{
            headRow: {
              style: {
                fontWeight: "bold",
                textAlign: "center",
                backgroundColor: "whitesmoke",
                border: "none",
                color: "dodgerblue",
              },
            },
            rows: {
              style: {
                background: "whitesmoke",
                border: "none",
              },
            },
          }}
        />
        <DataTable
          columns={km_workshop}
          dense
          data={[
            {
              ...row.data,
            },
          ]}
          customStyles={{
            headRow: {
              style: {
                fontWeight: "bold",
                textAlign: "center",
                backgroundColor: "whitesmoke",
                border: "none",
                color: "dodgerblue",
              },
            },
            rows: {
              style: {
                background: "whitesmoke",
                border: "none",
              },
            },
          }}
        />
        <DataTable
          columns={repairsColumns}
          dense
          data={row.data.repairs.map((repair) => `${repair}[${row.data._id}]`)}
          customStyles={{
            headRow: {
              style: {
                fontWeight: "bold",
                textAlign: "center",
                backgroundColor: "whitesmoke",
                border: "none",
                color: "dodgerblue",
              },
            },
            rows: {
              style: {
                background: "whitesmoke",
                border: "none",
              },
            },
          }}
          noDataComponent={
            <ButtonsContainer>
              <AddRepairButton
                onClick={() => {
                  handleRepairAdd(row.data._id, -1);
                }}
              >
                ADD REPAIR
              </AddRepairButton>
            </ButtonsContainer>
          }
        />
        <DataTable
          columns={notes}
          dense
          data={[
            {
              ...row.data,
            },
          ]}
          customStyles={{
            headRow: {
              style: {
                fontWeight: "bold",
                textAlign: "center",
                backgroundColor: "whitesmoke",
                border: "none",
                color: "dodgerblue",
              },
            },
            rows: {
              style: {
                background: "whitesmoke",
                border: "none",
              },
            },
          }}
        />
        <FilesContainer>
          <ServiceLogFiles logId={row.data._id} />
        </FilesContainer>
        <ButtonsContainer
          style={{
            justifyContent: "flex-end",
            //background: "linear-gradient(whitesmoke, rgb(235,235,250))",
          }}
        >
          <DeleteButton
            onClick={() => {
              if (userIsPermittedToHandleServiceRecords) {
                if (window.confirm("Delete this record?")) {
                  handleDeleteEntry(row.data._id);
  }
              } else {
                alert('You do not have permission to handle service records')
              }

            }}
          >
            <i className="fas fa-trash"></i>
          </DeleteButton>
        </ButtonsContainer>
      </TableContainer>
    );
  };

  //------------------------------------------------------------------------

  return (
    <Wrapper>
      <TopRow>
        <span
          style={{
            fontWeight: "bold",
            fontSize: "15px",
            marginLeft: "5px",
          }}
        >
          {"Service Records"}
        </span>

        <OutsideClickHandler
          onOutsideClick={() => {
            setIsPopoverOpen(false);
          }}
        >
          <OverlayTrigger
            placement="left-start"
            show={isPopoverOpen}
            overlay={
              <Popover
                id="popover-basic"
                style={{
                  position: "absolute",
                }}
              >
                <PopoverContainer>
                  <PopoverBtn
                    onClick={() => {
                      if (userIsPermittedToHandleServiceRecords) {
                        openModal();
                        setIsPopoverOpen(false);
                      } else {
                        alert('You do not have permission to handle service records')
                      }
                    }}
                  >
                    Add new Service record
                  </PopoverBtn>
                </PopoverContainer>
              </Popover>
            }
          >
            <MenuButton onClick={() => setIsPopoverOpen(!isPopoverOpen)}>
              <i className="fas fa-ellipsis-v"></i>
            </MenuButton>
          </OverlayTrigger>
        </OutsideClickHandler>
      </TopRow>
      <Container>
        {isLoading && <SpinnerContainer>Loading records...</SpinnerContainer>}
        {!isLoading && !isFetchingEntities && serviceLogEntries && (
          <DataTable
            columns={[
              {
                name: "PLATE",
                cell: (row) => {
                  const vehicle = entities?.data?.vehicles.find(
                    (vehicle) => vehicle._id === row.vehicle_id
                  );
                  return vehicle?.plate || "-";
                },
              },
              {
                name: "DATE",
                selector: (row) => row.date,
                format: (row) => moment(row.date).format("DD/MM/YYYY"),
              },
              {
                name: "WORKSHOP",
                selector: (row) => row.workshop,
              },
              {
                name: "KM",
                selector: (row) => row.odometer,
              },
            ]}
            data={data}
            customStyles={{
              table: {
                style: {
                  border: "none",
                  margin: "0",
                  padding: "0",
                  backgroundColor: "whitesmoke",
                },
              },
              headRow: {
                style: {
                  fontWeight: "bold",
                },
              },
              cells: {
                style: {
                  //textAlign: 'center',
                  //backgroundColor: "whitesmoke",
                },
              },
              subHeader: {
                style: {
                  padding: "0",
                  margin: "0",
                  border: "none",
                  maxHeight: "50px",
                },
              },
            }}
            fixedHeader
            dense
            highlightOnHover
            striped
            expandableRows
            expandableRowsComponent={renderExpandableRow}
            expandOnRowClicked
            responsive
            subHeaderAlign="center"
            noDataComponent={<NoData>There are no records to display</NoData>}
            // selectableRows
            // selectableRowsHighlight
          />
        )}
      </Container>
    </Wrapper>
  );
};

export default ServiceLogsFeedTable;
