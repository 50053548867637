import styled from "styled-components";
import { motion, AnimatePresence } from "framer-motion";
import { useState } from "react";
import MultiSelect from "../../../MultiSelect";

//--------------------------------------------

const Container = styled.div`
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
  font-size: 12px;
  overflow: hidden;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: white;
  padding: 5px;
  border-radius: 3px;
  align-items: center;
  user-select: none;
  cursor: pointer;
  i {
    transform: ${({ isDropdownOpen }) =>
      isDropdownOpen ? "rotateX(180deg)" : "rotateX(0deg)"};
    transition: transform 0.3s ease-in-out;
    font-size: 12px;
    color: black;
  }
  &:hover {
    background-color: ${(props) => props.theme.colors.blue};
    color: white;
    select {
      color: white;
    }
    i {
      color: white;
    }
  }
`;

const ListContainer = styled(motion.div)`
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 3px;
  padding: 5px;
  overflow: hidden;
`;

const MultiSelectContainer = styled.div`
  flex: 1;
  height: 100%;
  max-height: 250px;
  display: flex;
  flex-direction: column;
  overflow: overlay;
  ::-webkit-scrollbar {
    width: 3px;
  }
  ::-webkit-scrollbar-thumb {
    background: #91c9ff;
    /* border-radius: 50px; */
  }
`;

const UncheckAllButton = styled.button`
  border: none; 
  outline: none;  
  background-color: transparent;  
  text-align: center;
  color: ${(props) => props.theme.colors.blue}; 
  font-size: 12px;  
  cursor: pointer;  
  &:hover {
    text-decoration: underline;
  }

`

//--------------------------------------------

const FilterDropdown = ({ header, data, selected, onSet }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  return (
    <Container>
      <Header
        onClick={() => setIsDropdownOpen(!isDropdownOpen)}
        isDropdownOpen={isDropdownOpen}
      >
        <span>{header}</span>
        <i className="fa-solid fa-angle-down"></i>
      </Header>

      <AnimatePresence>

        {isDropdownOpen && (
          <>
            <UncheckAllButton onClick={() => onSet([])}>Uncheck all</UncheckAllButton>
          <ListContainer
            initial={{ opacity: 0, y: -10 }}
            animate={{
              opacity: 1,
              y: 0,
              transition: {
                duration: 0.2,
              },
            }}
            //exit={{ opacity: 0, y: -10 }}
          >
            <MultiSelectContainer>
              <MultiSelect
                data={data}
                selectedData={selected}
                onSelect={onSet}
              />
            </MultiSelectContainer>
          </ListContainer>
          </>
        )}
      </AnimatePresence>
    </Container>
  );
};

export default FilterDropdown;
