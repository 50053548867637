import { useState, useEffect, useContext } from "react";
import ReactDOMServer from "react-dom/server";
import mapboxgl, { LngLat } from "mapbox-gl";
import styled from "styled-components";
import { FleetTrackingContext } from "./FleetTrackingContent";

//----------------------------------------------

const PopupContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  padding: 1px 5px 0 5px;
  //shadow
  //blurred bg with transparency
  background-color: transparent;

  span {
    padding: 0;
    margin: 0;
  }
`;

const PopupItem = styled.div`
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
  i {
    font-size: 12px;
  }
`;

const Popup = ({
  index,
  time,
  name,
}: {
  index: number;
  time: string;
  name: string;
}) => {
  return (
    <PopupContainer>
      <PopupItem
        style={{
          fontWeight: "bold",
        }}
      >
        <i
          style={{
            color: "#1976d1",
          }}
          className="fa-solid fa-hashtag"
        ></i>
        {index + 1}
      </PopupItem>
      <PopupItem>
        <i className="fa-regular fa-clock"></i>
        {time}
      </PopupItem>
      <PopupItem>
        <i className="fa-solid fa-location-dot"></i>
        {name}
      </PopupItem>
    </PopupContainer>
  );
};

//----------------------------------------------

const MeetingPoints = ({ map }: { map: mapboxgl.Map }) => {
  const [markers, setMarkers] = useState<mapboxgl.Marker[]>([]);
  const [lines, setLines] = useState<mapboxgl.Layer[]>([]);
  const { meetingPoints } = useContext(FleetTrackingContext);

  useEffect(() => {
    if (meetingPoints?.pickups.length > 0) {
      // Remove existing markers and lines
      markers.forEach((marker) => marker.remove());
      lines.forEach((line) => map.removeLayer(line.id).removeSource(line.id));
      setMarkers([]);
      setLines([]);

      const newMarkers = [];
      const newLines = [];

      for (let i = 0; i < meetingPoints.pickups.length; i++) {
        const { meeting_point, lat, lon, time } = meetingPoints.pickups[i];
        const latLng = new LngLat(lon, lat);

        const popupToHTML = ReactDOMServer.renderToString(
          <Popup index={i} time={time} name={meeting_point} />
        );

        const newMarker = new mapboxgl.Marker()
          .setLngLat(latLng)
          .addTo(map)
          .setPopup(
            new mapboxgl.Popup({
              closeButton: false,
              closeOnClick: false,
              closeOnMove: false,
              className: "tracking-page-popup-meeting-point",
            }).setHTML(popupToHTML)
          );
       
        newMarkers.push(newMarker);

        // Draw lines between points
        if (i > 0) {
          const prevPoint = meetingPoints.pickups[i - 1];
          const prevLatLng = new LngLat(prevPoint.lon, prevPoint.lat);

          const lineId = `line-${prevPoint.meeting_point}-${meeting_point}`;
          const line = {
            id: lineId,
            type: "line",
            source: {
              type: "geojson",
              data: {
                type: "Feature",
                geometry: {
                  type: "LineString",
                  coordinates: [
                    [prevLatLng.lng, prevLatLng.lat],
                    [latLng.lng, latLng.lat],
                  ],
                },
              },
            },
            layout: {
              "line-join": "round",
              "line-cap": "round",
            },
            paint: {
              "line-color": "#b641ce",
              "line-width": 4,
              "line-dasharray": [1, 2], // Dash 2 units, gap 4 units
            },
          };

          map.addLayer(line as any);
          newLines.push(line);
        }
      }

      setMarkers(newMarkers);
      setLines(newLines as any);
    } else {
      // Clear all markers and lines if no meeting points
      markers.forEach((marker) => marker.remove());
      lines.forEach((line) => map.removeLayer(line.id).removeSource(line.id));
      setMarkers([]);
      setLines([]);
    }
    // eslint-disable-next-line
  }, [meetingPoints]);

  return null;
};

// const MeetingPoints = ({ map }: { map: mapboxgl.Map }) => {
//   const [markers, setMarkers] = useState<mapboxgl.Marker[]>([]);
//   const { meetingPoints } = useContext(FleetTrackingContext);

//   useEffect(() => {
//     if (meetingPoints?.pickups.length > 0) {
//       for (let i = 0; i < meetingPoints?.pickups.length; i++) {
//         const { meeting_point, lat, lon, time } = meetingPoints?.pickups[i];
//         const latLng = new LngLat(lon, lat);

//         const popupToHTML = ReactDOMServer.renderToString(
//           <Popup index={i} time={time} name={meeting_point} />
//         );

//         for (const marker of markers) {
//           if (marker) {
//             marker.remove();
//           }
//         }

//         const newMarker = new mapboxgl.Marker()
//           .setLngLat(latLng)
//           .addTo(map)
//           .setPopup(
//             new mapboxgl.Popup({
//               //offset: 20,
//               closeButton: false,
//               closeOnClick: false,
//               closeOnMove: false,
//               className: "tracking-page-popup-meeting-point",
//             }).setHTML(popupToHTML)
//           );
//         // newMarker.getPopup().addTo(map); //adds it on load
//         //the above but add  Popup component
//         newMarker.togglePopup();
//         setMarkers((prev) => [...prev, newMarker]);
//       }
//     } else {
//       for (const marker of markers) {
//         if (marker) {
//           marker.remove();
//         }
//       }
//     }
//     // eslint-disable-next-line
//   }, [meetingPoints]);

//   return null;
// };

export default MeetingPoints;
