import { useAxios } from "../../../../../axiosProvider";
import { useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import styled from "styled-components";
import { motion } from "framer-motion";
import Toggle from "react-toggle";
import {
  useAddNewUser,
  useUpdateUser,
  useDeleteUser,
  useLogoutUserFromMobileDevices
} from "../../../../../reactQueryHooks";
import BarLoader from "react-spinners/BarLoader";
import { useFetchEntities } from "../../../../../reactQueryHooks";
import SearchableMultiSelect from "./SearchableMultiSelect";
import CustomDropdown from "./CustomDropdown";



//---------------------------------------------------------

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`;

const Modal = styled(motion.div)`
  background-color: white;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  gap: 5px;
  width: 90%;
  max-width: 500px;
  max-height: 90%;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow-y: auto;
`;

const CloseContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`;

const CloseButton = styled.button`
  background-color: whitesmoke;
  border: none;
  color: white;
  height: 30px;
  width: 30px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  i {
    color: black;
    font-size: 15px;
  }
  &:hover {
    i {
      color: indianred;
    }
  }
`;
const RowSContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 5px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
  width: 100%;
  height: 40px;
`;

const ItemContainer = styled.div<{
  isValidated?: boolean;
  isDropdownOpen?: boolean;
}>`
  flex: 1;
  height: 100%;
  border: 1px solid rgb(228, 228, 228);
  border-radius: 4px;
  background-color: ${(props) =>
    props.isValidated ? "#daffda" : "whitesmoke"};
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
  padding: 0 5px;
  font-size: 13px;

  .fa-angle-down {
    transform: ${({ isDropdownOpen }) =>
      isDropdownOpen ? "rotateX(180deg)" : "rotateX(0deg)"};
    transition: transform 0.3s ease-in-out;
    font-size: 12px;
    color: black;
  }
`;

const Input = styled.input`
  flex: 1;
  height: 100%;
  width: 100%;
  background-color: transparent;
  padding: 0 8px;
  font-size: 13px;
  min-width: 0px;
`;

const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  height: 40px;
  border: none;
  border-radius: 4px;
  background-color: #b4b4b4;
  color: white;
  padding: 0 8px;
  cursor: pointer;
  font-size: 13px;
  &:hover {
    filter: brightness(1.1);
  }
  &:focus {
    outline: none;
  }
  .spinner {
    animation: spin 1s linear infinite;
    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
  width: 100%;
`;

const DeviceContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 7px ;
  border-radius: 5px;
  border: 1px solid rgb(228, 228, 228);
  width: 100%;
  background-color: whitesmoke;
  span{
    font-size: 13px;
  }
`

const GeneratePasswordButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 40px;
  border: none;
  border-radius: 4px;
  background-color: whitesmoke;
  color: black;
  border: 1px solid rgb(228, 228, 228);
 
 &:hover {
    filter: brightness(0.9);
  }
  &:focus {
    outline: none;
  }
`

//---------------------------------------------------------

const generateRandomPassword = (): string => {
  const length: number = 15;
  const specialCharacters: string = "_!$@";
  const lowerLetters: string = "abcdefghijklmnopqrstuvwxyz";
  const upperLetters: string = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  const numbers: string = "0123456789";

  // Ensure at least 2 special characters
  let password: string =
    specialCharacters.charAt(Math.floor(Math.random() * specialCharacters.length)) +
    specialCharacters.charAt(Math.floor(Math.random() * specialCharacters.length));

  // Ensure at least 4 lower and 4 upper letters, and 4 numbers
  for (let i: number = 0; i < 4; i++) {
    password +=
      lowerLetters.charAt(Math.floor(Math.random() * lowerLetters.length)) +
      upperLetters.charAt(Math.floor(Math.random() * upperLetters.length)) +
      numbers.charAt(Math.floor(Math.random() * numbers.length));
  }

  // Complete the password with random characters
  for (let i: number = password.length; i < length; i++) {
    const allCharacters: string = lowerLetters + upperLetters + numbers + specialCharacters;
    password += allCharacters.charAt(Math.floor(Math.random() * allCharacters.length));
  }

  // Convert the password to an array to shuffle
  const passwordArray: string[] = password.split('');

  // Shuffle the password characters using Fisher-Yates algorithm
  for (let i: number = passwordArray.length - 1; i > 0; i--) {
    const j: number = Math.floor(Math.random() * (i + 1));
    [passwordArray[i], passwordArray[j]] = [passwordArray[j], passwordArray[i]];
  }

  // Convert the array back to a string
  password = passwordArray.join('');

  // Ensure the password does not start with a special character
  if (specialCharacters.includes(password.charAt(0))) {
    // Swap the first character with a random character from the rest of the password
    const firstCharIndex: number = Math.floor(Math.random() * (password.length - 1)) + 1;
    [passwordArray[0], passwordArray[firstCharIndex]] = [passwordArray[firstCharIndex], passwordArray[0]];
    password = passwordArray.join('');
  }

  return password;
};



const AddUsersModal = ({ close, data }: { close: () => void; data: any }) => {
  const [isGroupsListShown, setIsGroupsListShown] = useState(false);
  const [isRolesListShown, setIsRolesListShown] = useState(false);

  const {
    data: entities,
    refetch,
  }: {
    data: any;
    refetch: () => void;
  } = useFetchEntities();
  const { user } = useAxios();

  const { mutateAsync: addNewUser, isLoading: isAddingNewUser } =
    useAddNewUser();

  const { mutateAsync: updateUser, isLoading: isUpdatingUser } =
    useUpdateUser();

  const { mutateAsync: deleteUser, isLoading: isDeletingUser } =
    useDeleteUser();

      const { mutateAsync: logoutUser, isLoading: isLoggingOutUser } =
    useLogoutUserFromMobileDevices();


  return (
    <Formik
      validateOnMount
      enableReinitialize
      initialValues={{
        name: data?.name || "",
        username: data?.username || "",
        password: data?.password || "",
        id_number: data?.id_number || "",
        afm_number: data?.afm_number || "",
        amka_number: data?.amka_number || "",
        driver_license_number: data?.driver_license_number || "",
        guide_reg_number: data?.guide_reg_number || "",
        email: data?.contact?.email || "",
        tel: data?.contact?.tel || "",
        isAdmin: data?.isAdmin || false,
        isModerator: data?.isModerator || false,
        shouldReceiveAnnouncements: data?.shouldReceiveAnnouncements || false,
        isEmergencyContact: data?.isEmergencyContact || false,
        addNewTasks: data?.permissions?.addNewTasks || false,
        addNewActivities: data?.permissions?.addNewActivities || false,
        addNewStaff: data?.permissions?.addNewStaff || false,
        addNewStaffGroups: data?.permissions?.addNewStaffGroups || false,
        addNewStaffRoles: data?.permissions?.addNewStaffRoles || false,
        addNewVehicles: data?.permissions?.addNewVehicles || false,
        handleRequests: data?.permissions?.handleRequests || false,
        publishAnnouncements: data?.permissions?.publishAnnouncements || false,
        sendOlpEmail: data?.permissions?.sendOlpEmail || false,
        addMeetingPoints: data?.permissions?.addMeetingPoints || false,
        handleBalance: data?.permissions?.handleBalance || false,
        sortBookingsInDayPlanner:
          data?.permissions?.sortBookingsInDayPlanner || false,
        assignSchedule: data?.permissions?.assignSchedule || false,
        assignVehicle: data?.permissions?.assignVehicle || false,
        addMessageDrafts: data?.permissions?.addMessageDrafts || false,
        addNotes: data?.permissions?.addNotes || false,
        uploadFiles: data?.permissions?.uploadFiles || false,
        updateBookings: data?.permissions?.updateBookings || false,
        editGuidePlan: data?.permissions?.editGuidePlan || false,
        handleServiceRecords: data?.permissions?.handleServiceRecords || false,
        hideTourGroups: data?.permissions?.hideTourGroups || false,
        createNewTourGroups: data?.permissions?.createNewTourGroups || false,
        groups: data?.groups || [],
        roles: data?.roles || [],
        loggedDevices: data?.loggedDevices || [],
        mobileLogStatus: data?.mobileLogStatus || false,
      }}
      validationSchema={Yup.object({
        name: Yup.string().required("Name is required"),
        username: Yup.string().required("Username is required"),
        password: data._id
          ? Yup.string()
          : Yup.string().required("Password is required"),
        id_number: Yup.string(),
        afm_number: Yup.string(),
        amka_number: Yup.string(),
        driver_license_number: Yup.string(),
        guide_reg_number: Yup.string(),
        email: Yup.string().email("Invalid email address"),
        tel: Yup.string().required("Tel is required"),
        isAdmin: Yup.boolean(),
        isModerator: Yup.boolean(),
        isEmergencyContact: Yup.boolean(),
      })}
      onSubmit={async (values, { setSubmitting, setFieldValue }) => {
        try {
          const payload = {
            ...values,
            password: values.password || null,
            contact: {
              email: values.email,
              tel: values.tel,
            },
            permissions: {
              addNewTasks: values.addNewTasks,
              addNewActivities: values.addNewActivities,
              addNewStaff: values.addNewStaff,
              addNewStaffGroups: values.addNewStaffGroups,
              addNewStaffRoles: values.addNewStaffRoles,
              addNewVehicles: values.addNewVehicles,
              handleRequests: values.handleRequests,
              publishAnnouncements: values.publishAnnouncements,
              sendOlpEmail: values.sendOlpEmail,
              addMeetingPoints: values.addMeetingPoints,
              addMessageDrafts: values.addMessageDrafts,
              handleBalance: values.handleBalance,
              sortBookingsInDayPlanner: values.sortBookingsInDayPlanner,
              assignSchedule: values.assignSchedule,
              assignVehicle: values.assignVehicle,
              addNotes: values.addNotes,
              uploadFiles: values.uploadFiles,
              updateBookings: values.updateBookings,
              editGuidePlan: values.editGuidePlan,
              handleServiceRecords: values.handleServiceRecords,
              hideTourGroups: values.hideTourGroups,
              createNewTourGroups: values.createNewTourGroups,
            },
          };

          if (data._id) {
            const response = await updateUser({
              id: data._id,
              payload,
            } as any);
            //@ts-ignore
            alert(response?.data);
          } else {
            const response = await addNewUser(payload as any);
            //@ts-ignore
            alert(response?.data);
          }

          refetch();
          close();
        } catch (err) {
          //@ts-ignore
          if (err.response.data) {
            //@ts-ignore
            alert(err.response.data);
          } else {
            //@ts-ignore
            alert(err.toString());
          }
          console.log(err);
        }
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
        resetForm,
        isSubmitting,
        validateForm,
      }) => {
        const groupsArray = entities.data.groups.map((group: any) => {
          return {
            label: group.title,
            value: group._id,
          };
        });
        const rolesArray = entities.data.roles.map((role: any) => {
          return {
            label: role.title,
            value: role._id,
          };
        });

        const userGroups = values.groups.map((group: any) => {
          return {
            label: group.title,
            value: group._id,
          };
        });

        const userRoles = values.roles.map((role: any) => {
          return {
            label: role.title,
            value: role._id,
          };
        });

        return (
          <Wrapper>
            <Modal
              initial={{ opacity: 0, scale: 0 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{
                opacity: { duration: 0.2 },
                scale: {
                  //type: "spring",
                  // stiffness: 300, // Adjust stiffness for more/less bounce
                  // damping: 20, // Adjust damping for faster/slower settle
                  // duration: 0.02,
                },
              }}
            >
              <CloseContainer>
                <CloseButton onClick={close}>
                  <i className="fas fa-times"></i>
                </CloseButton>
              </CloseContainer>
              <ContentContainer>
                <RowSContainer>
                  <Row>
                    <ItemContainer isValidated={values.name ? true : false}>
                      <Input
                        title="Name"
                        placeholder="Name (Required)"
                        name="name"
                        autoComplete="off"
                        autoCorrect="off"
                        autoCapitalize="off"
                        spellCheck="false"
                        value={values.name}
                        onChange={handleChange}
                      />
                    </ItemContainer>
                    <ItemContainer isValidated={values.username ? true : false}>
                      <Input
                        title="Username"
                        placeholder="Username (Required)"
                        name="username"
                        autoComplete="off"
                        autoCorrect="off"
                        autoCapitalize="off"
                        spellCheck="false"
                        value={values.username}
                        onChange={handleChange}
                      />
                    </ItemContainer>
                  </Row>
                  <Row>
                    <ItemContainer isValidated={values.email ? true : false}>
                      <Input
                        title="Email"
                        placeholder="Email"
                        name="email"
                        autoComplete="off"
                        autoCorrect="off"
                        autoCapitalize="off"
                        spellCheck="false"
                        value={values.email}
                        onChange={handleChange}
                      />
                    </ItemContainer>
                    <ItemContainer isValidated={values.tel ? true : false}>
                      <Input
                        title="Tel"
                        placeholder="Tel (Required)"
                        name="tel"
                        autoComplete="off"
                        autoCorrect="off"
                        autoCapitalize="off"
                        spellCheck="false"
                        value={values.tel}
                        onChange={handleChange}
                      />
                    </ItemContainer>
                  </Row>
                  <Row>
                    <ItemContainer
                      isValidated={values.id_number ? true : false}
                    >
                      <Input
                        title="ID Number"
                        placeholder="ID Number"
                        name="id_number"
                        autoComplete="off"
                        autoCorrect="off"
                        autoCapitalize="off"
                        spellCheck="false"
                        value={values.id_number}
                        onChange={handleChange}
                      />
                    </ItemContainer>
                    <ItemContainer
                      isValidated={values.afm_number ? true : false}
                    >
                      <Input
                        title="AFM Number"
                        placeholder={"AFM Num"}
                        name="afm_number"
                        autoComplete="off"
                        autoCorrect="off"
                        autoCapitalize="off"
                        spellCheck="false"
                        value={values.afm_number}
                        onChange={handleChange}
                      />
                    </ItemContainer>
                  </Row>
                  <Row>
                    <ItemContainer
                      isValidated={values.amka_number ? true : false}
                    >
                      <Input
                        title="AMKA Number"
                        placeholder="AMKA Num"
                        name="amka_number"
                        autoComplete="off"
                        autoCorrect="off"
                        autoCapitalize="off"
                        spellCheck="false"
                        value={values.amka_number}
                        onChange={handleChange}
                      />
                    </ItemContainer>
                    <ItemContainer
                      isValidated={values.driver_license_number ? true : false}
                    >
                      <Input
                        title="Driver License Number"
                        placeholder="Driver License Num"
                        name="driver_license_number"
                        autoComplete="off"
                        autoCorrect="off"
                        autoCapitalize="off"
                        spellCheck="false"
                        value={values.driver_license_number}
                        onChange={handleChange}
                      />
                    </ItemContainer>
                    <ItemContainer
                      isValidated={values.guide_reg_number ? true : false}
                    >
                      <Input
                        title="Guide Reg. Number"
                        placeholder={"Guide Reg. Num"}
                        name="guide_reg_number"
                        autoComplete="off"
                        autoCorrect="off"
                        autoCapitalize="off"
                        spellCheck="false"
                        value={values.guide_reg_number}
                        onChange={handleChange}
                      />
                    </ItemContainer>
                  </Row>
                  <Row>
                    <ItemContainer isValidated={values.password ? true : false}>
                      <Input
                        title="Password"
                        placeholder={
                          data._id
                            ? "Password (It will change if provided)"
                            : "Password (Required)"
                        }
                        name="password"
                        autoComplete="off"
                        autoCorrect="off"
                        autoCapitalize="off"
                        spellCheck="false"
                        value={values.password}
                        onChange={handleChange}
                      />
                    </ItemContainer>
                    <GeneratePasswordButton
                      title="Generate password"
                      onClick={() => {
                        const randomPassword = generateRandomPassword();
                        setFieldValue("password", randomPassword);
                      }}
                    >
                      <i className="fa-solid fa-key"></i>
                    </GeneratePasswordButton>
                  </Row>
                  <Row>
                    <CustomDropdown
                      isShown={(isShown) => {
                        setIsGroupsListShown(isShown);
                      }}
                      dropdownContent={
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            height: "100%",
                            width: "100%",
                            padding: "10px",
                            backgroundColor: "white",
                            borderRadius: "5px",
                            boxShadow: "0 0 5px 0 rgba(0, 0, 0, 0.2)",
                            gap: "10px",
                          }}
                        >
                          <SearchableMultiSelect
                            data={groupsArray}
                            selectedData={userGroups}
                            onSelect={(data: any) => {
                              const newGroups = data.map((groupId: any) => {
                                return entities.data.groups.find(
                                  (group: any) => group._id === groupId
                                );
                              });
                              setFieldValue("groups", newGroups);
                            }}
                            searchable
                          />
                        </div>
                      }
                    >
                      <ItemContainer
                        isDropdownOpen={isGroupsListShown}
                        style={{
                          justifyContent: "space-between",
                          cursor: "pointer",
                        }}
                      >
                        <span
                          style={{
                            flex: 1,
                          }}
                        >
                          Select groups
                        </span>
                        <i className="fas fa-angle-down"></i>
                      </ItemContainer>
                    </CustomDropdown>
                    <CustomDropdown
                      isShown={(isShown) => {
                        setIsRolesListShown(isShown);
                      }}
                      dropdownContent={
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            height: "100%",
                            width: "100%",
                            padding: "10px",
                            backgroundColor: "white",
                            borderRadius: "5px",
                            boxShadow: "0 0 5px 0 rgba(0, 0, 0, 0.2)",
                            gap: "10px",
                          }}
                        >
                          <SearchableMultiSelect
                            data={rolesArray}
                            selectedData={userRoles}
                            onSelect={(data: any) => {
                              const newRoles = data.map((roleId: any) => {
                                return entities.data.roles.find(
                                  (role: any) => role._id === roleId
                                );
                              });
                              setFieldValue("roles", newRoles);
                            }}
                            searchable
                          />
                        </div>
                      }
                    >
                      <ItemContainer
                        isDropdownOpen={isRolesListShown}
                        style={{
                          justifyContent: "space-between",
                          cursor: "pointer",
                        }}
                      >
                        <span
                          style={{
                            flex: 1,
                          }}
                        >
                          Select roles
                        </span>
                        <i className="fas fa-angle-down"></i>
                      </ItemContainer>
                    </CustomDropdown>
                  </Row>
                  <Row>
                    <ItemContainer>
                      <Toggle
                        checked={values.shouldReceiveAnnouncements}
                        onChange={(value) => {
                          setFieldValue(
                            "shouldReceiveAnnouncements",
                            value.target.checked
                          );
                        }}
                      />
                      <span>Should receive announcements</span>
                    </ItemContainer>
                  </Row>
                  {user.isAdmin && (
                    <Row>
                      {!values.isModerator && (
                        <ItemContainer>
                          <Toggle
                            checked={values.isAdmin}
                            onChange={(value) => {
                              setFieldValue("isAdmin", value.target.checked);
                              setFieldValue("isModerator", false);
                              // setIsRolesListShown(false);
                              // setIsGroupsListShown(false);
                            }}
                          />
                          <span>Admin</span>
                        </ItemContainer>
                      )}
                      {!values.isAdmin && (
                        <ItemContainer>
                          <Toggle
                            checked={values.isModerator}
                            onChange={(value) => {
                              setFieldValue(
                                "isModerator",
                                value.target.checked
                              );
                              setFieldValue("isAdmin", false);
                              // setIsRolesListShown(false);
                              // setIsGroupsListShown(false);
                            }}
                          />
                          <span>Moderator</span>
                        </ItemContainer>
                      )}

                      {(values.isAdmin || values.isModerator) && (
                        <ItemContainer>
                          <Toggle
                            checked={values.isEmergencyContact}
                            onChange={(value) => {
                              setFieldValue(
                                "isEmergencyContact",
                                value.target.checked
                              );
                            }}
                          />
                          <span>Emergency Contact</span>
                        </ItemContainer>
                      )}
                    </Row>
                  )}
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "5px",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "13px",
                        fontWeight: "bold",
                      }}
                    >
                      Logged devices
                    </span>
                    {values.loggedDevices.length === 0 ||
                    !values.mobileLogStatus ? (
                      <DeviceContainer>
                        <span
                          style={{
                            fontSize: "13px",
                            fontWeight: "bold",
                            color: "indianred",
                          }}
                        >
                          No logged devices
                        </span>
                      </DeviceContainer>
                    ) : (
                      <>
                        {values.loggedDevices.map((device: any) => {
                          return (
                            <DeviceContainer>
                              <span>{device.device}</span>
                            </DeviceContainer>
                          );
                        })}
                        <ButtonWrapper
                          style={{
                            width: "50%",
                          }}
                        >
                          <Button
                            style={{
                              background: "grey",
                            }}
                            onClick={async () => {
                              try {
                                if (
                                  window.confirm(
                                    "Logout user from all devices ?"
                                  )
                                ) {
                                  await logoutUser(data?._id);
                                  setFieldValue("mobileLogStatus", false);
                                }
                              } catch (error) {
                                alert(error?.toString());
                              }
                            }}
                          >
                            {isLoggingOutUser ? (
                              <BarLoader color="#606060" loading={true} />
                            ) : (
                              <span>LOGOUT ALL DEVICES</span>
                            )}
                          </Button>
                        </ButtonWrapper>
                      </>
                    )}
                  </div>
                  {values.isModerator && (
                    <>
                      <span
                        style={{
                          fontSize: "13px",
                          fontWeight: "bold",
                        }}
                      >
                        Permissions
                      </span>
                      <Row>
                        <ItemContainer>
                          <Toggle
                            checked={values.addNewStaff}
                            onChange={(value) => {
                              setFieldValue(
                                "addNewStaff",
                                value.target.checked
                              );
                            }}
                          />
                          <span>Add new users</span>
                        </ItemContainer>
                        <ItemContainer>
                          <Toggle
                            checked={values.addNewActivities}
                            onChange={(value) => {
                              setFieldValue(
                                "addNewActivities",
                                value.target.checked
                              );
                            }}
                          />
                          <span>Add new products</span>
                        </ItemContainer>
                      </Row>
                      <Row>
                        <ItemContainer>
                          <Toggle
                            checked={values.addNewStaffGroups}
                            onChange={(value) => {
                              setFieldValue(
                                "addNewStaffGroups",
                                value.target.checked
                              );
                            }}
                          />
                          <span>Add new user groups</span>
                        </ItemContainer>
                        <ItemContainer>
                          <Toggle
                            checked={values.addNewStaffRoles}
                            onChange={(value) => {
                              setFieldValue(
                                "addNewStaffRoles",
                                value.target.checked
                              );
                            }}
                          />
                          <span>Add new user roles</span>
                        </ItemContainer>
                      </Row>
                      <Row>
                        <ItemContainer>
                          <Toggle
                            checked={values.addNewTasks}
                            onChange={(value) => {
                              setFieldValue(
                                "addNewTasks",
                                value.target.checked
                              );
                            }}
                          />
                          <span>Add new tasks</span>
                        </ItemContainer>
                        <ItemContainer>
                          <Toggle
                            checked={values.addNewVehicles}
                            onChange={(value) => {
                              setFieldValue(
                                "addNewVehicles",
                                value.target.checked
                              );
                            }}
                          />
                          <span>Add new vehicles</span>
                        </ItemContainer>
                      </Row>
                      <Row>
                        <ItemContainer>
                          <Toggle
                            checked={values.handleBalance}
                            onChange={(value) => {
                              setFieldValue(
                                "handleBalance",
                                value.target.checked
                              );
                            }}
                          />
                          <span>Handle user balance</span>
                        </ItemContainer>
                        <ItemContainer>
                          <Toggle
                            checked={values.handleRequests}
                            onChange={(value) => {
                              setFieldValue(
                                "handleRequests",
                                value.target.checked
                              );
                            }}
                          />
                          <span>Handle user requests</span>
                        </ItemContainer>
                      </Row>
                      <Row>
                        <ItemContainer>
                          <Toggle
                            checked={values.publishAnnouncements}
                            onChange={(value) => {
                              setFieldValue(
                                "publishAnnouncements",
                                value.target.checked
                              );
                            }}
                          />
                          <span>Publish announcements</span>
                        </ItemContainer>
                        <ItemContainer>
                          <Toggle
                            checked={values.sendOlpEmail}
                            onChange={(value) => {
                              setFieldValue(
                                "sendOlpEmail",
                                value.target.checked
                              );
                            }}
                          />
                          <span>Send Olp email</span>
                        </ItemContainer>
                      </Row>
                      <Row>
                        <ItemContainer>
                          <Toggle
                            checked={values.addMeetingPoints}
                            onChange={(value) => {
                              setFieldValue(
                                "addMeetingPoints",
                                value.target.checked
                              );
                            }}
                          />
                          <span>Add meeting points</span>
                        </ItemContainer>
                        <ItemContainer>
                          <Toggle
                            checked={values.sortBookingsInDayPlanner}
                            onChange={(value) => {
                              setFieldValue(
                                "sortBookingsInDayPlanner",
                                value.target.checked
                              );
                            }}
                          />
                          <span>Sort bookings</span>
                        </ItemContainer>
                      </Row>
                      <Row>
                        <ItemContainer>
                          <Toggle
                            checked={values.assignSchedule}
                            onChange={(value) => {
                              setFieldValue(
                                "assignSchedule",
                                value.target.checked
                              );
                            }}
                          />
                          <span>Assign schedule</span>
                        </ItemContainer>
                        <ItemContainer>
                          <Toggle
                            checked={values.uploadFiles}
                            onChange={(value) => {
                              setFieldValue(
                                "uploadFiles",
                                value.target.checked
                              );
                            }}
                          />
                          <span>Upload files</span>
                        </ItemContainer>
                      </Row>
                      <Row>
                        <ItemContainer>
                          <Toggle
                            checked={values.assignVehicle}
                            onChange={(value) => {
                              setFieldValue(
                                "assignVehicle",
                                value.target.checked
                              );
                            }}
                          />
                          <span>Assign vehicle</span>
                        </ItemContainer>
                        <ItemContainer>
                          <Toggle
                            checked={values.addNotes}
                            onChange={(value) => {
                              setFieldValue("addNotes", value.target.checked);
                            }}
                          />
                          <span>Add notes</span>
                        </ItemContainer>
                      </Row>
                      <Row>
                        <ItemContainer>
                          <Toggle
                            checked={values.updateBookings}
                            onChange={(value) => {
                              setFieldValue(
                                "updateBookings",
                                value.target.checked
                              );
                            }}
                          />
                          <span>Update bookings</span>
                        </ItemContainer>
                        <ItemContainer>
                          <Toggle
                            checked={values.editGuidePlan}
                            onChange={(value) => {
                              setFieldValue(
                                "editGuidePlan",
                                value.target.checked
                              );
                            }}
                          />
                          <span>Edit guide plan</span>
                        </ItemContainer>
                      </Row>
                      <Row>
                        <ItemContainer>
                          <Toggle
                            checked={values.handleServiceRecords}
                            onChange={(value) => {
                              setFieldValue(
                                "handleServiceRecords",
                                value.target.checked
                              );
                            }}
                          />
                          <span>Handle service records</span>
                        </ItemContainer>
                        <ItemContainer>
                          <Toggle
                            checked={values.addMessageDrafts}
                            onChange={(value) => {
                              setFieldValue(
                                "addMessageDrafts",
                                value.target.checked
                              );
                            }}
                          />
                          <span>Handle message drafts</span>
                        </ItemContainer>
                      </Row>
                      <Row>
                        <ItemContainer>
                          <Toggle
                            checked={values.hideTourGroups}
                            onChange={(value) => {
                              setFieldValue(
                                "hideTourGroups",
                                value.target.checked
                              );
                            }}
                          />
                          <span>Hide tour groups</span>
                        </ItemContainer>
                        <ItemContainer>
                          <Toggle
                            checked={values.createNewTourGroups}
                            onChange={(value) => {
                              setFieldValue(
                                "createNewTourGroups",
                                value.target.checked
                              );
                            }}
                          />
                          <span>Create new tour groups</span>
                        </ItemContainer>
                      </Row>
                    </>
                  )}
                </RowSContainer>
              </ContentContainer>
              <ButtonWrapper>
                <Button
                  style={{
                    backgroundColor: "#6eafbb",
                  }}
                  onClick={() => {
                    resetForm();
                  }}
                >
                  CLEAR
                </Button>
                <Button
                  style={{
                    backgroundColor: "#7ab87d",
                    flex: 4,
                  }}
                  title="Submit"
                  type="submit"
                  onClick={() => {
                    if (Object.keys(errors).length > 0) {
                      validateForm();
                      const errorsString = Object.values(errors).join("\n");
                      alert(errorsString);
                    } else {
                      if (
                        window.confirm(
                          "Submit ? \nPlease make sure all the data is correct"
                        )
                      ) {
                        handleSubmit();
                      }
                    }
                  }}
                >
                  {isAddingNewUser || isUpdatingUser ? (
                    <BarLoader color="#606060" loading={true} />
                  ) : (
                    "SUBMIT"
                  )}
                </Button>
              </ButtonWrapper>
              {data?._id && (
                <ButtonWrapper>
                  <Button
                    onClick={async () => {
                      try {
                        if (
                          window.confirm(
                            "Are you sure you wish to delete this item?"
                          )
                        ) {
                          const response = await deleteUser(data?._id);
                          //@ts-ignore
                          alert(response?.data);
                          refetch();
                          close();
                        }
                      } catch (error) {
                        alert(error?.toString());
                      }
                    }}
                    style={{ backgroundColor: "rgb(185,119,119)" }}
                  >
                    {isDeletingUser ? (
                      <BarLoader color="#606060" loading={true} />
                    ) : (
                      <i className="fas fa-trash"></i>
                    )}
                  </Button>
                </ButtonWrapper>
              )}
            </Modal>
          </Wrapper>
        );
      }}
    </Formik>
  );
};

export default AddUsersModal;
