import styled from 'styled-components';
import SortableTableInfoGroup from './SortableTableInfoGroup';
import SortableTableInfoPax from './SortableTableInfoPax';
import SortableTableInfoRevenue from './SortableTableInfoRevenue';
import SortableTableInfoTask from './SortableTableInfoTask';
import SortableTableInfoNotes from './SortableTableInfoNotes';
import SortableTableInfoFiles from './SortableTableInfoFiles';
import NotesListInSortableTable from './NotesListInSortableTable';
//import FilesListInSortableTable from './FilesListInSortableTable';
import SortableTableInfoAssignees from './SortableTableInfoAssignees';
import SortableTableInfoVehicle from './SortableTableInfoVehicle';
import { motion } from 'framer-motion';
import { useAddOrUpdateNoteInTourGroup } from '../../../../reactQueryHooks';
import { useAxios } from '../../../../axiosProvider';
import { Formik } from "formik";
//-------------------------------------------

const Container = styled.div`
    flex: 1;    
    display: flex;  
    flex-direction: column; 
    justify-content: center;    
    align-items:  flex-start;   
    gap: 6px;
`

const Row = styled(motion.div)`
flex: 1;
width: 100%;
display: flex;
flex-direction: row;
gap: 6px;
flex-wrap: wrap;    
`
//-------------------------------------------

const SortableTableInfo = ({ tourGroup, refetch }) => {
    const { user } = useAxios();

    const { mutateAsync: addOrUpdateNotes } = useAddOrUpdateNoteInTourGroup();

    return (
        <Formik
            enableReinitialize
            initialValues={{
                notes_list: tourGroup?.notes_list || [],
            }}
            onSubmit={(values, { setSubmitting, setFieldValue }) => { }}
        >
            {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
                resetForm,
                isSubmitting,
                validateForm,
            }) => {
                return (
                  <Container>
                    <Row>
                      <SortableTableInfoGroup tourGroup={tourGroup} />
                      <SortableTableInfoPax tourGroup={tourGroup} />
                      <SortableTableInfoRevenue tourGroup={tourGroup} />
                      <SortableTableInfoFiles tourGroup={tourGroup} />
                      <SortableTableInfoNotes
                        add={() => {
                          setFieldValue("notes_list", [
                            ...values.notes_list,
                            {
                              author: user._id,
                              body: "",
                              date: new Date().toISOString(),
                              isEditing: true,
                            },
                          ]);
                        }}
                      />
                      <SortableTableInfoVehicle
                        tourGroup={tourGroup}
                        refetch={refetch}
                      />
                      <SortableTableInfoAssignees
                        tourGroup={tourGroup}
                        refetch={refetch}
                      />
                      <SortableTableInfoTask
                        tourGroup={tourGroup}
                        refetch={refetch}
                      />
                    </Row>
                    {values.notes_list.length > 0 && (
                      <Row
                        //a fade in with a little upwards movement
                        initial={{ opacity: 0, y: 5 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.2 }}
                      >
                        <NotesListInSortableTable
                          tourGroup={{
                            notes_list: values.notes_list,
                          }}
                          onChange={async (notes_list) => {
                            setFieldValue("notes_list", notes_list);
                            await addOrUpdateNotes({
                              id: tourGroup._id,
                              notes: notes_list,
                            });
                            refetch();
                          }}
                        />
                      </Row>
                    )}

                    {/* <FilesListInSortableTable
                      tourGroupId={tourGroup._id}
                    /> */}
                  </Container>
                );
            }}
        </Formik>
    )
};

export default SortableTableInfo;