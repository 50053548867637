import { useContext } from "react";
import styled from "styled-components";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { useState, useEffect, memo } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { AppContext } from "../../../App";

//----------------------------------------------------------

const Wrapper = styled(motion.div)`
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 10px;
  background-color: rgba(255, 255, 255, 0.4);
  border-radius: 5px;
  position: relative;
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
`;

const FieldWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 5px;
  flex-wrap: wrap;
`;

const SortingOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
  border-radius: 5px;
  cursor: grab;
  &:hover {
    i {
      color: #b2d9ff;
    }
  }
`;

const FieldContainer = styled(motion.div)`
  flex: 1;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  background-color: whitesmoke;
  border-radius: 5px;
  min-height: 45px;
  padding: 0 5px;
  color: black;
  &:hover {
    background-color: white;
  }

  .react-datepicker-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    div {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
    }
  }
  input {
    flex: 1;
    height: 100%;
    text-align: center;
    align-self: center;
    background-color: transparent;
    font-family: "Roboto", sans-serif;
    cursor: default;
    //box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.1);
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }
    //placeholder styles
    &::placeholder {
      text-align: center;
    }

    //placeholder styles when input is focused
    &:focus::placeholder {
      color: dodgerblue;
    }
  }

  select {
    flex: 1;
    //max-width: 150px;
    border: none;
    background-color: transparent;
    height: 100%;
    width: 150px;
    font-weight: 500;
    text-align: center;
    &:focus {
      outline: none;
    }
    text-overflow: ellipsis;

    overflow-y: overlay;
    ::-webkit-scrollbar {
      width: 3px;
    }
    ::-webkit-scrollbar-thumb {
      background: #91c9ff;
    }
  }
  .icon {
    font-size: 14px;
    color: #636363;
  }

  .spinner {
    animation: spin 1s linear infinite;
    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
  i {
    color: grey;
  }
  div {
    &:hover {
      i {
        color: black;
      }
    }
  }
`;

const FormContainer = styled.div`
  flex: 1;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const Input = styled.input`
  flex: 1;
  height: 100%;
  text-align: center;
  align-self: center;
  background-color: transparent;
  font-family: "Roboto", sans-serif;
  cursor: default;
  text-transform: capitalize;
`;

const variants = {
  hidden: {
    opacity: 0,
    y: -10,
  },
  visible: {
    opacity: 1,
    y: 0,
  },
  exit: {
    opacity: 0,
    y: -10,
  },
};

//variant to move from right to left
const variants2 = {
  hidden: {
    opacity: 0,
    x: 10,
  },
  visible: {
    opacity: 1,
    x: 0,
  },
  exit: {
    opacity: 0,
    x: 10,
  },
};

//------------------------------------------------------------------

const PickupItem = ({
  pickup,
  setPickup,
  meetingPoints,
  deletePickup,
  isSortingPickups,
}) => {
  const [isPickupLocationFindLoading, setIsPickupLocationFindLoading] =
    useState(false);
  const [shouldShowMeetingPointSelect, setShouldShowMeetingPointSelect] =
    useState(true);
  const [isPickupLocationValid, setIsPickupLocationValid] = useState(false);

  const appContext = useContext(AppContext);
  const { googleInstance } = appContext;

  useEffect(() => {
    if (pickup.meeting_point) {
      setShouldShowMeetingPointSelect(false);
    } else {
      setShouldShowMeetingPointSelect(true);
    }
    if (pickup.meeting_point && pickup.lat && pickup.lon) {
      setIsPickupLocationValid(true);
    } else {
      setIsPickupLocationValid(false);
    }
  }, [pickup.meeting_point, pickup.lat, pickup.lon]);

  // pickups: Yup.array().of(
  //     Yup.object().shape({
  //       meeting_point: Yup.string(),
  //       time: Yup.string(),
  //       details: Yup.string(),
  //       lat: Yup.number(),
  //       lon: Yup.number(),
  //       guests: Yup.array().of(
  //         Yup.object().shape({
  //           name: Yup.string(),
  //           count: Yup.number(),
  //         })
  //       ),
  //     })
  //   ),

  const handleFindPickupLocation = async (address) => {
    try {
      if (!googleInstance) {
        alert("Google Maps API has trouble loading ! Please check logs");
        return;
      }

      setIsPickupLocationFindLoading(true);
      const geocoder = new googleInstance.Geocoder();
      geocoder.geocode({ address }, (results, status) => {
        if (status === "OK") {
          const location = results[0].geometry.location;
          const lat = location.lat();
          const lng = location.lng();
          const formattedAddress = results[0].formatted_address;

          if (
            window.confirm(
              `Location found !

Do you want to add " ${formattedAddress} " as pickup location?
              
By clicking cancel,pickup location will be set to " ${address} "`
            )
          ) {
            setPickup({
              ...pickup,
              meeting_point: formattedAddress,
              lat: lat,
              lon: lng,
            });
          } else {
            setPickup({
              ...pickup,
              meeting_point: address,
              lat: lat,
              lon: lng,
            });
          }

          setIsPickupLocationFindLoading(false);
        } else {
          setIsPickupLocationFindLoading(false);
          alert(
            "Location not found,try adding PO BOX or a more precise address"
          );
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.toString());
    }
  };

  return (
    <AnimatePresence>
      <Wrapper
        variants={variants}
        initial="hidden"
        animate="visible"
        exit="exit"
        style={{
          userSelect: isSortingPickups ? "none" : "auto",
        }}
        id={JSON.stringify(pickup)}
      >
        <SortingOverlay
          style={{
            display: isSortingPickups ? "flex" : "none",
          }}
          className="sorting-overlay"
        >
          <i
            style={{
              fontSize: "30px",
            }}
            className="fa-solid fa-sort"
          ></i>
        </SortingOverlay>
        <Container
          style={{
            userSelect: isSortingPickups ? "none" : "auto",
          }}
          id={JSON.stringify(pickup)}
        >
          <FieldContainer
            style={{
              flex: 0,
              minWidth: "70px",
            }}
            onClick={() => deletePickup()}
          >
            <i
              style={{
                color: "indianred",
                fontSize: "20px",
              }}
              className="fas fa-trash"
            ></i>
          </FieldContainer>
          <FormContainer>
            <FieldWrapper>
              <FieldContainer
                style={{
                  backgroundColor: isPickupLocationValid
                    ? "#bfffbf"
                    : "#ffc9cb",
                }}
              >
                <Input
                  type="text"
                  placeholder="Meeting point"
                  onChange={(e) =>
                    setPickup({
                      ...pickup,
                      meeting_point: e.target.value,
                      lat: null,
                      lon: null,
                    })
                  }
                  value={pickup.meeting_point ? pickup.meeting_point : ""}
                  autoComplete="off"
                  spellCheck="false"
                />
                <div
                  style={{
                    position: "absolute",
                    left: "15px",
                  }}
                >
                  <i
                    style={{
                      fontSize: "11px",
                      color: "#1874ce",
                    }}
                    className="fa-solid fa-location-dot"
                  ></i>
                </div>
                <div
                  style={{
                    position: "absolute",
                    right: "15px",
                    display: pickup.meeting_point ? "flex" : "none",
                  }}
                  onClick={() => {
                    setPickup({
                      ...pickup,
                      meeting_point: "",
                    });
                  }}
                >
                  <i
                    style={{
                      fontSize: "14px",
                    }}
                    className="fa-solid fa-times"
                  ></i>
                </div>
              </FieldContainer>
              {shouldShowMeetingPointSelect && (
                <FieldContainer>
                  <select
                    onChange={(e) => {
                      const meetingPoint = meetingPoints.find(
                        (meetingPoint) => meetingPoint._id === e.target.value
                      );
                      if (e.target.value === "reset") {
                        setPickup({
                          ...pickup,
                          meeting_point: "",
                          lat: null,
                          lon: null,
                        });
                      } else {
                        setPickup({
                          ...pickup,
                          meeting_point: meetingPoint.name,
                          lat: meetingPoint.latitude,
                          lon: meetingPoint.longitude,
                        });
                      }
                    }}
                    value={
                      pickup.meeting_point ? pickup.meeting_point : "reset"
                    }
                  >
                    <option value="reset">Select a meeting point</option>
                    {meetingPoints.map((meetingPoint, index) => (
                      <option value={meetingPoint._id} key={index}>
                        {meetingPoint.name}
                      </option>
                    ))}
                  </select>
                </FieldContainer>
              )}

              {!isPickupLocationValid && !shouldShowMeetingPointSelect && (
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip
                      style={{
                        position: "absolute",
                      }}
                    >
                      Search pickup location
                    </Tooltip>
                  }
                >
                  <FieldContainer
                    variants={variants2}
                    initial="hidden"
                    animate="visible"
                    exit="exit"
                    onClick={() => {
                      if (pickup.meeting_point.length > 9) {
                        handleFindPickupLocation(pickup.meeting_point);
                      } else {
                        alert("Pickup location min 10 characters");
                      }
                    }}
                    style={{
                      flex: 0,
                      minWidth: "100px",
                    }}
                  >
                    {isPickupLocationFindLoading ? (
                      <i
                        style={{
                          fontSize: "13px",
                        }}
                        className="fa-solid fa-circle-notch spinner icon"
                      ></i>
                    ) : (
                      <i className="fa-solid fa-magnifying-glass icon"></i>
                    )}
                  </FieldContainer>
                </OverlayTrigger>
              )}

              <FieldContainer
                style={{
                  flex: 0,
                  minWidth: "100px",
                }}
              >
                <Input
                  type="time"
                  placeholder="Pickup time"
                  name="Pickup time"
                  onChange={(e) => {
                    setPickup({ ...pickup, time: e.target.value });
                  }}
                  value={pickup.time}
                  autoComplete="off"
                  spellCheck="false"
                />
              </FieldContainer>
              <FieldContainer
                style={{
                  flex: 0,
                  minWidth: "100px",
                }}
                onClick={() =>
                  setPickup({
                    ...pickup,
                    guests: [...pickup.guests, { name: "", count: "" }],
                  })
                }
              >
                <i
                  style={{
                    fontSize: "18px",
                    color: "darkgreen",
                  }}
                  className="fas fa-user-plus"
                ></i>
              </FieldContainer>
            </FieldWrapper>
            {pickup.guests.map((guest, guestIndex) => {
              return (
                <FieldWrapper key={guestIndex}>
                  <FieldContainer>
                    <input
                      style={{
                        //camelCase
                        textTransform: "capitalize",
                      }}
                      placeholder="Guest name"
                      type="text"
                      value={guest.name}
                      onChange={(e) => {
                        const newGuests = [...pickup.guests];
                        newGuests[guestIndex].name = e.target.value;
                        setPickup({
                          ...pickup,
                          guests: newGuests,
                        });
                      }}
                    />
                    <div
                      style={{
                        position: "absolute",
                        left: "15px",
                      }}
                    >
                      <i
                        style={{
                          fontSize: "11px",
                          color: "tomato",
                        }}
                        className="fa-solid fa-user"
                      ></i>
                    </div>
                  </FieldContainer>
                  <FieldContainer
                    style={{
                      flex: 0,
                      minWidth: "100px",
                    }}
                  >
                    <input
                      placeholder="Pax"
                      type="number"
                      min={0}
                      max={20}
                      value={guest.count}
                      onChange={(e) => {
                        const newGuests = [...pickup.guests];
                        newGuests[guestIndex].count = e.target.value;
                        setPickup({
                          ...pickup,
                          guests: newGuests,
                        });
                      }}
                    />
                  </FieldContainer>
                  <FieldContainer
                    style={{
                      flex: 0,
                      minWidth: "100px",
                    }}
                    onClick={() => {
                      const isOnlyGuest =
                        guestIndex === 0 && pickup.guests.length === 1;
                      if (!isOnlyGuest) {
                        const newGuests = [...pickup.guests];
                        newGuests.splice(guestIndex, 1);
                        setPickup({
                          ...pickup,
                          guests: newGuests,
                        });
                      }
                    }}
                  >
                    <i
                      style={{
                        fontSize: "18px",
                        color: "indianred",
                      }}
                      className="fas fa-user-minus"
                    ></i>
                  </FieldContainer>
                </FieldWrapper>
              );
            })}
          </FormContainer>
        </Container>
        <FieldContainer>
          <input
            style={{
              textAlign: "left",
            }}
            placeholder="Pickup details"
            onChange={(e) => {
              setPickup({ ...pickup, details: e.target.value });
            }}
            value={pickup.details}
          />
        </FieldContainer>
      </Wrapper>
    </AnimatePresence>
  );
};

export default memo(PickupItem);
