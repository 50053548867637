import moment from "moment";
import { useState, useEffect } from "react";
import styled from "styled-components";
import { useFetchEntities } from "../../../../reactQueryHooks";
import { motion } from "framer-motion";

//---------------------------------------------------------

const Modal = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(100, 100, 100, 0.8);
  border-radius: 5px;
  padding: 5px;
  overflow: hidden;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
`;

const Container = styled(motion.div)`
  border-radius: 5px;
  padding: 5px;
  height: 100%;
  width: 100%;
  max-width: 500px;
  max-height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
  overflow: hidden;
  font-size: 13px;
`;

const ContentContainer = styled.div`
  height: 100%;
  width: 100%;
  overflow-y: auto;
  padding: 0 5px;
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  height: 100%;
  width: 100%;
  padding: 5px;
  background-color: white;
  border-radius: 5px;
`;

const Button = styled.button`
  border: none;
  outline: none;
  flex: 1;
  border-radius: 4px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: lab(100 0 -0.03);
  padding: 0 5px;
`;

const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
  min-height: 35px;
`;

const CloseButton = styled.button`
  border: none;
  color: white;
  height: 35px;
  width: 35px;
  border-radius: 4px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: absolute;
  top: 5px;
  right: 5px;
  i {
    font-size: 20px;
  }
  &:hover {
    i {
      color: indianred;
    }
  }
`;

const TextAreaContainer = styled.div`
  height: 100%;
  width: 100%;
  flex: 1;
  border-radius: 4px;
  background-color: whitesmoke;
`;

const TextArea = styled.textarea`
  background-color: transparent;
  width: 100%;
  height: 100%;
  padding: 8px;
  resize: none;
  font-size: 13px;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.00938em;
  color: rgba(0, 0, 0, 0.87);
  border: none;
  outline: none;
`;

//---------------------------------------------------------

const ProposalModal = ({ close, data }) => {
  const { data: entities } = useFetchEntities();
  const [mailBody, setMailBody] = useState(
    data
      .map((item) => {
        const productIdentifier = entities.data.products.find(
          (product) => product._id === item.product_id
        ).guide_assignment_identifier;
        return `${moment(item.date).format(
          "DD/MM/YY ddd"
        )} - ${productIdentifier} - ${item.time}`;
      })
      .join("\n")
  );
  const [copyEmailText, setCopyEmailText] = useState();

  //---------------------------------------------------

  useEffect(() => {
    if (copyEmailText) {
      navigator.clipboard.writeText(copyEmailText);
    }
  }, [copyEmailText]);

  //-----------------------------------

  return (
    <Modal>
      <CloseButton onClick={close}>
        <i className="fas fa-times"></i>
      </CloseButton>

      <Container
        initial={{ opacity: 0, scale: 0 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{
          opacity: { duration: 0.2 },
          scale: {
            //type: "spring",
            // stiffness: 300, // Adjust stiffness for more/less bounce
            // damping: 20, // Adjust damping for faster/slower settle
            // duration: 0.02,
          },
        }}
      >
        <ContentContainer>
          <FormContainer>
            <TextAreaContainer>
              <TextArea
                autoFocus
                value={mailBody}
                onChange={(e) => setMailBody(e.target.value)}
              />
            </TextAreaContainer>

            <RowContainer>
              <Button
                type="button"
                style={{
                  backgroundColor: "rgb(85,165,85)",
                }}
                onClick={() => {
                  if (mailBody.length === 0) {
                    alert("Email body is empty");
                    return;
                  }

                  setCopyEmailText(mailBody);
                }}
                onMouseLeave={() => setCopyEmailText(null)}
              >
                {copyEmailText ? "Copied !" : "Copy to clipboard"}
              </Button>
            </RowContainer>
          </FormContainer>
        </ContentContainer>
      </Container>
    </Modal>
  );
};

export default ProposalModal;
