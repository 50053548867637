import styled from "styled-components";
import { useFetchEntities } from "../../../../reactQueryHooks";

//----------------------------------------------------------------

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 0 5px;
  border-radius: 10px;
  overflow-y: auto;
`;

const VehicleRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #e6e6e6;
  font-size: 14px;
  i {
    color: #e6e6e6;
  }
  user-select: none;
  &:hover {
    .fa-chevron-right {
      color: grey;
    }
    .fa-van-shuttle {
      color: grey;
    }
    border-bottom: 1px solid rgb(193, 190, 190);
  }
  cursor: pointer;
`;

const IconAndTextContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  i {
    color: rgb(181, 179, 179);
  }
`;

const SpinnerContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  align-self: center;
  height: 100%;
  background-color: read-only;
  i {
    color: #e6e6e6;
    font-size: 50px;
    animation: spin 2s linear infinite;
  }
`;

//----------------------------------------------------------------

const VehiclesListPage = ({ setVehicleId, setPage }) => {
  const {
    data: entities,
    isFetching: isFetchingEntities,
    isRefetching: isRefetchingEntities,
  } = useFetchEntities();
  const vehicles = entities?.data?.vehicles;

  const isLoading = isFetchingEntities || isRefetchingEntities;

  return (
    <Container>
      {isLoading ? (
        <SpinnerContainer>Loading vehicles...</SpinnerContainer>
      ) : (
        vehicles?.map((vehicle, index) => {
          return (
            <VehicleRow
              key={index}
              onClick={() => {
                setVehicleId(vehicle._id);
                setPage(2);
              }}
            >
              <IconAndTextContainer>
                <i className="fas fa-van-shuttle"></i>
                <div>{vehicle?.plate}</div>
              </IconAndTextContainer>
              <i className="fas fa-chevron-right"></i>
            </VehicleRow>
          );
        })
      )}
    </Container>
  );
};

export default VehiclesListPage;
