import { ContentItem, ContentItemContent, ContentItemTitle } from './styled';

//-------------------------------------------

const SortableTableInfoGroup = ({ tourGroup }) => {


    return (
        <ContentItem
            title='Group index'
            //a fade in with a little left to right movement
            initial={{ opacity: 0, x: -5 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: -5 }}
            transition={{ duration: 0.25, delay: 0.1 }}
        >
            <ContentItemTitle>Group</ContentItemTitle>
            <ContentItemContent>
                <i className="fa-solid fa-hashtag"></i>
                <span style={{
                    fontWeight: "bold",
                    color: "black",
                }}>{tourGroup.index}</span>
            </ContentItemContent>
        </ContentItem>
    )
};

export default SortableTableInfoGroup;